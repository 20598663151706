<div class="main fx-col">
    <div class="header-container">
        <div class="filter">
            <chq-dropdown [dropdownModel]="dropdownModelFilter"  (clicked)="hideDropdown(dropdownModelFilter.name);" #filter (selected)="selectFilter($event)">
            </chq-dropdown>
            <chq-dropdown *ngIf="input.status" #status  (clicked)="hideDropdown(dropdownModelStatus.name);" [dropdownModel]="dropdownModelStatus" (selected)="selectFilter($event)">
            </chq-dropdown>
            <chq-dropdown *ngIf="input.make"  (clicked)="hideDropdown(dropdownModelMake.name);" #make [dropdownModel]="dropdownModelMake" (selected)="selectFilter($event)">
            </chq-dropdown>
            <chq-dropdown *ngIf="input.provider" #provider (clicked)="hideDropdown(dropdownModelWorkProvider.name);"  [dropdownModel]="dropdownModelWorkProvider"
                (selected)="selectFilter($event)">
            </chq-dropdown>
            <chq-dropdown *ngIf="input.org" #org (clicked)="hideDropdown(dropdownModelOrganization.name);" [dropdownModel]="dropdownModelOrganization"
                (selected)="selectFilter($event)">
            </chq-dropdown>
            <chq-dropdown class="user-dropdown" *ngIf="input.user" #user (clicked)="hideDropdown(dropdownModelUsers.name);"  [dropdownModel]="dropdownModelUsers" (selected)="selectFilter($event)">
            </chq-dropdown>
            <chq-input *ngIf="input.date" [inputModel]="creationStartDateTextBox" (controlOutput)="updateInput($event)" class="date-filter">
            </chq-input>
            <chq-input *ngIf="input.date" [inputModel]="creationEndDateTextBox" class="date-filter"
                (controlOutput)="updateInput($event)"></chq-input>
            <chq-button [buttonModel]="applyButtonModel" >Apply</chq-button>
            <chq-button [buttonModel]="resetButtonModel" >Reset All</chq-button>
            <chq-button *ngIf="isBodyshopOrServiceAdvisor" [buttonModel]="exportButtonModel">Export</chq-button>
            <a class="d-none" #exportTag [download]='downloadFileName' [href]="exportUrl"></a>
        </div>

    </div>

    <div class="table-container">
        <div class="table-wrapper">
            <xa-table [config]="tableConfig" [tableData]="tableData" [cellClicked]="handleCellClick"
            [selectAllRows]="selectAllRows" [sortChanged]="handleSortChange"
            [pageChanged]="pageChanged" [i18n]="i18n"></xa-table>
        </div>
        <div class="pagination-wrapper" *ngIf="tableData?.length > 0 && (pagination.count > pagination.pageSize)">
            <xa-paginator #paginator (paginationChange)="handlePaginationChange($event)"
                [pagination]="pagination">
            </xa-paginator>
        </div>        
    </div>
    <div class="footer-full-wrapper">
        <div class="footer-image-wrapper">
            <div class="image-wrapper">
                <img src="assets/img/addenda-quote.png" />
            </div>
        </div>
    </div>
</div>