import { Component, Input } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'xa-error',
  templateUrl: './xa-error.component.html',
  styleUrls: [ './xa-error.component.scss' ]
})
export class XaError {
  @Input() formGroup?: FormGroup;
  @Input() controlName?: string;
  @Input() label?: string;

  /**
    Constructor for the PaymentListComponent.
    @constructor
    @param {MonitorService} monitorService - Service for monitoring operations.
    @param {PaymentService} paymentService - Service for payment.
    @param {CommonService} commonService - An instance of the CommonService class.
  */
  constructor(
      private translateService: TranslateService,
  ) {}

  /**
   *
   * @param formGroup
   * @param fieldId
   * @param label
   * @returns
   */
  getErrorMessage(formGroup: FormGroup, fieldId: string, label: string): string {
    const field = formGroup.get(fieldId);
    const errors: ValidationErrors = field?.errors;
    if(field.dirty || field.touched) {
      if (field.hasError('required')) {
        return errors['required'] && typeof errors['required'] === 'string' ? errors['required'] : `${this.translateService.instant(label)} is required`;
      } else if (field.hasError('pattern')) {
        return errors['pattern'] && typeof errors['pattern'] === 'string' ? errors['pattern'] : `${this.translateService.instant(label)} has invalid characters`;
      }
    }
    return '';
  }

}
