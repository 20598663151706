import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Observable, forkJoin, firstValueFrom, catchError, of, Subscription } from 'rxjs';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { ChqWidgetsDropdownModel } from 'src/app/model/chq-widgets-dropdown-model';
import { ChqWidgetsInputModel, IControlValue } from 'src/app/model/chq-widgets-input-model';
import { CaseService } from 'src/app/services/case/case.service';
import { CommonService, Localization } from 'src/app/services/common/common.service';
import { CellClickedCallback, SortChangedCallback, TableCellData, TableConfig, TableRowData, CellSelectCallback } from 'src/app/widgets/xa-table/xa-table.component';
import { ApiPagination, defaultPagination, PaginateInfo } from 'src/app/widgets/xa-paginator/xa-paginator.component';
import * as dayjs from 'dayjs'
import { VehicleService } from 'src/app/services/vehicle/vehicle.service';
import { NavigationStart, Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';
import { InspectionService } from 'src/app/services/inspection/inspection.service';
import { ChqDropdownComponent } from 'src/app/widgets/chq-dropdown/chq-dropdown.component';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { CasesTableConfig, ExternalCasesTableConfig } from 'src/app/config/display/cases-table-config';
import { menuItem, menuItems } from 'src/app/model/menu';
import { ConfigurationService } from 'src/app/config/configuration/service/configuration.service';
  
const caseStatusMapper = {
  'draft': 'quote/case/{{caseGUID}}',
  'vehicledetails': 'quote/case/{{caseGUID}}/vehicle',
  'uploaddocuments': 'quote/case/{{caseGUID}}/document',
  'uploadphotos': 'quote/case/{{caseGUID}}/photos',
  'aianalysis': 'quote/case/{{caseGUID}}/ai',
  'repairestimation': 'quote/case/{{caseGUID}}/estimate',
  'approved': 'quote/case/{{caseGUID}}/estimate',
  'reject': 'quote/case/{{caseGUID}}/estimate',
  'rejected': 'quote/case/{{caseGUID}}/estimate',
  'closed': 'quote/case/{{caseGUID}}/estimate',
  'complete': 'quote/case/{{caseGUID}}/estimate',
  'pendingapproval': 'quote/case/{{caseGUID}}/estimate'
}

@Component({
  selector: 'chq-cases-table',
  templateUrl: './chq-cases-table.component.html',
  styleUrls: [ './chq-cases-table.component.scss' ]
})

export class ChqCasesTableComponent implements OnInit, OnDestroy {

  public tableConfig: TableConfig;
  public tableData: any;
  public pagination: ApiPagination = defaultPagination;
  public showDeleteButton: boolean = false;
  public statuses: string = '';
  public pageChanged: boolean = false;
  public workProviderList: Array<any>;
  public makeList: Array<any>;
  public statusList: Array<any>;
  public createdByUsersList: Array<any>;
  public organizationList: Array<any>;
  public exportUrl:string = '';
  public downloadFileName:string = 'Case List.csv';

  @ViewChild('filter') filter: ChqDropdownComponent;
  @ViewChild('status') status: ChqDropdownComponent;
  @ViewChild('make') make: ChqDropdownComponent;
  @ViewChild('provider') provider: ChqDropdownComponent;
  @ViewChild('org') org: ChqDropdownComponent;
  @ViewChild('user') user: ChqDropdownComponent;
  @ViewChild('exportTag') exportTag:ElementRef;

  public input: any = { status: false, make: false, date: false, provider: false, org: false, user: false };

  public deleteButtonModel: ChqWidgetsButtonModel = {
    label: 'Delete',
    type: 'red',
    icon: 'delete'
  }

  public applyButtonModel: ChqWidgetsButtonModel = {
    label: 'Apply',
    type: 'primary',
    icon: ''
  }

  public resetButtonModel: ChqWidgetsButtonModel = {
    label: 'Reset All',
    type: 'outline',
    icon: ''
  }

  public exportButtonModel: ChqWidgetsButtonModel = {
    label: 'Export',
    type: 'outline',
    icon: ''
  }

  public dropdownModelFilter: ChqWidgetsDropdownModel = {
    options: [ { label: 'Your cases' }, { label: 'Creation Date' }, { label: 'Status' }, { label: 'Vehicle Make' }, { label: 'Work Provider' }
      , { label: 'Organization' }, { label: 'Created By' } ],
    placeHolder: 'Filter',
    label: 'Filter',
    name: 'filterDropdown',
    displayValue: 'label',
    selectedOption: { label: 'Your cases' }
  };

  public dropdownModelStatus: ChqWidgetsDropdownModel = {
    options: [],
    placeHolder: 'Select Status',
    label: 'Select Status',
    name: 'statusDropdown',
    displayValue: 'description',
    selectedOption: [],
    isMultiSelect: true
  };

  public dropdownModelMake: ChqWidgetsDropdownModel = {
    options: [],
    placeHolder: 'Select Make',
    label: 'Select Make',
    name: 'makeDropdown',
    displayValue: 'name',
    selectedOption: [],
    isMultiSelect: true
  };

  public dropdownModelWorkProvider: ChqWidgetsDropdownModel = {
    options: [],
    placeHolder: 'Type',
    label: 'Type',
    name: 'providerDropdown',
    displayValue: 'name',
    selectedOption: [],
    isMultiSelect: true
  };

  public dropdownModelOrganization: ChqWidgetsDropdownModel = {
    options: [],
    placeHolder: 'Type',
    label: 'Type',
    name: 'orgDropdown',
    displayValue: 'name',
    selectedOption: [],
    isMultiSelect: true
  }

  public dropdownModelUsers: ChqWidgetsDropdownModel = {
    options: [],
    placeHolder: 'By',
    label: 'By',
    name: 'usersDropdown',
    displayValue: 'name',
    selectedOption: [],
    isMultiSelect: true
  }

  public creationStartDateTextBox: ChqWidgetsInputModel = {
    placeHolder: 'From Date',
    label: 'From Date',
    name: 'fromDate',
    value: dayjs().subtract(1, 'month').toISOString(),
    maxDate: dayjs().toDate(),
    minDate: new Date('2023-01-01'),
    type: 'date'
  }

  public creationEndDateTextBox: ChqWidgetsInputModel = {
    placeHolder: 'To Date',
    label: 'To Date',
    name: 'toDate',
    value: dayjs().toISOString(),
    minDate: dayjs().subtract(1, 'month').toDate(),
    maxDate: dayjs().toDate(),
    type: 'date'
  }

  private selectedCases: Array<string> = [];
  private queryParams: string = '';
  private prevSort: string = '';
  public static currentPage: number = 1;
  public static filterDetail: any = [];  
  private configData: any = CasesTableConfig;

  i18n: Localization;

  subscription: Subscription;
  isExternal: boolean = false;
  public isBodyshopOrServiceAdvisor = false;
  menuItems: menuItem[] = menuItems;

  /**
   * Constructor
   * @param caseService 
   */
  constructor(
    private caseService: CaseService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private monitorService:MonitorService,
    private commonService: CommonService,
    public vehicleService: VehicleService,
    private inspectionService:InspectionService,
    private configurationService:ConfigurationService,
    public userService: UserService) {
    router.events?.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate') {
          this.monitorService.logEvent('popstate', [ 'ChqCasesTableComponent', 'addenda-quote' ]);
          const pageNumber = parseInt(sessionStorage.getItem('pagenumber'));
          ChqCasesTableComponent.currentPage = isNaN(pageNumber) ? 1 : pageNumber;
        }
      }
    });
    this.i18n = this.commonService.geti18nInfo();

    this.subscription = this.commonService.userProfileData.subscribe({
      next: (res: any) => {
        if (res) {
          const automotiveServices = res?.data?.userPermission?.automotiveServices;
          const permissionList = automotiveServices.find(x => x.automotiveServiceName.toLowerCase() === 'carheal quote')?.permissions;
          const quoteMenus = this.menuItems.find(x => x.name === 'quote')?.childMenu;
	        const roles = res?.data?.roleDetail;
          const isBodyShopOrSA = roles.filter((service)=>{
            return service.name.toLowerCase() === 'bodyshop manager' || service.name.toLowerCase() === 'service advisor';
          }).length > 0;
          if(isBodyShopOrSA){
            this.isBodyshopOrServiceAdvisor = true;
          }
          if (quoteMenus && quoteMenus?.length > 0 && permissionList && permissionList?.length > 0) {
            this.isExternal = this.commonService.hasPermission('case.external', permissionList);
          }
        }
    
      }
    });
  }

  /**
   * filter hide
   */
  hideDropdown(name:string):void{
    if(name === 'filterDropdown'){
      if(this.status){
        this.status.closeDropdown();
      }
      
      if(this.make){
        this.make.closeDropdown();
      }

      if(this.provider){
        this.provider.closeDropdown();
      }

      if(this.org){
        this.org.closeDropdown();
      }

      if(this.user){
        this.user.closeDropdown();
      }

    }else{
      this.filter.closeDropdown();
    }
  }

  /**
   * ngoninit
   */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'ChqCasesTableComponent', 'addenda-quote' ]);
    this.commonService.showLoading();
    this.tableConfig = this.configData;
    if(this.isExternal) this.tableConfig = ExternalCasesTableConfig;
    this.applyButtonModel.onclick = this.filterCases.bind(this);
    this.resetButtonModel.onclick = this.resetFilter.bind(this);
    this.exportButtonModel.onclick = this.export.bind(this);

    if (ChqCasesTableComponent.filterDetail['mainFilter']) {
      this.pagination = ChqCasesTableComponent.filterDetail['pagination'];
      const pagination: ApiPagination = {
        pageSize: this.pagination?.pageSize,
        offset: this.pagination?.pageSize * (ChqCasesTableComponent.currentPage - 1),
        currentPage: ChqCasesTableComponent.currentPage
      };
  
      this.pagination.currentPage = pagination?.currentPage ?? 1;
      this.pagination = { ...this.pagination, ...pagination };
      this.displayFilter();
      return;
    }

    this.getTotalCaseCount().subscribe((count) => {
      const currentPage = ChqCasesTableComponent.currentPage ?? 1;
      this.pagination = {
        ...this.pagination, ...{
          count: count,
          currentPage: currentPage,
          orderBy: 'CreatedDate',
          sortDirection: 'desc'
        }
      };

      const paginationInfo: PaginateInfo = {
        currentPage: currentPage,
        offset: this.pagination.pageSize * (currentPage - 1),
        pageSize: this.pagination.pageSize
      }
      this.handlePaginationChange(paginationInfo);
    });
  }

  /**
   * ngAfterViewInit hook
   * @param fb FormBuilder 
   */
  ngAfterViewInit(): void {
    this.cd.detectChanges();
    this.monitorService.logEvent('ngAfterViewInit', [ 'ChqCasesTableComponent', 'addenda-quote' ]);

  }


  /**
   * get case Details and statuses
   * @param row 
   * @param column 
   * @param event 
   */
  goToCaseDetailPage(caseGuid:string, caseStatus:string, inspections:any[], caseId:number, orgId: number):void {
    const route = caseStatusMapper[caseStatus.toLowerCase()]
    const extraParam = {};
    this.monitorService.logEvent('goToCaseDetailPage', [ 'ChqCasesTableComponent', 'addenda-quote', {
      caseGuid,
      caseStatus
    } ]);

    const stepData = this.configurationService.getStepByDomainId(2100)?.sort((a, b) => a.SortOrder - b.SortOrder);
    const photoStep = stepData.find((step) =>{ return step.ItemKey === 'Addenda_Quote_Photos'});

    if (route?.indexOf('/estimate') !== -1 && photoStep) {
      //extraParam['skipUpdate'] = true;
      //extraParam['fromList'] = true;
      this.getInspectionItems(caseId, route, caseGuid, extraParam, orgId);
    }else{
      sessionStorage.setItem('pagenumber', this.pagination.currentPage.toString());
      sessionStorage.setItem('filter', ChqCasesTableComponent.filterDetail);
      this.router.navigate([ route.replace('{{caseGUID}}', caseGuid) ], { queryParams: { ...extraParam } });
    }
    
  }

  /**
   * map inspection from api
   * @param inspectionData 
   */
  mapInspection(inspectionData: any, route:string, caseGuid:string, extraParam:any, minBulkCount:number):void {
    if(inspectionData) {
      try {        
        const inspection = inspectionData;
        const bulkUploadedItemsWithImages = inspection.inspectionItems
          .filter((step) => {
            return step.rawAzureBlobUrl != null && step.rawAzureBlobUrl != '';
          });
        sessionStorage.setItem('pagenumber', this.pagination.currentPage.toString());
        sessionStorage.setItem('filter', ChqCasesTableComponent.filterDetail);
        const requiredStepWOImage = 
        inspection.inspectionItems.find((x: any) => Object.prototype.hasOwnProperty.call(x, 'isSkipEnabled') && !x.isSkipEnabled && x.url == '');
        if((bulkUploadedItemsWithImages.length < minBulkCount && inspection.uploadType ==='Bulk')){
          const numbertoDisplay = minBulkCount;
          this.monitorService.logEvent('mapInspection', [ 'ChqCasesTableComponent', 'addenda-quote', {
            caseGuid,
            message: `All ${numbertoDisplay} sides images are mandatory and cannot skip to review section.`
          } ]);
          this.commonService.showToast(0, `All ${numbertoDisplay} sides images are mandatory and cannot skip to review section.`);
          this.router.navigate([ caseStatusMapper['uploadphotos'].replace('{{caseGUID}}', caseGuid) ]);
        }else if(requiredStepWOImage){
          const numbertoDisplay = inspection.inspectionItems.filter((x: any) => 
            Object.prototype.hasOwnProperty.call(x, 'isSkipEnabled') && !x.isSkipEnabled && x.url == '').length;
          this.monitorService.logEvent('mapInspection', [ 'ChqCasesTableComponent', 'addenda-quote', {
            caseGuid,
            message: `All ${numbertoDisplay} sides images are mandatory and cannot skip to review section.`
          } ]);
          this.commonService.showToast(0, `All ${numbertoDisplay} sides images are mandatory and cannot skip to review section.`);
          this.router.navigate([ caseStatusMapper['uploadphotos'].replace('{{caseGUID}}', caseGuid) ]);
        }else{
          this.monitorService.logEvent('mapInspection', [ 'ChqCasesTableComponent', 'addenda-quote', {
            caseGuid,
            message: 'Navigated successfully'
          } ]);
          this.router.navigate([ route.replace('{{caseGUID}}', caseGuid) ], { queryParams: { ...extraParam } });
        }
      } catch (error: any) {
        this.monitorService.logEvent('mapInspection', [ 'ChqCasesTableComponent', 'addenda-quote', {
          caseGuid,
          error
        } ]);
        console.log(error);
      }
    }
  }


  /**
   * get current inspectionitems length
   */
  getInspectionItems(id, route:string, caseGuid:string, extraParam:any, orgId:number):void{
    this.commonService.showLoading();
    const configObj = forkJoin({
      templateList: this.inspectionService.getInspectionTemplateList(),
      inspectionIdList: this.caseService.getInspectionIdFromList(id, orgId)
    }).pipe(
      catchError(error => of(error))
    );

    configObj.subscribe({
      next: (configResultMap:any)=>{
        if(configResultMap.inspectionIdList && configResultMap.templateList){
          const caseResult = configResultMap.inspectionIdList;
          const caseData = caseResult?.value[0];
          const inspections = caseData?.Inspections[0];
          const templateListData = configResultMap.templateList;
          let inspectionTemplateId = 0;
          const repairInspectionTemplate = templateListData?.data;
        
          if(repairInspectionTemplate.length > 0){
            inspectionTemplateId = repairInspectionTemplate[0].id
          }
          const serverDetails = forkJoin({
            inspectionTemplate: this.inspectionService.getInspectionTemplate(inspectionTemplateId),
            inspectionDetail: this.inspectionService.getInspection(inspections.InspectionId)
          }).pipe(
            catchError(error => of(error))
          )
          serverDetails.subscribe({
            next: (mapResponse: any) => {
              if(mapResponse){
                if (mapResponse.inspectionDetail) {
                  const minCount = mapResponse.inspectionTemplate.minImageLimit;
                  this.mapInspection(mapResponse.inspectionDetail, route, caseGuid, extraParam, minCount);
                } 
              }
              this.commonService.hideLoading();
            },
            complete: () => {
              this.commonService.hideLoading();
            },
          });
        }else{
          this.commonService.hideLoading();
        }
        
      },
      error: (err:any)=>{
        this.commonService.hideLoading();
      }
    })
  }

  handleCellClick: CellClickedCallback = (row: TableRowData, column: Partial<TableCellData>, event: PointerEvent): void => {
    const target = event.target as HTMLInputElement
    const caseId = row.find(c => c.id === 'caseGuid')?.result.value;
    const status = row.find(c => c.id === 'Status')?.result.value;

    if (column.id === 'delete' && status?.toLowerCase() === 'draft') {
      this.deleteCase(caseId);
      return;
    }

    this.monitorService.logEvent('mapInspection', [ 'ChqCasesTableComponent', 'addenda-quote', {
      target
    } ]);
    
    if (target.checked) {
      this.selectedCases = [ ...this.selectedCases, caseId ];
    } else if (target.nodeName !== 'INPUT') { 
      const status = row.find(c => c.id === 'Status')?.result.value;
      const currentCase = this.tableData?.filter(q => q.CaseGuid === caseId)[0];
      const inspection = currentCase?.Inspections
      const id = currentCase?.Id;
      const orgId = currentCase?.OrgId;
      const statusOnly = status?.split('$#$')[0].replace(' ', '');
      if((!currentCase?.RegistrationDate || !currentCase?.ModelYearRange) && statusOnly?.toLowerCase() !== 'draft' && this.isExternal) {
        this.goToCaseDetailPage(caseId, 'vehicledetails', inspection, id, orgId);
        return;
      }
      this.goToCaseDetailPage(caseId, statusOnly, inspection, id, orgId);
    } else {
      const index = this.selectedCases.findIndex(d => d === caseId);
      this.selectedCases.splice(index, 1);
    }

    if (this.tableData?.filter(q => this.selectedCases.includes(q.CaseGuid) && q.Status.toLowerCase() === 'draft').length > 0) {
      this.showDeleteButton = true;
    } else {
      this.showDeleteButton = false;
    }
  };

  handleSortChange: SortChangedCallback = (sort: Sort): void => {
    if (sort) {
      this.pagination = { ...this.pagination, ...{ orderBy: sort.active, sortDirection: sort.direction } };
      this.commonService.showLoading();
      this.getCases();
    }
  }

  /**
   * handlePaginationChange
   * @param event
   */
  handlePaginationChange(event: PaginateInfo): void {
    const pagination: ApiPagination = {
      pageSize: event.pageSize,
      offset: event.offset,
      currentPage: event.currentPage
    };

    this.pagination.currentPage = event.currentPage;
    this.pagination = { ...this.pagination, ...pagination };

    this.pageChanged = true;
    this.showDeleteButton = false;
    this.commonService.showLoading();
    this.getCases();
  }

  /**
   * select all the checkboxes 
   */
  selectAllRows: CellSelectCallback = (selectAll: boolean): void => {
    this.selectedCases = [];
    if (!selectAll) {
      this.showDeleteButton = false;
      return;
    }

    this.monitorService.logEvent('selectAllRows', [ 'ChqCasesTableComponent', 'addenda-quote', {
      selectAll
    } ]);

    this.selectedCases = this.tableData.filter(x => x.Status.toLowerCase() == 'draft').map((data) => { return data.CaseGuid });

    if (this.selectedCases.length > 0) {
      this.showDeleteButton = true;
    } else {
      this.showDeleteButton = false;
    }
  }

  /**
   * get cases 
   */
  public async getCases(): Promise<any> {
    return firstValueFrom(this.caseService.getCases(this.pagination, this.queryParams)).then((response) => {
      this.tableData = response.value.map(function (x) {
        return { ...x, IntegrationStatus: x.StatusDescription + '$#$' + (x.IntegrationStatus || '-') };
      });
      this.commonService.hideLoading();
    }).catch(() => {
      this.applyButtonModel.type = 'primary';
      this.commonService.hideLoading();
    });
  }

  /**
   * calculates total cases
   * @returns Observable
   */
  private getTotalCaseCount(queryParams: string = ''): Observable<number> {
    this.commonService.showLoading();
    return this.caseService.getTotalCountCase(queryParams);
  }

  /**
   * 
   * @param year 
   * @returns 
   */
  private daysInYear(year):number {
    return ((year % 4 === 0 && year % 100 > 0) || year %400 == 0) ? 366 : 365;
  }

  /**
   * export body generator
   */
  exportBodyGenerator():any{
    const selectedFilter = this.dropdownModelFilter.selectedOption?.label;
    let startDate = ''
    let endDate = ''
    let value = '';
    let exportObj:any;
    let defaultObj = {};
    const exportObjList:any = [];

    if(selectedFilter?.toLowerCase() === 'creation date'){
      startDate = new Date(this.creationStartDateTextBox.value as Date | string).toLocaleDateString('fr-CA');
      const days = dayjs(new Date()).diff(dayjs(startDate), 'days');
      if(days > this.daysInYear(new Date().getFullYear())/2){
        this.commonService.showToast(0, 'Start Date cannot be earlier than six months ');
        this.commonService.hideLoading();
        return;
      }
    }

    defaultObj = {
      'name': 'StartDate',
      'value': dayjs().format('YYYY-MM-DD')
    }
    switch (selectedFilter?.toLowerCase()) {
    case 'vehicle make':
      this.dropdownModelMake?.selectedOption?.map((q: any) => q.name).forEach((make) => {
        value += `,${make}`;
      });
      exportObj = {
        'name': 'VehicleMake',
        'value': value.substring(1)
      }
      exportObjList.push(defaultObj);
      exportObjList.push(exportObj);
      break;
    case 'status':
      this.dropdownModelStatus?.selectedOption?.map((q: any) => q.name).forEach((status) => {
        value += `,${status}`
      });
      exportObj = {
        'name': 'Status',
        'value': value.substring(1)
      }
      
      exportObjList.push(defaultObj);
      exportObjList.push(exportObj);
      break;
    case 'work provider':
      this.dropdownModelWorkProvider?.selectedOption?.map((q: any) => q.name).forEach((provider) => {
        value += `,${provider} `;
      });
      exportObj = {
        'name': 'WorkProviderName',
        'value': value.substring(1)
      }
      exportObjList.push(defaultObj);
      exportObjList.push(exportObj);
      break;
    case 'organization':
      this.dropdownModelOrganization?.selectedOption?.map((q: any) => q.name).forEach((org) => {
        value += `,${org}`;
      });
      exportObj = {
        'name': 'OrgName',
        'value': value.substring(1)
      }
      exportObjList.push(defaultObj);
      exportObjList.push(exportObj);
      break;
    case 'created by':
      this.dropdownModelUsers?.selectedOption?.map((q: any) => q.id).forEach((id) => {
        value += `,${id}`;
      });
      exportObj = {
        'name': 'CreatedUserId',
        'value': value.substring(1)
      }
    
      exportObjList.push(defaultObj);
      exportObjList.push(exportObj);
      break;
    case 'creation date':
      startDate = new Date(this.creationStartDateTextBox.value as Date | string).toLocaleDateString('fr-CA');
      endDate = new Date(this.creationEndDateTextBox.value as Date | string).toLocaleDateString('fr-CA');
      exportObj = {
        'name': 'StartDate',
        'value': startDate
      }
      defaultObj = {
        'name': 'EndDate',
        'value': endDate
      }
      exportObjList.push(defaultObj);
      exportObjList.push(exportObj);
      break;
      
    default:
      exportObjList.push(defaultObj);
      break;
    }
    return exportObjList;
  }

  /**
   * 
   */
  public filterCases(): any {
    const selectedFilter = this.dropdownModelFilter.selectedOption?.label;
    this.queryParams = '';
    let startDate = ''
    let endDate = ''
    let endDateTimeZone = '';
    let StartDateTimeZone = '';
    this.statuses = '';

    switch (selectedFilter?.toLowerCase()) {
    case 'vehicle make':
      this.dropdownModelMake?.selectedOption?.map((q: any) => q.name).forEach((make) => {
        this.queryParams += `VehicleMake eq '${make}' OR `;
      });
      break;
    case 'status':
      this.dropdownModelStatus?.selectedOption?.map((q: any) => q.name).forEach((status) => {
        this.statuses += `, ${status}`
        this.queryParams += `Status eq '${status}' OR `;
      });
      this.statuses = this.statuses.substring(1).toLowerCase();
      break;
    case 'work provider':
      this.dropdownModelWorkProvider?.selectedOption?.map((q: any) => q.name).forEach((provider) => {
        this.queryParams += `WorkProviderName eq '${provider}' OR `;
      });
      break;
    case 'organization':
      this.dropdownModelOrganization?.selectedOption?.map((q: any) => q.name).forEach((org) => {
        this.queryParams += `OrgName eq '${org}' OR `;
      });
      break;
    case 'created by':
      this.dropdownModelUsers?.selectedOption?.map((q: any) => q.id).forEach((id) => {
        this.queryParams += `CreatedUserId eq ${id} OR `;
      });
      break;
    case 'creation date':
      startDate = dayjs(this.creationStartDateTextBox.value as Date | string).format('YYYY-MM-DD 00:00:00');
      endDate = dayjs(this.creationEndDateTextBox.value as Date | string).format('YYYY-MM-DD 00:00:00');
      StartDateTimeZone = new Date(new Date(startDate)).toISOString();
      endDateTimeZone = new Date(new Date(new Date(endDate).toDateString()).getTime()+1000*60*60*24-1000).toISOString();
      this.queryParams += `CreatedDate ge ${StartDateTimeZone} AND CreatedDate le ${endDateTimeZone})`
      break;
    default:
      break;
    }

    if (selectedFilter?.toLowerCase() == 'creation date') {
      ChqCasesTableComponent.filterDetail['start'] = this.creationStartDateTextBox.value;
      ChqCasesTableComponent.filterDetail['end'] = this.creationEndDateTextBox.value;
    } else {
      ChqCasesTableComponent.filterDetail['start'] = '';
      ChqCasesTableComponent.filterDetail['end'] = '';
    }

    if (this.queryParams != '') {
      if (this.queryParams.indexOf('OR') !== -1) {
        this.queryParams = this.queryParams.substring(0, this.queryParams.lastIndexOf('OR')) + ')';
      }

      this.queryParams = '$filter=(' + this.queryParams;
    }

    const query = (this.queryParams) ? '?' + this.queryParams : this.queryParams;

    /*return this.getTotalCaseCount(query).subscribe(async (count) => {
      this.pagination = { ...this.pagination, ...{ count: count, currentPage: ChqCasesTableComponent.currentPage } };      
      this.commonService.showLoading();
      await this.getCases();
      this.showDeleteButton = false;
    });*/
    this.monitorService.logEvent('filterCases', [ 'ChqCasesTableComponent', 'addenda-quote', {
      selectedFilter,
      query
    } ]);

    this.applyButtonModel.type = 'disabled';
    
    this.commonService.showLoading();
    return this.getTotalCaseCount(query).subscribe(async (count) => {
      this.pagination = { ...this.pagination, ...{ count: count, currentPage: ChqCasesTableComponent.currentPage } }; 
      ChqCasesTableComponent.filterDetail['pagination'] = this.pagination;     
      await this.getCases();
      ChqCasesTableComponent.currentPage = 1;
      this.showDeleteButton = false;
      setTimeout(() => {
        this.applyButtonModel.type = 'primary';
      });
    });
  }

  /**
   * selected any filter in the dropdown
   * @param $event 
   */
  public selectFilter($event: any): void {
    this.monitorService.logEvent('selectFilter', [ 'ChqCasesTableComponent', 'addenda-quote', {
      $event
    } ]);
    if ($event?.name == 'filterDropdown') {
      this.dropdownModelFilter.selectedOption = $event.value
      /*this.dropdownModelStatus.selectedOption = '';
      this.dropdownModelMake.selectedOption = '';
      this.dropdownModelWorkProvider.selectedOption = '';
      this.dropdownModelOrganization.selectedOption = '';
      this.dropdownModelUsers.selectedOption = ''*/;
      this.dropdownModelStatus.selectedOption = [];
      this.dropdownModelMake.selectedOption = [];
      this.dropdownModelWorkProvider.selectedOption = [];
      this.dropdownModelOrganization.selectedOption = [];
      this.dropdownModelUsers.selectedOption = []

      switch ($event.value[this.dropdownModelFilter.displayValue]?.toLowerCase()) {
      case 'vehicle make':
        this.hideShowDropdown('make');
        this.getVehicleMakeData();
        break;
      case 'status':
        this.hideShowDropdown('status');
        this.getCaseStatus();
        break;
      case 'creation date':
        this.hideShowDropdown('date');
        break;
      case 'work provider':
        this.hideShowDropdown('provider');
        this.getWorkProviders();
        break;
      case 'organization':
        this.hideShowDropdown('org');
        this.getOrganizations();
        break;
      case 'created by':
        this.hideShowDropdown('user');
        this.getCreatedByUsers();
        break;   
      default:
        this.hideShowDropdown('');
        break;
      }
    } else if ($event?.name == 'statusDropdown') {
      this.dropdownModelStatus.selectedOption = $event.value;
    } else if ($event?.name == 'makeDropdown') {
      this.dropdownModelMake.selectedOption = $event.value;
    } else if ($event?.name == 'providerDropdown') {
      this.dropdownModelWorkProvider.selectedOption = $event.value;
    }else if ($event?.name == 'orgDropdown') {
      this.dropdownModelOrganization.selectedOption = $event.value;
    }else if ($event?.name == 'usersDropdown') {
      this.dropdownModelUsers.selectedOption = $event.value;
    }

    if ($event?.name == 'filterDropdown') {
      ChqCasesTableComponent.filterDetail['mainFilter'] = $event;
    } else {
      ChqCasesTableComponent.filterDetail['subFilter'] = $event;
    }
  }

  /**
   * hide show dropdown
  */
  private hideShowDropdown(key: string): void {
    this.input[key] = true;
    Object.keys(this.input).forEach(
      (value) => {
        if (value != key) {
          this.input[value] = false;
        }
      }
    )
  }
  /**
   * 
   * @param event 
   */
  public updateInput(event: IControlValue): void {
    if (event.name == 'fromDate') {
      this.creationStartDateTextBox.value = event.value;
      this.creationEndDateTextBox.minDate = dayjs(event.value).toDate();
    } else if (event.name == 'toDate') {
      this.creationEndDateTextBox.value = event.value;
    }
  }

  /**
   * Deleting the case 
   * @param data 
   */
  public deleteCase(id: string): void {
    const dialogRef = this.commonService.openConfirmationOkDialog('Delete case', 'Do you want to delete the selected case?');
    this.monitorService.logEvent('deleteCase', [ 'ChqCasesTableComponent', 'addenda-quote', {
      id
    } ]);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.commonService.showLoading();
        this.caseService.deleteCase(id).subscribe({
          next: () => {
            this.getCases();
            this.commonService.hideLoading();
          },
          error: () => {
            this.commonService.hideLoading();
          }
        })
      }
    });
  }

  /**
   * get work provider list
   */
  getOrganizations(): void {
    if (this.organizationList) {
      this.dropdownModelOrganization = { ...this.dropdownModelOrganization, ...{ options: this.organizationList } };
      return;  
    }
    this.commonService.showLoading();
    this.caseService.getOrganizations().subscribe({
      next: (response) => {
        this.organizationList = response.data;     
        this.dropdownModelOrganization = { ...this.dropdownModelOrganization, ...{ options: this.organizationList } };
        this.commonService.hideLoading();
      },
      error: (err:any) => {      
        this.monitorService.logEvent('getOrganizations', [ 'ChqCasesTableComponent', 'addenda-quote', {
          err
        } ]);  
        this.commonService.hideLoading();
      }
    });
  }

  /**
   * get work provider list
   */
  getCreatedByUsers(): void {
    if (this.createdByUsersList) {
      this.dropdownModelUsers = { ...this.dropdownModelUsers, ...{ options: this.createdByUsersList } };
      return;  
    }
    this.commonService.showLoading();
    //DomainId : quote:2100, repair:3100
    this.caseService.getCaseUsers('domainId=2100').subscribe({
      next: (response) => {
        this.createdByUsersList = response.data || [];
        this.dropdownModelUsers = { ...this.dropdownModelUsers, ...{ options: this.createdByUsersList } };
        this.commonService.hideLoading();
      },
      error: () => {        
        this.commonService.hideLoading();
      }
    });
  }

  /**
   * get work provider list
   */
  getWorkProviders(): void {
    if (this.workProviderList) {
      this.dropdownModelWorkProvider = { ...this.dropdownModelWorkProvider, ...{ options: this.workProviderList } };
      return;  
    }
    this.commonService.showLoading();
    this.caseService.getProviders().subscribe({
      next: (response) => {
        this.workProviderList = [];
        response?.data?.forEach((element:string, index:number) => {
          this.workProviderList.push({ name: element, id: index });
        });   
        this.dropdownModelWorkProvider = { ...this.dropdownModelWorkProvider, ...{ options: this.workProviderList } };
        this.commonService.hideLoading();
      },
      error: () => {        
        this.commonService.hideLoading();
      }
    });
  }
  
  /**
   * get vehicle make list
   */
  getVehicleMakeData(): void {
    if (this.makeList) {
      this.dropdownModelMake = { ...this.dropdownModelMake, ...{ options: this.makeList } };
      return;  
    }
    this.commonService.showLoading();
    this.vehicleService.getVehicleMake().subscribe({
      next: (response) => {
        this.makeList = response?.sort((a, b) => a.name.localeCompare(b.name));        
        this.dropdownModelMake = { ...this.dropdownModelMake, ...{ options: this.makeList } };
        this.commonService.hideLoading();
      },
      error: () => {        
        this.commonService.hideLoading();
      }
    });   
  }

  /**
   * get vehicle make list
   */
  getCaseStatus(): void {
    if (this.statusList) {
      this.dropdownModelStatus = { ...this.dropdownModelStatus, ...{ options: this.statusList } };
      return;  
    }
    this.commonService.showLoading();
    this.caseService.getCaseStatus().subscribe({
      next: (response) => {
        this.statusList = response?.data;
        this.dropdownModelStatus = { ...this.dropdownModelStatus, ...{ options: this.statusList } };
        this.commonService.hideLoading();
      },
      error: () => {        
        this.commonService.hideLoading();
      }
    });   
  }

  /**
   * store current filter
  */
  private displayFilter(): void {
    //this.commonService.showLoading();
    this.selectFilter(ChqCasesTableComponent.filterDetail['mainFilter']);
    if (ChqCasesTableComponent.filterDetail['start'] !== '') {
      this.creationEndDateTextBox.value = ChqCasesTableComponent.filterDetail['end'];
      this.creationStartDateTextBox.value = ChqCasesTableComponent.filterDetail['start'];
    } else {
      this.selectFilter(ChqCasesTableComponent.filterDetail['subFilter']);
    }

    this.filterCases();
    /*await this.filterCases().then(() => {
      ChqCasesTableComponent.currentPage = 1;
    })*/
  }

  /**
   * reset Filter
   */
  resetFilter(): void {
    if(this.dropdownModelFilter.selectedOption?.label === 'Your cases') {
      return;
    }
    const obj = {
      name: 'filterDropdown',
      type: 'select',
      value: { label: 'Your cases' }
    }
    this.selectFilter(obj);
    this.filterCases();
  }

  /**
   * export 
   */
  export(): void {
    this.commonService.showLoading();
    const requestBody = this.exportBodyGenerator();
    if(!requestBody){
      return;
    }
    this.caseService.exportCase(requestBody).subscribe((res:any)=>{
      this.exportUrl = res.fileURL;
      setTimeout(()=>{
        this.exportTag.nativeElement.click();
        this.exportUrl = '';
      });
      
      //window.open(res.fileURL, '_blank');
      this.commonService.hideLoading();
    });
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscription.unsubscribe();
  }
}
