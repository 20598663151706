export const InvoiceSummaryData = {
  'value': [ {
    title: 'TOTAL JOBS',
    percentage: 0,
    amount: 150,
  }, {
    title: 'TOTAL LABOUR (36%)',
    percentage: 0,
    amount: 350526,
  }, {
    title: 'PANEL LABOUR(23%)',
    percentage: 0,
    amount: 350526,
  }, {
    title: 'PAINT LABOUR (14%)',
    percentage: 0,
    amount: 350526,
  }, {
    title: 'PARTS',
    percentage: 0,
    amount: 350526,
  }, {
    title: 'MISC',
    percentage: 0,
    amount: 350526,
  }, {
    title: 'SUBLET',
    percentage: 0,
    amount: 350526,
  }, {
    title: 'TOTAL',
    percentage: 0,
    amount: 350526,
  } ]
}