import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { AddPaymentForm, newPaymentForm, paymentAmountValidators } from 'src/app/helper/form/payment.helper';
import { PaymentFromDataModel } from 'src/app/model/chq-payment-model';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { IControlValue } from 'src/app/model/chq-widgets-input-model';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { PaymentService } from 'src/app/services/payment/payment.service';

@Component({
  selector: 'payment-add',
  templateUrl: './payment-add.component.html',
  styleUrls: [ './payment-add.component.scss' ]
})
export class PaymentAddComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() repairId: any;
  @Input() repairPaymentStatus: string;
  @Input() invoiceNumber:string;
  @Input() invoiceDate:string;
  @Input() dueAmount: number;
  @Output() handleAddPayment: EventEmitter<any> = new EventEmitter();
  public uploader: ElementRef;
  /**
    Sets the content of the uploader element.
    @param {ElementRef} content - The content of the uploader element.
  */
  @ViewChild('uploader') set content(content: ElementRef) {
    if(content) { // initially setter gets called with undefined
      this.uploader = content;
    }
  }
  public savePaymentButtonModel: ChqWidgetsButtonModel = {
    label: 'save_payment',
    type: 'outline',
    icon: 'save',
  }

  public attachmentButton: ChqWidgetsButtonModel = {
    label: 'add_attachment',
    type: 'outline',
    icon: 'attachment',
  }
  
  public formModel?: AddPaymentForm;
  public formData: PaymentFromDataModel = {
    paymentTypeId: 0,
    paymentDate: '',
    amount: 0,
    description: '',
    paymentStatus: ''
  };
  private paymentStatusMapper = {
    'unpaid': 'Unpaid',
    'partialpaid': 'Partially Paid',
    'paid': 'Paid'
  }

  public file: any[] = [];
  /**
    Constructor for the PaymentListComponent.
    @constructor
    @param {MonitorService} monitorService - Service for monitoring operations.
    @param {PaymentService} paymentService - Service for payment.
    @param {CommonService} commonService - An instance of the CommonService class.
  */
  constructor(
    public monitorService: MonitorService,
    public paymentService: PaymentService,
    private commonService: CommonService,
    private translateService: TranslateService,
  ) {}

  /**
    Lifecycle hook that is called after the component is initialized.
  */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'PaymentListComponent', 'addenda-repair' ]);
    this.formModel = newPaymentForm(this.translateService);
    if(!this.formModel.paymentMethod.observable) {
      this.formModel.paymentMethod.observable = this.paymentService.getPaymentTypes();
      this.formModel.paymentMethod.onObservableLoad = this.onObservableLoadPaymentMethods.bind(this)
    }
    // Set value of payment status
    this.formModel.paymentStatus = { ...this.formModel.paymentStatus, value: this.paymentStatusMapper[this.repairPaymentStatus?.toLowerCase()] };
    this.formData.paymentStatus = this.repairPaymentStatus;

    this.formModel.amount = { ...this.formModel.amount, value: this.dueAmount?.toFixed(2) };
    this.formData.amount = this.dueAmount ;

    // Set default value of payment date
    this.formData.paymentDate = new Date();
    this.formModel.paymentDate = { ...this.formModel.paymentDate, minDate: dayjs(this.invoiceDate).toDate() }
  } 

  /**
   * ngAfterViewInit hook
   */
  ngAfterViewInit(): void {
    // Set max value of payment amount
    if(this.dueAmount) {
      this.formModel.amount.formControl.setValidators(Validators.compose([ Validators.required, Validators.pattern(/^\d{1,12}\.?\d{1,3}$/), paymentAmountValidators(this.translateService, this.dueAmount) ]))
    }
  }

  /**
   * ngOnChanges hook
   */
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['dueAmount'] && this.formModel?.amount?.formControl) {
      this.formModel.amount.formControl.setValidators(Validators.compose([ 
        Validators.required, paymentAmountValidators(this.translateService, changes['dueAmount']?.currentValue) 
      ]));
      this.formModel.amount = { ...this.formModel.amount, value: this.dueAmount?.toFixed(2) };
      this.formData.amount = this.dueAmount ;
    }
    if(changes['repairPaymentStatus'] && this.formModel) {
      this.formModel.paymentStatus = { ...this.formModel?.paymentStatus, value: this.paymentStatusMapper[changes['repairPaymentStatus']?.currentValue] }
    }
  }

  /**
   * update validation
   */
  updateValidation(dueAmount:number, status:string):void{
    this.formModel.amount.formControl.setValidators(Validators.compose([ 
      Validators.required, paymentAmountValidators(this.translateService, dueAmount) 
    ]));
    this.formModel.amount = { ...this.formModel.amount, value: dueAmount?.toFixed(2) };
    this.formData.amount = dueAmount;
    this.formModel.paymentStatus = { ...this.formModel?.paymentStatus, value: this.paymentStatusMapper[status?.toLowerCase()] }
  }

  /**
    Updates the form model and form data based on the output received from the form.
    @param {IControlValue} output - The output received from the form.
    @returns {void}
  */
  onFormUpdate(output: IControlValue): void {
    if (output.type === 'select') {
      // Set Selected Values of dropdown
      this.formModel[output.name] = {
        ...this.formModel[output.name], selectedOption: output.value
      };
      if(output.name === 'paymentMethod') {
        this.formData['paymentTypeId'] = output.value.id
      } else {
        this.formData[output.name] = output.value
      }
    } else {
      this.formModel[output.name].value = output.value;
      this.formData[output.name] = output.value
    }
    this.checkForFormValidation();
  }

  /**
    Updates the payment method options in the form model.
    @param {any} items - The payment method options to update.
    @returns {void}
  */
  onObservableLoadPaymentMethods(items: any): void {
    this.formModel['paymentMethod'] = { ...this.formModel['paymentMethod'], options: items }
  }

  /**
   * check for form validation and set button type
   * @returns {void}
  */
  checkForFormValidation(): void {
    if (this.isPaymentFormValid()) {
      this.savePaymentButtonModel.type = 'primary';
    } else {
      this.savePaymentButtonModel.type = 'outline';
    }
  }

  /**
   * check for form validation
   * @returns {void}
   */
  isPaymentFormValid(): boolean {
    let isValid = true;
    if(
      !this.formData.amount
      || !this.formData.paymentDate
      || !this.formData.paymentTypeId
      || !this.formModel.amount.formControl.valid 
      || !this.formModel.paymentDate.formControl.valid 
    ) {
      isValid = false
    }
    return isValid;
  }
  
  /**
   * checks file size
   * @param blob 
   * @returns boolean
   */
  checkFileSize(img: Blob): boolean {
    this.monitorService.logEvent('checkFileSize', [ 'PaymentAddComponent', 'addenda-repair', {
      fileSize: img.size
    } ]);
    if (img.size > this.commonService.maxFileSize) {
      return false;
    }
    return true;
  }

  /**
   * check filename
   * @param filename 
   * @returns 
   */
  public isValidImageFile(filename: string): boolean {
    const regex: RegExp = new RegExp('^.*\\.[a-zA-Z]+$', 'gm');
    this.monitorService.logEvent('isValidImageFile', [ 'PaymentAddComponent', 'addenda-repair', {
      fileName: filename
    } ]);
    if (regex.test(filename)) {
      const extension = filename.split('.').pop();
      return this.commonService.allowFilesExtension.includes(extension?.toLowerCase());
    }
    return false;
  }
  
  /**
   * handle file upload
   * @param event 
  */
  handleUpload(event: any): void {
    const currentFile = event.target.files || event.srcElement.files;
    this.monitorService.logEvent('handleUpload', [ 'PaymentAddComponent', 'addenda-quote', {
      event
    } ]);
    if (currentFile !== null && currentFile !== '' && currentFile.length > 0) {
      if (!this.checkFileSize(currentFile[0])) {
        const message = this.translateService.instant('file_size_5mb_error_message');
        this.commonService.showToast(0, message);
        this.uploader.nativeElement.value = null;
        return;
      }
      if (!this.isValidImageFile(currentFile[0].name)) {
        const message = this.translateService.instant('upload_type_not_supported');
        this.commonService.showToast(0, message);
        this.uploader.nativeElement.value = null;
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(currentFile[0]);
      reader.onload = (): void => {
        this.file.push(currentFile[0]);
        this.checkForFormValidation()
      }
    } else {
      this.checkForFormValidation();
    }
  }

  /**
    Removes a file from the component's state.
    @param {any} event - The event object that triggered the function.
    @returns {void}
  */
  handleFileRemove(index: any): void {
    this.commonService.openDeleteDialog(
      '', 
      this.translateService.instant('attachment_delete_confirmation'), 
      this.translateService.instant('delete'), 
      this.translateService.instant('cancel')
    )
      .afterClosed().subscribe((data) => {
        if (data) {
          this.monitorService.logEvent('handleFileRemove', [ 'PaymentAddComponent', 'addenda-repair' ]);
          this.file.splice(index, 1);
        }
      });
    
  }

  /**
  Handles the click event for the "Add File" button.
  @param {Event} event - The click event.
  @returns {void}
  */
  handleAddFileClick(): void {
    this.uploader.nativeElement.click()
  }

  /**
    Handles the form submission.
    @returns {void}
  */
  handleFormSubmit(): void {
    if(!this.isPaymentFormValid()) return;
    this.monitorService.logEvent('submitForm', [ 'PaymentAddComponent', 'addenda-repair' ]); 
    const paymentData: PaymentFromDataModel = {
      amount: this.formData.amount/1,
      paymentTypeId: this.formData.paymentTypeId,
      paymentDate: dayjs.utc(this.formData.paymentDate).format('YYYY-MM-DDTHH:mm:ss.000') + 'Z',
      description: this.formData.description,
      invoiceNumber: this.invoiceNumber
    }
    if(this.file && this.file.length > 0){
      paymentData.files = this.file;
    }
    this.commonService.showLoading();
    this.paymentService.addPayment(paymentData).subscribe({
      next: (result: any) => {
        console.log('result', result)
        if (result) {
          this.commonService.hideLoading();
          this.handleAddPayment.emit({ 'Amount': this.formData.amount });
          this.resetForm()
        }
      },
      error: (err) => {
        this.commonService.hideLoading();
        this.monitorService.logException(err, SeverityLevel.Error);
      }
    });
  }

  /**
    Resets the form
    @param {any} event - The event object that triggered the function.
    @returns {void}
  */
  resetForm(): void {
    this.formModel.amount = { ...this.formModel.amount, value: null }
    this.formData.amount = null
    this.formModel.amount.formControl.reset();

    this.formModel.paymentMethod = { ...this.formModel.paymentMethod, selectedOption: { } }
    this.formData.paymentTypeId = null;
    
    this.formModel.paymentDate = { ...this.formModel.paymentDate, value: new Date() };
    this.formData.paymentDate = new Date()

    this.formModel.description = { ...this.formModel.description, value: null }
    this.formData.description = null
    this.formModel.description.formControl.reset()
    
    this.file = [];
  }
}
