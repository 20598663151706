import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberSuffix'
})
export class NumberSuffixPipe implements PipeTransform {
  /**
    Transforms a given input value into a formatted string with appropriate suffixes for thousands, millions, billions, etc.
    @param {any} input - The input value to be transformed.
    @param {any} args - Optional arguments for formatting the output.
    @returns {any} The transformed input value with appropriate suffixes.
  */
  transform(input: any, args?: any): any {
    let exp;
    const suffixes = [ 'K', 'M', 'B', 'T', 'P', 'E' ];
    const isNagtiveValues = input < 0;
    if (Number.isNaN(input) || (input < 1000 && input >= 0) || !this.isNumeric(input) || (input < 0 && input > -1000)) {
      if (!!args && this.isNumeric(input) && !(input < 0) && input != 0) {
        return input.toFixed(args);
      } else {
        return input;
      }
    }

    if (!isNagtiveValues) {
      exp = Math.floor(Math.log(input) / Math.log(1000));

      return (input / Math.pow(1000, exp)).toFixed(args) + suffixes[exp - 1];
    } else {
      input = input * -1;

      exp = Math.floor(Math.log(input) / Math.log(1000));

      return (input * -1 / Math.pow(1000, exp)).toFixed(args) + suffixes[exp - 1];
    }
  }

  /**
    Checks if a given value is numeric.
    @param {number} value - The value to check.
    @returns {boolean} - True if the value is numeric, false otherwise.
  */
  isNumeric(value): boolean {
    if (value < 0) value = value * -1;
    if (/^-{0,1}\d+$/.test(value)) {
      return true;
    } else if (/^\d+\.\d+$/.test(value)) {
      return true;
    } else {
      return false;
    }
  }

  /**
    Formats a number in a string with commas as thousands separators.
    @param {number} n - The number to be formatted.
    @returns {string} The formatted number as a string.
  */
  formatInThousand(n): string {
    if (n >= 1e3) return +(n / 1e3).toFixed(1) + 'K';
    else return n;
  }

  /**
   * formatInThousandNoDecimals
   * @param {*} n
   * @returns {string}
   */
  formatInThousandNoDecimals(n): string {
    if (n >= 1e3) return +(n / 1e3).toFixed(0) + 'K';
    else return n.toFixed(0);
  }
}
