/* eslint-disable @typescript-eslint/no-empty-function */
import { AfterContentInit, Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

let nextUniqueId = 0;
@Component({
  selector: 'xa-recommendation',
  templateUrl: './xa-recommendation.component.html',
  styleUrls: [ './xa-recommendation.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XaRecommendation),
      multi: true
    },
  ]
})
export class XaRecommendation implements ControlValueAccessor, AfterContentInit {
  recommendationValues: Array<any> = [
    {
      value: 1,
      color: '#D30027',
      showBackground: false
    },
    {
      value: 2,
      color: '#EB0B0B',
      showBackground: false
    },
    {
      value: 3,
      color: '#F13708',
      showBackground: false
    },
    {
      value: 4,
      color: '#F87501',
      showBackground: false
    },
    {
      value: 5,
      color: '#F8B300',
      showBackground: false
    },
    {
      value: 6,
      color: '#F8DD00',
      showBackground: false
    },
    {
      value: 7,
      color: '#CCDE0E',
      showBackground: false
    },
    {
      value: 8,
      color: '#89D421',
      showBackground: false
    },
    {
      value: 9,
      color: '#44BC38',
      showBackground: false
    },
    {
      value: 10,
      color: '#12AD47',
      showBackground: false
    }
  ];
  @Input() label?: string;
  value: any;
  _isInitialized: boolean = false;

  _uniqueId: string = `xa-recommendation-${nextUniqueId++}`;

  _controlValueAccessorChangeFn: (value: any) => void = () => {};
  onTouched: () => any = () => {};

  /**
   * after content init
   */
  ngAfterContentInit(): void {
    this._isInitialized = true;
  }

  /**
   * Sets the value value. Implemented as part of ControlValueAccessor.
   * @param value
   */
  writeValue(value: any): void {
    this.value = value;
    this.setBackground(this.value);
  }

  /**
     * Registers a callback to be triggered when the value value changes.
     * Implemented as part of ControlValueAccessor.
     * @param fn Callback to be registered.
     */
  registerOnChange(fn: (value: any) => void): void {
    this._controlValueAccessorChangeFn = fn;
  }

  /**
   * Registers a callback to be triggered when the control is touched.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /** Triggered when the radio button receives an interaction from the user. */
  _onInputInteraction(): void {
    this._controlValueAccessorChangeFn(this.value);
  }

  /**
   * get generated id
   */
  get inputId(): string {
    return `${this._uniqueId}-input`;
  }


  /**
   * on selection
   * @param value 
   */
  onSelect(recommendation): void {
    this.value = recommendation.value;
    this._onInputInteraction();
    this.onTouched();
    this.setBackground(this.value);
  }

  /**
   * on mouse over set background color
   * @param recommendation 
   */
  onMouseOver(recommendation): void {
    this.setBackground(recommendation.value);
  }

  /**
   * on mouse leave reset background color
   */
  onMouseOut(): void {
    this.setBackground(this.value);
  }

  /**
   * set background color based on value
   * @param value 
   */
  setBackground(value): void {
    this.recommendationValues.map((x) => {
      if(x.value <= value) {
        x.showBackground = true;
      } else {
        x.showBackground = false;
      }
    })
  }
}
