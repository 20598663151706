import { Component, Input, ViewChild } from '@angular/core';
import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexDataLabels,
  ApexPlotOptions,
  ApexXAxis,
  ApexLegend,
  ApexFill,
  ApexStroke,
  ApexAnnotations,
  ApexAxisChartSeries,
  ApexMarkers,
  ApexNoData,
  ApexTooltip,
  ApexYAxis,
  ApexForecastDataPoints,
  ApexGrid,
  ApexStates,
  ApexTitleSubtitle,
  ApexTheme,
  ChartComponent
} from 'ng-apexcharts';

@Component({
  selector: 'chq-chart',
  templateUrl: './chq-chart.component.html',
  styleUrls: [ './chq-chart.component.scss' ]
})
export class ChqChartComponent {
  @ViewChild('chartRef') chartRef: ChartComponent
  @Input() chart: ApexChart;
  @Input() annotations: ApexAnnotations;
  @Input() colors: any[];
  @Input() dataLabels: ApexDataLabels;
  @Input() series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  @Input() stroke: ApexStroke;
  @Input() labels: string[];
  @Input() legend: ApexLegend;
  @Input() markers: ApexMarkers;
  @Input() noData: ApexNoData;
  @Input() fill: ApexFill;
  @Input() tooltip: ApexTooltip;
  @Input() plotOptions: ApexPlotOptions;
  @Input() responsive: ApexResponsive[];
  @Input() xaxis: ApexXAxis;
  @Input() yaxis: ApexYAxis | ApexYAxis[];
  @Input() forecastDataPoints: ApexForecastDataPoints;
  @Input() grid: ApexGrid;
  @Input() states: ApexStates;
  @Input() title: ApexTitleSubtitle;
  @Input() subtitle: ApexTitleSubtitle;
  @Input() theme: ApexTheme;
  @Input() autoUpdateSeries: boolean;

  /**
   * constructor
   */
  constructor() {
    console.warn('\'chq-chart\' selector is deprecated; use \'xa-chart\' instead.');

  }
}
