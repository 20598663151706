<div class="message-container">
    <div class="header">
        <h3>Notification!</h3>
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'" (click)="cancel();"></mat-icon>
        </div>
    </div>
    <div class="content">
        <label>{{data.message}}</label>
    </div>
    <div class="footer form-data-footer">
        <chq-button [buttonModel]="cancelButton" class="left"></chq-button>
        <chq-button [buttonModel]="confirmButton" class="right"></chq-button>
    </div>
</div>