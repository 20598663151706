import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { claimSearchData } from 'src/app/config/constants/claims.constants';
import { SearchFilterParams } from 'src/app/model/chq-search-result-section-model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  
  private webApiUrl: string = 'api/generalsearch';
  /**
   * constructor
   * @param http 
   */
  constructor(
    private http: HttpClient
  ) {
  }


  /**
   * Generic function that searches the item provided
   * @param serviceName 
   * @param searchString 
   * @returns Observable
   */
  public generalSearch(searchObject: SearchFilterParams): Observable<any> {
    return this.http.post<any>(`${environment.caseMgmtUrl}/${this.webApiUrl}`, searchObject);
  }

  /**
   * Generic function for claim that searches the item provided
   * @param searchObject 
   * @returns 
   */
  public generalSearchClaim(searchObject: SearchFilterParams): Observable<any> {
    return this.http.post<any>(`${environment.fnolApiUrl}/${this.webApiUrl}`, searchObject);
    //return of(claimSearchData);
    //return this.http.post<any>(`${environment.caseMgmtUrl}/${this.webApiUrl}`, searchObject);
  }

  /**
   * Generic function with specific property for the item provided
   * @param serviceName 
   * @param searchString 
   * @returns Observable
   */
  public generalSearchClaimWithProperty(searchObject: SearchFilterParams): Observable<any> {
    return this.http.post<any>(`${environment.fnolApiUrl}/${this.webApiUrl}/by-section`, searchObject);
  }

  /**
   * Generic function with specific property for the item provided
   * @param serviceName 
   * @param searchString 
   * @returns Observable
   */
  public generalSearchWithProperty(searchObject: SearchFilterParams): Observable<any> {
    return this.http.post<any>(`${environment.caseMgmtUrl}/${this.webApiUrl}/by-section`, searchObject);
  }
}
