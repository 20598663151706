import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

export interface PaginateInfo {
  totalItems?: number;
  currentPage?: number;
  pageSize?: number;
  totalPages?: number;
  startPage?: number;
  endPage?: number;
  startIndex?: number;
  endIndex?: number;
  pages?: number[];
  offset?: number;
}
export interface ApiPagination {
  maxPageButtons?: number;
  count?: number;
  offset?: number;
  currentPage?: number;
  pageSize?: number;
  orderBy?: string;
  sortDirection?: string;
}

export const defaultPagination: ApiPagination = {
  offset: 0,
  pageSize: 20,
  maxPageButtons: 6,
  currentPage: 1,
  sortDirection: 'asc'
}

@Component({
  selector: 'xa-paginator',
  templateUrl: './xa-paginator.component.html',
  styleUrls: [ './xa-paginator.component.scss' ]
})
export class XaPaginator implements OnChanges {

  @Output() paginationChange = new EventEmitter<PaginateInfo>(true);
  @Input() pagination: ApiPagination = defaultPagination;
  @Input() searchMode='';

  pager: PaginateInfo = {};
  totalPageCount: number = 0;


  /**
   * ngOnChanges
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pagination']?.currentValue) {
      if (this.pagination.count) {
        this.totalPageCount = Math.ceil(this.pagination.count / (this.pagination.pageSize ?? 20));
        this.pager = this.paginate(this.pagination.count, this.pagination.currentPage ?? 0,
          this.pagination.pageSize ?? 20, this.pagination.maxPageButtons ?? 3, this.pagination.offset ?? 0);
      }
      if (this.pagination.offset === 0) {
        // get new pager object for specified page
        if (this.pagination?.count) {
          this.pager = this.paginate(this.pagination.count, 1, this.pagination.pageSize ?? 20,
            this.pagination.maxPageButtons ?? 3, this.pagination.offset);
        }
      }
    }
  }

  /**
   * setPage
   * @param page
   */
  public setPage(page: number): void {
    if (page) {
      // eslint-disable-next-line radix
      page = parseInt(page.toString());
    }
    // get new pager object for specified page
    this.pager = this.paginate(this.pagination.count ?? 0, page, this.pagination.pageSize ?? 20,
      this.pagination.maxPageButtons ?? 3, this.pagination.offset ?? 0);

    // call change page function in parent component
    this.paginationChange.emit(this.pager);
  }

  /**
   * setPageSize
   * @param pageSize
   * @param page
   */
  public setPageSize(pageSize: number, page: number): void {
    this.pagination.pageSize = pageSize;
    this.pager = this.paginate(this.pagination.count ?? 0, page, this.pagination.pageSize,
      this.pagination.maxPageButtons ?? 3, this.pagination.offset ?? 0);
    this.paginationChange.emit(this.pager);
  }

  /**
   * paginate
   * @param totalItems
   * @param currentPage
   * @param pageSize
   * @param maxPageButtons
   * @param skip
   * @returns
   */
  paginate(totalItems: number, currentPage: number, pageSize: number, maxPageButtons: number, skip: number): PaginateInfo {
    if (currentPage === void 0) {
      // eslint-disable-next-line radix
      currentPage = this.pagination.currentPage || 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    if (!maxPageButtons) {
      maxPageButtons = 10;
    }

    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);
    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }
    let startPage: number;
    let endPage: number;
    if (totalPages <= maxPageButtons) {
      // total pages less than max so show all pages
      startPage = 1;
      endPage = totalPages;
    } else {
      // total pages more than max so calculate start and end pages
      const maxPagesBeforeCurrentPage = Math.floor(maxPageButtons / 2);
      const maxPagesAfterCurrentPage = Math.ceil(maxPageButtons / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrentPage) {
        // current page near the start
        startPage = 1;
        endPage = maxPageButtons;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        // current page near the end
        startPage = totalPages - maxPageButtons + 1;
        endPage = totalPages;
      } else {
        // current page somewhere in the middle
        startPage = currentPage - maxPagesBeforeCurrentPage;
        endPage = currentPage + maxPagesAfterCurrentPage;
      }
    }
    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    // create an array of pages to ng-repeat in the pager control
    const pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
    // return object with all pager properties required by the view
    const skipVal: number = (currentPage - 1) * pageSize;
    this.totalPageCount = totalPages;

    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
      offset: skipVal
    };
  }
  /**
   * blockNegative
   */
  blockNegative(e: any): boolean {
    if (!((e.keyCode > 95 && e.keyCode < 106)
      || (e.keyCode > 47 && e.keyCode < 58)
      || e.keyCode === 8)) {
      return false;
    }
    return true;
  }
}
