export enum XAButtonTypes {
    default = 'default',
    outline = 'outline',
    outlineRadius = 'outline-radius',
    basic = 'basic',
    chip = 'chip',
    fab = 'fab',
    fabOutline = 'outline-fab',
    fabBasic = 'fab-basic',
    radius = 'radius',
    miniFab = 'mini-fab',
    miniFabOutline = 'mini-fab-outline'
}

export enum XAButtonSizes {
    xs = 'xs',
    sm = 'sm',
    md = 'md',
    lg = 'lg'
}

export enum XAButtonColors {
    primary = 'primary',
    secondary = 'secondary',
    tertiary = 'tertiary',
    success = 'success',
    danger = 'danger',
    dark = 'dark',
    gray = 'gray'
}


export enum XAStatusButtonColors {
    grey = 'grey',
    green = 'green',
    red = 'red',
    bluishGrey = 'bluish-grey',
    teal = 'teal',
    brown = 'brown',
    yellow = 'yellow',
    purple = 'purple',
    blue = 'blue'
}

