import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, NgZone, Output } from '@angular/core';

class Options {
  public bounds: any;
  public componentRestrictions: any;
  public types: string[];
  public fields: string[];
  public strictBounds: boolean;
  public origin: any;
  /**
   constructor.
  */
  public constructor(opt?: Partial<Options>) {
    if (!opt)
      return;
    Object.assign(this, opt);
  }
}

export class Address {
  address_components: any[];
  adr_address: string;
  formatted_address: string;
  formatted_phone_number: string;
  geometry: any;
  html_attributions: string[];
  icon: string;
  id: string;
  international_phone_number: string;
  name: string;
  opening_hours: any;
  permanently_closed: boolean;
  photos: any[];
  place_id: string;
  price_level: number;
  rating: number;
  reviews: any[];
  types: string[];
  url: string;
  utc_offset: number;
  vicinity: string;
  website: string;
}    

declare let google: any;

@Directive({
  selector: '[chqGooglePlacesAutocomplete]',
  exportAs: 'chq-places'
})

export class ChqGooglePlacesAutocompleteDirective implements AfterViewInit {
    @Input() options: Options;
    @Output() addressChanged: EventEmitter<Address> = new EventEmitter();
    private autocomplete: any;
    private eventListener: any;
    public place: Address;
    /**
    constructor.
    */
    constructor(private el: ElementRef, private ngZone: NgZone) {
    }

    /**
      Lifecycle hook that is called after a component's view has been initialized.
      It initializes the component by calling the initialize() method. 
    */
    ngAfterViewInit(): void {
      if (!this.options)
        this.options = new Options();
      this.initialize();
    }

    /**
      Checks if the Google Maps API library is available.
      @returns {boolean} True if the library is available, false otherwise. 
    */
    private isGoogleLibExists(): boolean {
      return !(!google || !google.maps || !google.maps.places);
    }

    /**
      Initializes the Google Autocomplete component..
    */
    private initialize(): void {
      if (!this.isGoogleLibExists())
        throw new Error('Google maps library can not be found');

      this.autocomplete = new google.maps.places.Autocomplete(this.el.nativeElement, this.options);

      if (!this.autocomplete)
        throw new Error('Autocomplete is not initialized');

      if (!this.autocomplete.addListener != null) { // Check to bypass https://github.com/angular-ui/angular-google-maps/issues/270
        this.eventListener = this.autocomplete.addListener('place_changed', () => {
          this.handleChangeEvent()
        });
      }

      this.el.nativeElement.addEventListener('keydown', (event: KeyboardEvent) => {
        if(!event.key) {
          return;
        }

        const key = event.key.toLowerCase();

        if (key == 'enter' && event.target === this.el.nativeElement) {
          event.preventDefault();
          event.stopPropagation();
        }
      });

      // according to https://gist.github.com/schoenobates/ef578a02ac8ab6726487
      if (window && window.navigator && window.navigator.userAgent && navigator.userAgent.match(/(iPad|iPhone|iPod)/g)) {
        setTimeout(() => {
          const containers = document.getElementsByClassName('pac-container');

          if (containers) {
            const arr = Array.from(containers);

            if (arr) {
              for (const container of arr) {
                if (!container)
                  continue;

                container.addEventListener('touchend', (e) => {
                  e.stopImmediatePropagation();
                });
              }

            }
          }
        }, 500);
      }
    }

    /**
    Resets the autocomplete component to its initial state.
    @returns {void} 
    */
    public reset(): void {
      this.autocomplete.setComponentRestrictions(this.options.componentRestrictions);
      this.autocomplete.setTypes(this.options.types);
    }

    /**
      Handles the change event of the autocomplete component.
      Emits the selected place object and runs the place object in the Angular zone. 
    */
    private handleChangeEvent(): void {
      this.ngZone.run(() => {
        this.place = this.autocomplete.getPlace();

        if (this.place) {
          this.addressChanged.emit(this.place);
        }
      });
    }
}

