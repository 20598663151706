<div class="repair dialog-container" *ngIf="!data?.imageGallery && !showComment && !showEmail && !showNotes">
    <div class="heading">
        <div class="heading-content">
            {{data.status}}
        </div>
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'" (click)="cancelDialog();"></mat-icon>
        </div>
    </div>
    <div class="info">
        {{data.info}}
    </div>
    <div class="content">
        <form [formGroup]="commentForm" class="comment-form-wrapper">
            <div>
                <chq-input [inputModel]="TMInitialEstimateDeliveryDate"
                (controlOutput)="onFormUpdate($event)" *ngIf="isExternal && data.buttonModel.label === 'Export to DMS'"></chq-input>
            </div>
            <div style="position: relative;">
                <label>Comments</label>
                <textarea class="input-wrapper" rows="8" formControlName="comment" maxlength="500"
                    placeholder=""></textarea>
                <div class="invalid" *ngIf="(formControl.dirty || formControl.touched) && formControl.errors"> Please enter comment </div>
            </div>
        </form>        
    </div>
    <div class="button">
        <chq-button [buttonModel]="data.buttonModel"></chq-button>
    </div>
</div>
<div class="dialog-container-image" *ngIf="data?.imageGallery">
    <div class="heading">
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'" (click)="cancelDialog();"></mat-icon>
        </div>
    </div>
    <div class="image-content">
        <div class="navigation prev-button" *ngIf="thumbnails.length > 1">
            <mat-icon aria-hidden="false" aria-label="Example chevron left icon" fontIcon="chevron_left" (click)="prevImageClick()"></mat-icon>
        </div>
        <img (error)="updateLoading()" (load)="updateLoading()" [src]='currentImage' />
        <chq-custom-loader [showLoading]="showLoading"></chq-custom-loader>
        <div class="navigation next-button" *ngIf="thumbnails.length > 1">
            <mat-icon aria-hidden="false" aria-label="Example chevron right icon" fontIcon="chevron_right" (click)="nextImageClick()"></mat-icon>
        </div> 
    </div>
    <div class="slider">
        <div class="prev-button" *ngIf="thumbnails.length > 1">
            <mat-icon aria-hidden="false" aria-label="Example chevron left icon" fontIcon="chevron_left" (click)="prevImageClick()"></mat-icon>
        </div>
        <ng-image-slider [images]="thumbnails" [infinite]="false" [autoSlide]="1"
            [imageSize]="{ width: '75px', height: '75px' }" [imagePopup]="false" slideImage="1" [showArrow]="false"
            (imageClick)="showImage($event)" #nav>
        </ng-image-slider>

        <div class="next-button" *ngIf="thumbnails.length > 1">
            <mat-icon aria-hidden="false" aria-label="Example chevron right icon" fontIcon="chevron_right" (click)="nextImageClick()"></mat-icon>
        </div>
    </div>
    <div class="footer">
        <span>{{index}}/{{totalImages}}</span>
    </div>
</div>

<div class="dialog-container dialog-container-comment" *ngIf="showComment">
    <div class="heading heading-comment">
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'" (click)="cancelDialog();"></mat-icon>
        </div>
    </div>
    <div class="comment-content">
        <div class="comment-status">
            {{data.status}}
        </div>
        <div class="comment">
            {{comments}}
        </div>
    </div>

</div>
<div class="dialog-container dialog-container-email" *ngIf="showEmail">
    <div class="heading heading-comment">
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'" (click)="cancelDialog();"></mat-icon>
        </div>
    </div>
    <div class="email-status">{{data?.status}}</div>
    <div class="email-content">
      <p><strong>{{'to' | translate}}:</strong> {{ emailContent.To }}</p>
      <p><strong>{{'subject' | translate}}:</strong> {{ emailContent.Subject }}</p>
      <div [innerHTML]="emailContent.Content"></div>
    </div>
</div>
<div class="dialog-container dialog-container-email" *ngIf="showNotes">
    <div class="heading heading-notes">
        <div class="notes-status">{{data?.status}}</div>
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'" (click)="cancelDialog();"></mat-icon>
        </div>
    </div>
    
    <chq-input [inputModel]="notesModel"></chq-input>
</div>
