/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable require-jsdoc */
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule, UrlSerializer } from '@angular/router';

import { CurrencyPipe, DatePipe, registerLocaleData } from '@angular/common';
import localeAr from '@angular/common/locales/ar-AE';
import localeQa from '@angular/common/locales/ar-QA';
import localeSr from '@angular/common/locales/ar-SA';
import localeEn from '@angular/common/locales/en';
import localeGb from '@angular/common/locales/en-GB';
import localeIn from '@angular/common/locales/en-IN';
import { MsalService } from '@azure/msal-angular';
import * as Hammer from 'hammerjs';
import { environment } from 'src/environments/environment';
import { routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { LogoutExtComponent } from './b2c-policy-core/logout-ext/logout-ext.component';
import { MSALProviders } from './b2c-policy-core/msal-provider';
import { appConfigFactory } from './config/configuration/init-config.factory';
import { ConfigurationService } from './config/configuration/service/configuration.service';
import { AlertDialogComponent } from './dialogs/alert-dialog/alert-dialog.component';
import { ChatNotificationComponent } from './dialogs/chat-notification/chat-notification.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { GtMotiveEstimationDialogComponent } from './dialogs/gt-motive-estimation-dialog/gt-motive-estimation-dialog.component';
import { NotificationComponent } from './dialogs/notification/notification.component';
import { ToastComponent } from './dialogs/toast/toast.component';
import { PipeModule } from './helper/pipe.module';
import { NumberSuffixPipe } from './helper/pipe/number-suffix/number-suffix.pipe';
import { interceptorProviders } from './http-interceptors';
import CustomUrlSerializer from './http-interceptors/custom-Url-Serializer';
import { IconsModule } from './icons.module';
import { LayoutModule } from './layout/layout.module';
import { MaterialModule } from './material.module';
import { initializeMsalStorage } from './msal-storage.factory';
import { DEFAULT_AI_TIMEOUT, ENABLE_AI_RECURSION, RECURSION_MAX_RETRIES } from './shared/damage-analysis/damage-analysis.component';
import { NgxTranslateModule } from './translate/translate.module';
import { MultiActionConfirmDialogComponent } from './dialogs/multi-action-confirm-dialog/multi-action-confirm-dialog.component';
import { WidgetsModule } from './widgets/widgets.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

registerLocaleData(localeIn);
registerLocaleData(localeAr);
registerLocaleData(localeEn);
registerLocaleData(localeGb);
registerLocaleData(localeSr);
registerLocaleData(localeQa);
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  override overrides = <any>{
    'swipe': {
      // override default settings
      velocity: 0.4,
      threshold: 20,
      direction: Hammer.DIRECTION_ALL,
      enabled: true
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    AlertDialogComponent,
    ConfirmDialogComponent,
    ToastComponent,
    NotificationComponent,
    LogoutExtComponent,
    GtMotiveEstimationDialogComponent,
    ChatNotificationComponent,
    MultiActionConfirmDialogComponent
  ],
  providers: [
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    interceptorProviders,
    [ { provide: DEFAULT_AI_TIMEOUT, useValue: environment.ai.recursionTimeout },
      { provide: RECURSION_MAX_RETRIES, useValue: environment.ai.maxRetryCount },
      { provide: ENABLE_AI_RECURSION, useValue: environment.ai.enableRecursion } ],
    MSALProviders,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => initializeGoogleMap,
      multi: true
    }, {
      provide: APP_INITIALIZER,
      useFactory: () => initializeOpenCV,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => initializeMsalStorage,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appConfigFactory,
      deps: [ ConfigurationService, MsalService ],
      multi: true
    },
    DatePipe,
    NumberSuffixPipe,
    CurrencyPipe,
    PipeModule
  ],
  bootstrap: [ AppComponent ],
  imports: [
    HammerModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    LayoutModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    }),
    IconsModule,
    HttpClientModule,
    NgxTranslateModule,
    MaterialModule,
    PipeModule,
    WidgetsModule
  ]
})
export class AppModule { }

/**
   * app initializer
   * @returns promise
*/
function initializeGoogleMap(): Promise<boolean> {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsKey}&libraries=places&language=en`;
    document.getElementsByTagName('head')[0].appendChild(script);
    resolve(true);
  });
}

/**
   * app initializer
   * @returns promise
*/
function initializeOpenCV(): Promise<boolean> {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = '/assets/lib/opencv.js';
    document.getElementsByTagName('head')[0].appendChild(script);
    resolve(true);
  });
}
