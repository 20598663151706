export const PaymentsTableConfig = {
  columns: [
    {
      id: 'Id',
      width: '0px',
      style: 'display: none',
      header: {
        text: 'Id',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      cellStyle: 'display: none; max-width: 0',
      selector: {
        field: 'paymentId',
      },
      widget: {
        type: 'text'
      }
    },
    {
      id: 'Payment',
      sortable: false,
      header: {
        text: 'payment',
        tooltip: '',
      },
      selector: {
        field: 'paymentId',
      },
      widget: {
        type: 'text',
      }
    },
    {
      id: 'Amount',
      sortable: false,
      selector: {
        field: 'amount',
      },
      header: {
        text: 'amount',
        tooltip: '',
        style: 'text-align: center;'
      },
      cellStyle: 'justify-content: center;',
      widget: {
        type: 'currency',
      },
    },
    {
      id: 'PaymentDate',
      sortable: false,
      selector: {
        field: 'paymentDate',
      },
      header: {
        text: 'payment_date',
        tooltip: '',
        style: 'text-align: center;'
      },
      cellStyle: 'justify-content: center;',
      widget: {
        type: 'dateTime',
        dateFormat: 'dd/MM/YYYY hh:mm a'
      },
    },
    {
      id: 'PaymentMethod',
      sortable: false,
      selector: {
        field: 'paymentType',
      },
      header: {
        text: 'payment_method',
        tooltip: '',
        style: 'text-align: center;'
      },
      cellStyle: 'justify-content: center; text-transform: capitalize;',
      widget: {
        type: 'text',
      },
    },
    {
      id: 'Attachment',
      sortable: false,
      selector: {
        field: 'attachment',
      },
      header: {
        text: 'attachment',
        tooltip: 'attachment',
        style: 'text-align: center;'
      },
      childColumns: [ {
        id: 'AttachmentReference',
        selector: {
          field: 'attachment',
        },
        widget: {
          type: 'icon',
          config: {
            svgIcon: 'attachment-reference',
          }
        },
      }, {
        id: 'Download',
        disableColumns: true,
        selector: {
          field: 'attachment',
        },
        widget: {
          type: 'icon',
          config: {
            svgIcon: 'download-arrow',
          }
        },
      }, {
        id: 'Preview',
        selector: {
          field: 'attachment',
        },
        disableColumns: true,
        widget: {
          type: 'icon',
          config: {
            svgIcon: 'eye',
          }
        },
      } 
      ],
      cellStyle: 'justify-content: center;',
    },
    {
      id: 'DeleteAction',
      width: '100px',
      sortable: false,
      selector: {
        field: 'Id',
      },
      header: {
        text: 'action',
        tooltip: '',
        style: 'text-align: center;'
      },
      cellStyle: 'justify-content: center',
      widget: {
        type: 'icon',
        config: {
          svgIcon: 'delete',
          style: 'fill: var(--xa-dark-red2) !important; width: 15px; height: 15px;'
        }
      },
    },
    {
      id: 'paymentGuid',
      width: '0px',
      style: 'display: none',
      header: {
        text: 'paymentGuid',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      cellStyle: 'display: none; max-width: 0',
      selector: {
        field: 'paymentGuid',
      },
      widget: {
        type: 'text'
      }
    },
  ]
}