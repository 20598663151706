import { AfterContentChecked, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService, Localization } from 'src/app/services/common/common.service';
import { RepairEstimateService } from 'src/app/services/repair-estimate/repair-estimate.service';
import { ChqEstimateDetailsComponent } from 'src/app/widgets/chq-estimate-details/chq-estimate-details.component';

@Component({
  selector: 'app-manual-estimate-preview',
  templateUrl: './manual-estimate-preview.component.html',
  styleUrls: [ './manual-estimate-preview.component.scss' ]
})
export class ManualEstimatePreviewComponent implements AfterContentChecked {
  @ViewChild('estimateDetailsPreviewPopup') estimateDetailsPreviewPopup: ChqEstimateDetailsComponent;
  i18n: Localization;
  formIsValid: boolean
  /**
    Constructor for the RepairEstimateOperationComponent.
    @constructor
    @param {any} data - Data passed to the component.
    @param {MatDialogRef} dialogRef - Reference to the dialog box.
    @param {MonitorService} monitorService - Service for monitoring operations.
  */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdref: ChangeDetectorRef,
    public commonService: CommonService,
    private repairEstimateService: RepairEstimateService,
    public dialogRef: MatDialogRef<ManualEstimatePreviewComponent>,
  ) {
    this.i18n = this.commonService.geti18nInfo();
  }
  /**
    Constructor for the RepairEstimateOperationComponent.
    @constructor
    @param {any} data - Data passed to the component.
    @param {MatDialogRef} dialogRef - Reference to the dialog box.
    @param {MonitorService} monitorService - Service for monitoring operations.
  */
  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }
  /**
    Closes the dialog with the given data.
    @param {any} data - The data to be passed back to the dialog caller.
  */
  cancelDialog(): void {
    this.dialogRef.close({
      eventType: 'cancel'
    });
  }

  /**
    Handles the click event on an icon.
    @param {object} output - The output object containing the ID and action of the icon.
  */
  isFormValid(output): void {
    this.formIsValid = output.isFormValid
  }

  /**
      Handles the click event on an icon.
      @param {string} section - The section to upd.
      @param {string} repairOperationGuid - The GUID of the repair operation associated with the icon.
      @fires iconClicked
  */
  handleSaveIconClick(action): void {
    if (this.formIsValid) {
      const chargesToUpdate = [];
      this.estimateDetailsPreviewPopup?.partsCost?.forEach((ele) => {
        const payloadToUpdate: any = {};
        payloadToUpdate.code = ele.formModel.code.value;
        payloadToUpdate.quantity = ele.formModel.quantity.value ? Number(ele.formModel.quantity.value) : 0
        payloadToUpdate.cost = ele.formModel.cost.value ? Number(ele.formModel.cost.value) : 0
        payloadToUpdate.estimateChargeId = ele.id;
        chargesToUpdate.push(payloadToUpdate)
      })
      this.estimateDetailsPreviewPopup?.paintMaterial?.forEach((ele) => {
        const payloadToUpdate: any = {};
        payloadToUpdate.code = ele.formModel.code.value;
        payloadToUpdate.quantity = ele.formModel.quantity.value ? Number(ele.formModel.quantity.value) : 0
        payloadToUpdate.cost = ele.formModel.cost.value ? Number(ele.formModel.cost.value) : 0
        payloadToUpdate.estimateChargeId = ele.id;
        chargesToUpdate.push(payloadToUpdate)
      })
      this.estimateDetailsPreviewPopup?.paintLabour?.forEach((ele) => {
        const payloadToUpdate: any = {};
        payloadToUpdate.code = ele.formModel.code.value;
        payloadToUpdate.quantity = ele.formModel.quantity.value ? Number(ele.formModel.quantity.value) : 0
        payloadToUpdate.cost = ele.formModel.cost.value ? Number(ele.formModel.cost.value) : 0
        payloadToUpdate.estimateChargeId = ele.id;
        chargesToUpdate.push(payloadToUpdate)
      })
      this.estimateDetailsPreviewPopup?.labourCost?.forEach((ele) => {
        const payloadToUpdate: any = {};
        payloadToUpdate.code = ele.formModel.code.value;
        payloadToUpdate.quantity = ele.formModel.quantity.value ? Number(ele.formModel.quantity.value) : 0
        payloadToUpdate.cost = ele.formModel.cost.value ? Number(ele.formModel.cost.value) : 0
        payloadToUpdate.estimateChargeId = ele.id;
        chargesToUpdate.push(payloadToUpdate)
      })
      if (chargesToUpdate?.length > 0) {
        this.commonService.showLoading();
        this.repairEstimateService.updateEstimateV2(this.data?.repairGuid, {
          estimateGuid: this.data?.estimateResponse?.estimateGuid,
          estimateCharges: chargesToUpdate
        }).subscribe({
          next: (data) => {
            this.commonService.hideLoading();
            this.dialogRef.close({
              eventType: action
            })
          }
        })
      } else {
        this.dialogRef.close({
          eventType: action
        })
      }
    }

  }
}
