<div class="dialog-container">
    <div mat-dialog-title *ngIf="!data.hideTitle">
        {{ data.title | translate }}
    </div>
    
    <div mat-dialog-content>
        {{ data.message | translate }}
    </div>
</div>

<div class="btn-container" *ngIf="!data.hideButton">
    <button class="btn-ok" mat-dialog-close (click)="close()">Ok</button>
</div>