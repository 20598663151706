<div class="form-field-text">
  <label class="form-label pointer-events" for="inputId">
    {{label | translate}}
    <span class="required" *ngIf="required && label">*</span>    
  </label>
  <div class="text-field">
    <input class="form-value" autocomplete="off" #input
      [id]="inputId"
      [name]="nameId"
      placeholder="{{placeholder || label | translate}}" 
      [readonly]="readonly" 
      [disabled]="disabled" 
      [required]="required"
      [value]="value"
      [attr.maxlength]="blockMaxLength ? maxLength : null"
      (keyup)="_onInputInteraction();onTouched()"/>
  </div>
</div>