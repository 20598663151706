<div class="dialog-container">
  <div mat-dialog-title *ngIf="!titleContainsHtml">
    {{ data.title | translate }}
  </div>

  <div mat-dialog-title *ngIf="titleContainsHtml" [innerHTML]="data.title | translate"></div>
    <div mat-dialog-content *ngIf="!messageContainsHtml">
        {{ data.message | translate:{ value: (data.messageEntity || 'item') } }}
    </div>
    <div mat-dialog-content *ngIf="messageContainsHtml" [innerHTML]="data.message | translate"></div>
</div>

<div class="btn-container">
  <button *ngIf="data.cancelText" class="btn-cancel" mat-dialog-close>
    {{ data.cancelText | translate }}
  </button>
  <button *ngIf="data.okText" class="btn-confirm" mat-dialog-close (click)="close()">
    {{ data.okText | translate }}
  </button>
</div>
