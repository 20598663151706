<div class="radio-option">
    <input #input type="radio"
        [id]="inputId"
        [checked]="checked"
        [disabled]="disabled"
        [name]="name"
        [value]="value"
        [required]="required"
        (change)="_onInputInteraction($event)">
    <label class="form-label" [for]="inputId">
        <ng-content></ng-content>
    </label>
</div>