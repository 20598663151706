import { Component, Input, OnInit } from '@angular/core';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';

@Component({
  selector: 'chq-button',
  templateUrl: './chq-button.component.html',
  styleUrls: [ './chq-button.component.scss' ]
})
export class ChqButtonComponent implements OnInit {
@Input()
  public buttonModel: ChqWidgetsButtonModel = {
    label: '',
    type: 'primary',
    icon: ''
  };
svgWidthHeightStyle: string = '';
  
/**
   * getWidthHeightStyles
   * @returns {string}
   */
getWidthHeightStyles(): void {
  if(this.buttonModel?.width) this.svgWidthHeightStyle += `width: ${this.buttonModel.width};`;
  if(this.buttonModel?.height) this.svgWidthHeightStyle += `height: ${this.buttonModel.height};`;
}

/**
 * ngOnInit
 */
ngOnInit(): void {
  this.getWidthHeightStyles();
}
}
