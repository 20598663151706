import { NgModule } from '@angular/core';
import { FilterPipe } from './pipe/filter/filter.pipe';
import { FilterByKeyPipe } from './pipe/filterByKey/filterByKey.pipe';
import { OrderPipe } from './pipe/order/order.pipe';
import { SumPipe } from './pipe/sum/sum.pipe';
import { TimeSince } from './pipe/time-since/time-since.pipe';
import { TrimPipe } from './pipe/trim/trim.pipe';
import { GroupByPipe } from './pipe/groupBy/groupby.pipe';
import { XaCurrencyPipe } from './pipe/xa-currency/xa-currency.pipe';
import { TimeFormatPipe } from './pipe/time-format/time-format.pipe';
import { MinuteToHourMinutePipe } from './pipe/minuteToHourMinutePipe/minuteToHourMinutePipe.pipe';

@NgModule({
  declarations: [
    FilterPipe,
    FilterByKeyPipe,
    OrderPipe,
    SumPipe,
    TrimPipe,
    XaCurrencyPipe,
    TimeSince,
    GroupByPipe,
    TimeFormatPipe,
    MinuteToHourMinutePipe
  ],
  exports: [
    FilterPipe,
    FilterByKeyPipe,
    SumPipe,
    TrimPipe,
    OrderPipe,
    XaCurrencyPipe,
    TimeSince,
    GroupByPipe,
    TimeFormatPipe,
    MinuteToHourMinutePipe
  ],
  providers: [
    TrimPipe
  ]
})
export class PipeModule { }
