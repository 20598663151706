<div class="dialog-container">
  <div mat-dialog-title
       *ngIf="data.title && !titleContainsHtml">
    {{ data.title | translate }}
  </div>

  <div mat-dialog-title
       *ngIf="data.title && titleContainsHtml"
       [innerHTML]="data.title | translate"></div>
  <div mat-dialog-content
       *ngIf="!messageContainsHtml">
    {{ data.message | translate:{ value: (data.messageEntity || 'item') } }}
  </div>
  <div mat-dialog-content
       *ngIf="messageContainsHtml"
       [innerHTML]="data.message | translate"></div>
  <div>
    <form class="form-container"
          [formGroup]="actionForm">
      <xa-radio-group formControlName="Action"
                      [ngClass]="data.action.orientation">
        <xa-radio *ngFor="let option of data.action.options"
                  [value]="option.value">{{ option.label }}</xa-radio>
      </xa-radio-group>
      <xa-error *ngIf="(actionForm.get('Action').dirty || formSubmitted) && actionForm.get('Action').hasError('required')">
        Select action first.
      </xa-error>
    </form>
  </div>
</div>

<div class="btn-container">
  <button *ngIf="data.cancelText"
          class="btn-cancel"
          mat-dialog-close>
    {{ data.cancelText | translate }}
  </button>
  <button *ngIf="data.okText"
          class="btn-confirm"
          (click)="close()">
    {{ data.okText | translate }}
  </button>
</div>