import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { MatDialog } from '@angular/material/dialog';
import { ChqLabourRateComponent } from 'src/app/dialogs/labour-rate/labour-rate.component';
import { ChqWidgetsHeaderModel } from 'src/app/model/chq-widgets-header-model';
import { ActivatedRoute } from '@angular/router';
import { UploadStepModel } from 'src/app/model/chq-upload-model';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'chq-step-header',
  templateUrl: './chq-step-header.component.html',
  styleUrls: [ './chq-step-header.component.scss' ],
})
export class ChqStepHeaderComponent implements OnInit, OnChanges {
  @Input() chqModel?: ChqWidgetsHeaderModel;
  @Input() hideHeader: boolean;
  @Input() showButton: boolean;
  @Input() steps: UploadStepModel[]
  @Input() hideBack:boolean;
  @Input() backTitle: string;
  @Input() hideDelete:boolean;
  @Output() back: EventEmitter<{ back: boolean }> = new EventEmitter();
  @Output() caseHistory: EventEmitter<void> = new EventEmitter();
  @Output() uploadBackHandler: EventEmitter<void> = new EventEmitter();
  @Output() deleteAllHandler: EventEmitter<void> = new EventEmitter();

  public nextButtonModel: ChqWidgetsButtonModel = {
    label: 'ai_analysis',
    type: 'primary',
    icon: 'chip'
  }

  public caseId: string;

  public repairStatus: string = '';
  public claimStatus: string = '';
  public hideLabourRate: boolean = false;

  /**
   * constructor
   * @param dialog 
   */
  constructor(private dialog: MatDialog,
    private monitorService:MonitorService,
    public commonService: CommonService,
  private route: ActivatedRoute,) { }

  /**
   * init
   */
  ngOnInit(): void{
    this.monitorService.logEvent('ngOnInit', [ 'ChqStepHeaderComponent', 'addenda-quote' ]);
    if (this.chqModel?.step === 1 || this.chqModel?.step === 2 || this.chqModel?.isClaimModule) {
      this.hideLabourRate = true;
    } else {
      this.hideLabourRate = false;
      this.caseId = this.route.parent?.snapshot.paramMap.get('id') || '0';
    }

    if(this.chqModel?.isRepairModule) {
      this.commonService.repairStatus.subscribe((repairStatus) => {
        if (repairStatus) {
          this.repairStatus = repairStatus;
        }
      });
    }

    if(this.chqModel?.isClaimModule) {
      this.commonService.claimStatus.subscribe((claimStatus) => {
        if (claimStatus) {
          this.claimStatus = claimStatus;
        }
      });
    }
    this.nextButtonModel.onclick = this.showAI.bind(this);
  }

  /**
   * show ai
   */
  showAI(): void{
    this.monitorService.logEvent('showAI', [ 'ChqStepHeaderComponent', 'addenda-quote' ]);
    this.back.emit({ back: false });
  }

  /**
   * delete all handler
   */
  deleteAll():void{
    this.deleteAllHandler.emit();
  }

  /**
   * emit back handling
   */
  backHandler():void{
    this.uploadBackHandler.emit();
  }

  /**
   * ng input changes
   * @param changes 
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.monitorService.logEvent('ngOnChanges', [ 'ChqStepHeaderComponent', 'addenda-quote', {
      changes
    } ]);
  }

  /**
   * handle back
   */
  handleBack():void {
    this.monitorService.logEvent('handleBack', [ 'ChqStepHeaderComponent', 'addenda-quote' ]);
    this.back.emit({ back: true });
  }

  /** 
   * open labour rate popup
   */
  openLabourRatePopup(): void {
    this.monitorService.logEvent('openLabourRatePopup', [ 'ChqStepHeaderComponent', 'addenda-quote' ]);
    const dialogRef = this.dialog.open(ChqLabourRateComponent, {
      data:
        this.caseId,
      autoFocus: false 
    });

    dialogRef.afterClosed().subscribe((result:any) => {
      if(this.chqModel.step == 5 && result){
        this.caseHistory.emit();
      }
    })
  }
}
