import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  dashboardTabs$ = new BehaviorSubject([]);
  dashboardComponents$ = new BehaviorSubject([]);
  dashboardFilters$ = new BehaviorSubject([]);
  dashboardNewFiltersKeyMapper = {
    'orgnization': 'OrgId',
    'workProvider': 'WorkProvider',
    'bookingType': 'bookingType',
    'repairType': 'RepairTypeId',
    'Customer': 'ContactId',
    'CustomerName': 'CustomerName',
    'Company': 'CompanyId',
    'CompanyName': 'CompanyName',
    'startDate': 'Startdate',
    'endDate': 'Enddate'
  }

  /**
   * constructor
   * @param http
   */
  constructor(
    private http: HttpClient
  ) { }

  /**
    Retrieves dashboard Summary information.
    @returns {Observable} An observable that emits the dashboard information.
  */
  public getDashboardSummaryInformation(data: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/dashboard/1`;
    return this.http.post(url, data);
  }

  /**
    Retrieves dashboard reapir details information for.
    @returns {Observable} An observable that emits the dashboard information.
  */
  public getDashboardRepairDetailsInformation(data: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/dashboard/2`;
    return this.http.post(url, data);
  }

  /**
    Retrieves dashboard Customer details information for.
    @returns {Observable} An observable that emits the dashboard information.
  */
  public getDashboardCustomerDetailsInformation(data: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/dashboard/3`;
    return this.http.post(url, data);
  }

  /**
    Retrieves dashboard booking type.
    @returns {Observable} An observable that emits the dashboard information.
  */
  public getBookingType(data: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/dashboard/filter/booking-type`;
    return this.http.post(url, data);
  }

  /**
    Retrieves dashboard Types.
    @returns {Observable} An observable that gets the dashboard types.
  */
  public getDashboardTypes(type='repair'): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/dashboard/types/${type}`;
    return this.http.get(url);
  }

  /**
    Retrieves dashboard Data.
    @returns {Observable} An observable that gets the dashboard types.
  */
  public getDashboardData(dashboardName, data): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/dashboard/${dashboardName}`;
    return this.http.post(url, data);
  }

  /**
  Retrieves dashboard Data.
  @returns {Observable} An observable that gets the dashboard types.
*/
  public getDashboardExport(dashboardName, data): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/dashboard/export/${dashboardName}`;
    return this.http.post(url, data);
  }
}
