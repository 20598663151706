import { ChangeDetectorRef, Component, Input, ViewChild, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';
import { AddEstimateOperationForm, newEstimateOperationForm, newQuantityWidget, newLabourTimeWidget, newLabourAmountWidget, newPriceWidget } from 'src/app/helper/form/estimate-operation.helper';
import { ChqInputComponent } from 'src/app/widgets/chq-input/chq-input.component';
import { IControlValue } from 'src/app/model/chq-widgets-input-model';
import { EstimateOperationFromDataModel, EstimateOperationModel } from 'src/app/model/chq-estimate-operation-model'
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { BookingService } from 'src/app/services/booking/booking.service';
import { RepairEstimateOperationService } from 'src/app/services/repair-estimate-operation/repair-estimate-operation.service';
import { OPERATION_TYPE, DEFAULT_QUANTITY_VALUE } from 'src/app/config/constants/estimate-operation-type';
import { CommonService, Localization } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Modules } from 'src/app/model/chq-upload-model';
import { TranslateService } from '@ngx-translate/core';
const EDIT_BUTTON_TEXT = 'save';
const ADD_BUTTON_TEXT = 'add';

@Component({
  selector: 'app-operation-add',
  templateUrl: './operation-add.component.html',
  styleUrls: [ './operation-add.component.scss' ]
})

export class OperationAddComponent implements OnInit, AfterViewInit {
  @Input() operationToEdit: any
  @Input() repairId: any
  @ViewChild('operationTypeControl') operationTypeControl: ChqInputComponent;
  @ViewChild('repairTypeControl') repairTypeControl: ChqInputComponent;
  @ViewChild('operationControl') operationControl: ChqInputComponent;
  @ViewChild('codeControl') codeControl: ChqInputComponent;
  @ViewChild('informationControl') informationControl: ChqInputComponent;
  @ViewChild('labourAmountControl', { static: false }) labourAmountControl: ChqInputComponent;
  @ViewChild('labourTimeControl', { static: false }) labourTimeControl: ChqInputComponent;
  @ViewChild('quantityControl', { static: false }) quantityControl: ChqInputComponent;
  @ViewChild('priceControl', { static: false }) priceControl: ChqInputComponent;



  @Output() switchToOperationLIst: EventEmitter<any> = new EventEmitter();
  @Output() cancelDialog: EventEmitter<any> = new EventEmitter();
  public OPERATION_TYPE: any = OPERATION_TYPE;
  public formModel?: AddEstimateOperationForm;
  public formData: EstimateOperationFromDataModel = {
    operationType: '',
    operation: '',
    code: '',
    repairType: '',
    information: '',
    quantity: 0,
    price: 0,
    labourTime: 0,
    labourAmount: 0,
    saveOperation: false
  };
  public currencySymbol: string = ''
  public addButtonModel: ChqWidgetsButtonModel = {
    label: ADD_BUTTON_TEXT,
    type: 'disabled',
    icon: '',
    onclick: this.beforeSubmit.bind(this)
  }

  public resetButtonModel: ChqWidgetsButtonModel = {
    label: 'reset',
    type: 'outline',
    icon: '',
    onclick: this.resetForm.bind(this)
  }
  i18n: Localization;
  /**
    Constructor for the class.
    @constructor
    @param {BookingService} bookingService - Service for booking operations.
    @param {RepairEstimateOperationService} repairEstimateOperationService - Service for repair estimate operations.
    @param {CommonService} commonService - Service for common operations.
    @param {MonitorService} monitorService - Service for monitoring operations.
    @param {ChangeDetectorRef} changeDetectorRef - Service to detect changes
  */
  constructor(
    private bookingService: BookingService,
    private repairEstimateOperationService: RepairEstimateOperationService,
    private commonService: CommonService,
    public monitorService: MonitorService,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {
    this.i18n = this.commonService.geti18nInfo();
    this.currencySymbol = this.i18n.currencySymbol
  }

  /**
    Lifecycle hook that is called when the component is initialized.
  */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'OperationAddComponent', 'addenda-repair' ]);
    this.formModel = newEstimateOperationForm();
    if (!this.formModel.operationType.observable && !this.repairEstimateOperationService.repairOperationType?.length) {
      this.formModel.operationType.observable = this.repairEstimateOperationService.getRepairOperationType();
      this.formModel.operationType.onObservableLoad = this.onObservableLoadOperationType.bind(this)
    }

    if (!this.formModel.repairType.observable && !this.bookingService.repairTypes?.length) {
      this.formModel.repairType.observable = this.bookingService.getRepairTypes();
      this.formModel.repairType.onObservableLoad = this.onObservableLoadRepairTypes.bind(this)
    }
  }

  /**
    Lifecycle hook that is called after a component's view has been initialized.
  */
  public ngAfterViewInit(): void {
    this.monitorService.logEvent('ngAfterViewInit', [ 'OperationAddComponent', 'addenda-repair' ]);
    this.populateFormData()
    if (this.repairEstimateOperationService.repairOperationType?.length) {
      this.onObservableLoadOperationType(this.repairEstimateOperationService.repairOperationType)
    }
    if (this.bookingService.repairTypes?.length) {
      this.onObservableLoadRepairTypes(this.bookingService.repairTypes)
    }
    this.checkForFormValidation();
  }

  /**
    Populates the form data with the values of an existing operation.
    @returns {void}
  */
  populateFormData(): void {
    if (this.operationToEdit) {
      if (Object.keys(this.operationToEdit).length > 0) this.addButtonModel.label = EDIT_BUTTON_TEXT;

      if (this.operationToEdit.Code) {
        this.formModel.code = { ...this.formModel.code, value: this.operationToEdit.Code };
        this.formData.code = this.operationToEdit.Code
      }

      if (this.operationToEdit.Information) {
        this.formModel.information.value = this.operationToEdit.Information;
        this.formData.information = this.operationToEdit.Information
      }

      if (this.operationToEdit.OperationName) {
        this.formModel.operation = { ...this.formModel.operation, value: this.operationToEdit.OperationName };
        this.formData.operation = this.operationToEdit.OperationName
      }
    }
  }

  /**
    Populates conditional fields data based on the operation to edit.
    @returns {void}
  */
  populateConditionalFieldsData(): void {
    if (this.operationToEdit) {
      // Condition based fields
      if (this.operationToEdit.Price && this.formModel.price) {
        this.formModel.price.value = this.operationToEdit.Price;
        this.formData.price = this.operationToEdit.Price
      }

      if (this.operationToEdit.LabourTime && this.formModel.labourTime) {
        this.formModel.labourTime.value = this.operationToEdit.LabourTime;
        this.formData.labourTime = this.operationToEdit.LabourTime
      }

      if (this.operationToEdit.LabourAmount && this.formModel.labourAmount) {
        this.formModel.labourAmount.value = this.operationToEdit.LabourAmount;
        this.formData.labourAmount = this.operationToEdit.LabourAmount;
      }

      if (this.operationToEdit.Quantity && this.formModel.quantity) {
        this.formModel.quantity.value = this.operationToEdit.Quantity;
        this.formData.quantity = this.operationToEdit.Quantity
      }

      if ((this.operationToEdit.LabourAmount || this.operationToEdit.LabourTime)
        && this.formData.operationType !== OPERATION_TYPE.replace
        && this.formData.operationType !== OPERATION_TYPE.painting
      ) {
        if (this.operationToEdit.LabourAmount) {
          if (this.formModel?.labourTime) this.formModel.labourTime.disabled = true;
        } else {
          if (this.formModel?.labourAmount) this.formModel.labourAmount.disabled = true;
        }
      }
      this.checkForFormValidation();
      this.triggerValidation();
    }
  }

  /**
    Triggers validation on the form.
    @returns {void}
  */
  triggerValidation(): void {
    Object.keys(this.formModel).forEach((key: string) => {
      if (key !== 'saveOperation') {
        if (this.formModel[key]?.formControl?.markAsTouched) {
          this.formModel[key].formControl.markAsTouched()
        }
      }
    })
  }

  /**
    Sets the initial state of the form by prefilling form data and showing/hiding fields based on the selected operation type.
    If an operation to edit is provided, it will be used to prefill the form data. If not, the default value of "Replace" will be selected.
    @returns {void}
  */
  setInitialStateOfForm(): void {
    // Prefill form data
    if (this.operationToEdit?.RepairOperationTypeId) {
      const operationObj = this.formModel.operationType.options.find((ele: any) => ele.id === this.operationToEdit.RepairOperationTypeId)
      if (operationObj) {
        this.formModel.operationType = { ...this.formModel.operationType, selectedOption: operationObj }
        this.showHideFieldsBasedOnOperationType({
          name: this.formModel.operationType.name,
          type: this.formModel.operationType.type,
          value: operationObj
        })
        this.populateConditionalFieldsData()
      }
    } else {
      //Set Selected Value as Replace
      const replaceObj = this.formModel.operationType.options.find((ele: any) => ele.name === OPERATION_TYPE.replace)
      if (replaceObj) {
        this.formModel.operationType = { ...this.formModel.operationType, selectedOption: replaceObj }
        this.showHideFieldsBasedOnOperationType({
          name: this.formModel.operationType.name,
          type: this.formModel.operationType.type,
          value: replaceObj
        })
      }
    }

  }

  /**
    Hides the dropdown associated with the given name.
    @param {any} name - The name of the dropdown to hide.
  */
  hideDropdown(name: any): void {
    if (name === this.formModel.operationType.name) {
      this.repairTypeControl.closeDropdown();
    } else if (name === this.formModel.repairType.name) {
      this.operationTypeControl.closeDropdown();
    }
  }

  /**
    Updates the form model and form data based on the output received.
    @param {IControlValue} output - The output received from the form control.
    @returns {void}
  */
  onFormUpdate(output: IControlValue): void {
    if (output.type === 'select') {
      // Set Selected Values of dropdown
      this.formModel[output.name] = {
        ...this.formModel[output.name], selectedOption: output.value
      };

      // IF field is operation type then save string of operation type in formData not a json
      if (output.name === 'operationType') {
        this.formData[output.name] = output?.value?.name || '';
        // Various validation start here
        // 1. Based on Operation type show diffrent fields such as quantity, price, labourTime, labourAmount
        this.showHideFieldsBasedOnOperationType(output)

        // 2. if the operation is painting or Polish the Repair Type will be Paint by default
        if (output?.value?.name === OPERATION_TYPE.painting || output?.value?.name === OPERATION_TYPE.polish) {
          const paintRepairTypeObj = this.formModel.repairType.options?.find((ele: any) => ele.name.toLowerCase() === 'paint');
          if (paintRepairTypeObj) {
            this.formModel.repairType = {
              ...this.formModel.repairType,
              selectedOption: paintRepairTypeObj
            }
            this.formData.repairType = paintRepairTypeObj.name
            this.repairTypeControl.getDropdownOutput({
              name: this.formModel.repairType.name,
              type: this.formModel.repairType.type,
              value: paintRepairTypeObj
            })
          }
        } else {
          this.formModel.repairType = {
            ...this.formModel.repairType,
            selectedOption: {}
          }
          this.formData.repairType = ''
        }
        // Various validation end here
      } else {
        // Save the as it is value in form data
        if (output.name === 'repairType') this.formData[output.name] = output.value.name
        else this.formData[output.name] = output.value
      }
    } else {
      this.formModel[output.name].value = output.value;
      this.formData[output.name] = output.value;

      // Various Validation start here
      if (this.formData.operationType !== OPERATION_TYPE.painting) {
        if (output.name === 'labourAmount') { //if user add time the labour amount will be disable
          if (output.value) this.formModel.labourTime.disabled = true;
          else this.formModel.labourTime.disabled = false;
        } else if (output.name === 'labourTime') { //if the user add amount the labour time will be disable
          if (output.value) this.formModel.labourAmount.disabled = true;
          else this.formModel.labourAmount.disabled = false;
        }
      }
      // Various Validation  here
    }
    this.checkForFormValidation();
  }

  /**
    Shows or hides fields based on the operation type selected.
    @param {IControlValue} output - The output object containing the selected operation type.
  */
  showHideFieldsBasedOnOperationType(output: IControlValue): void {
    this.formData[output.name] = output?.value?.name || '';
    if (output?.value?.name === OPERATION_TYPE.replace || output?.value?.name === OPERATION_TYPE.painting) {
      delete this.formModel.labourTime;
      delete this.formModel.labourAmount;
      this.formModel.quantity = newQuantityWidget();
      this.formModel.price = newPriceWidget(this.translateService, this.currencySymbol);
      this.formModel.labourTime = newLabourTimeWidget();
      if (output?.value?.name === OPERATION_TYPE.replace) {
        this.formModel.labourAmount = newLabourAmountWidget(this.translateService, this.currencySymbol);
      }
      this.resetFormData();
    } else if (
      output?.value?.name === OPERATION_TYPE.repair
      || output?.value?.name === OPERATION_TYPE.removeAndRefit
      || output?.value?.name === OPERATION_TYPE.polish
      || output?.value?.name === OPERATION_TYPE.checkFields
      || output?.value?.name === OPERATION_TYPE.performFields
    ) {
      delete this.formModel.quantity;
      delete this.formModel.price;
      delete this.formModel.labourTime;
      this.formModel.labourAmount = newLabourAmountWidget(this.translateService, this.currencySymbol);
      this.formModel.labourTime = newLabourTimeWidget();
      this.resetFormData();
    } else {
      delete this.formModel.quantity;
      delete this.formModel.price;
      delete this.formModel.labourTime;
      delete this.formModel.labourAmount;
      delete this.formModel.labourTime;
      this.resetFormData();
    }
  }

  /**
   * reset form data
   * @returns {void}
  */
  resetFormData(): void {
    this.formData.labourTime = 0
    this.formData.labourAmount = 0
    this.formData.price = 0
    this.formData.quantity = 0

    //Set default value
    if (!(this.operationToEdit?.Quantity)) {
      if (this.formModel.quantity) {
        this.formModel.quantity.value = DEFAULT_QUANTITY_VALUE;
        this.formData.quantity = DEFAULT_QUANTITY_VALUE
      }
    }

  }

  /**
   * check for form validation and set button type
   * @returns {void}
  */
  checkForFormValidation(): void {
    if (this.isOperationFormValid()) {
      this.addButtonModel.type = 'primary';
    } else {
      this.addButtonModel.type = 'disabled';
    }
  }

  /**
   * check for form validation
   * @returns {void}
   */
  isOperationFormValid(): boolean {
    this.changeDetectorRef.detectChanges()
    let isValid = true;
    if (
      !this.formData['operationType']
      || !this.formData['repairType']
      || !this.formData['operation']
      || !this.formData['code']
      || !this.operationControl.formControl.valid
      || !this.codeControl.formControl.valid
      || !this.informationControl.formControl.valid
    ) {
      isValid = false;
    }

    if (this.formData['operationType'] === OPERATION_TYPE.replace || this.formData['operationType'] === OPERATION_TYPE.painting) {
      if (
        !this.quantityControl.formControl.valid
        || !this.priceControl.formControl.valid
        || !this.labourTimeControl.formControl.valid
      ) {
        isValid = false;
      }

      if (this.formData['operationType'] === OPERATION_TYPE.replace && !this.labourAmountControl.formControl.valid) {
        isValid = false;
      }

      if (this.formData['operationType'] === OPERATION_TYPE.replace && !this.formData['labourTime'] && !this.formData['labourAmount']) {
        isValid = false;
      }

    } else if (
      this.formData['operationType'] === OPERATION_TYPE.repair
      || this.formData['operationType'] === OPERATION_TYPE.removeAndRefit
      || this.formData['operationType'] === OPERATION_TYPE.polish
      || this.formData['operationType'] === OPERATION_TYPE.checkFields
      || this.formData['operationType'] === OPERATION_TYPE.performFields
    ) {
      if (
        !this.labourAmountControl.formControl.valid
        || !this.labourTimeControl.formControl.valid
      ) {
        isValid = false;
      }
    }

    return isValid;
  }

  /**
    Updates the repair type form model with the given items and sets the dropdown item icon.
    @param {any} items - The repair type items to update the form model with.
    @returns {void}
  */
  onObservableLoadRepairTypes(items: any): void {
    if (items && items.length > 0) {
      items.forEach((item, i) => {
        items[i] = { ...items[i], dropdownItemIcon: this.getIcon(item.name) }
      })
    }
    this.formModel['repairType'] = { ...this.formModel['repairType'], options: items }
    if (!this.bookingService.repairTypes?.length) {
      this.bookingService.repairTypes = items
    }

    // On form edit prefill the form data
    if (this.operationToEdit?.RepairServiceTypeId) {
      const repairTYpeObj = this.formModel.repairType.options?.find((ele: any) => ele.id === this.operationToEdit.RepairServiceTypeId);
      if (repairTYpeObj) {
        this.formModel.repairType = {
          ...this.formModel.repairType,
          selectedOption: repairTYpeObj
        }
        this.formData.repairType = repairTYpeObj.name;
        this.checkForFormValidation();
      }
    } else {
      // IF User has selected the operation type before load repaire type data the should add the validations
      // 1. if the operation is painting or Polish the Repair Type will be Paint by default
      if (this.formData.operationType === OPERATION_TYPE.painting || this.formData.operationType === OPERATION_TYPE.polish) {
        const paintRepairTypeObj = this.formModel.repairType.options?.find((ele: any) => ele.name === 'Paint');
        if (paintRepairTypeObj) {
          this.formModel.repairType = {
            ...this.formModel.repairType,
            selectedOption: paintRepairTypeObj
          }
          this.formData.repairType = paintRepairTypeObj.name
        }
      }
    }
  }

  /**
    Returns the appropriate icon based on the given item.
    @param {string} item - The item for which to return the icon.
    @returns {string} - The icon corresponding to the given item.
  */
  getIcon(item: string): string {
    switch (item) {
    case 'Body':
      return 'car';
    case 'Maintenance':
      return 'house';
    case 'Tires':
      return 'tire';
    case 'Tyres':
      return 'tire';
    case 'Paint':
      return 'paintbrush';
    case 'Others':
      return 'gears';
    case 'Electrical':
      return 'bolt-car';
    case 'Diagnosis':
      return 'diagnosis';
    case 'Mechanical':
      return 'mechanical';
    default:
      return '';
    }
  }

  /**
   * Confirmation required before submit
   * @returns {void}
   */
  beforeSubmit(): void {
    if ((this.operationToEdit?.RepairOperationGuid || this.operationToEdit?.OperationGuid) && this.commonService.quotePermission) {
      this.commonService.showChangeWarningMessage(this.submitForm.bind(this), Modules.quote);
    } else {
      this.submitForm();
    }
  }

  /**
    Submits the form data to the server.
    @returns {void}
  */
  submitForm(): void {
    if (!this.isOperationFormValid()) {
      return;
    }
    this.monitorService.logEvent('submitForm', [ 'OperationAddComponent', 'addenda-repair' ]);
    const operationTypeObj = this.formModel.operationType.options.find((ele: any) => ele.name === this.formData.operationType)
    if (!operationTypeObj) return;

    let repairTypeObj;
    if (this.formData.repairType) {
      repairTypeObj = this.formModel.repairType.options.find((ele: any) => ele.name === this.formData.repairType)
    }
    const operationData: EstimateOperationModel = {
      'operationName': this.formData.operation,
      'repairOperationTypeId': operationTypeObj?.id,
    }
    if (this.formData.code) operationData.code = this.formData.code;
    if (this.formData.information) operationData.information = this.formData.information;
    if (repairTypeObj) operationData.repairServiceTypeId = repairTypeObj.id;
    if (this.formData.operationType === OPERATION_TYPE.replace || this.formData.operationType === OPERATION_TYPE.painting) {
      if (this.formData.quantity) operationData.quantity = this.formData.quantity;
      if (this.formData.price) operationData.price = this.formData.price;
      if (this.formData.labourTime) operationData.labourTime = this.formData.labourTime;
      if (this.formData.operationType === OPERATION_TYPE.replace && this.formData.labourAmount) operationData.labourAmount = this.formData.labourAmount;
    } else {
      if (this.formData.labourTime) operationData.labourTime = this.formData.labourTime;
      if (this.formData.labourAmount) operationData.labourAmount = this.formData.labourAmount;
    }

    if (operationData.labourTime && typeof operationData.labourTime === 'string') operationData.labourTime = parseFloat(operationData.labourTime)
    if (operationData.labourAmount
      && typeof operationData.labourAmount === 'string') operationData.labourAmount = parseFloat(operationData.labourAmount)
    if (operationData.quantity && typeof operationData.quantity === 'string') operationData.quantity = parseFloat(operationData.quantity)
    if (operationData.price && typeof operationData.price === 'string') operationData.price = parseFloat(operationData.price)

    this.commonService.showLoading();

    if (this.operationToEdit?.RepairOperationGuid) {
      operationData.repairOperationGuid = this.operationToEdit.RepairOperationGuid;
      this.monitorService.logEvent('editRepairEstimateOperation', [ 'OperationAddComponent', 'addenda-repair' ], {
        repairId: this.repairId,
        data: operationData
      });
      this.repairEstimateOperationService.editRepairEstimateOperation(this.repairId, operationData).subscribe({
        next: (result: any) => {
          if (result) {
            this.commonService.hideLoading();
            this.cancelDialog.emit(true)
          }
        },
        error: (err) => {
          this.commonService.hideLoading();
          this.monitorService.logException(err, SeverityLevel.Error);
        }
      });
    } else if (this.operationToEdit?.OperationGuid) {
      operationData.operationGuid = this.operationToEdit.OperationGuid;
      this.monitorService.logEvent('editRepairOperation', [ 'OperationAddComponent', 'addenda-repair' ], {
        repairId: this.repairId,
        data: operationData
      });
      this.repairEstimateOperationService.editRepairOperation(operationData).subscribe({
        next: (result: any) => {
          if (result) {
            this.commonService.hideLoading();
            this.switchToOperationLIst.emit()
          }
        },
        error: (err) => {
          this.commonService.hideLoading();
          this.monitorService.logException(err, SeverityLevel.Error);
        }
      });
    } else {
      operationData.saveOperation = this.formData.saveOperation
      this.monitorService.logEvent('addRepairOperation', [ 'OperationAddComponent', 'addenda-repair' ], {
        repairId: this.repairId,
        data: operationData
      });
      this.repairEstimateOperationService.addRepairOperation(this.repairId, operationData).subscribe({
        next: (result: any) => {
          if (result) {
            this.commonService.hideLoading();
            this.cancelDialog.emit(true)
          }
        },
        error: (err) => {
          this.commonService.hideLoading();
          this.monitorService.logException(err, SeverityLevel.Error);
        }
      });
    }
  }

  /**
    Resets the form to its initial state.
    @param {boolean} isResetOpeartionType - Determines whether to reset the operation type. Default is true.
  */
  resetForm(isResetOpeartionType = true): void {
    if (this.operationToEdit?.Id) {
      this.populateFormData();
      this.setInitialStateOfForm();
      this.onObservableLoadRepairTypes(this.formModel.repairType.options)
    } else {
      if (isResetOpeartionType) {
        this.setInitialStateOfForm()
      }

      // Reset code
      this.formModel.code = { ...this.formModel.code, value: '' }
      this.formData.code = ''

      //Reset operation
      this.formModel.operation = { ...this.formModel.operation, value: '' }
      this.formData.operation = '';
      this.formModel.operation.formControl.reset();

      //repairType
      this.formModel.repairType = { ...this.formModel.repairType, selectedOption: [] }
      this.formData.repairType = ''

      //information
      this.formModel.information = { ...this.formModel.information, value: '' }
      this.formData.information = ''

      //quantity
      if (this.formModel.quantity) {
        this.formModel.quantity = { ...this.formModel.quantity, value: 0 }
        this.formData.quantity = 0
      }

      //price
      if (this.formModel.price) {
        this.formModel.price = { ...this.formModel.price, value: 0 }
        this.formData.price = 0
      }

      //labourTime
      if (this.formModel.labourTime) {
        this.formModel.labourTime = { ...this.formModel.labourTime, value: 0 }
        this.formData.labourTime = 0
      }

      //labourAmount
      if (this.formModel.labourAmount) {
        this.formModel.labourAmount = { ...this.formModel.labourAmount, value: 0 }
        this.formData.labourAmount = 0
      }

      //Save Operation
      if (this.formModel.saveOperation) {
        const options = this.formModel.saveOperation.options
        options[0].checked = false
        this.formModel.saveOperation = { ...this.formModel.saveOperation, options: [ ...options ] }
        this.formData.saveOperation = false
      }
    }
  }

  /**
    Updates the form model's 'operationType' property with the given items 
    and sets the repairEstimateOperationService's 'epairOperationType' property to the same items. Also calls the setInitialStateOfForm() method.
    @param {any} items - The new items to update the 'operationType' property with.
    @returns {void}
  */
  onObservableLoadOperationType(items: any): void {
    this.formModel['operationType'] = { ...this.formModel['operationType'], options: items }
    this.repairEstimateOperationService.repairOperationType = items
    this.setInitialStateOfForm();
  }
}
