<div class="range-picker-wrapper">
  <div clss="range-text">
    <span *ngIf="selectedOption === 'day'">{{ startDate | date: 'd MMMM YYYY' }}</span>
    <span *ngIf="selectedOption === 'month'">{{ startDate | date: 'MMMM YYYY' }}</span>
    <span *ngIf="selectedOption === 'week' && sameMonth">{{ startDate | date: 'd' }}</span>
    <span *ngIf="selectedOption === 'week' && !sameMonth">{{ startDate | date: 'd MMM' }}</span>
    <span *ngIf="selectedOption === 'week'">-</span>
    <span *ngIf="selectedOption === 'week' && sameMonth">{{ endDate | date: 'd MMMM YYYY' }}</span>
    <span *ngIf="selectedOption === 'week' && !sameMonth">{{ endDate | date: 'd MMM YYYY' }}</span>
  </div>
  <div class="range-action">
    <mat-icon [svgIcon]="'arrow-left'" (click)="goPrevious()"></mat-icon>
    <xa-button type="outline" (click)="updateDateRange()">Today</xa-button>
    <mat-icon [svgIcon]="'arrow-right'" (click)="goNext()"></mat-icon>
  </div>
  <div *ngIf="showRangeType">
    <!-- <xa-select [(ngModel)]="selectedOption" (ngModelChange)="selectOption($event)"
      [options]="[{label: 'Day', value: 'day'},{label: 'Week', value: 'week'},{label: 'Month', value: 'month'}]">
    </xa-select> -->

    <ul class="list">
      <li class="list-item" [class.active]="selectedOption === 'week'" (click)="selectOption('week')">
        Week
      </li>
      <li class="list-item" [class.active]="selectedOption === 'month'" (click)="selectOption('month')">
        Month
      </li>
    </ul>
  </div>
</div>