<third-party-common-layout class="registered-request-invoice-wrapper" #commonLayout>
  <ng-container *ngIf="!isSubmissionSuccess">
    <div class="card-header">
      <div class="row">
        <h2 class="col card-heading">{{"invoice_request" | translate }}</h2>
        <div class="col-auto ml-auto">
          <chq-button [buttonModel]="viewRepairDetails"></chq-button>
        </div>
      </div>
    </div>
    <div class="card-body mobile-view">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{"claim_details" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <div class="row">
            <div class="col-12">
              <label>{{"insurance_company_name" | translate }}</label>
              <span>{{claimDetails?.organizationDetail?.nameOfOrganization || '-'}}</span>
            </div>
          </div>
          <div class="row mt-40">
            <div class="col-4">
              <label>{{"policy_number" | translate }}</label>
              <span>{{claimDetails?.customerDetails?.policyNo || '-'}}</span>
            </div>
            <div class="col-4">
              <label>{{"claim_number" | translate }}</label>
              <span>{{claimDetails?.claimNo || '-'}}</span>
            </div>
            <div class="col-4">
              <label>{{"type_of_claim" | translate }}</label>
              <span>{{claimDetails?.customerDetails?.category || '-'}}</span>
            </div>
          </div>
          <div class="row mt-40">
            <div class="col-4">
              <label>{{"claimant_name" | translate }}</label>
              <span>{{claimDetails?.customerDetails?.customerFullName || '-'}}</span>
            </div>
            <div class="col-4">
              <label>{{"email_address" | translate }}</label>
              <span>{{claimDetails?.customerDetails?.policyHolderEmail || '-'}}</span>
            </div>
            <div class="col-4 row m-0">
              <div class="col-auto p-0 mr-25px">
                <label>{{"code" | translate }} *</label>
                <span>{{claimDetails?.customerDetails?.policyMobileCountryCode || '-'}}</span>
              </div>
              <div class="col-auto">
                <label>{{"mobile_number" | translate }} *</label>
                <span>{{claimDetails?.customerDetails?.policyHolderMobileNumber || '-'}}</span>
              </div>
            </div>
          </div>
          <div class="row mt-40">
            <div class="col-4">
              <label>{{"vehicle_make" | translate }}</label>
              <span>{{claimDetails?.vehicleMetaData?.make || '-'}}</span>
            </div>
            <div class="col-4">
              <label>{{"vehicle_model" | translate }}</label>
              <span>{{claimDetails?.vehicleMetaData?.model || '-'}}</span>
            </div>
            <div class="col-4">
              <label>{{"vehicle_plate_number" | translate }}</label>
              <span>{{claimDetails?.vehicleMetaData?.plateNo || '-'}}</span>
            </div>
          </div>
          <div class="row mt-40 mb-40">
            <div class="col">
              <label>{{"accident_location" | translate }}</label>
              <span>{{claimDetails?.lossDetails?.lossLocation || '-'}}</span>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <div class="row  mt-40">
        <chq-input class="col-6" [inputModel]="formModel.invoiceDate"
          (controlOutput)="onFormUpdate($event)"></chq-input>
        <chq-input class="col-6" [inputModel]="formModel.totalInvoiceAmount"
          (controlOutput)="onFormUpdate($event)"></chq-input>
      </div>
      <div class="row  mt-40">
        <chq-input class="col-6" [inputModel]="formModel.insuranceCompanyAmount" (controlOutput)="onFormUpdate($event)"></chq-input>
        <chq-input class="col-6" [inputModel]="formModel.excessPayableCustomer"
          (controlOutput)="onFormUpdate($event)"></chq-input>
      </div>
      <div class="row  mt-40">
        <chq-input class="col-6" [inputModel]="formModel.serviceAdvisorName"
          (controlOutput)="onFormUpdate($event)"></chq-input>
        <chq-input class="col-6" [inputModel]="formModel.notes"
          (controlOutput)="onFormUpdate($event)"></chq-input>
      </div>
      <div class="row  mt-40">
        <div class="col-6 file-view-wrapper">
          <div class="">
            <div class="file-proof" *ngFor="let f of file; let i = index;">
              <span class="file-name">{{f?.name}}</span>
              <div class="action-icons">
                <span><mat-icon [svgIcon]="'cross'" (click)="handleFileRemove(i)"></mat-icon></span>
              </div>
            </div>
          </div>
          <chq-button *ngIf="file.length < 3" [buttonModel]="uploadAttachmentButtonModel"
            (click)="handleAddFileClick($event)"></chq-button>
          <input hidden type="file" #uploader (change)="handleUpload($event)"
            accept="image/png, image/jpg, image/jpeg, application/pdf">
        </div>
      </div>
      <div class="row mt-40">
        <div class="col-auto ml-auto">
          <chq-button [buttonModel]="submitButton"></chq-button>
        </div>
      </div>
    </div>
</ng-container>
  <div class="success-submission" *ngIf="isSubmissionSuccess">
    <h3>Success!</h3>
    <label>Your response has been submitted successfully.</label>
  </div>
</third-party-common-layout>