import { AfterContentInit, Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { CommonService } from 'src/app/services/common/common.service';
import { Editor } from 'tinymce';

let nextRichTextEditorUniqueId = 0;
@Component({
  selector: 'xa-rich-text-editor',
  templateUrl: './xa-rich-text-editor.component.html',
  styleUrls: [ './xa-rich-text-editor.component.scss' ]
})
export class XaRichTextEditorComponent implements OnInit, ControlValueAccessor, AfterContentInit {
  @Input() height?: number;
  @Input() menubar?: boolean;
  @Input() plugins?: string;
  @Input() toolbar?: string;
  @Input() maxCharacterLimit:number;
  @Input() readonly?: boolean;
  @Input() disabled?: boolean;
  @Input() required?: boolean;
  @Input() label?: string;
  @Input() name?: string;
  @Input() placeholder?: string;
  @Input() maxLength?: string | number;
  @Input() minLength?: string | number;
  @Input() blockMaxLength?: boolean;

  value: any;
  init = {}
  characterLength: number = 0;
  _isInitialized: boolean = false;

  
  _uniqueId: string = `xa-rich-text-editor-${nextRichTextEditorUniqueId++}`;

  _controlValueAccessorChangeFn: (value: any) => void = () => {};
  onTouched: () => any = () => {};

  /**
   * Creates an instance of ChqRichTextEditorComponent.
   * @date 8/13/2023 - 4:24:08 PM
   *
   * @constructor
   */
  constructor(private commonService: CommonService){}

  /**
   * init editor config
   */
  ngOnInit(): void {
    this.init = {
      base_url: '/tinymce',
      suffix: '.min',
      height: this.height ?? 500,
      menubar: this.menubar ?? false,
      statusbar: false,
      plugins: this.plugins ?? 'lists advlist',
      toolbar: this.toolbar ?? 'undo redo | formatselect | bold italic underline forecolor | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | strikethrough removeformat',
    }
    this.value ??= '';
    this.characterLength = this.stripHtmlTags(this.value).length;
    if(this.maxCharacterLimit && this.maxCharacterLimit > 0){
      this.init = { ...this.init, setup: (editor: Editor):void => {
        editor.on('keydown', (event: any) => {
          setTimeout(() => this.enforceCharacterLimit(event, editor), 0);
        });
  
        editor.on('paste', (event: any) => {
          setTimeout(() => this.enforceCharacterLimit(event, editor), 0);
        });
      }
      }
    }else{
      this.init = { ...this.init, setup: (editor: Editor):void => {
        editor.on('keydown', () => {
          this.inputChange(editor);
        });
  
        editor.on('paste', () => {
          this.inputChange(editor);
        });
      }
      }
    }
  }

  /**
   * after content init
   */
  ngAfterContentInit(): void {
    this._isInitialized = true;
  }

  /**
   * 
   * @param content enforceCharacterLimit
   * @returns 
   */
  enforceCharacterLimit(event: any, editor: Editor):void {
    const content = editor?.getContent();
    const strippedContent = this.stripHtmlTags(content);
    if (strippedContent.length > this.maxCharacterLimit) {
      event.preventDefault();
      this.commonService.openAlertDialog('Max Characters Exceeded', `Total Content cannot be more than ${this.maxCharacterLimit} characters`);
      setTimeout(() => editor?.undoManager.undo(), 10);
    }
    this._onInputInteraction(strippedContent);
  }

  /**
   * 
   * @param content strip html tags
   * @returns 
   */
  stripHtmlTags(content: string): string {
    const tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = content;
    return tempDivElement.textContent || tempDivElement.innerText || '';
  }

  /**
   * input change handler
   * @param {*} event
   */
  inputChange(editor:Editor): void {
    const val = this.stripHtmlTags(editor?.getContent());
    this.characterLength = val.length;
    this._controlValueAccessorChangeFn(val);
  }

  
  /**
   * Sets the value value. Implemented as part of ControlValueAccessor.
   * @param value
   */
  writeValue(value: any): void {
    this.value = value;
  }

  /**
   * Registers a callback to be triggered when the value value changes.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnChange(fn: (value: any) => void): void {
    this._controlValueAccessorChangeFn = fn;
  }

  /**
   * Registers a callback to be triggered when the control is touched.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /** Triggered when the radio button receives an interaction from the user. */
  _onInputInteraction(newValue:any): void {
    this._controlValueAccessorChangeFn(newValue);
  }

  /**
   * get generated id
   */
  get inputId(): string {
    return `${this._uniqueId}-input`;
  }

  /**get name */
  get nameId(): string {
    return this.name ?? `${this._uniqueId}`;
  }

}
