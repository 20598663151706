<div class="loading"
     *ngIf="isPDFLoading">
    <div class="overlay"></div>
    <div class="spinner">
        <mat-spinner [strokeWidth]="5"
                     [diameter]="50"></mat-spinner>
    </div>
</div>

<div class="page-container"
     #elementRef>
    <ng-container *ngIf="!isClaim && !isFNOL; else fnolPDFTemplate">
        <div class="header">
        </div>
        <div class="body">
            <h4 class="body-title">Damage Analysis Report</h4>
            <div class="body-first">
                <div class="case-header">
                <ng-container *ngIf="!isExternal; else externalTem">
                    <div>
                        <h4>{{isRepair ? 'Repair': 'Case'}} ID:&nbsp; </h4>
                        <label>{{isRepair? caseDetail?.repairNumber : caseDetail?.caseNumber}}</label>
                    </div>
                </ng-container>
                <ng-template #externalTem>
                    <div>
                        <h4>Job Number:&nbsp; </h4>
                        <label>{{caseDetail?.externalId}}</label>
                    </div>
                </ng-template>
                
                
                <div>
                    <h4>Creation Date:&nbsp; </h4>
                    <label>{{caseDetail?.createdDate | date:'dd/MM/yyyy hh:mm a'}}</label>
                </div>
            </div>
            <div class="case-header second">
                <div>
                    <h4>Case created by:&nbsp; </h4>
                    <label>{{caseDetail?.createdByUserName }}</label>
                </div>
                <div>
                    <h4>Tel:&nbsp; </h4>
                    <ng-container *ngIf="caseDetail?.phone; else noTel">
                        <label>{{caseDetail?.phone}}&nbsp; |&nbsp; </label>
                    </ng-container>
                    <ng-template #noTel>
                        <label>-&nbsp; |&nbsp;</label>
                    </ng-template>  
                    <h4> Email:&nbsp;</h4>
                    <ng-container *ngIf="!isExternal; else hideEmail">
                        <label>{{caseDetail?.createdBy }}</label>
                    </ng-container>
                    <ng-template #hideEmail>
                        <label>-</label>
                    </ng-template>  
                </div>
            </div>

                <div class="vehicle-detail-wrapper">
                    <div class="content-details no-padding">
                        <div class="border-light-gray-galaxy">
                            <div class="vehicle-block">
                                <img src="assets/img/front-right.png" />
                                <div class="text-block">
                                    <label>{{ caseDetail?.vehicleMake + ' ' + caseDetail?.vehicleModel?.replace('(' +
                                        caseDetail?.modelYearRange + ')','') }}</label>
                                    <span>{{ caseDetail?.modelYearRange }}</span>
                                </div>
                            </div>
                            <div class="text-row">
                                <h5>Plate Number</h5>
                                <h5 class="end">{{caseDetail?.licensePlateNumber}}</h5>
                            </div>
                            <div class="text-row">
                                <h5>VIN</h5>
                                <h5 class="end">{{caseDetail?.vinNumber}}</h5>
                            </div>
                            <div class="text-row">
                            <h5>MILEAGE</h5>
                                <ng-container *ngIf="caseDetail?.vehicleMileage; else notMileage">
                                    <h5 class="end">{{caseDetail?.vehicleMileage}}</h5>
                                </ng-container>
                                <ng-template #notMileage>
                                    <h5 class="end">-</h5>
                                </ng-template>
                            </div>
                            <div class="text-row">
                            <h5>REGISTRATION DATE</h5>
                                <ng-container *ngIf="caseDetail?.registrationDate; else notRegistrationDate">
                                    <h5 class="end">{{caseDetail?.registrationDate | date:'dd/MM/yyyy'}}</h5>
                                </ng-container>
                                <ng-template #notRegistrationDate>
                                    <h5 class="end">-</h5>
                                </ng-template>
                            </div>
                            <div class="text-row no-border"
                                 *ngIf="isRepair">
                                <h5>Manufacturing Date</h5>
                                <ng-container *ngIf="caseDetail?.manufacturingDate; else notManufacturingDate">
                                    <h5 class="end">{{caseDetail?.manufacturingDate | date:'dd/MM/yyyy'}}</h5>
                                </ng-container>
                                <ng-template #notManufacturingDate>
                                    <h5 class="end">-</h5>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="company-detail-wrapper"
                     *ngIf="caseDetail?.customerTypeName !=='Person'">
                    <div class=" col-6 content-details border-light-gray-galaxy no-padding"
                         *ngIf="!caseDetail?.caseDetail?.companyId">
                        <div class="wrapper-header">
                            <h4>Company Details</h4>
                        </div>
                        <div>
                            <h5>Company Name</h5>
                            <ng-container *ngIf="caseDetail?.caseDetail?.companyName; else notCompanyName2">
                                <h5>{{caseDetail?.caseDetail?.companyName}}</h5>
                            </ng-container>
                            <ng-template #notCompanyName2>
                                <h5>-</h5>
                            </ng-template>
                        </div>
                        <div class="no-border text-data-wrapper">
                            <h5>Company license</h5>
                            <ng-container *ngIf="caseDetail?.caseDetail?.licenseNumber; else notLicenseNumber">
                                <h5>{{caseDetail?.caseDetail?.licenseNumber}}</h5>
                            </ng-container>
                            <ng-template #notLicenseNumber>
                                <h5>-</h5>
                            </ng-template>
                        </div>
                    </div>
                    <div class="col-6 content-details border-light-gray-galaxy no-padding"
                         *ngIf="caseDetail?.caseDetail?.companyId">
                        <div class="wrapper-header">
                            <h4>Company Details</h4>
                        </div>
                        <div class="text-data-wrapper">
                            <h5>Company Name</h5>
                            <ng-container *ngIf="caseDetail?.caseDetail?.companyName; else notCompanyName">
                                <h5>{{caseDetail?.caseDetail?.companyName}}</h5>
                            </ng-container>
                            <ng-template #notCompanyName>
                                <h5>-</h5>
                            </ng-template>
                        </div>
                        <div class="text-data-wrapper">
                            <h5>Company license</h5>
                            <ng-container *ngIf="caseDetail?.caseDetail?.licenseNumber; else notLicenseNumber2">
                                <h5>{{caseDetail?.caseDetail?.licenseNumber}}</h5>
                            </ng-container>
                            <ng-template #notLicenseNumber2>
                                <h5>-</h5>
                            </ng-template>
                        </div>
                        <div class="no-border text-data-wrapper">
                            <h5>Claim ID</h5>
                            <ng-container *ngIf="(caseDetail?.claimNumber || caseDetail?.caseDetail?.claimNumber); else notClaimNumber">
                                <h5>{{caseDetail?.claimNumber ?? caseDetail?.caseDetail?.claimNumber}}</h5>
                            </ng-container>
                            <ng-template #notClaimNumber>
                                <h5>-</h5>
                            </ng-template>
                        </div>
                    </div>
                    <div class="col-6 content-details border-light-gray-galaxy no-padding">
                        <div class="wrapper-header">
                            <h4>Driver Details</h4>
                        </div>
                        <div class="text-data-wrapper">
                            <h5>Driver Name</h5>
                        <ng-container *ngIf="caseDetail?.caseDetail?.firstName && caseDetail?.caseDetail?.firstName !== '-' else elseName">
                            <h5>{{ caseDetail?.caseDetail?.firstName + ' ' + caseDetail?.caseDetail?.lastName }}</h5>
                        </ng-container>
                        <ng-template #elseName>
                            <h5>-</h5>
                        </ng-template>                        
                        </div>
                        <div class="text-data-wrapper">
                            <h5>Email</h5>
                            <h5>{{caseDetail?.caseDetail?.email}}</h5>
                        </div>
                        <div class="no-border text-data-wrapper">
                            <h5>Telephone</h5>
                        <ng-container *ngIf="caseDetail?.caseDetail?.mobileNumber && caseDetail?.caseDetail?.firstName !== '-'; else elseMobile">
                                <h5>{{caseDetail?.caseDetail?.countryCode + ' ' + caseDetail?.caseDetail?.mobileNumber}}
                                </h5>
                            </ng-container>
                            <ng-template #elseMobile>
                                <h5>-</h5>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="image-container">
                <h4 class="step-name">Summary</h4>
                <h4 class="step-subtitle">AI Repair Recommendation Summary</h4>
                <div class="data-wrapper">
                    <div class="table-container">
                        <div class="table-wrapper">
                            <div class="table-header-wrapper row">
                                <h4 class="col-8">Part</h4>
                                <h4 class="col-4">Repair Recommendation</h4>
                            </div>
                            <div class="row data-cols"
                                 *ngFor="let row of damageRecommendations">
                                <span class="col-8 border-right">{{row.partName}}</span>
                                <span class="col-4">{{row.recommendation}}</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="uploadSteps.length">
                <div class="image-container"
                     *ngFor="let step of uploadSteps">
                    <h4 class="step-name">{{step.stepName}}</h4>
                    <div class="data-wrapper">
                        <div class="image-wrapper">
                            <span>ANALYZED IMAGE</span>
                            <img *ngIf="step.url"
                                 [src]="step.url" />
                        </div>
                        <ng-container *ngIf="getRepairCategory(step)"></ng-container>
                        <div class='repair-category'
                             *ngIf="currentRepairCategory?.categorization">
                            <h4>Repair Category</h4>
                            <div class='category'>
                                <img [src]="currentRepairCategory?.categorizationIconUrl"
                                     alt="repair category logo">
                                <label>{{currentRepairCategory?.categorization}}</label>
                            </div>
                        </div>
                        <div class="table-container">
                            <div class="table-wrapper"
                                 *ngIf="step?.inspectionItem?.length > 0 && !step.isEmpty">
                                <div class="table-header-wrapper row">
                                    <h4 class="col-4">Part-Wise Damage</h4>
                                    <h4 class="col-2">Part Conf(%)</h4>
                                    <h4 class="col-2">Damage Conf( %)</h4>
                                    <h4 class="col-4">AI Recommendation</h4>
                                </div>
                                <div class="row data-cols"
                                     *ngFor="let row of step.inspectionItem">
                                    <span class="col-4 border-right">{{row.partWiseDetails}}</span>
                                    <span class="col-2 border-right">{{row.partConfidence ? ((row.partConfidence * 100 |
                                        number:'1.0-0') + '%'):''}}</span>
                                    <span class="col-2 border-right">{{row.damageConfidence ? ((row.damageConfidence *
                                        100 |
                                        number:'1.0-0') +
                                        '%'):''}}</span>
                                    <span class="col-4">{{row.damageRecommendation}}</span>
                                </div>

                            </div>
                            <div *ngIf="step?.inspectionItem?.length === 0 || step.isEmpty">
                                <label class="no-data">No data present</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-header">
            <label>This was generated by Addenda {{ !isRepair ? 'Quote': 'Repair'}}</label>
            <img src="assets/img/{{isRepair? 'addenda-repair': 'addenda-quote'}}.png">
        </div>
    </ng-container>

    <ng-template #fnolPDFTemplate>
        <ng-container *ngIf="isFNOL; else claimPDFTemplate">
            <div class="header">
            </div>
            <div class="body">
                <h4 class="body-title">Damage Analysis Report</h4>
                <div class="body-first">
                    <div class="vehicle-detail-wrapper">
                        <div class="content-details no-padding">
                            <div class="border-light-gray-galaxy">
                                <div class="vehicle-block">
                                    <img src="assets/img/front-right.png" />
                                    <div class="text-block">
                                        <label>{{ claimDetail?.vehicleMetaData?.make + ' ' + claimDetail?.vehicleMetaData?.model }}</label>
                                        <span>{{ claimDetail?.vehicleMetaData?.modelYear }}</span>
                                    </div>
                                </div>
                                <div class="text-row">
                                    <h5>Plate Number</h5>
                                    <h5 class="end">{{claimDetail?.vehicleMetaData?.registrationNumber}}</h5>
                                </div>
                                <div class="text-row">
                                    <h5>VIN</h5>
                                    <h5 class="end">{{claimDetail?.vehicleMetaData?.vin}}</h5>
                                </div>

                                <div class="text-row">
                                    <h5>Registration Date</h5>
                                    <ng-container *ngIf="claimDetail?.vehicleMetaData?.registrationDate; else notRegistrationDate">
                                        <h5 class="end">{{claimDetail?.vehicleMetaData?.registrationDate | date:'dd/MM/yyyy'}}</h5>
                                    </ng-container>
                                    <ng-template #notRegistrationDate>
                                        <h5 class="end">-</h5>
                                    </ng-template>
                                </div>
                                <div class="text-row no-border">
                                    <h5>Manufacturing Date</h5>
                                    <ng-container *ngIf="claimDetail?.vehicleMetaData?.manufacturingDate; else notManufacturingDate">
                                        <h5 class="end">{{claimDetail?.vehicleMetaData?.manufacturingDate | date:'dd/MM/yyyy'}}</h5>
                                    </ng-container>
                                    <ng-template #notManufacturingDate>
                                        <h5 class="end">-</h5>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="image-container">
                    <h4 class="step-name">Summary</h4>
                    <h4 class="step-subtitle">AI Repair Recommendation Summary</h4>
                    <div class="data-wrapper">
                        <div class="table-container">
                            <div class="table-wrapper">
                                <div class="table-header-wrapper row">
                                    <h4 class="col-8">Part</h4>
                                    <h4 class="col-4">Repair Recommendation</h4>
                                </div>
                                <div class="row data-cols"
                                     *ngFor="let row of damageRecommendations">
                                    <span class="col-8 border-right">{{row.partName}}</span>
                                    <span class="col-4">{{row.recommendation}}</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="uploadSteps?.length">
                    <div class="image-container"
                         *ngFor="let step of uploadSteps">
                        <h4 class="step-name">{{step.stepName}}</h4>
                        <div class="data-wrapper">
                            <div class="image-wrapper">
                                <span>ANALYZED IMAGE</span>
                                <img *ngIf="step.url"
                                     [src]="step.url" />
                            </div>
                            <ng-container *ngIf="getRepairCategory(step)"></ng-container>
                            <div class='repair-category'
                                 *ngIf="currentRepairCategory?.categorization">
                                <h4>Repair Category</h4>
                                <div class='category'>
                                    <img [src]="currentRepairCategory?.categorizationIconUrl"
                                         alt="repair category logo">
                                    <label>{{currentRepairCategory?.categorization}}</label>
                                </div>
                            </div>
                            <div class="table-container">
                                <div class="table-wrapper"
                                     *ngIf="step?.inspectionItem?.length > 0 && !step.isEmpty">
                                    <div class="table-header-wrapper row">
                                        <h4 class="col-4">Part-Wise Damage</h4>
                                        <h4 class="col-2">Part Conf(%)</h4>
                                        <h4 class="col-2">Damage Conf( %)</h4>
                                        <h4 class="col-4">AI Recommendation</h4>
                                    </div>
                                    <div class="row data-cols"
                                         *ngFor="let row of step.inspectionItem">
                                        <span class="col-4 border-right">{{row.partWiseDetails}}</span>
                                        <span class="col-2 border-right">{{row.partConfidence ? ((row.partConfidence * 100 |
                                            number:'1.0-0') + '%'):''}}</span>
                                        <span class="col-2 border-right">{{row.damageConfidence ? ((row.damageConfidence * 100 |
                                            number:'1.0-0') +
                                            '%'):''}}</span>
                                        <span class="col-4">{{row.damageRecommendation}}</span>
                                    </div>

                                </div>
                                <div *ngIf="step?.inspectionItem?.length === 0 || step.isEmpty">
                                    <label class="no-data">No data present</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-header">
                <label>This was generated by Addenda Claim</label>
                <img alt=""
                     src="assets/img/addenda-claims.png">
            </div>
        </ng-container>

    </ng-template>

    <ng-template #claimPDFTemplate>
        <div class="header">
        </div>
        <div class="body">
            <h4 class="body-title">Damage Analysis Report</h4>
            <div class="body-first">
                <div class="case-header">
                    <div>
                        <h4>Claim ID:&nbsp; </h4>
                        <label>{{claimDetail?.claimNo}}</label>
                    </div>
                    <div>
                        <h4>Claim Intimation Date:&nbsp; </h4>
                        <label>{{claimDetail.customerDetails?.claimIntimationDate | date:'dd/MM/yyyy'}}</label>
                    </div>
                </div>

                <div class="vehicle-detail-wrapper">
                    <div class="content-details no-padding">
                        <div class="border-light-gray-galaxy">
                            <div class="vehicle-block">
                                <img src="assets/img/front-right.png" />
                                <div class="text-block">
                                    <label>{{ claimDetail?.vehicleMetaData?.vehicleName + ' ' + claimDetail?.vehicleMetaData?.vehicleType }}</label>
                                    <span>{{ claimDetail?.vehicleMetaData?.year }}</span>
                                </div>
                            </div>
                            <div class="text-row">
                                <h5>Plate Number</h5>
                                <h5 class="end">{{claimDetail?.vehicleMetaData?.plateNo}}</h5>
                            </div>
                            <div class="text-row">
                                <h5>VIN</h5>
                                <h5 class="end">{{claimDetail?.vehicleMetaData?.vinNum}}</h5>
                            </div>

                            <div class="text-row">
                                <h5>Registration Date</h5>
                                <ng-container *ngIf="claimDetail?.vehicleMetaData?.registrationDate; else notRegistrationDate">
                                    <h5 class="end">{{claimDetail?.vehicleMetaData?.registrationDate | date:'dd/MM/yyyy'}}</h5>
                                </ng-container>
                                <ng-template #notRegistrationDate>
                                    <h5 class="end">-</h5>
                                </ng-template>
                            </div>
                            <div class="text-row no-border">
                                <h5>Manufacturing Date</h5>
                                <ng-container *ngIf="claimDetail?.vehicleMetaData?.manufacturingDate; else notManufacturingDate">
                                    <h5 class="end">{{claimDetail?.vehicleMetaData?.manufacturingDate | date:'dd/MM/yyyy'}}</h5>
                                </ng-container>
                                <ng-template #notManufacturingDate>
                                    <h5 class="end">-</h5>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="company-detail-wrapper">
                    <div class=" col-6 content-details border-light-gray-galaxy no-padding">
                        <div class="wrapper-header">
                            <h4>Policy Details</h4>
                        </div>
                        <div>
                            <h5>Policy Number</h5>
                            <h5>{{claimDetail?.customerDetails?.policyNo}}</h5>
                        </div>
                        <div class="no-border text-data-wrapper">
                            <h5>Insurance Organization Name</h5>
                            <h5>{{claimDetail?.customerDetails?.insuranceOrganizationName}}</h5>
                        </div>
                    </div>
                    <div class="col-6 content-details border-light-gray-galaxy no-padding">
                        <div class="wrapper-header">
                            <h4>Policy Holder Details</h4>
                        </div>
                        <div class="text-data-wrapper">
                            <h5>Policy Holder Name</h5>
                            <h5>{{ claimDetail?.customerDetails?.policyHolderFirstName + ' ' + claimDetail?.customerDetails?.policyHolderLastName }}</h5>
                        </div>
                        <div class="text-data-wrapper">
                            <h5>Email</h5>
                            <h5>{{claimDetail?.customerDetails?.policyHolderEmail}}</h5>
                        </div>
                        <div class="no-border text-data-wrapper">
                            <h5>Telephone</h5>
                            <ng-container *ngIf="claimDetail?.customerDetails?.policyHolderMobileNumber; else elseMobile">
                                <h5>{{claimDetail?.customerDetails?.policyMobileCountryCode + ' ' + claimDetail?.customerDetails?.policyHolderMobileNumber}}
                                </h5>
                            </ng-container>
                            <ng-template #elseMobile>
                                <h5>-</h5>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="image-container">
                <h4 class="step-name">Summary</h4>
                <h4 class="step-subtitle">AI Repair Recommendation Summary</h4>
                <div class="data-wrapper">
                    <div class="table-container">
                        <div class="table-wrapper">
                            <div class="table-header-wrapper row">
                                <h4 class="col-8">Part</h4>
                                <h4 class="col-4">Repair Recommendation</h4>
                            </div>
                            <div class="row data-cols"
                                 *ngFor="let row of damageRecommendations">
                                <span class="col-8 border-right">{{row.partName}}</span>
                                <span class="col-4">{{row.recommendation}}</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="uploadSteps?.length">
                <div class="image-container"
                     *ngFor="let step of uploadSteps">
                    <h4 class="step-name">{{step.stepName}}</h4>
                    <div class="data-wrapper">
                        <div class="image-wrapper">
                            <span>ANALYZED IMAGE</span>
                            <img *ngIf="step.url"
                                 [src]="step.url" />
                        </div>
                        <ng-container *ngIf="getRepairCategory(step)"></ng-container>
                        <div class='repair-category'
                             *ngIf="currentRepairCategory?.categorization">
                            <h4>Repair Category</h4>
                            <div class='category'>
                                <img [src]="currentRepairCategory?.categorizationIconUrl"
                                     alt="repair category logo">
                                <label>{{currentRepairCategory?.categorization}}</label>
                            </div>
                        </div>
                        <div class="table-container">
                            <div class="table-wrapper"
                                 *ngIf="step?.inspectionItem?.length > 0 && !step.isEmpty">
                                <div class="table-header-wrapper row">
                                    <h4 class="col-4">Part-Wise Damage</h4>
                                    <h4 class="col-2">Part Conf(%)</h4>
                                    <h4 class="col-2">Damage Conf( %)</h4>
                                    <h4 class="col-4">AI Recommendation</h4>
                                </div>
                                <div class="row data-cols"
                                     *ngFor="let row of step.inspectionItem">
                                    <span class="col-4 border-right">{{row.partWiseDetails}}</span>
                                    <span class="col-2 border-right">{{row.partConfidence ? ((row.partConfidence * 100 |
                                        number:'1.0-0') + '%'):''}}</span>
                                    <span class="col-2 border-right">{{row.damageConfidence ? ((row.damageConfidence * 100 |
                                        number:'1.0-0') +
                                        '%'):''}}</span>
                                    <span class="col-4">{{row.damageRecommendation}}</span>
                                </div>

                            </div>
                            <div *ngIf="step?.inspectionItem?.length === 0 || step.isEmpty">
                                <label class="no-data">No data present</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-header">
            <label>This was generated by Addenda Claim</label>
            <img alt=""
                 src="assets/img/addenda-claims.png">
        </div>
    </ng-template>
</div>