import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiPagination } from 'src/app/widgets/xa-paginator/xa-paginator.component';
import { InvoiceData } from './invoice-data';
import { InvoiceSummaryData } from './invoice-summary-data';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class InvoiceService {

  /**
   * constructor
   * @param http
  */
  constructor(private http: HttpClient) { }

  /**
   *
   * @param pagination
   * @returns observable
   */
  public getInvoices(pagination: ApiPagination, queryParams: string = null): Observable<any> {
    return of(InvoiceData);
  }

  /**
   * get invoice per case
   */
  public invoicePerDetail(caseId:string):Observable<any>{
    const url = `${environment.caseMgmtUrl}/api/invoice/${caseId}`;
    return this.http.get(url);
  }

  /**
     * get invoice per case
     */
  public saveInvoicePerDetail(body:any):Observable<any>{
    const url = `${environment.caseMgmtUrl}/api/invoice/detail`;
    return this.http.post(url, body);
  }

  /**
   * get invoice pdf
   */
  public invoicePDF(repairGuid:string, invoiceGuid:string, body:any):Observable<any>{
    const localTime = new Date().toString();
    const headers = new HttpHeaders().append('localtime', localTime)
    const url = `${environment.caseMgmtUrl}/api/invoice/${repairGuid}/${invoiceGuid}/report`; 
    return this.http.post(url, body, { headers });
  }

  /**
   * get invoice per case
   */
  public invoicePerDetailProvider(caseId:string, type:number):Observable<any>{
    const url = `${environment.caseMgmtUrl}/api/invoice/${caseId}/detail/${type}`;
    return this.http.get(url);
  }

  /**
   * get invoice per case
   */
  public assignInvoice(body:any):Observable<any>{
    const url = `${environment.caseMgmtUrl}/api/invoice`;
    return this.http.post(url, body);
  }


  /**
   *
   * @param
   * @returns observable
   */
  public getInvoiceSummary(): Observable<any> {
    return of(InvoiceSummaryData);
  }

  /**
   * Generate Tax invoice
   */
  public generateTaxInvoice(repairGuid:string):Observable<any>{
    const url = `${environment.caseMgmtUrl}/api/invoice/${repairGuid}/sendinvoicetointegrationsystem`;
    return this.http.put(url, null);
  }
}
