import { Component, ViewChild, HostListener, Output, EventEmitter, AfterViewInit } from '@angular/core';

export type position = {
  x: string | number;
  y: string | number;
};
@Component({
  selector: 'xa-signature',
  templateUrl: './xa-signature.component.html',
  styleUrls: [ './xa-signature.component.scss' ]
})
export class XaSignature implements AfterViewInit{
  @ViewChild('sigPad') sigPad;
  @Output() controlOutput: EventEmitter<any> = new EventEmitter();
  sigPadElement;
  context;
  isDrawing = false;
  img;

  /**
   * after view init
   */
  ngAfterViewInit(): void {
    this.sigPadElement = this.sigPad.nativeElement;
    this.context = this.sigPadElement.getContext('2d');
    this.context.strokeStyle = '#000';
  }

  /**
   * document mouseup event
   * @param e 
   */
  @HostListener('document:mouseup', [ '$event' ])
  onMouseUp(e): void {
    this.isDrawing = false;
    if(e.srcElement?.id === 'signature'){
      this.img = this.sigPadElement.toDataURL('image/png');
      this.controlOutput.emit(this.img);
    }
  }

  /**
   * on mouse down event
   * @param e 
   */
  onMouseDown(e): void {
    this.isDrawing = true;
    const coords = this.relativeCoords(e);
    this.context.moveTo(coords.x, coords.y);
  }

  /**
   * on mouse move event
   * @param e 
   */
  onMouseMove(e): void {
    if (this.isDrawing) {
      const coords = this.relativeCoords(e);
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
    }
  }

  /**
   * document touch end event
   * @param e 
   */
  @HostListener('document:touchend', [ '$event' ])
  onTouchEnd(e): void {
    this.isDrawing = false;
    this.img = this.sigPadElement.toDataURL('image/png');
    this.controlOutput.emit(this.img);
  }

  /**
   * document touch start event
   * @param e 
   */
  @HostListener('document:touchstart', [ '$event' ])
  onTouchStart(e): void {
    this.onMouseDown(e);
  }

  /**
   * document touch move event
   * @param e 
   */
  @HostListener('document:touchmove', [ '$event' ])
  onTouchMove(e): void{
    this.onMouseMove(e);
  }

  /**
   * get position x and y 
   * @param event 
   * @returns 
   */
  private relativeCoords(event): position {
    const bounds = event.target.getBoundingClientRect();
    let x, y;
    if(event.type === 'touchmove' || event.type === 'touchstart'){
      x = event.touches[0].clientX - bounds.left;
      y = event.touches[0].clientY - bounds.top;
    }else{
      x = event.clientX - bounds.left;
      y = event.clientY - bounds.top;
    }
    return { x: x, y: y };
  }

  /**
   * clear the signature
   */
  clear(): void {
    this.context.clearRect(0, 0, this.sigPadElement.width, this.sigPadElement.height);
    this.context.beginPath();
  }

}
