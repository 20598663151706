import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { whiteSpaceValidator } from 'src/app/helper/form/repair-stages.helper';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'app-multi-action-confirm-dialog',
  templateUrl: './multi-action-confirm-dialog.component.html',
  styleUrls: [ './multi-action-confirm-dialog.component.scss' ]
})
export class MultiActionConfirmDialogComponent {

  /**
   * constructor
   * @param dialogRef
   * @param data
   */
  titleContainsHtml: boolean = false;
  messageContainsHtml: boolean = false;
  htmlTags = [ '<span>', '<b>', '<div>', '<h1>', '<h2>', '<h3>', '<h4>', '<h5>', '<a>', '<p>', '<br />', '<mat-icon>', '</a>' ];
  formSubmitted: boolean = false;

  public actionForm = new FormGroup({
    Action: new FormControl('', [ Validators.required, whiteSpaceValidator() ])
  });

  /**
   * constructor
   */
  constructor(
    public dialogRef: MatDialogRef<MultiActionConfirmDialogComponent>,
    private monitorService: MonitorService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    for (const key in this.htmlTags) {
      if (!this.titleContainsHtml && data.title.indexOf(this.htmlTags[key]) !== -1) {
        this.titleContainsHtml = true;
      }

      if (!this.messageContainsHtml && data.message.indexOf(this.htmlTags[key]) !== -1) {
        this.messageContainsHtml = true;
      }
    }
  }

  /**
   * close dialog
   */
  close(): void {
    this.formSubmitted = true;
    if (this.actionForm.valid) {
      this.dialogRef.close(this.actionForm.controls.Action.value);
      this.monitorService.logEvent('close', [ 'MultiActionConfirmDialogComponent', 'addenda-quote' ]);
    }
  }

}
