<div class="message-container">
    <div class="header">
        <h3>{{data.hideUpdate ? 'View Notes' : !this.data.notes? 'Add Notes':'Edit Notes'}}</h3>
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'" (click)="close();"></mat-icon>
        </div>
    </div>
    <div class="content">
        <chq-input [inputModel]="notesModel" (controlOutput)="onFormUpdate($event);" tabindex="-1"></chq-input>
    </div>
    <div class="footer form-data-footer">
        <chq-button [buttonModel]="cancelButton"
            *ngIf="this.data.notes && !this.data.hideUpdate && !this.commonService.isViewOnly"
            class="left"></chq-button>
        <chq-button [buttonModel]="confirmButton" *ngIf="!this.data.hideUpdate && !this.commonService.isViewOnly"
            class="right"></chq-button>
    </div>
</div>