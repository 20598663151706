import { Component, Input } from '@angular/core';

@Component({
  selector: 'xa-tab',
  styles: [
    `
    .pane{
      padding-top: 1em;
      -webkit-transition: all 1s linear;
      -moz-transition: all 1s linear;
      -o-transition: all 1s linear;
      transition: all 1s linear;
    }
  `
  ],
  template: `
    <div [hidden]="!active" class="pane">
      <ng-content></ng-content>
    </div>
  `
})
export class XaTabComponent {
  @Input() tabTitle: string;
  @Input() active:boolean = false;
  @Input() disabled: boolean = false;
}
