<div class="close"
     [ngClass]="{ 'lock-screen':getOrientationLock}"
     *ngIf="image || showCamera">
    <mat-icon (click)="closeCamera();"
              [svgIcon]="'cross'"></mat-icon>
    <span *ngIf="displaySkip"
          (click)="updateSkip();">{{skipText | translate}}</span>
</div>
<div class="camera-wrapper"
     [ngClass]="{'show-cam':showCamera || image, 'lock-screen':getOrientationLock}">
    <div class="camera-container"
         [hidden]="!showCamera">
        <video #videoElement
               autoplay
               playsinline>
        </video>
        <div class="text-container">
            <div class="procedure"><span>{{procedureStep}}</span></div>
        </div>
        <ng-container *ngIf="showCamera">
            <xa-camera-overlay [vehicleModel]="outlineUrl"
                               [procedureStep]="currentStep"
                               #overlayElement></xa-camera-overlay>
        </ng-container>
    </div>
    <div class="shutter-container"
         *ngIf="showCamera && !processing">
        <mat-icon (click)="captureImage()"
                  svgIcon="shutter"></mat-icon>
        <span>{{'Photo' | translate}}</span>
    </div>
    <div class="loading"
         *ngIf="processing">
        <span>{{'Processing...' | translate}}</span>
    </div>

    <div class="image-container"
         *ngIf="image && !showCamera">
        <img class="image-captured"
             [src]="image"
             alt="img" />
        <div class="actions">
            <xa-button type="outline"
                       (click)="startCamera();">Retake</xa-button>
            <xa-button (click)="emitUpload();">Upload</xa-button>
        </div>
    </div>
</div>