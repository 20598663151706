import { ValidatorFn, Validators, ValidationErrors } from '@angular/forms';
import { ChqWidgetsInputModel } from 'src/app/model/chq-widgets-input-model';
import { menuItem } from 'src/app/model/menu';

interface KeyValueType<T> {
  [k: string]: T;
}

export interface AddEstimatePartForm extends KeyValueType<ChqWidgetsInputModel> {
  operationType: ChqWidgetsInputModel;
  operation: ChqWidgetsInputModel;
  code: ChqWidgetsInputModel;
  repairType: ChqWidgetsInputModel;
  quantity?: ChqWidgetsInputModel;
  price?: ChqWidgetsInputModel;
  labourTime?: ChqWidgetsInputModel;
  labourAmount?: ChqWidgetsInputModel;
}

export const partsMenu: menuItem[] = [
  {
    name: 'Front',
    displayName: 'Front',
    image: 'assets/img/part-front.png',
    title: '',
    subTitle: '',
    active: true
  },
  {
    name: 'Right Side',
    displayName: 'Right Side',
    image: 'assets/img/part-right.png',
    title: '',
    subTitle: ''
  },
  {
    name: 'Left Side',
    displayName: 'Left Side',
    image: 'assets/img/part-left.png',
    title: '',
    subTitle: ''
  },
  {
    name: 'Above',
    displayName: 'Above',
    image: 'assets/img/part-above.png',
    title: '',
    subTitle: ''
  },
  {
    name: 'Rear',
    displayName: 'Rear',
    image: 'assets/img/part-rear.png',
    title: '',
    subTitle: ''
  },
  {
    name: 'Interior',
    displayName: 'Interior',
    image: 'assets/img/part-interior.png',
    title: '',
    subTitle: ''
  },
  {
    name: 'Mechanical',
    displayName: 'Mechanical',
    image: 'assets/img/part-mechanical.png',
    title: '',
    subTitle: ''
  }
]
  
export const newEstimatePartForm = (): AddEstimatePartForm => {
  return {
    operationType: {
      placeHolder: 'Select Operation Type',
      name: 'operationType',
      label: 'Operation Type',
      options: [ { 'id': 1, 'name': 'Check' } ],
      displayValue: 'name',
      fieldValue: 'id',
      type: 'select',
      value: '',
      required: true,
      validation: {
        name: 'operationType',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.required ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return 'Please select operation type'
          }
          return '';
        }
      },
    },
    operation: {
      placeHolder: 'Part/Operation',
      label: 'part/operation',
      name: 'operation',
      options: [ { 'id': 1, 'name': 'Top-Section M' } ],
      displayValue: 'name',
      fieldValue: 'id',
      type: 'select',
      value: '',
      required: true,
      validation: {
        name: 'operation',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.required ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return 'Please enter part/operation'
          }
          return '';
        }
      }
    },
    code: {
      placeHolder: 'Code',
      label: 'Code',
      name: 'code',
      value: '',
      type: 'text',
      maxLength: 50,
      validation: {
        name: 'code',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.maxLength(50), Validators.pattern(/^[a-z\d\s]+$/i) ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['maxlength']) {
            return 'Code should have not more than 50 characters'
          }
          if (error['pattern']) {
            return 'Only alphanumberic characters are allowed'
          }
          return '';
        }
      }
    },
    repairType: {
      placeHolder: 'Select Repair type',
      name: 'repairType',
      label: 'REPAIR TYPE',
      options: [ {} ],
      displayValue: 'name',
      fieldValue: 'name',
      type: 'select',
      value: '',
    },
    quantity: {
      placeHolder: 'Quantity',
      label: 'Quantity',
      name: 'quantity',
      value: '',
      type: 'number',
      validation: {
        name: 'quantity',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ ])
        },
        validationMessage: (error: ValidationErrors): string => {
        //   if (error['maxlength']) {
        //     return 'Code should have not more than 50 characters'
        //   }
        //   if (error['pattern']) {
        //     return 'Only alphanumberic characters are allowed'
        //   }
          return '';
        }
      }
    },
    price: {
      placeHolder: 'Price',
      label: 'Price',
      name: 'price',
      value: '',
      type: 'number',
      validation: {
        name: 'price',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ ])
        },
        validationMessage: (error: ValidationErrors): string => {
        //   if (error['maxlength']) {
        //     return 'Code should have not more than 50 characters'
        //   }
        //   if (error['pattern']) {
        //     return 'Only alphanumberic characters are allowed'
        //   }
          return '';
        }
      }
    },
    labourTime: {
      placeHolder: 'Labour Time',
      name: 'labourTime',
      label: 'Labour time(HOURs)',
      value: 0,
      type: 'number',
      validation: {
        name: 'time',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ ])
        },
        validationMessage: (error: ValidationErrors): string => {
          //   if (error['maxlength']) {
          //     return 'Code should have not more than 50 characters'
          //   }
          //   if (error['pattern']) {
          //     return 'Only alphanumberic characters are allowed'
          //   }
          return '';
        }
      }
    },
  }
};