import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { caseToSearchSectionMapper, SearchSectionModel } from 'src/app/model/chq-search-result-section-model';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { SearchService } from 'src/app/services/search/search.service';
import { ApiPagination, defaultPagination } from '../../xa-paginator/xa-paginator.component';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { menuItem, menuItems } from 'src/app/model/menu';

@Component({
  selector: 'chq-search-detail',
  templateUrl: './chq-search-detail.component.html',
  styleUrls: [ './chq-search-detail.component.scss' ]
})
export class ChqSearchDetailComponent implements AfterViewInit, OnInit {
  public pagination: ApiPagination = { ...defaultPagination, orderBy: 'CreatedDate' };
  public queryParams = '';
  public searchResult = [];
  public currentWidth = '';
  public searchInProgress = false;
  private currentSearchTerm = '';
  public groupedSearchResult: SearchSectionModel[] = [];
  @Input() searchKey = '';
  @Input() isClaimModule: boolean = false;
  public showSearchResults = false;
  @ViewChild('row', { static: false }) row: ElementRef;
  @ViewChild('searchBox', { static: false }) searchBox: ElementRef;
  public bookingButton: ChqWidgetsButtonModel = {
    label: 'New Booking',
    type: 'primary',
    icon: 'plus-active',
    color: 'primary'
  }

  public estimateButton: ChqWidgetsButtonModel = {
    label: 'New Quote',
    type: 'primary',
    icon: 'plus-active',
    color: 'primary'
  }
  @Output() closeSearch: EventEmitter<any> = new EventEmitter();

  repairPermission: boolean = false;
  quotePermission: boolean = false;


  subscription: Subscription;
  isExternal: boolean = false;
  menuItems: menuItem[] = menuItems;
  repairerView: boolean = false;


  /**
   * is search Empty
   */
  get empty(): boolean {
    return this.groupedSearchResult.filter((value) => {
      return value?.count > 0
    }).length === 0;
  }

  /**
   * constructor
   * @param router 
   */
  constructor(private commonService: CommonService,
    private searchService: SearchService,
    private eRef: ElementRef,
    private router: Router,
    private monitorService: MonitorService) {
    this.commonService.userPermission.subscribe((res: any) => {
      this.repairPermission = res.repair;
      this.quotePermission = res.quote;
    });

    this.subscription = this.commonService.userProfileData.subscribe({
      next: (res: any) => {
        if (res) {
          const automotiveServices = res?.data?.userPermission?.automotiveServices;
          const permissionList = automotiveServices.find(x => x.automotiveServiceName.toLowerCase() === 'carheal quote')?.permissions;
          const quoteMenus = this.menuItems.find(x => x.name === 'quote')?.childMenu;
          if (quoteMenus && quoteMenus?.length > 0 && permissionList && permissionList?.length > 0) {
            this.isExternal = this.commonService.hasPermission('case.external', permissionList);
          }
        }

      }
    });
  }

  /**
   * close
   */
  close(): void {
    this.showSearchResults = false;
    this.searchBox.nativeElement.value = '';
    this.closeSearch.emit();
  }

  /**
   * handle user icon click in search result
   */
  handleUserAction($event: any): void {
    if ($event['module'] === 'quote') {
      this.openNewQuote(true);
    } else {
      this.openNewBooking(true, $event.caseId);
    }
  }

  /**
   * handle search event
   * @param $event 
   * @returns 
   */
  search(searchKey: string): void {
    if (!searchKey) {
      return;
    }
    if (this.currentSearchTerm === searchKey) {
      return;
    }
    this.commonService.showLoading();
    const searchObject = {
      'pageSize': this.isClaimModule ? 10 : 5,
      'pageNumber': 1,
      searchKey
    }
    this.monitorService.logEvent('search', [ 'ChqSearchBarComponent', 'Addenda-Quote' ]);
    this.searchInProgress = true;

    if (this.isClaimModule && !this.repairerView) {
      this.searchService.generalSearchClaim(searchObject).subscribe({
        next: (results) => {
          if (results?.data) {
            this.searchResult = results.data;
          } else {
            this.searchResult = [];
            this.groupedSearchResult = [];
          }
          this.commonService.hideLoading();
          this.currentSearchTerm = searchKey;
          this.showSearchResults = true;
          this.searchInProgress = false;
          this.groupedSearchResult = caseToSearchSectionMapper(this.searchResult, searchKey, false);
        },
        error: () => {
          this.searchResult = [];
          this.groupedSearchResult = [];
          this.showSearchResults = true;
          this.commonService.hideLoading();
        }
      });
    } else {
      this.searchService.generalSearch(searchObject).subscribe({
        next: (results) => {
          if (results?.data) {
            this.searchResult = results.data;
          } else {
            this.searchResult = [];
            this.groupedSearchResult = [];
          }
          this.commonService.hideLoading();
          this.currentSearchTerm = searchKey;
          this.showSearchResults = true;
          this.searchInProgress = false;
          this.groupedSearchResult = caseToSearchSectionMapper(this.searchResult, searchKey, this.isExternal);
        },
        error: () => {
          this.searchResult = [];
          this.groupedSearchResult = [];
          this.showSearchResults = true;
          this.commonService.hideLoading();
        }
      })
    }

  }

  /**
   * open new booking 
   */
  openNewBooking(isFromSearchResult?: boolean, caseGuid?: string): void {
    if (!isFromSearchResult) {
      this.commonService.setSessionStorage('selectedSearchItem', '');
    }
    this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
      this.router.navigate([ 'repair/booking/0' ], { queryParams: { 'caseId': caseGuid } });
    });
    this.close();
  }

  /**
   * open new case 
   */
  openNewQuote(isFromSearchResult?: boolean): void {
    if (!isFromSearchResult) {
      this.commonService.setSessionStorage('selectedSearchItem', '');
    }
    this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
      this.router.navigate([ 'quote/case/0' ]);
    });
    this.close();
  }


  /**
   * on init
   */
  ngOnInit(): void {
    if (this.router.url.indexOf('repairer-view') != -1 ) {
      this.repairerView = true;
    }

    this.search(this.searchKey);
    this.bookingButton.onclick = this.openNewBooking.bind(this);
    this.estimateButton.onclick = this.openNewQuote.bind(this);
  }

  /**
   * ngAfterViewInit
   */
  ngAfterViewInit(): void {
    if (this.searchKey) {
      this.searchBox.nativeElement.value = this.searchKey;
    }
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscription.unsubscribe();
  }
}
