import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OtpGuard implements CanActivate {
  /**
   * constructor
   * @param router
   */
  constructor(
    private router: Router
  ) { }

  /**
   * canActivate
   * @returns
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkOtpVerification();
  }

  /**
  * checkUserPermission
  * @returns
  */
  checkOtpVerification(): boolean {
    const tokenData = window.localStorage.getItem('XAuserDetails');
    let token = '';
    if (tokenData) {
      const data = JSON.parse(tokenData);
      token = data?.idToken;
      if(token){
        return true;
      }
    }
    this.router.navigateByUrl(`claim/verify-otp${location.search}`);
    return false;
  }
}
