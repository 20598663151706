import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { HttpClientModule, HttpClient } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

/**
 * load translation
 */
export function HttpLoaderFactory(http: HttpClient): any {

  return new TranslateHttpLoader(http,

    './assets/i18n/',

    '.json');

}

@NgModule({

  declarations: [],

  imports: [

    CommonModule,

    HttpClientModule,

    TranslateModule.forRoot({
      defaultLanguage: 'en',

      loader: {

        provide: TranslateLoader,

        useFactory: HttpLoaderFactory,

        deps: [ HttpClient ]

      },
        
      isolate: true,
      extend: false

    }),

  ],

  exports: [ TranslateModule ],

})

export class NgxTranslateModule { }