import { outputAst } from '@angular/compiler';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: [ './notification.component.scss' ]
})

export class NotificationComponent {
  @Input() message:string;
  @Input() display:boolean=false;
  @Output() closedialog: EventEmitter<any> = new EventEmitter();

  /**
   * dismiss notification
   */
  dismiss(): void{
    this.closedialog.emit();
  }

}
