import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common/common.service';
import { CoreService } from 'src/app/services/core/core.service';
@Injectable({
  providedIn: 'root'
})
export class MainGuard implements CanActivate {
  /**
   * constructor
   * @param router
   */
  constructor(
  private commonService: CommonService,
  private msalGuard: MsalGuard,
  private router: Router,
  private coreService:CoreService
  ) { }

  /**
 * canActivate
 * @returns
 */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree {
    if (this.msalGuard.canActivate(next, state)) {
      return this.checkUserPermission(next);
    } else {
      this.router.navigate([ 'home' ]);
      return false;
    }
  }

  /**
* checkUserPermission
* @returns
*/
  checkUserPermission(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.commonService.userProfileData.pipe(
      switchMap((res: any) => {
        if(res) {
          const automotiveServices = res?.data?.userPermission?.automotiveServices;
          this.commonService.setPermission(automotiveServices);
          // eslint-disable-next-line max-len
          const automotiveService = automotiveServices?.some(x => x.automotiveServiceName === route.data['automotiveService']);
          if(!automotiveService) this.router.navigate([ 'home' ]);
          return of(automotiveService);
        } else {
          this.commonService.showLoading();
          return this.coreService.getUserProfile().pipe(
            switchMap((res)=> {
              if(res) {
                this.commonService.hideLoading();
                this.commonService.userProfileData.next(res);
                if (res?.data.userProfileThumbnailImageBlobUrl || res?.data.userProfileImageBlobUrl) {
                  this.commonService.avatar.next({
                    image: res?.data.userProfileThumbnailImageBlobUrl ?? res?.data.userProfileImageBlobUrl
                  });
                }
                if(res?.data?.userPermission?.automotiveServices) {
                  const automotiveServices = res?.data?.userPermission?.automotiveServices;                  
                  this.commonService.setPermission(automotiveServices);
                  // eslint-disable-next-line max-len
                  const automotiveService = automotiveServices?.some(x => x.automotiveServiceName === route.data['automotiveService']);
                  if(!automotiveService) this.router.navigate([ 'home' ]);
                  return of(automotiveService);
                } else {                  
                  this.router.navigate([ 'home' ]);
                  return of(false);
                }
              } else {
                this.commonService.hideLoading();
                this.router.navigate([ 'home' ]);
                return of(false);
              }
            })     
          )
        }
      })
    );
  }
  
}
