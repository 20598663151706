import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as dayjs from 'dayjs';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { ChqWidgetsInputModel } from 'src/app/model/chq-widgets-input-model';
import { validateEmails } from 'src/app/modules/quote/chq-new-cases/chq-new-customer/chq-new-customer-form-data-helper';
import { CommonService } from 'src/app/services/common/common.service';
import { CoreService } from 'src/app/services/core/core.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { NotifyService } from 'src/app/services/notify/notify.service';
import { ChqInputComponent } from 'src/app/widgets/chq-input/chq-input.component';

export interface NotifyRequest {
  subject: string;
  messageContent: string;
  toEmails: string[];
  ccEmails?: string[];
  phoneNumber?: string;
  communicationTypeIds: number[],
  templateKey: string,
}


@Component({
  selector: 'notify-dialog',
  templateUrl: './notify-dialog.component.html',
  styleUrls: [ './notify-dialog.component.scss' ]
})
export class NotifyDialogComponent implements OnInit {
  public pickUpDate: string;
  public sendButton: ChqWidgetsButtonModel;
  public showCC: boolean = false;
  public emailRequest: NotifyRequest = {
    subject: '',
    messageContent: '',
    toEmails: [],
    ccEmails: [],
    phoneNumber: '',
    communicationTypeIds: [],
    templateKey: ''
  }
  public pageReady: boolean = false;
  public emailForm: any = {
    email: {
      label: 'email_address',
      name: 'email',
      placeHolder: 'email_address',
      value: '',
      type: 'text',
      validation: {
        name: 'email',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.required,
            validateEmails('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$') ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return 'email_required'
          }

          if (error['invalidEmailNumber']) {
            return 'email_invalid_number';
          }

          if (error['invalidEmail']) {
            return 'email_valid'
          }
          return '';
        }
      },
      disabled: true
    },
    phone: {
      label: 'mobile_number',
      name: 'phone',
      placeHolder: 'mobile_number',
      value: '',
      type: 'number',
      validation: {
        name: 'phone',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.required, Validators.pattern('^[0-9]+[0-9 ,.\'-]+$') ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return 'required_mobile_number'
          }
          if (error['pattern']) {
            return 'valid_mobile_number'
          }
          return '';
        }
      },
      disabled: true
    },
    cc: {
      label: 'CC',
      name: 'cc',
      placeHolder: 'CC',
      value: '',
      type: 'text',
      validation: {
        name: 'cc',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.email, Validators.maxLength(50),
            Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$') ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['maxlength']) {
            return 'email_length'
          }
          if (error['pattern'] || error['email']) {
            return 'email_valid'
          }
          return '';
        }
      }
    },
    ccExtra: {
      label: 'CC',
      name: 'ccExtra',
      placeHolder: 'CC',
      value: '',
      type: 'text',
      validation: {
        name: 'ccExtra',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.email, Validators.maxLength(50),
            Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$') ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['maxlength']) {
            return 'email_length'
          }
          if (error['pattern'] || error['email']) {
            return 'email_valid'
          }
          return '';
        }
      }
    }
  }

  public communicationOptions = {
    placeHolder: 'communication',
    name: 'communication',
    label: '',
    value: '',
    type: 'checkbox',
    options: []
  };

  @ViewChild('emailRef') emailRef: ChqInputComponent;
  @ViewChild('codeRef') codeRef: ChqInputComponent;
  @ViewChild('phoneRef') phoneRef: ChqInputComponent;
  public dropdownModel: ChqWidgetsInputModel = {
    placeHolder: 'select_dot',
    label: 'code',
    name: 'code',
    value: 0,
    type: 'select',
    showImageFlag: true,
    displayValue: 'phoneCountryCode',
    fieldValue: 'phoneCountryCode',
    options: [ { label: '+90' }, { label: '+971' }, { label: '+977' } ],
    validation: {
      name: 'code',
      validationFunction: (): ValidatorFn => {
        return Validators.compose([ Validators.required ])
      },
      validationMessage: (error: ValidationErrors): string => {
        if (error['required']) {
          return 'required_code'
        }
        return '';
      }
    },
    disabled: true
  };
  selectedCode: string = '';

  public allCommunicationSent: boolean = false;

  /**
   * constructor
   * @param dialogRef 
   * @param data 
   */
  constructor(public dialogRef: MatDialogRef<NotifyDialogComponent>,
    private monitorService: MonitorService,
    private coreService: CoreService,
    private commonService: CommonService,
    private notifyService: NotifyService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  /**
   * on data update
   */
  onFormUpdate($event): void {
    if ($event.name === 'communication') {
      const prevValue = this.emailRequest.communicationTypeIds || [];
      const keyFieldValue = Number($event.keyFieldValue);
      if ($event.value) {
        if (prevValue.indexOf(keyFieldValue) === -1) {
          prevValue.push(keyFieldValue);
        }
      } else {
        const index = prevValue.indexOf(keyFieldValue);
        prevValue.splice(index, 1);
      }

      this.emailRequest.communicationTypeIds = prevValue;
      this.communicationTouched = true;

      if (this.emailRequest.communicationTypeIds.includes(2)) {
        this.emailForm.email = {
          ...this.emailForm.email,
          disabled: false,
          validation: {
            name: 'email',
            validationFunction: (): ValidatorFn => {
              return Validators.compose([ Validators.required,
                validateEmails('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$') ])
            },
            validationMessage: (error: ValidationErrors): string => {
              if (error['required']) {
                return 'email_required'
              }

              if (error['invalidEmailNumber']) {
                return 'email_invalid_number';
              }

              if (error['invalidEmail']) {
                return 'email_valid'
              }
              return '';
            }
          }
        }

        this.emailRef?.setDirty();
      } else {
        this.emailForm.email = { ...this.emailForm.email, disabled: true, validation: null };
        this.emailRef?.setUnTouched();
      }

      if (this.emailRequest.communicationTypeIds.includes(1) || this.emailRequest.communicationTypeIds.includes(3)) {
        this.dropdownModel = {
          ...this.dropdownModel, disabled: false, validation: {
            name: 'code',
            validationFunction: (): ValidatorFn => {
              return Validators.compose([ Validators.required ])
            },
            validationMessage: (error: ValidationErrors): string => {
              if (error['required']) {
                return 'required_code'
              }
              return '';
            }
          }
        };
        this.emailForm.phone = {
          ...this.emailForm.phone,
          disabled: false,
          validation: {
            name: 'phone',
            validationFunction: (): ValidatorFn => {
              return Validators.compose([ Validators.required, Validators.pattern('^[0-9]+[0-9 ,.\'-]+$') ])
            },
            validationMessage: (error: ValidationErrors): string => {
              if (error['required']) {
                return 'required_mobile_number'
              }
              if (error['pattern']) {
                return 'valid_mobile_number'
              }
              return '';
            }
          }
        };

        if(this.codeRef){
          this.codeRef?.setDirty();
          this.codeRef.dropdownContainer.dropdownConfig.isTouched = true;
          if (!this.selectedCode) {
            this.codeRef.dropdownValidationMessage = 'required_code';
          }
        }
        this.phoneRef?.setDirty();
      } else {
        this.emailForm.phone = { ...this.emailForm.phone, disabled: true, validation: null };
        this.dropdownModel = { ...this.dropdownModel, disabled: true, validation: null };

        if(this.codeRef){
          this.codeRef?.setUnTouched();
          this.codeRef.dropdownValidationMessage = '';
          this.codeRef.dropdownContainer.dropdownConfig.isDirty = false;
          this.codeRef.dropdownContainer.dropdownConfig.isTouched = false;
          this.codeRef.dropdownContainer.empty = false;
        }
        this.phoneRef?.setUnTouched();
      }

    } else if ($event.name === 'email') {
      if ($event.isValid) {
        this.emailRequest.toEmails = $event.name === 'email' ? ($event.value?.split(',') || []) : $event.value;
      } else {
        this.emailRequest.toEmails = [];
      }
      if (this.emailRequest.toEmails && this.emailRequest.toEmails.length > 0) {
        this.sendButton = { ...this.sendButton, type: 'primary' };
      } else {
        this.sendButton = { ...this.sendButton, type: 'disabled' };
      }

      this.emailForm.email.value = $event.value;
    } else if ($event.name === 'code') {
      this.selectedCode = $event.value.phoneCountryCode;
      const output = $event.value;
      const validationPattern = output['regExpression'];
      this.dropdownModel = { ...this.dropdownModel, selectedOption: $event.value };
      this.phoneRef?.updateCurrentValidation(validationPattern, true);
      this.dropdownModel.value = $event.value;
    } else if ($event.name === 'phone') {
      this.emailRequest.phoneNumber = $event.value;
      if ($event.isValid) {
        this.phoneIsValid = true;
      } else {
        this.phoneIsValid = false;
      }

      this.emailForm.phone.value = $event.value;
    } else if ($event.name === 'cc') {
      if ($event.isValid) {
        this.ccIsValid = true;
        this.ccValue = $event.value;
      } else {
        this.ccIsValid = false;
      }
    } else if ($event.name === 'ccExtra') {
      if ($event.isValid) {
        this.ccExtraIsvalid = true;
        this.ccExtraValue = $event.value;
      } else {
        this.ccExtraIsvalid = false;
      }
    }

    if ($event.name === 'cc' || $event.name === 'ccExtra') {
      const array = [];
      if (this.ccValue) {
        array.push(this.ccValue)
      }
      if (this.ccExtraValue) {
        array.push(this.ccExtraValue)
      }

      this.emailRequest.ccEmails = array;
    }

    this.checkCanValidateSend();
  }

  public communicationTouched: boolean = false;
  private ccValue = '';
  private ccExtraValue = '';
  public ccIsValid = true;
  public ccExtraIsvalid = true;
  public phoneIsValid = true;
  /**
   * handle close
   */
  cancel(): void {
    this.monitorService.logEvent('send', [ 'NotifyDialogComponent', 'addenda' ]);
    this.dialogRef.close();
  }

  /**
   * handle send
   */
  send(): void {
    this.monitorService.logEvent('send', [ 'NotifyDialogComponent', 'addenda' ]);

    const postData = {
      ...this.emailRequest,
      phoneNumber: this.selectedCode + this.emailRequest.phoneNumber,
      subject: btoa(this.emailRequest.subject),
      messageContent: btoa(this.emailRequest.messageContent),
      parameters: [ { name: 'pickupdate', value: this.pickUpDate } ]
    }

    if (!(this.emailRequest.communicationTypeIds.includes(1) || this.emailRequest.communicationTypeIds.includes(3))) {
      postData.phoneNumber = null;
    }


    if (!this.emailRequest.communicationTypeIds.includes(2)) {
      postData.toEmails = [];
    }

    this.commonService.showLoading();

    this.notifyService.sendNotify(this.data.repairGuid, postData).subscribe({
      next: () => {
        this.commonService.hideLoading();
        this.dialogRef.close({ notifySend: true, templateKey: this.emailRequest.templateKey, selectedCommunication: this.emailRequest.communicationTypeIds });
      }, error: () => {
        this.commonService.hideLoading();
      }
    });
  }

  /**
   * ng on init
   */
  ngOnInit(): void {
    this.getNotifyTemplate();

    this.sendButton = {
      label: 'send',
      type: 'disabled',
      icon: 'send',
      onclick: this.send.bind(this)
    }
  }

  /**
   * 
   */
  getNotifyTemplate(): void {
    this.commonService.showLoading();

    this.notifyService.getNotifyTemplate(this.data.repairGuid, this.data.domainId, this.data.templateId).subscribe({
      next: (res) => {
        this.commonService.hideLoading();
        this.data.subject = res[1].data.subject
          .replace('{{Make}}', res[0].data.vehicle.vehicleMake)
          .replace('{{Model}}', res[0].data.vehicle.vehicleModel)
          .replace('{{License Plate}}', res[0].data.vehicle.licensePlateNumber)
          .replace('{{make}}', res[0].data.vehicle.vehicleMake)
          .replace('{{model}}', res[0].data.vehicle.vehicleModel)
          .replace('{{licensenumber}}', res[0].data.vehicle.licensePlateNumber);
        this.pickUpDate = dayjs(res[0].data.pickUpDate).local().format('DD/MM/YYYY h:mm a');
        this.data.body = res[1].data.body
          .replace('{{firstname}}', res[0].data.customer.firstName)
          .replace('{{lastname}}', res[0].data.customer.lastName)
          .replace('{{make}}', res[0].data.vehicle.vehicleMake)
          .replace('{{model}}', res[0].data.vehicle.vehicleModel)
          .replace('{{licensenumber}}', res[0].data.vehicle.licensePlateNumber)
          .replace('{{pickupdate}}', this.pickUpDate)
          .replace('{{message}}', '')
          .replace('{{orgname}}', res[0].data.organizationDetail.nameOfOrganization)
          .replace('{{orgcontactnumber}}', res[0].data.organizationDetail.phoneNumber);

        this.emailRequest = { ...this.emailRequest, subject: this.data.subject, messageContent: this.data.body, templateKey: res[1].data.templateKey };

        this.monitorService.logEvent('ngOnInit', [ 'NotifyDialogComponent', 'addenda' ]);
        if (!this.dropdownModel.selectedOption) {
          this.dropdownModel.selectedOption = {};
        }
        this.dropdownModel.selectedOption[this.dropdownModel.displayValue] = res[0].data.customer.countryCode || '+91';
        if (!this.dropdownModel.observable) {
          this.dropdownModel.observable = this.coreService.getPhoneNumber();
        }
        this.selectedCode = res[0].data.customer.countryCode || '+91';
        if (this.selectedCode) {
          this.dropdownModel.onObservableLoad = this.onObservableLoad.bind(this);
        }

        if (res[0].data.customer.email) {
          this.emailForm.email = { ...this.emailForm.email, value: res[0].data.customer.email, };
          this.emailRequest = { ...this.emailRequest, toEmails: [ res[0].data.customer.email, ] };
        }

        if (res[0].data.customer.mobileNumber) {
          this.emailForm.phone = { ...this.emailForm.phone, value: res[0].data.customer.mobileNumber };
          this.emailRequest = { ...this.emailRequest, phoneNumber: res[0].data.customer.mobileNumber };
        }

        this.communicationOptions.options = [
          { label: 'SMS', value: '3', checked: false, disabled: !(this.data.communicationList.includes(3) && !this.data.communicatedList.some(q => q.communicationTypeId == 3 && q.eventName == res[1].data.templateKey)) },
          { label: 'Email', value: '2', checked: false, disabled: !(this.data.communicationList.includes(2) && !this.data.communicatedList.some(q => q.communicationTypeId == 2 && q.eventName == res[1].data.templateKey)) },
          { label: 'WhatsApp', value: '1', checked: false, disabled: !(this.data.communicationList.includes(1) && !this.data.communicatedList.some(q => q.communicationTypeId == 1 && q.eventName == res[1].data.templateKey)) }
        ];

        this.allCommunicationSent = !this.communicationOptions.options.some(q => !q.disabled);

        this.pageReady = true;
        this.checkCanValidateSend();
      },
      error: (e) => {
        this.commonService.hideLoading();
        this.pageReady = true;
      }
    });
  }

  /**
   * 
   */
  checkCanValidateSend(): void {
    let activateSend = false;

    if (
      this.emailRequest.communicationTypeIds?.length > 0 &&
      (this.emailRequest.phoneNumber || !(this.emailRequest.communicationTypeIds.includes(1) || this.emailRequest.communicationTypeIds.includes(3))) &&
      (this.emailRequest.toEmails?.length > 0 || !this.emailRequest.communicationTypeIds.includes(2)) &&
      this.ccIsValid && this.ccExtraIsvalid &&
      (this.phoneIsValid || !(this.emailRequest.communicationTypeIds.includes(1) || this.emailRequest.communicationTypeIds.includes(3)))
    ) {
      activateSend = true;
    }

    if (activateSend) {
      this.sendButton = { ...this.sendButton, type: 'primary' };
    } else {
      this.sendButton = { ...this.sendButton, type: 'disabled' };
    }
  }

  /**
   * on dropdown observable load
   */
  onObservableLoad(items: any): void {
    this.monitorService.logEvent('onObservableLoad', [ 'EditMobileDialogComponent', 'addenda-quote' ]);

    if (!items) {
      this.dialogRef.close();
      return;
    }
    if (items && items.length > 0) {
      const selectedItem = items.filter((it: any) => {
        return it.phoneCountryCode === this.selectedCode;
      });
      if (selectedItem.length > 0) {
        const output = selectedItem[0];
        const validationPattern = output['regExpression'];
        this.dropdownModel = { ...this.dropdownModel, selectedOption: output };
        this.phoneRef?.updateCurrentValidation(validationPattern, true);
      }
    }
  }

}

