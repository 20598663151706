<div class="form-field-text">
  <label *ngIf="label" class="form-label pointer-events" for="inputId">
    {{label | translate}}
    <span class="required" *ngIf="required">*</span>    
  </label>
  <div class="text-field">
    <input class="form-value" autocomplete="off" #input inputmode="numeric"
      [id]="inputId"
      [name]="nameId"
      placeholder="{{placeholder || label | translate}}" 
      [readonly]="readonly" 
      [disabled]="disabled" 
      [required]="required"
      [value]="value"
      onkeydown="javascript: return (['Backspace','Delete','ArrowLeft','ArrowRight', 'Period', 'Tab'].includes(event.key) || ['Backspace','Delete','ArrowLeft','ArrowRight', 'Period', 'Tab'].includes(event.code)) ? true : !isNaN(Number(event.key)) && (event.key!=='Space' || event.code!=='Space')"
      [attr.maxlength]="blockMaxLength ? maxLength : null"
      (keyup)="_onInputInteraction();onTouched()"/>
  </div>
</div>