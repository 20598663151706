import { Component, Inject } from '@angular/core';
import { ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SettingsAddTasksForm } from 'src/app/helper/form/settings-job-tasks.helper';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { ChqWidgetsInputModel, IControlValue } from 'src/app/model/chq-widgets-input-model';
import { BookingService } from 'src/app/services/booking/booking.service';
import { CommonService } from 'src/app/services/common/common.service';
import { JobSettingsService } from 'src/app/services/job-settings/job-settings.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { AddSettingsTasksFormDataModel } from 'src/app/model/chq-settings-jobs-tasks-model';
import { MAX_TASKS_PER_REPAIR } from 'src/app/config/constants/app.constants';

@Component({
  selector: 'app-add-task-dialog',
  templateUrl: './add-task-dialog.component.html',
  styleUrls: [ './add-task-dialog.component.scss' ]
})
export class AddTaskDialogComponent {
  public formModel?: SettingsAddTasksForm;
  public repairTypeModel: any[] = [];
  public saveButtonModel: ChqWidgetsButtonModel = {
    label: 'Save',
    type: 'disabled',
    icon: '',
    onclick: this.submitForm.bind(this)
  }

  public deleteButtonModel: ChqWidgetsButtonModel = {
    label: '',
    type: 'outline-danger',
    icon: 'delete',
    onclick: this.deleteTask.bind(this)
  }

  public repairTypesData: any = [];
  checkedRepairTypes: number[] = []
  jobGuid: any;
  createdByUserName: any;
  enabledRepairTypes: number[] = []
  currentUserName: any = '';
  userPermission: any = {};
  isGtEstimatePermission: boolean = false;
  isImportFromGTMotive: boolean = false;
  totalTaskLength: number = 0;
  tasksPerRepair = [];
  repairsExceededLimits = [];
  nameRX: RegExp = new RegExp(/^[a-z\d\s-_\\/]+$/i);

  /**
   Constructor for the RepairEstimateOperationComponent.
   @constructor
   @param {any} data - Data passed to the component.
   @param {MatDialogRef} dialogRef - Reference to the dialog box.
   @param {MonitorService} monitorService - Service for monitoring operations.
 */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddTaskDialogComponent>,
    public monitorService: MonitorService,
    private commonService: CommonService,
    private jobsService: JobSettingsService,
    private bookingService: BookingService
  ) {
    this.commonService.userProfileData.subscribe((res) => {
      if (res && res.data) {
        const { userPermission } = res.data;
        this.userPermission = userPermission || {};
        this.checkGTEstimatePermission()
      }
    })
  }

  newAddTasksForm = (): SettingsAddTasksForm => {
    return {
      name: {
        placeHolder: 'Task Name',
        label: 'Task Name',
        name: 'name',
        value: this.data.taskData ? this.data.taskData.name ? this.data.taskData.name : '' : '',
        maxLength: 50,
        type: 'text',
        validation: {
          name: 'name',
          validationFunction: (): ValidatorFn => {
            return Validators.compose([ Validators.required, Validators.min(1), Validators.pattern(/^[a-z\d\s-_\\/]+$/i) ])
          },
          validationMessage: (error: ValidationErrors): string => {
            if (error['required']) {
              return 'Please enter task name'
            } if (error['min']) {
              return 'Name should have at least 1 character'
            } if (error['pattern']) {
              return 'Only alphanumeric characters are allowed'
            }
            return '';
          }
        }
      },
      isMandatory: {
        placeHolder: '',
        label: '',
        name: 'isMandatory',
        value: this.data.taskData ? this.data.taskData.isMandatory ? this.data.taskData.isMandatory : false : false,
        type: 'checkbox'
      },
      importFromGTMotive: {
        placeHolder: '',
        label: '',
        name: 'importFromGTMotive',
        idField: 'importFromGTMotive',
        value: 'Import from Estimate',
        type: 'checkbox',
        options: [ {
          label: 'Import from Estimate', id: 1,
          checked: this.data.taskData ? this.data.taskData.importFromGTMotive ? this.data.taskData.importFromGTMotive: false : false
        } ],
      },
      createdByUserName: {
        placeHolder: '',
        label: '',
        name: 'createdByUserName',
        value: this.data.taskData ? this.data.taskData.createdByUserName ? this.data.taskData.createdByUserName : '' : '',
        type: 'text',
      }
    };
  }

  repairTypeForm = (): any => {
    return this.repairTypesData.map((repairType) => {
      const formObj: ChqWidgetsInputModel = {
        placeHolder: '',
        label: '',
        name: 'repairServiceTypeIds',
        idField: 'repairServiceTypeIds',
        value: repairType.name,
        type: 'checkbox',
        options: [ {
          label: repairType.name, id: repairType.id,
          checked: this.checkedRepairTypes?.includes(repairType.id),
        } ],
      }
      return formObj;
    })
  }

  /**
   * on init
   */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'AddTaskDialogComponent', 'addenda-repair' ]);
    this.jobGuid = this.data.jobGuid
    this.checkedRepairTypes = this.data && this.data.checkedRepairTypes || [];
    this.createdByUserName = this.data.createdByUserName;
    this.enabledRepairTypes = this.data.enabledRepairTypes;
    this.totalTaskLength = this.data.tasksLength - this.checkedRepairTypes.length;
    this.tasksPerRepair = this.data.tasksPerRepair || [];
    this.currentUserName = this.commonService.userProfileData.value?.data?.firstName + ' ' + this.commonService.userProfileData.value?.data?.lastName
    this.commonService.showLoading();
    this.formModel = this.newAddTasksForm();
    this.getRepairTypes();
    if(this.data && this.data.taskData) this.isImportFromGTMotive = this.data?.taskData?.importFromGTMotive || false;
  }

  /**
   * get repair Types
   */
  getRepairTypes(): void {
    this.bookingService.getRepairTypes().subscribe({
      next: (result: any) => {
        if (result) {
          this.repairTypesData = result.data.filter(x => this.enabledRepairTypes.includes(x.id))
          this.repairTypeModel = this.repairTypeForm();
          this.commonService.hideLoading();
        }
      },
      error: (err: any) => {
        this.commonService.hideLoading();
        this.monitorService.logException(err);
      }
    });
  }

  /**
   * check gtEstimate permission
   */
  checkGTEstimatePermission(): void {
    const { orgPackages = [] } = this.userPermission;
    const repairEstimatePackage = orgPackages.filter((orgPackage: any) => {
      return orgPackage.automotiveServiceName === 'Repair Estimate';
    });

    if (repairEstimatePackage && repairEstimatePackage.length > 0) {
      const { permissions } = repairEstimatePackage[0];
      const gtEstimatePermission = permissions.filter((res: any) => {
        return res.permissionName === 'gt.estimate' || res.permissionName === 'gt.estimate.vinquery';
      });
      if (gtEstimatePermission && gtEstimatePermission.length > 0) {
        this.isGtEstimatePermission = true;
      }
    }
  }

  /**
     * on form update
     */
  onFormUpdate(output: IControlValue): void {
    if (output) {
      if(output.name === 'repairServiceTypeIds'){
        this.repairTypeModel.forEach((repairType: any, index: number) => {
          if(this.repairTypeModel[index].options[0].id !== output.keyFieldValue){
            this.repairTypeModel[index].options = [ { ...this.repairTypeModel[index].options[0], checked: false } ]
          }
        })
      }
      const repairTypeIds = this.repairTypeModel.filter(ele => ele.options.some((opt: any) => opt.checked)).flatMap(ele => ele.options.map(opt => opt.id))
      this.repairsExceededLimits = this.tasksPerRepair?.filter(tasks => repairTypeIds.indexOf(tasks.id)!=-1 && this.checkedRepairTypes.indexOf(tasks.id)==-1 && tasks.tasks.length >= MAX_TASKS_PER_REPAIR).map(item=> item.name)
      if (output.name == 'importFromGTMotive') {
        if (output.value == true) {
          this.isImportFromGTMotive = true;
        } else {
          this.isImportFromGTMotive = false;
        }
        this.formModel[output.name].value = output.value;
      } else if(this.repairsExceededLimits.length > 0){
        this.repairTypeModel = this.repairTypeModel && this.repairTypeModel.length ? this.repairTypeModel.map((repairType: any) => {
          return {
            ...repairType,
            options: repairType.options && repairType.options.length ? this.repairsExceededLimits.indexOf(repairType.value) !==-1 ? [ { ...repairType.options[0], checked: false } ] : repairType.options : repairType?.options || []
          }
        }) : this.repairTypeModel || [];
        this.commonService.showToast(5000, `${this.repairsExceededLimits.join(', ')} exceeded ${MAX_TASKS_PER_REPAIR} task per job limit !`);
        return;
      } else if (output.name !== 'repairServiceTypeIds' && output.name !== 'importFromGTMotive') {
        this.formModel[output.name].value = output.value;
      }
      this.checkForFormValidation();
    }
  }

  /**
   * on toggle change
   */
  onToggleChange(e): void {
    if (e && e.name == 'isMandatory') {
      this.formModel.isMandatory.value = e.checked
      this.checkForFormValidation();
    }
  }

  /**
  * check for form validation and set button type
  * @returns {void}
  */
  checkForFormValidation(): void {
    if (this.isTaskFormValid()) {
      this.saveButtonModel.type = 'primary';
    } else {
      this.saveButtonModel.type = 'disabled';
    }
  }

  /**
   * check for form validation
   * @returns {void}
   */
  isTaskFormValid(): boolean {
    let isValid = true;
    const repairTypeIds = this.repairTypeModel.filter(ele => ele.options.some((opt: any) => opt.checked)).flatMap(ele => ele.options.map(opt => opt.id))
    if(repairTypeIds.length !== 1){
      return false;
    }
    if ((repairTypeIds.length !== 1 && this.formModel.importFromGTMotive.value !== true) || this.formModel.name.value == ''|| !this.formModel.name.value.toString().match(this.nameRX)) {
      isValid = false;
    } else {
      isValid = true;
    }
    if(this.repairsExceededLimits.length>0) isValid = false;
    return isValid;
  }

  /**
     * submitForm
   */
  submitForm(): void {
    if (!this.isTaskFormValid()) {
      return;
    }
    const repairTypeIds = this.repairTypeModel.filter(ele => ele.options.some((opt: any) => opt.checked)).flatMap(ele => ele.options.map(opt => opt.id))
    const taskData: AddSettingsTasksFormDataModel =
    {
      name: this.formModel.name.formControl.value,
      isMandatory: this.isImportFromGTMotive ? false : Boolean(this.formModel.isMandatory.value),
      importFromGTMotive: this.formModel.importFromGTMotive.options[0].checked,
      repairServiceTypeIds: repairTypeIds,
      //repairServiceTypeIds: this.isImportFromGTMotive ? null : repairTypeIds,
    }
    this.commonService.showLoading();
    if (this.data != null && this.data.taskData != null) {
      this.jobsService.editTask(
        taskData, this.jobGuid, this.data.taskData.jobTaskGuid
      ).subscribe({
        next: () => {
          this.dialogRef.close(true)
          this.commonService.hideLoading();
        },
        error: (err: any) => {
          this.commonService.hideLoading();
          this.monitorService.logException(err);
        }
      });
    } else {
      this.jobsService.addNewTask(
        taskData, this.jobGuid
      ).subscribe({
        next: () => {
          this.dialogRef.close(true)
          this.commonService.hideLoading();
        },
        error: (err: any) => {
          this.commonService.hideLoading();
          this.monitorService.logException(err);
        }
      });
    }
  }


  /**
  * on Delete Task 
  */
  deleteTask(): void {
    const dialogRef = this.commonService.openConfirmationOkDialog('Confirm Deleting',
      'Are you sure you want to delete this task ?');
    this.monitorService.logEvent('deleteTask', [ 'AddTaskDialogComponent', 'addenda-repair' ]);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.commonService.showLoading();
        this.jobsService.deleteTaskById(this.jobGuid, this.data.taskData.jobTaskGuid).subscribe({
          next: () => {
            this.dialogRef.close(result)
            this.commonService.hideLoading();
          },
          error: () => {
            this.commonService.hideLoading();
          }
        })
      }
    });
  }

  /**
      Closes the dialog with the given data.
      @param {any} data - The data to be passed back to the dialog caller.
    */
  cancelDialog(data: any): void {
    this.dialogRef.close(data);
  }


}

