<div class="dropdown-container" [formGroup]="formGroup" [ngClass]="{
  'text-mode': dropdownModel.mode === 'text',
  'dropdown-edit-mode': dropdownModel.mode !== 'text',
  'disabled': this.empty || dropdownModel.disabled,
  'button-mode': dropdownModel?.appearance === 'button',
  'disabled-dropdown': dropdownModel?.disabled,
  'dropdown-invalid': (dropdownConfig?.isDirty || dropdownConfig?.isTouched || empty) && !showOptions && formControl.errors
}">
  <label class="label" [hidden]="!dropdownModel?.label">{{ dropdownModel.label | translate }}
    <span *ngIf="dropdownModel?.required" class="required">*</span>
    <mat-icon 
        *ngIf="dropdownModel.hintIcon && dropdownModel.hintText"
        class="hint-icon"
        [svgIcon]="dropdownModel.hintIcon" 
        [matTooltip]="dropdownModel.hintText"
      ></mat-icon>
  </label>
  <a href="javascript:void(0)" class="dropdown" (click)="onSelectPress($event)">
    <label class="selected-label">
      <div class="content">
        <img *ngIf="dropdownModel?.showImageFlag && dropdownModel.selectedOption?.urlFlag"
          [src]="dropdownModel.selectedOption?.urlFlag" alt="flag-img"/>
        <mat-icon *ngIf="dropdownModel?.placeHolderIcon" class="placeholder-icon"
          [svgIcon]="dropdownModel?.placeHolderIcon" aria-hidden="false" aria-label="dropdown icon"
          (click)="onSelectPress($event)"></mat-icon>

        <span [ngClass]="{'placeHolder':!isNotNull(dropdownModel.selectedOption)}" [hidden]="showLoader">
          {{ isNotNull(dropdownModel.selectedOption)
          ? selectedValues()
          : (dropdownModel.mode === 'text') ? '--' : dropdownModel.placeHolder | translate }}
        </span>
      </div>
      <mat-icon *ngIf="!showOptions && !dropdownModel?.hideDropdownIcon && !showLoader" [svgIcon]="'dropdown'"
        aria-hidden="false" aria-label="dropdown icon" (click)="onSelectPress($event)"></mat-icon>
      <mat-icon *ngIf="showOptions && !dropdownModel?.hideDropdownIcon" [svgIcon]="'dropdown-up'" aria-hidden="false"
        aria-label="dropdown-up icon" (click)="onSelectPress($event)"></mat-icon>

      <span [hidden]="!showLoader" class="spanLoading">Loading...</span>
      <div class="loading" *ngIf="showLoader">
        <div class="overlay"></div>
        <div class="spinner">
          <mat-spinner [strokeWidth]="2" [diameter]="20"></mat-spinner>
        </div>
      </div>
    </label>
    <ul [style]="{
        top: dropdownConfig.top,
        width: dropdownConfig.width,
        left: dropdownConfig.left
      }" class="dropdown-wrapper"
      *ngIf="showOptions === true && ((dropdownModel?.options && dropdownModel.options.length > 0) || (dropdownOptions && dropdownOptions.length > 0 ))">
      <li class="dropdown-item" [ngClass]="{'selected-item': !dropdownModel.selectedOption[dropdownModel.displayValue]}"
        *ngIf="dropdownModel.showSelectOption === true" (click)="onItemSelect('')"><label class="dropdown-item-label">
          {{dropdownModel.placeHolder | translate}}
        </label></li>
      <li class="dropdown-item"
        [ngClass]="{'selected-item':item[dropdownModel.displayValue] === dropdownModel.selectedOption[dropdownModel.displayValue]}"
        (click)="onItemSelect(item)" *ngFor="let item of dropdownOptions">
        <mat-icon *ngIf="item['dropdownItemIcon'] && !dropdownModel.isMultiSelect" [svgIcon]="item['dropdownItemIcon']"
          aria-hidden="false" [style.fill]="item['color']" aria-label="dropdown icon"></mat-icon>
        <mat-icon *ngIf="item['dropdownItemIconMaterial'] && !dropdownModel.isMultiSelect" aria-hidden="false"
          [style.fill]="item['color']" [style.fontSize]="item['fontSize']" [style.height]="item['height']"
          [style.width]="item['size']" aria-label="dropdown icon">{{item['dropdownItemIconMaterial']}}</mat-icon>
        <img *ngIf="dropdownModel?.showImageFlag && item?.urlFlag" [src]="item?.urlFlag" />
        <label [for]="item[dropdownModel.keyFieldName]+'_' + dropdownModel.name" *ngIf="!dropdownModel.isMultiSelect"
          (click)="dropdownModel.isMultiSelect === true?$event.stopPropagation():''" class="dropdown-item-label">

          {{item[dropdownModel.displayValue] | translate }}
        </label>
        <div class="checkbox-wrapper" *ngIf="dropdownModel.isMultiSelect === true"
          [ngStyle]="item['disabled'] ? {'pointer-events': 'none', 'opacity': '0.4'} : {}">
          <mat-icon *ngIf="item['dropdownItemIcon']" [svgIcon]="item['dropdownItemIcon']" aria-hidden="false"
              [style.fill]="item['color']" aria-label="dropdown icon" style="position: absolute; left: 40px;height: 20px;width: 20px;"></mat-icon>
          <label class="checkbox"
            [for]="item[dropdownModel.keyFieldName || dropdownModel.displayValue]+'_' + dropdownModel.name"
            (click)="$event.stopPropagation()" [style.padding-left]="item['dropdownItemIcon'] ? '40px' : ''">
            <span class="span-label" *ngIf="item['html']" [innerHTML]="item.html"></span>
            <span class="span-label" *ngIf="!item['html']">{{ item[dropdownModel.displayValue] }}</span>
            <input [id]="item[dropdownModel.keyFieldName || dropdownModel.displayValue]+'_' + dropdownModel.name"
              *ngIf="dropdownModel.isMultiSelect === true" type="checkbox"
              [value]="item[dropdownModel.keyFieldName || dropdownModel.displayValue]" [checked]="isChecked(item)"
              (click)="updateSelection($event,item);" />
            <span class="checkmark" #spanCheckbox [ngClass]="dropdownModel.checkboxColor"></span>
          </label>
        </div>
      </li>
    </ul>

  </a>

  <input [name]="dropdownModel.name" [formControlName]="dropdownModel.name" type="hidden"
    [value]="dropdownModel.selectedOption[dropdownModel.displayValue]" />
</div>