import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { CommonService } from 'src/app/services/common/common.service';
import { Message, MessageStatus } from '../models/message';
import { Thread } from '../models/thread';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { MessagesService } from 'src/app/services/message/message.service';
import { debounceTime, distinctUntilChanged, filter, fromEvent, tap } from 'rxjs';
import { SignalRService } from 'src/app/services/signalr/signalr.service';
import { DxScrollViewComponent } from 'devextreme-angular';


@Component({
  selector: 'chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: [ './chat-list.component.scss' ]
})
export class ChatListComponent implements OnInit, AfterViewInit {
  @Input() ConversationGuid: string;
  @ViewChild(DxScrollViewComponent, { static: false }) scrollView: DxScrollViewComponent;
  public messages: Message[] = [];
  public _chatList: Array<Thread>;
  public newMessage: string;
  public isChatDetail: boolean = false;
  private draftPageNumber: number = 1;
  public draftDataCount: number = 0;
  public searchText: string = '';
  public pageReady: boolean = false;

  //View child
  @ViewChild('searchField') searchField: ElementRef;

  /**
   * thread
   */
  @Input()
  set chatList(t: Array<Thread>) {
    if (!t) {
      return;
    }
    this._chatList = t;
    this.messages = [];
  }

  /**
   * get thread
   */
  get chatList(): Array<Thread> {
    return this._chatList;
  }

  @Output() itemSelected: EventEmitter<{ thread: Thread, hasUnreadCount: boolean, chatId: string }> = new EventEmitter();

  public searchForm = new FormGroup({
    search: new FormControl('', [ Validators.required ])
  });

  @ViewChild('messageRepair') public messageRepair: MatSidenav;
  public showNewRepair: boolean = false;
  private currentUserId: number = 0;

  /**
   * 
   * @param commonService 
   * @param messageService 
   */
  constructor(
    private commonService: CommonService,
    private messageService: MessagesService,
    private signalR: SignalRService
  ) {
    this.signalR.hubReceivedMessage.subscribe((data: any) => {
      if (this.pageReady && data?.messageType === 'BidirectionalNotification') {
        this.chatList = [];
        this.getChatList(this.draftPageNumber * 10, 0);
      }
    });
  }

  /**
   * filter chat window
   */
  public handleInput(): void {
    setTimeout(() => {
      fromEvent(this.searchField.nativeElement, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(1500),
          distinctUntilChanged(),
          tap(() => {
            this.chatList = [];
            this.draftDataCount = 0;
            this.draftPageNumber = 1;
            this.pageReady = false;
            const searchValue = this.searchForm.get('search').value;
            this.searchText = searchValue;
            this.getChatList();
          })
        )
        .subscribe();
    }, 1000);
  }

  /**
   * ng after view init
   */
  ngAfterViewInit(): void {
    this.handleInput();
  }

  /**
   * ng init
   */
  ngOnInit(): void {
    this.commonService.userProfileData.subscribe((res) => {
      this.currentUserId = res?.data?.id;
      this.getChatList();
    });
  }

  /**
   * 
   */
  getChatList(top?: number, skip?: number): void {
    let queryParams = `?$expand=ChatConversations&$count=true&$top=${top || 10}&$skip=${skip === 0 ? 0 : (this.draftPageNumber - 1) * 10}&$orderby=LastActivityOn desc`;
    if (this.searchText) {
      queryParams += `&$filter=contains(tolower(CustomerName), '${this.searchText.toLowerCase()}') or contains(tolower(CustomerMobileNumber), '${this.searchText.toLowerCase()}')`
    }
    this.messageService.getGeneralCommunicationMessages(queryParams).subscribe({
      next: (resp) => {
        if (resp) {
          this.draftDataCount = resp['@odata.count'];
          const response = resp.value;
          if (response) {
            if (!this.chatList) this.chatList = [];
            for (const r in response) {
              const chatObj = response[r];

              if (chatObj.ChatConversations.length > 0) {
                let th = Thread.make(chatObj.Id.toString(), chatObj.CustomerName, [], chatObj.CustomerMobileNumber, null, 3100);
                th = Thread.update(th, '', '', '', '', chatObj.ChatConversations, this.currentUserId, chatObj.UnreadConversationCount);
                this.chatList.push(th);
              }

            }
          }
          this.pageReady = true;
          if (this.ConversationGuid) {
            const thread = this.chatList.filter(chat => chat.chatConversations.find(item => item.ConversationGuid === this.ConversationGuid))
            this.itemClicked(thread[0], response[0].UnreadConversationCount > 0, thread[0].id);
          }

          if (this.draftPageNumber * 10 >= this.draftDataCount) {
            this.scrollView?.instance?.release(true);
          } else {
            this.scrollView?.instance?.release(false);
          }
        }

      },
      complete: () => {
        this.commonService.hideLoading();
      }
    })
  }

  /**
   *
   * @param e
   */
  updateBottomContent(e): void {
    this.draftPageNumber++;
    this.getChatList();
  }

  /**
   * 
   */
  closeNewMessage(): void {
    this.showNewRepair = false;
  }

  /**
   * 
   * @param repairId 
   */
  itemClicked(thread: Thread, hasUnreadCount: boolean, chatId: string): void {
    this.itemSelected.emit({ thread, hasUnreadCount, chatId });
  }
}
