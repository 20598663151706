<div class="h-100 fx-col" *ngIf="showData">
    <div class="selected-operations" >
        <div>{{'selected_operations' | translate }}: {{selectedRows.length}}</div>
    </div>
    <div class="table-container">
        <ng-container>
            <div class="table-wrapper" [ngClass]="(tableData?.length > 0 && (pagination.count > pagination.pageSize)) ? 'height-with-pagination': 'height-without-pagination'">
                <xa-table #operationTable [config]="tableConfig" [tableData]="tableData" [cellClicked]="handleCellClick"
                [pageChanged]="pageChanged" [i18n]="i18n"></xa-table>
            </div>
            <div class="pagination-wrapper" *ngIf="tableData?.length > 0 && (pagination.count > pagination.pageSize)">
                <xa-paginator #paginator (paginationChange)="handlePaginationChange($event)"
                    [pagination]="pagination">
                </xa-paginator>
            </div>   
        </ng-container>     
    </div>
    <div class="footer-button">
        <chq-button [buttonModel]="refreshButtonModel" (click)="handleRefreshOperationList($event)"></chq-button>
        <chq-button [buttonModel]="addButtonModel" (click)="handleAddClick($event)"></chq-button>
    </div>
</div>