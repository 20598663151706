import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { Subject } from 'rxjs';
import { ChqWidgetsInputModel, IControlValue } from 'src/app/model/chq-widgets-input-model';

@Component({
  selector: 'comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: [ './comment-dialog.component.scss' ]
})
export class CommentDialogComponent implements OnInit {
  
  public confirmButton: ChqWidgetsButtonModel;
  public cancelButton: ChqWidgetsButtonModel;
  subject: Subject<boolean>;
  isUpdate:boolean=false;
  public notesModel:ChqWidgetsInputModel = {
    placeHolder: 'Notes',
    label: 'Notes',
    name: 'notes',
    value: '',
    type: 'textarea',
    maxLength: 100
  };

  /**
   * constructor
   * @param dialogRef 
   * @param data 
   * @param changeDedectionRef 
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CommentDialogComponent>,
    private monitorService:MonitorService,
    public commonService:CommonService

  ) {
    this.subject = data.subject;
  }

  /**
   * handle close
   */
  continue():void{
    this.monitorService.logEvent('continue', [ 'CommentDialogComponent', 'addenda-repair' ]);
    this.dialogRef.close({ 'comment': this.notesModel.value });
  }

  /**
   * on form update
   * @param output o
   */
  onFormUpdate(output: IControlValue): void {
    this.notesModel.value = output.value;
    if(this.notesModel.value.toString().trim()){
      this.confirmButton = { ...this.confirmButton, type: 'primary' };
    }else{
      this.confirmButton = { ...this.confirmButton, type: 'disabled' };
    }
  }

  /**
   * handle close
   */
  cancel():void{
    this.monitorService.logEvent('cancel', [ 'CommentDialogComponent', 'addenda-repair' ]);
    //this.subject.next(false);
    //this.subject.complete();
    this.dialogRef.close({ 'delete': true });
  }

  /**
   * close
   */
  close():void{
    this.dialogRef.close();
  }


  /**
   * ng on init
   */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'CommentDialogComponent', 'addenda-repair' ]);
    this.confirmButton = {
      label: 'Save',
      type: 'disabled',
      onclick: this.continue.bind(this)
    }

    this.cancelButton = {
      label: 'Delete Notes',
      type: 'outline-danger',
      onclick: this.cancel.bind(this)
    }

    this.notesModel = { ...this.notesModel, value: this.data.notes, disabled: this.data.hideUpdate }
  }

}
