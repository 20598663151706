<div class="dialog-container">

  <ng-container *ngIf="!isLoading">
    <div class="header">
      <h3 *ngIf="editMode">Edit Analysis</h3>
      <h3 *ngIf="!editMode">Add Analysis</h3>
      <div class="close-icon" (click)="cancel()">
        <mat-icon [svgIcon]="'cross'"></mat-icon>
      </div>
    </div>
    <div class="content">
      <chq-input
        [inputModel]="partsDropdown"
        (controlOutput)="onFormUpdate($event)" #parts (clicked)="hideDropdown($event);"
      ></chq-input>
      <chq-input
        [inputModel]="damageDropdown"
        (controlOutput)="onFormUpdate($event)" #damage (clicked)="hideDropdown($event);"
      ></chq-input>
      <chq-input
        [inputModel]="recommendationDropdown"
        (controlOutput)="onFormUpdate($event)" #recommendation (clicked)="hideDropdown($event);"
      ></chq-input>
    </div>
    <div class="action">
      <chq-button [buttonModel]="sendButton" class="right"></chq-button>
    </div>
  </ng-container>
</div>
