/* eslint-disable indent */
/* eslint-disable require-jsdoc */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-empty-function */
import { AfterContentInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, Directive, DoCheck, ElementRef, EventEmitter, Inject, InjectionToken, Input, OnDestroy, OnInit, Optional, Output, QueryList, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';

export const XA_RADIO_GROUP = new InjectionToken<XaRadioGroup>('XaRadioGroup');

let nextUniqueId = 0;
export class XaRadioChange {
  /**
   * constructor
   * @param source 
   * @param value 
   */
  constructor(
    /** The radio button that emits the change event. */
    public source: XaRadio,
    /** The value of the radio button. */
    public value: any,
  ) {}
}
@Directive({
  selector: 'xa-radio-group',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XaRadioGroup),
      multi: true,
    },
    { provide: XA_RADIO_GROUP, useExisting: XaRadioGroup }
  ]
  
})

export class XaRadioGroup implements AfterContentInit, OnDestroy, ControlValueAccessor {

  public _value: any | null;
  public _name: string = `xa-radio-group-${nextUniqueId++}`
  public _selected: XaRadio | null = null;
  public _isInitialized: boolean = false;
  public _labelPosition: 'before' | 'after' = 'after';
  public _disabled: boolean = false;
  public _required: boolean = false;
  public _buttonChanges: Subscription;

  /** The method to be called in order to update ngModel */
  _controlValueAccessorChangeFn: (value: any) => void = () => {};

  /**
   * onTouch function registered via registerOnTouch (ControlValueAccessor).
   */
  onTouched: () => any = () => {};

  /**
   * Event emitted when the group value changes.
   */
  @Output() readonly changes: EventEmitter<XaRadioChange> = new EventEmitter<XaRadioChange>();

  /** Child radio buttons. */
  @ContentChildren(forwardRef(() => XaRadio), { descendants: true })
    _radios: QueryList<XaRadio>;


  /** Name of the radio button group. All radio buttons inside this group will use this name. */
  @Input()
  get name(): string {
    return this._name;
  }
  /**
   * set name
   */
  set name(value: string) {
    this._name = value;
    this._updateRadioNames();
  }

  /** Whether the labels should appear after or before the radio-buttons. Defaults to 'after' */
  @Input()
  get labelPosition(): 'before' | 'after' {
    return this._labelPosition;
  }
  /**
   * set label position
   */
  set labelPosition(v) {
    this._labelPosition = v === 'before' ? 'before' : 'after';
    this._markRadiosForCheck();
  }

  /**
   * Value for the radio-group. Should equal the value of the selected radio button if there is
   * a corresponding radio button with a matching value. If there is not such a corresponding
   * radio button, this value persists to be applied in case a new radio button is added with a
   * matching value.
   */
  @Input()
  get value(): any {
    return this._value;
  }
  /**
   * set value
   */
  set value(newValue: any) {
    if (this._value !== newValue) {
      // Set this before proceeding to ensure no circular loop occurs with selection.
      this._value = newValue;

      this._updateSelectedRadioFromValue();
      this._checkSelectedRadio();
    }
  }

  /**
   * check for selected radio
   */
  _checkSelectedRadio(): void {
    if (this._selected && !this._selected.checked) {
      this._selected.checked = true;
    }
  }

  /**
   * The currently selected radio button. If set to a new radio button, the radio group value
   * will be updated to match the new selected button.
   */
  @Input()
  get selected(): any {
    return this._selected;
  }
  /**
   * set selection
   */
  set selected(selected: XaRadio | null) {
    this._selected = selected;
    this.value = selected ? selected.value : null;
    this._checkSelectedRadio();
  }

  /** Whether the radio group is disabled */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  /**
   * set disabled
   */
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._markRadiosForCheck();
  }

  /** Whether the radio group is required */
  @Input()
  get required(): boolean {
    return this._required;
  }
  /**
   * set required
   */
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this._markRadiosForCheck();
  }

  /**
   * constructor
   */
  constructor(public _changeDetector: ChangeDetectorRef) {}
  /**
    * Initialize properties once content children are available.
    * This allows us to propagate relevant attributes to associated buttons.
    */
  ngAfterContentInit(): void {
    this._isInitialized = true;
    this._buttonChanges = this._radios.changes.subscribe(() => {
      if (this.selected && !this._radios.find(radio => radio === this.selected)) {
        this._selected = null;
      }
    });
  }

  /**
   * ng destroy
   */
  ngOnDestroy(): void {
    this._buttonChanges?.unsubscribe();
  }

  /**
   * Mark this group as being "touched" (for ngModel). Meant to be called by the contained
   * radio buttons upon their blur.
   */
  _touch() {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  public _updateRadioNames(): void {
    if (this._radios) {
      this._radios.forEach((radio) => {
        radio.name = this.name;
        radio._markForCheck();
      });
    }
  }

  /** Updates the `selected` radio button from the internal _value state. */
  public _updateSelectedRadioFromValue(): void {
    // If the value already matches the selected radio, do nothing.
    const isAlreadySelected = this._selected !== null && this._selected.value === this._value;

    if (this._radios && !isAlreadySelected) {
      this._selected = null;
      this._radios.forEach((radio) => {
        radio.checked = this.value === radio.value;
        if (radio.checked) {
          this._selected = radio;
        }
      });
    }
  }

  /** Dispatch change event with current selection and group value. */
  _emitChangeEvent(): void {
    if (this._isInitialized) {
      this.changes.emit(new XaRadioChange(this._selected!, this._value));
    }
  }

  _markRadiosForCheck() {
    if (this._radios) {
      this._radios.forEach(radio => radio._markForCheck());
    }
  }

  /**
   * Sets the model value. Implemented as part of ControlValueAccessor.
   * @param value
   */
  writeValue(value: any) {
    this.value = value;
    this._changeDetector.markForCheck();
  }

  /**
   * Registers a callback to be triggered when the model value changes.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnChange(fn: (value: any) => void) {
    this._controlValueAccessorChangeFn = fn;
  }

  /**
   * Registers a callback to be triggered when the control is touched.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  /**
   * Sets the disabled state of the control. Implemented as a part of ControlValueAccessor.
   * @param isDisabled Whether the control should be disabled.
   */
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this._changeDetector.markForCheck();
  }
}


abstract class XaRadioBase {
  abstract disabled: boolean;
  constructor(public _elementRef: ElementRef) {}
}

@Component({
  selector: 'xa-radio',
  templateUrl: './xa-radio.component.html',
  styleUrls: [ './xa-radio.component.scss' ],  
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class XaRadio extends XaRadioBase implements OnInit, AfterViewInit, OnDestroy {
  public _uniqueId: string = `xa-radio-${++nextUniqueId}`;
  public _labelPosition: 'before' | 'after';

  @Input() id: string = this._uniqueId;
  @Input() name: string;
  
  @Input()
  get checked(): boolean {
    return this._checked;
  }
  set checked(value: BooleanInput) {
    const newCheckedState = coerceBooleanProperty(value);
    if (this._checked !== newCheckedState) {
      this._checked = newCheckedState;
      if (newCheckedState && this.radioGroup && this.radioGroup.value !== this.value) {
        this.radioGroup.selected = this;
      } else if (!newCheckedState && this.radioGroup && this.radioGroup.value === this.value) {
        this.radioGroup.selected = null;
      }

      if (newCheckedState) {
        this._radioDispatcher.notify(this.id, this.name);
      }
      this._changeDetector.markForCheck();
    }
  }

  @Input()
  get value(): any {
    return this._value;
  }
  set value(value: any) {
    if (this._value !== value) {
      this._value = value;
      if (this.radioGroup !== null) {
        if (!this.checked) {
          this.checked = this.radioGroup.value === value;
        }
        if (this.checked) {
          this.radioGroup.selected = this;
        }
      }
    }
  }

  @Input()
  get labelPosition(): 'before' | 'after' {
    return this._labelPosition || (this.radioGroup && this.radioGroup.labelPosition) || 'after';
  }
  set labelPosition(value) {
    this._labelPosition = value;
  }

  @Input()
  get disabled(): boolean {
    return this._disabled || (this.radioGroup !== null && this.radioGroup.disabled);
  }
  set disabled(value: BooleanInput) {
    this._setDisabled(coerceBooleanProperty(value));
  }

  @Input()
  get required(): boolean {
    return this._required || (this.radioGroup && this.radioGroup.required);
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
  }

  /**
   * Event emitted when the checked state of this radio button changes.
   * Change events are only emitted when the value changes due to user interaction with
   * the radio button (the same behavior as `<input type-"radio">`).
   */
  @Output() readonly changes: EventEmitter<XaRadioChange> = new EventEmitter<XaRadioChange>();

  /** The parent radio group. May or may not be present. */
  radioGroup: XaRadioGroup;

  get inputId(): string {
    return `${this.id || this._uniqueId}-input`;
  }

  public _checked: boolean = false;
  public _disabled: boolean;
  public _required: boolean;
  public _value: any = null;

  public _removeUniqueSelectionListener: () => void = () => {};

  @ViewChild('input') _inputElement: ElementRef<HTMLInputElement>;

  constructor(
    @Optional() @Inject(XA_RADIO_GROUP) radioGroup: XaRadioGroup,
    elementRef: ElementRef,
    public _changeDetector: ChangeDetectorRef,
    public _focusMonitor: FocusMonitor,
    public _radioDispatcher: UniqueSelectionDispatcher
  ) {
    super(elementRef);
    this.radioGroup = radioGroup;
  }

  /**
   * Marks the radio button as needing checking for change detection.
   */
  _markForCheck() {
    this._changeDetector.markForCheck();
  }

  ngOnInit() {
    if (this.radioGroup) {
      // If the radio is inside a radio group, determine if it should be checked
      this.checked = this.radioGroup.value === this._value;

      if (this.checked) {
        this.radioGroup.selected = this;
      }

      // Copy name from parent radio group
      this.name = this.radioGroup.name;
    }

    this._removeUniqueSelectionListener = this._radioDispatcher.listen((id, name) => {
      if (id !== this.id && name === this.name) {
        this.checked = false;
      }
    });
  }

  ngAfterViewInit() {
    this._focusMonitor.monitor(this._elementRef, true).subscribe((focusOrigin) => {
      if (!focusOrigin && this.radioGroup) {
        this.radioGroup._touch();
      }
    });
  }

  ngOnDestroy() {
    this._focusMonitor.stopMonitoring(this._elementRef);
    this._removeUniqueSelectionListener();
  }

  /** Dispatch change event with current value. */
  public _emitChangeEvent(): void {
    this.changes.emit(new XaRadioChange(this, this._value));
  }


  _onInputClick(event: Event) {
    event.stopPropagation();
  }

  /** Triggered when the radio button receives an interaction from the user. */
  _onInputInteraction(event: Event) {
    event.stopPropagation();

    if (!this.checked && !this.disabled) {
      const groupValueChanged = this.radioGroup && this.value !== this.radioGroup.value;
      this.checked = true;
      this._emitChangeEvent();

      if (this.radioGroup) {
        this.radioGroup._controlValueAccessorChangeFn(this.value);
        if (groupValueChanged) {
          this.radioGroup._emitChangeEvent();
        }
      }
    }
  }

  /** Triggered when the user clicks on the touch target. */
  _onTouchTargetClick(event: Event) {
    this._onInputInteraction(event);

    if (!this.disabled) {
      this._inputElement.nativeElement.focus();
    }
  }

  /** Sets the disabled state and marks for check if a change occurred. */
  _setDisabled(value: boolean) {
    if (this._disabled !== value) {
      this._disabled = value;
      this._changeDetector.markForCheck();
    }
  }
}
