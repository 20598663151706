<div class="approval-form">
    <div class="wrapper" *ngIf="!cancelled && !success">
        <div class="approval-header">
            <img src="assets/img/xa.png" />
            <h3>Approval Request</h3>
        </div>
        <div class="wrapper-header">
            <h3>Approve Or Decline The Request</h3>
            <span>Select an option </span>
        </div>
        <div class="radio-container">
            <div class="radio-wrapper" [ngClass]="{'selected': checkedStatus === 'Approved'}">
                <mat-icon [svgIcon]="'check'" aria-hidden="false" aria-label="edit icon"></mat-icon>
                <label>Approve</label>
                <chq-input class="company-input col-3" [inputModel]="approveRadio"
                    (controlOutput)="onFormUpdate($event)"></chq-input>
            </div>
            <div class="radio-wrapper" [ngClass]="{'selected': checkedStatus === 'declined'}">
                <mat-icon [svgIcon]="'cross'" aria-hidden="false" aria-label="edit icon"></mat-icon>
                <label>Decline</label>
                <chq-input class="company-input col-3" [inputModel]="declineRadio"
                    (controlOutput)="onFormUpdate($event)"></chq-input>
            </div>
        </div>
        <div class="content" *ngIf="checkedStatus === 'declined'">
            <chq-input [inputModel]="notesModel" (controlOutput)="onFormUpdate($event);" tabindex="-1"></chq-input>
        </div>
        <div class="button-container">
            <chq-button [buttonModel]="cancelButtonModel"></chq-button>
            <chq-button [buttonModel]="nextButtonModel"></chq-button>
        </div>

    </div>
    <div class="result" *ngIf="cancelled || success">
        <div class="result-content" *ngIf="cancelled">
            <h3>The Request has been cancelled.</h3>
        </div>
        <div class="result-content-success" *ngIf="success">
            <h3>The Estimate was {{status}}</h3>
        </div>
    </div>
</div>