import { Component, EventEmitter, Input, Output, OnChanges, ChangeDetectorRef, SimpleChanges } from '@angular/core';

@Component({
  selector: 'chq-toggle',
  templateUrl: './chq-toggle.component.html',
  styleUrls: [ './chq-toggle.component.scss' ]
})
export class ChqToggleComponent implements OnChanges {
  @Input() name!: any;
  @Input() checked!: any;
  @Input() disabled: boolean;
  @Output() ToggleChange = new EventEmitter();


  /**
   * constructor
   */
  constructor(private cd: ChangeDetectorRef) { }

  // eslint-disable-next-line require-jsdoc
  statusChange(event: any): void {
    const value = {
      name: event.target.name,
      checked: event.target.checked
    }

    this.ToggleChange.emit(value);
  }

  /**
   * detects changes in input attributes
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.cd.detectChanges();
  }
}

