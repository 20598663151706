import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-not-found-error',
  templateUrl: './not-found-error.component.html',
  styleUrls: [ './not-found-error.component.scss' ]
})
export class NotFoundErrorComponent {

  /**
   * 
   * @param commonService 
   */
  constructor(private commonService: CommonService, private router: Router,) {
    this.commonService.userProfileData.subscribe({
      next: (res)=>{
        if(res === null){
          this.router.navigate([ 'home' ]);
        }
      }
    })
  }
  
}
