import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'chat-notification',
  templateUrl: './chat-notification.component.html',
  styleUrls: [ './chat-notification.component.scss' ]
})
export class ChatNotificationComponent implements OnInit {

  public notificationData: any;
  isWhatsApp = true;
  /**
   * constructor
   * @param dialogRef
   * @param data
   * @param changeDedectionRef
   */
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private monitorService: MonitorService,
    public commonService: CommonService
  ) {
    this.notificationData = data.data;
    if (!this.notificationData?.isWhatsApp) {
      this.isWhatsApp = false
    }
  }

  /**
   * ng on init
   */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'ChatNotificationComponent', 'addenda-repair' ]);
  }

  /**
   * 
   */
  public openGeneralComm(): void {
    this.commonService.openGeneralChatComponent('open');
  }
}
