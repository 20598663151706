import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { ChqWidgetsInputModel, IControlValue } from 'src/app/model/chq-widgets-input-model';
import { BookingService } from 'src/app/services/booking/booking.service';
import { CommonService } from 'src/app/services/common/common.service';
import { lastValueFrom } from 'rxjs' 
import { CaseService } from 'src/app/services/case/case.service';

@Component({
  selector: 'app-estimate-approval',
  templateUrl: './estimate-approval.component.html',
  styleUrls: [ './estimate-approval.component.scss' ]
})


export class EstimateApprovalComponent implements OnInit {

  

  public approveRadio:ChqWidgetsInputModel = {
    placeHolder: 'approve',
    label: 'approve',
    name: 'approve',
    idField: '',
    value: '1',
    type: 'radio',
    hideLabel: true,
    options: [ { label: 'approve', id: 1 } ]
  }

  public notesModel:ChqWidgetsInputModel = {
    placeHolder: 'let_us_know_issue',
    label: 'notes',
    name: 'notes',
    value: '',
    type: 'textarea',
    maxLength: 300,
    rows: 4
  };

  private repairId:string = '';
  private urlToken:string = '';
  public checkedStatus = '';
  public reason = '';
  private isCase:boolean;

  public declineRadio:ChqWidgetsInputModel = {
    placeHolder: 'decline',
    label: 'decline',
    name: 'decline',
    idField: '',
    value: '2',
    type: 'radio',
    hideLabel: true,
    options: [ { label: 'decline', id: 2 } ]
  }

  public cancelButtonModel: ChqWidgetsButtonModel = {
    label: 'cancel',
    type: 'outline'
  }
  public nextButtonModel: ChqWidgetsButtonModel = {
    label: 'confirm',
    type: 'disabled'
  }

  /**
    Constructor for the component.
    @param {CommonService} commonService - The CommonService instance.
    @param {BookingService} repairEstimateService - The RepairEstimateService instance.
    @param {ActivatedRoute} route - The ActivatedRoute instance.
  */
  constructor(
      public commonService: CommonService,
      private bookingService: BookingService,
      private route: ActivatedRoute,
      private caseService:CaseService,
      @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EstimateApprovalComponent>
  ) {
    
  }

  /**
   * cancelled
   */
  cancel():void{
    this.dialogRef.close(false);
  }

  /**
   * approve/reject repair
   */
  updateRepairStatus(): void{
    const status = this.checkedStatus === 'Approved'? 'Approved': 'Rejected';  
    this.commonService.showLoading();
    if(this.isCase){
      this.caseService.updateCaseStatus(this.repairId, status, this.reason.trim()).subscribe({
        next: () => {
          this.dialogRef.close(true);
          this.commonService.hideLoading();
        },
        complete: () => {
          this.commonService.hideLoading();
        },
        error: () => {
          this.commonService.hideLoading();
        }
      });
      return;
    }
    this.bookingService.updateRepairStatus(status, this.repairId, this.reason.trim()).subscribe({
      next: ()=>{
        this.dialogRef.close(true);
        this.commonService.hideLoading();
      },
      error: ()=>{
        this.commonService.hideLoading();
      }
    });
  }

  /**
   * on init
   */
  ngOnInit():void{
    if(typeof this.data ==='object' && this.data){
      this.repairId = this.data.caseGuid;
      this.isCase = this.data.isCase;
    }else{
      this.repairId = this.data;
    }
  

    this.nextButtonModel = { ...this.nextButtonModel, onclick: this.updateRepairStatus.bind(this) };
    this.cancelButtonModel = { ...this.cancelButtonModel, onclick: this.cancel.bind(this) };
  }

  /**
   * onFormUpdate
   */
  onFormUpdate($event:IControlValue):void{
    const { name, value } = $event;
    if(name === 'approve'){
      if(this.declineRadio.value !== 2 && value){
        this.checkedStatus = 'Approved';
        this.nextButtonModel = { ...this.nextButtonModel, type: 'primary' };
        this.declineRadio = { ...this.declineRadio, value: '' };
      }
    }else if(name === 'decline'){
      if(this.approveRadio.value !== 1 && value){
        this.checkedStatus = 'declined';
        if(this.reason.trim()){
          this.nextButtonModel = { ...this.nextButtonModel, type: 'primary' };
        }
        this.approveRadio = { ...this.approveRadio, value: '' };
      }
    }else if(name === 'notes'){
      this.reason = value;
      if(this.checkedStatus && this.checkedStatus === 'declined' && this.reason.trim()){
        this.nextButtonModel = { ...this.nextButtonModel, type: 'primary' };
      } else {
        this.nextButtonModel = { ...this.nextButtonModel, type: 'disabled' };
      }
    }
  }
}
