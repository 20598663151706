<div class="third-party-reject-claim-container">
    <div class="header">
        <h3>{{'please_enter_the_reason_below' | translate }}</h3>
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'" (click)="close();"></mat-icon>
        </div>
    </div>
    <div class="">
        <chq-input [inputModel]="messageModel" (controlOutput)="onFormUpdate($event);" tabindex="-1"></chq-input>
    </div>
    <div class="footer form-data-footer">
        <chq-button [buttonModel]="saveButton"></chq-button>
    </div>
</div>