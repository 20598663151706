import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { Editor } from 'tinymce';

@Component({
  selector: 'chq-rich-text-editor',
  templateUrl: './chq-rich-text-editor.component.html',
  styleUrls: [ './chq-rich-text-editor.component.scss' ]
})
export class ChqRichTextEditorComponent implements OnInit {
  @Input() height?: number;
  @Input() menubar?: boolean;
  @Input() plugins?: string;
  @Input() toolbar?: string;
  @Input() initialValue?: string;
  @Input() maxCharacterLimit:number;
  @Output() currentValue: EventEmitter<any> = new EventEmitter();
  init = {}
  characterLength: number = 0;

  /**
   * Creates an instance of ChqRichTextEditorComponent.
   * @date 8/13/2023 - 4:24:08 PM
   *
   * @constructor
   */
  constructor(private commonService: CommonService){}

  /**
   * init editor config
   */
  ngOnInit(): void {
    this.init = {
      base_url: '/tinymce',
      suffix: '.min',
      height: this.height ?? 500,
      menubar: this.menubar ?? false,
      statusbar: false,
      plugins: this.plugins ?? 'lists advlist',
      toolbar: this.toolbar ?? 'undo redo | formatselect | bold italic underline forecolor | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | strikethrough removeformat',
    }
    this.initialValue ??= '';
    this.characterLength = this.stripHtmlTags(this.initialValue).length;
    if(this.maxCharacterLimit && this.maxCharacterLimit > 0){
      this.init = { ...this.init, setup: (editor: Editor):void => {
        editor.on('keydown', (event: any) => {
          setTimeout(() => this.enforceCharacterLimit(event, editor), 0);
        });
  
        editor.on('paste', (event: any) => {
          setTimeout(() => this.enforceCharacterLimit(event, editor), 0);
        });
      }
      }
    }
  }

  /**
   * 
   * @param content enforceCharacterLimit
   * @returns 
   */
  enforceCharacterLimit(event: any, editor: Editor):void {
    const content = editor?.getContent();
    const strippedContent = this.stripHtmlTags(content);
    if (strippedContent.length > this.maxCharacterLimit) {
      event.preventDefault();
      this.commonService.openAlertDialog('Max Characters Exceeded', `Total Content cannot be more than ${this.maxCharacterLimit} characters`);
      setTimeout(() => editor?.undoManager.undo(), 10);
    }
  }

  /**
   * 
   * @param content strip html tags
   * @returns 
   */
  stripHtmlTags(content: string): string {
    const tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = content;
    return tempDivElement.textContent || tempDivElement.innerText || '';
  }

  /**
   * input change handler
   * @param {*} event
   */
  inputChange(event): void {
    this.characterLength = this.stripHtmlTags(event).length;
    this.currentValue.emit({ value: event });
  }
}
