import { ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { CommonService, Localization } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'chq-images-tables',
  templateUrl: './chq-images-tables.component.html',
  styleUrls: [ './chq-images-tables.component.scss' ],
})
export class ChqImagesTablesComponent implements OnInit {
  public damageCost: any = [];
  public totalDamageCost: number = 0;
  public labourCost: any = [];
  public totalLabourCost: number = 0;
  public paintCost: any = [];
  public totalPaintCost: number = 0;
  public parts: any = [];
  public paintLabour: any = [];
  public paintMaterial: any = [];

  @Input() estimateResponse: any;
  @Input() isRepairModule:boolean;

  public total: number = 0;
  public vat: number = 0;
  public discount: number = 0;
  public totalWithVat: number = 0;
  public vatPercent: number = 0;


  public chqPdfModel: ChqWidgetsButtonModel = {
    label: 'Upload',
    type: 'outline',
    icon: 'pdf'
  }

  @Output() downloadPdf = new EventEmitter<string>();
  i18n: Localization;

  /**
   * 
   * @param cd 
   */
  constructor(private cd: ChangeDetectorRef, private monitorService: MonitorService, private commonService: CommonService) {
    this.i18n = this.commonService.geti18nInfo();
  }

  /**
   * calculate total
   */
  calculate(): void {

    const estimateCharges = this.estimateResponse?.estimateCharges;
    this.labourCost = estimateCharges?.filter(q => q.serviceType?.toLowerCase() == 'labour');
    this.paintCost = estimateCharges?.filter(q => q.serviceType?.toLowerCase() == 'paint');
    this.parts = estimateCharges?.filter(q => q.serviceType?.toLowerCase() == 'part');

    this.paintLabour = this.paintCost?.filter(q => q.serviceSubType?.toLowerCase() == 'paintlabour');
    this.paintMaterial = this.paintCost?.filter(q => q.serviceSubType?.toLowerCase() == 'paintmaterial');

    const summary = this.estimateResponse?.estimateSummary;
    this.totalDamageCost = summary?.partsSubTotal || 0;
    this.totalLabourCost = summary?.labourTotalBeforeDiscount || 0;
    this.totalPaintCost = summary?.paintSubTotal || 0;

    this.discount = this.estimateResponse?.totalDiscount || 0;
    this.total = this.estimateResponse?.subTotal || 0;
    this.vatPercent = this.estimateResponse?.taxPercentage || 0;
    this.vat = this.estimateResponse?.taxTotal || 0;
    this.totalWithVat = this.estimateResponse?.total || 0;
    this.chqPdfModel = {
      ...this.chqPdfModel, label: 'repair_estimate_report',
      onclick: this.handlePdfSelection.bind(this)
    }
    //
  }

  /**
   * handle pdf select
   */
  handlePdfSelection($event): void {
    this.monitorService.logEvent('handlePdfSelection', [ 'ChqImagesTablesComponent', 'addenda-quote', {
      $event
    } ]);
    this.generatePdf();
  }

  /**
   * onInit hook
   */
  ngOnInit(): void {
    this.calculate();
    this.monitorService.logEvent('ngOnInit', [ 'ChqImagesTablesComponent', 'addenda-quote' ]);
  }

  /**
   * 
   */
  ngAfterViewInit(): void {
    this.cd.detectChanges();
    this.monitorService.logEvent('ngAfterViewInit', [ 'ChqImagesTablesComponent', 'addenda-quote' ]);
  }
  /**
   * generate pdf
   */
  generatePdf(): void {
    this.downloadPdf.emit();
    this.monitorService.logEvent('generatePdf', [ 'ChqImagesTablesComponent', 'addenda-quote' ]);
  }

  /**
   * show notes 
  */
  displayNotes(row: any): string {
    if (row['isAztPartDocumentedByGT']) {
      return '<img src="assets/img/gt.png" />'
    } else if (row['isConditionedBy']) {
      return '<b>C </b>'
    } else if (row['isIncluded']) {
      return '<b> I </b>'
    } else if (row['isNotIncluded']) {
      return '<b>NI</b>'
    } else if (row['isOverlappedBy']) {
      return '<b>-</b>'
    } else if (row['isPrepainted']) {
      return '<img src="assets/img/prepainted_part.png" />'
    } else if (row['isThrownBy']) {
      return '<b>+</b>'
    } else if (row['isManual']) {
      return '<img src="assets/img/white_flag.png" />'
    } else if(row['isApproximate']) {
      return '<img src="assets/img/approximate.png" />'
    }
    
    return '';
  }
}
