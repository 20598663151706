import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'no-access-error',
  templateUrl: './no-access-error.component.html',
  styleUrls: [ './no-access-error.component.scss' ]
})
export class NoAccessErrorComponent {


  /**
   * 
   * @param commonService 
   */
  constructor(private commonService: CommonService, private router: Router) {
    this.commonService.userProfileData.subscribe({
      next: (res)=>{
        if(res === null){
          this.router.navigate([ 'home' ]);
        }
      }
    })
  }

}
