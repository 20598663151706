import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';

@Directive({
  selector: '[xaCheckPermission]'
})
export class CheckPermissionDirective {
  private permissionKey: string;
  private serviceName: string;

  /**
   * 
   * @param templateRef 
   * @param viewContainer 
   * @param CommonService 
   */
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private commonService: CommonService
  ) { }

  /**
   * 
   */
  @Input()
  set xaCheckPermission(data: PermissionCheckData) {
    this.serviceName = data.serviceName;
    this.permissionKey = data.permissionKey;
    this.updateView();
  }

  /**
   * 
   */
  private updateView(): void {
    this.viewContainer.clear();

    if (this.serviceName && this.commonService.checkPermission(this.serviceName, this.permissionKey)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}

export class PermissionCheckData {
  serviceName: string;
  permissionKey: string;
}