<form class="fx-1 payment-add" *ngIf="dueAmount > 0;else fullyPaid">
    <div class="row">
        <div class="col-6">
            <chq-input [inputModel]="formModel.amount" (controlOutput)="onFormUpdate($event)"></chq-input>
        </div>
        <div class="col-6">
            <chq-input [inputModel]="formModel.paymentMethod" (controlOutput)="onFormUpdate($event)"></chq-input>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <chq-input [inputModel]="formModel.paymentDate" (controlOutput)="onFormUpdate($event)"></chq-input>
        </div>
        <div class="col-6 payment-status">
            <chq-input [inputModel]="formModel.paymentStatus" (controlOutput)="onFormUpdate($event)"></chq-input>
        </div>
    </div>
    <div class="row mt-30 align-items-end">
        <div class="col-6">
            <div class="input-wrapper">
                <label class="input-label">{{ 'attachments' | translate }}</label>
                <div class="file-proof" *ngFor="let f of file; let i = index;">
                    <span>{{f?.name}}</span>
                    <div>
                        <span><mat-icon [svgIcon]="'attachment-reference'" class="mr-20"></mat-icon></span>
                        <span><mat-icon [svgIcon]="'cross'" (click)="handleFileRemove(i)"></mat-icon></span>
                    </div>
                </div>
                <div class="" *ngIf="!file">
                    <span>-</span>
                </div>
            </div>
        </div>
        <div class="col-6">
            <chq-button class="add-file" *ngIf="file.length < 1" [buttonModel]="attachmentButton"
                (click)="handleAddFileClick()"></chq-button>
            <input hidden type="file" #uploader (change)="handleUpload($event)"
                accept="image/png, image/jpg, image/jpeg, application/pdf">
        </div>
    </div>
    <div class="row mt-30">
        <div class="col-12">
            <chq-input [inputModel]="formModel.description" (controlOutput)="onFormUpdate($event)"></chq-input>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="footer-button">
                <chq-button [buttonModel]="savePaymentButtonModel" class="save-payment"
                    (click)="handleFormSubmit()"></chq-button>
            </div>
        </div>
    </div>
</form>

<ng-template #fullyPaid>
    <div class="payment-add">
        <div class="input-wrapper">
            <label class="input-label uppercase">{{ 'payment_status' | translate }}</label>
            <div><span>{{ repairPaymentStatus | translate }}</span></div>
        </div>
    </div>
</ng-template>