import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgOtpInputComponent } from 'ng-otp-input';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { ChqWidgetsInputModel, IControlValue } from 'src/app/model/chq-widgets-input-model';
import { ChqInputComponent } from 'src/app/widgets/chq-input/chq-input.component';
import { CommonService } from 'src/app/services/common/common.service';
import { CoreService } from 'src/app/services/core/core.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'edit-mobile-dialog',
  templateUrl: './edit-mobile-dialog.component.html',
  styleUrls: [ './edit-mobile-dialog.component.scss' ]
})
export class EditMobileDialogComponent implements OnInit {
  
  @ViewChild(NgOtpInputComponent, { static: false }) ngOtpInput:NgOtpInputComponent;
  correct_otp: number = 0;
  otpLoading: boolean = false;
  errorMessages = '';
  showOTPScreen = false;
  selectedCode: string = '';
  enteredNumber: string = '';
  otpData: any = { mobileNumber: '' }
  dropdownLoading: boolean = false;
  validPhoneNumberLength: number;
  @ViewChild('phoneRef') phoneRef: ChqInputComponent;
  public dropdownModel: ChqWidgetsInputModel = {
    placeHolder: '',
    label: '',
    name: 'code',
    value: 0,
    type: 'select',
    showImageFlag: true,
    displayValue: 'phoneCountryCode',
    fieldValue: 'phoneCountryCode',
    options: [ { label: '+90' }, { label: '+971' }, { label: '+977' } ],
    validation: {
      name: 'code',
      validationFunction: ():ValidatorFn => {
        return Validators.compose([ Validators.required ])
      },
      validationMessage: (error: ValidationErrors):string => {
        if (error['required']) {
          return 'Please select code.'
        }
        return '';
      }
    }
  };

  public phone:ChqWidgetsInputModel = {
    placeHolder: '',
    label: '',
    name: 'phone',
    value: 0,
    type: 'number',
    validation: {
      name: 'phone',
      validationFunction: ():ValidatorFn => {
        return Validators.compose([ Validators.required, Validators.pattern('^[0-9]+[0-9 ,.\'-]+$') ])
      },
      validationMessage: (error: ValidationErrors):string => {
        if (error['required']) {
          return 'Please enter mobile number'
        }
        if (error['pattern']) {
          return 'Please enter valid mobile number'
        }
        return '';
      }
    }
  }

  public resetButtonModel: ChqWidgetsButtonModel = {
    label: 'Verify',
    icon: 'check',
    type: 'disabled'
  }

  /**
   * updateModal Screen
   */
  hideOTPPage(error):void {
    this.monitorService.logEvent('hideOTPPage', [ 'EditMobileDialogComponent', 'addenda-quote' ]);
    this.showOTPScreen = false;
    const errorRetries = 'Max send attempts reached'
    if (error) {
      try{
        if(error && error.error && error.error.msg.indexOf(errorRetries) != -1){
          this.errorMessages = 'Oops! You tried to request otp too many times, Please try again later after some time.';
        }else{
          this.errorMessages = 'Sorry something went wrong. Please try again';
        }
      } catch(e){
        this.errorMessages = 'Sorry something went wrong. Please try again';
      }finally{
        this.otpConfig.inputClass = 'border-danger';
      }
    }
  }

  /**
   * on dropdown observable load
   */
  onObservableLoad(items: any): void {
    this.monitorService.logEvent('onObservableLoad', [ 'EditMobileDialogComponent', 'addenda-quote' ]);
    this.commonService.hideLoading();
    
    if (!items) {
      this.dialogRef.close();
      return;
    }
    this.dropdownLoading = false;
    if (items && items.length > 0) {
      const selectedItem = items.filter((it: any) => {
        return it.phoneCountryCode === this.selectedCode;
      });
      if (selectedItem.length > 0) {
        const output = selectedItem[0];
        const validationPattern = output['regExpression'];        
        this.dropdownModel = { ...this.dropdownModel, selectedOption: output };
        this.phoneRef.updateCurrentValidation(validationPattern, true);
      }
    }
  }

  /**
   * on init
   */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'EditMobileDialogComponent', 'addenda-quote' ]);
    if (!this.dropdownModel.selectedOption) {
      this.dropdownModel.selectedOption = {};
    }
    this.dropdownModel.selectedOption[this.dropdownModel.displayValue] = this.data.countryCode || '+91';
    if (!this.dropdownModel.observable) {
      this.dropdownModel.observable = this.coreService.getPhoneNumber();
    }
    this.commonService.showLoading();
    this.resetButtonModel.onclick = this.updateUserPhoneNumber.bind(this);
    this.selectedCode = this.data.countryCode || '+91';
    this.enteredNumber = this.data.phoneNumber?.replace(this.data?.countryCode, '');
    this.resetButtonModel.label = this.data.isPhoneNumberAddition ? 'Next' : 'Update';
    this.phone.value = this.data?.phoneNumber?.replace(this.data?.countryCode, ''); 
    this.dropdownLoading = true;
    if (this.selectedCode) {
      this.dropdownModel.onObservableLoad = this.onObservableLoad.bind(this);
    }
  }
   
  /**
   * update user phoneNumber
   */
  updateUserPhoneNumber(): void {
    this.commonService.showLoading();
    const otpData = { mobileNumber: `${this.selectedCode}${this.enteredNumber}` }
    this.otpData = otpData
    this.showOTPScreen = true
  }

  /**
   * constructor
   * @param dialogRef 
   * @param data 
   */
  constructor(public dialogRef: MatDialogRef<EditMobileDialogComponent>, private coreService: CoreService,
       public commonService: CommonService,
       private monitorService:MonitorService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  
  otpConfig: any = {
    length: 4,
    allowNumbersOnly: true,
    disableAutoFocus: true,
    inputClass: ''
  };

  /**
   * 
   * @param event 
   */
  public onFormUpdate(output: IControlValue): void {
    this.monitorService.logEvent('onFormUpdate', [ 'EditMobileDialogComponent', 'addenda-quote', {
      output
    } ]);
    if (output.type === 'select') {
      if (output.value) {
        this.selectedCode = output.value[this.dropdownModel.displayValue];
        this.dropdownModel = {
          ...this.dropdownModel, selectedOption: output.value
        };
      }
      const validationPattern = output.value['regExpression'];
      
      this.phoneRef.updateCurrentValidation(validationPattern);
    } else {
      this.enteredNumber = output.value;
      if (output.isValid) {
        this.enteredNumber = output.value;
      } else {
        this.enteredNumber = '';
      }
    }
    let originalNumber = '';
    if (this.selectedCode && this.data.phoneNumber) {
      originalNumber = this.data.phoneNumber.replace(this.selectedCode, '');
    }
    if (this.enteredNumber && this.selectedCode && this.enteredNumber !== originalNumber) {
      this.resetButtonModel.type = 'primary';
    } else {
      this.resetButtonModel.type = 'disabled';
    }
  }

  /**
   * close dialog
   */
  close(): void {
    this.monitorService.logEvent('close', [ 'EditMobileDialogComponent', 'addenda-quote' ]);
    this.dialogRef.close(true);
  }

}
