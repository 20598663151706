import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * hours validator
 * @returns
 */
export function whiteSpaceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value.trim();
    if (value) {
      return null;
    } else {
      return { 'whiteSpaceValidator': true };
    }
  };
}