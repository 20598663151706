import { Component, ElementRef, HostListener, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgOtpInputComponent } from 'ng-otp-input';
import { ActivatedRoute, Router } from '@angular/router';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { CoreService } from 'src/app/services/core/core.service';
import { firstValueFrom } from 'rxjs';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(utc)
@Component({
  selector: 'verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: [ './verify-otp.component.scss' ]
})
export class VerifyOtpComponent implements OnInit {
  submitted: boolean = false;
  form: any;
  formInput = [ 'input1', 'input2', 'input3', 'input4' ];
  selected_country: any;
  correct_otp: number = 0;
  resend: boolean = false;
  isScroll = false;
  seconds=30;
  resendCounter=2;
  currentSize = 0;
  currentHeight:any;
  currentOTPValue:string='';
  selectedEmail:string = '';
  action: string = '' 

  @ViewChildren('formRow') rows: any;
  @ViewChild(NgOtpInputComponent, { static: false }) ngOtpInput:NgOtpInputComponent;

  otpConfig: any = {
    length: 4,
    allowNumbersOnly: true,
    disableAutoFocus: true,
    inputClass: ''
  };
  
  @ViewChild('ngOtpInput') ngOtpInputRef:any;
  abortController:any;
  private token = '';
  timer: any;
  otpLoading: boolean = false;
  isLinkExpired: boolean = false;
  /**
   * 
   * @param httpClient 
   * @param commonService 
   * @param router 
   * @param networkService 
   * @param monitorService 
   * @param eRef 
   */
  constructor(
    private coreService: CoreService, private commonService: CommonService, private router:Router, 
    private route: ActivatedRoute, private monitorService: MonitorService, private eRef: ElementRef) {
    this.form = this.toFormGroup(this.formInput);
    this.token = this.route.snapshot.queryParams['token'];
    const obj:any = atob(this.token);
    const parsedObj = JSON.parse(obj);
    this.selectedEmail = parsedObj.GarageEmail;
    this.action = parsedObj.Action;
    
    const expiryTime = dayjs.utc(parsedObj.LinkExpirationTimeStamp);
    if(dayjs().isAfter(expiryTime)) {
      this.isLinkExpired = true;
    }
  }

  /**
   * init 
   */
  ngOnInit(): void {
    this.monitorService.logEvent('verifyOtp', [ 'VerifyOtp', 'addenda-claim', {
      'email': this.selectedEmail
    } ]);
    const el: any = document.getElementById('custom-loader');
    if(el) {
      el.style.display = 'none';
    }
    if(!this.isLinkExpired) {
      setTimeout(() => {
        this.resend = true;
      }, 30000);
      
      this.sendOtp(false);
      this.updateSeconds();
    }
    
  }

  /**
   * 
   * @param event 
   */
  @HostListener('document:click', [ '$event' ])
  clickout(event:Event):void {
    if(!this.eRef.nativeElement.contains(event.target)) {
      const container:any = document.querySelector('.verify-otp-wrapper');
      if(this.currentHeight){
        document.documentElement.style.setProperty('--vh', `${this.currentHeight}px`);
      }
      container.scrollTop = 0;      
    } 
  }
  
  /**
   * opt request listiner
   */
  otpRequestListener():void {
    if ('OTPCredential' in window) {

      this.abortController = new AbortController();
      this.timer = setTimeout(() => {
        this.abortController.abort();
        this.otpRequestListener();
      }, 30 * 1000);

      const o: any = {
        otp: { transport: [ 'sms' ] },
        signal: this.abortController.signal
      };
      window.navigator['credentials'].get(o).then((sms:any)=>{
        const content = sms.code;
        this.monitorService.logEvent('sms otp from credentials', [ 'VerifyOtp', 'addenda-claim', {
          otp: content
        } ]);
        this.ngOtpInput.setValue(content);
      })
        .catch((e)=>{
          console.log(e);
        });
    
    }
  }

  /**
   * resize event listiner
   */
  @HostListener('window:resize', [ '$event' ])
  onResize(event:any):void {
    const size = window.innerWidth + window.innerHeight;
    this.monitorService.logEvent('resize ', [ 'Verify OTP', 'addenda-claim', {
      originalSize: this.currentSize,
      resize: size
    } ]);
    if(size != this.currentSize){
      this.isScroll = true;
    }else{
      this.isScroll = false;
    }
  }
  
  /**
   * toFormGroup
   */
  toFormGroup(elements: any):FormGroup {
    const group: any = {};
    elements.forEach((key: any) => {
      group[key] = new FormControl('', Validators.required);
    });
    return new FormGroup(group);
  }


  errorMessages='';

  /**
   * verify otp
   * @param otp 
   */
  verifyOtp():void {
    if(this.otpLoading) return;
    this.monitorService.logEvent('verifyOtp ', [ 'VerifyOTP', 'addenda-claim', {
      otp: this.currentOTPValue
    } ]);
    const payload = {
      otpCode: this.currentOTPValue,
      userIdType: 'email',
      isExternalToken: 'true',
      emailorPhoneNumber: this.selectedEmail
    }
    this.otpLoading = true;
    this.commonService.showLoading();
    this.coreService.generateTokenFromOTP(payload).subscribe({
      next: async(res: any) => {
        if (res.success) {       
          const obj = {
            'accessToken': res?.data.accessToken,
            'refreshToken': res?.data.refreshToken
          }       
          this.coreService.generateRefreshToken(obj).subscribe({
            next: async (res: any) => {
              this.commonService.hideLoading();
              const token = { idToken: res?.data.accessToken }
              localStorage.setItem('XAuserDetails', JSON.stringify(token));
              if (res && this.action) {
                this.router.navigateByUrl(`claim/${this.action}?token=${this.token}`);
              } else {
                this.errorMessages = res.errors;
                this.correct_otp = 3;
                this.otpConfig.inputClass = 'border-red';
              }
            }, error: (error)=>{
              try{
                if(error && error.error && 
                  // eslint-disable-next-line max-len
                  error.error.msg.indexOf('Oops! you tried to enter your OTP code too many times you will be redirected back to the sign up screen.') != -1){
                  this.errorMessages = error.error.msg;
                
                }else{
                  this.errorMessages = error.error.msg;
                  if(!this.errorMessages){
                    this.errorMessages = 'Sorry something went wrong. Please try again';
                  }
                }
              } catch(e){
                this.errorMessages = 'Sorry something went wrong. Please try again';
              }finally{
                this.correct_otp = 3;
                this.otpConfig.inputClass = 'border-red';
              }
              this.monitorService.logException(error, 1);
            }
          });
        } else {
          this.errorMessages = res.errors;
          this.correct_otp = 3;
          this.otpConfig.inputClass = 'border-red';
        }
        this.otpLoading = false;
      }, error: (error)=>{
        this.otpLoading = false;
        try{
          if(error && error.error && error.error.msg.indexOf(`Oops! you tried to enter your OTP code too many times 
          you will be redirected back to the sign up screen.`) != -1){
            this.errorMessages = error.error.msg;
          }else{
            this.errorMessages = error.error.msg;
            if(!this.errorMessages){
              this.errorMessages = 'Sorry something went wrong. Please try again';
            }
          }
        } catch(e:any){
          this.errorMessages = 'Sorry something went wrong. Please try again';
          this.monitorService.logException(e, SeverityLevel.Error);
        }finally{
          this.correct_otp = 3;
          this.otpConfig.inputClass = 'border-red';
        }
        this.monitorService.logException(error, 1);
      }
    })
  }
  /**
   * update seconds
   */
  updateSeconds():void{
    setTimeout(()=>{
      if(this.seconds >0) {
        this.seconds--;
        this.updateSeconds();
      }
    }, 1000)
  }

  /**
   * send otp
   */
  sendOtp(resetCounter: boolean = true):void{
    this.commonService.showLoading();
    firstValueFrom( this.coreService.generateEmailOTP(this.selectedEmail)).then((res: any) => {
      if(resetCounter) {
        this.resend = false;
        setTimeout(() => {
          if(resetCounter) this.resendCounter = this.resendCounter - 1;
          this.resend = true;
        }, 30000);
        this.seconds = 30;
        this.updateSeconds();
      }
      
      this.errorMessages = '';
      this.correct_otp = 0;
      this.otpConfig.inputClass = '';
      this.ngOtpInput.setValue('');
      const inputs = document.getElementsByClassName('otp-input');
      if(inputs) {
        for(let i = 0; i < inputs.length; i++) {
          (inputs[i] as HTMLInputElement).disabled = false;
        }
      }
      this.commonService.hideLoading();
    }).catch((err) => {
      this.monitorService.logException(err, SeverityLevel.Error);
    });
  }

  /**
   * resend sms
   */
  resend_otp():void {
    if(this.resendCounter ===0){
      return;
    }
    const header = '';
    this.monitorService.logEvent('resend_otp ', [ 'VerifyOTP', 'addenda-claim' ]);
    const message = `Please confirm to re-send OTP to the email ${this.selectedEmail}`;
    const dialogRef = this.commonService.openConfirmYesNoDialogBig(header, message, '', 'Ok', 'Cancel')
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.sendOtp();
      }
    });
    
  }

  /**
   * onOtpChange event
   */
  onOtpChange(event: any):void {
    this.monitorService.logEvent('onOtpChange ', [ 'VerifyOTP', 'addenda-claim' ]);
    if(event.length == 4) {
      this.currentOTPValue = event;
      this.correct_otp = 1;
    }else{
      this.correct_otp = 0;
    }
  }
}
