import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'app-form-data-dialog',
  templateUrl: './form-data-dialog.component.html',
  styleUrls: [ './form-data-dialog.component.scss' ]
})
export class FormDataDialogComponent implements OnInit {
  public confirmButton: ChqWidgetsButtonModel;
  public cancelButton: ChqWidgetsButtonModel;
  subject: Subject<boolean>;

  /**
   * constructor
   * @param dialogRef 
   * @param data 
   * @param changeDedectionRef 
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FormDataDialogComponent>,
    private monitorService:MonitorService,
    private commonService:CommonService

  ) {
    this.subject = data.subject;
  }

  /**
   * handle close
   */
  continue():void{
    this.monitorService.logEvent('continue', [ 'FormDataDialogComponent', 'addenda-quote' ]);
    this.subject.next(true);
    this.subject.complete();
    this.dialogRef.close();
  }

  /**
   * handle close
   */
  cancel():void{
    this.monitorService.logEvent('cancel', [ 'FormDataDialogComponent', 'addenda-quote' ]);
    this.subject.next(false);
    this.subject.complete();
    this.dialogRef.close();
  }

  /**
   * ng on init
   */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'FormDataDialogComponent', 'addenda-quote' ]);
    this.confirmButton = {
      label: 'Continue',
      type: 'primary',
      onclick: this.continue.bind(this)
    }

    this.cancelButton = {
      label: 'Cancel',
      type: 'outline',
      onclick: this.cancel.bind(this)
    }
  }
}
