import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { Observable, Observer, Subscription, catchError, forkJoin, of } from 'rxjs';
import { ImgDialogComponent } from 'src/app/dialogs/img-dialog/img-dialog.component';
import { CommonService } from 'src/app/services/common/common.service';
import { MessagesService } from 'src/app/services/message/message.service';
import { SignalRService } from 'src/app/services/signalr/signalr.service';
import { ChqRepairDialogComponent } from '../../chq-repair-dialog/chq-repair-dialog.component';
import { ChatAttachment, ChatConversations, Message, MessageStatus } from '../models/message';
import { Thread, chatOdataToChatConversation } from '../models/thread';

@Component({
  selector: 'chat-thread',
  templateUrl: './chat-detail.component.html',
  styleUrls: [ './chat-detail.component.scss' ]
})
export class ChatDetailComponent implements OnInit, OnDestroy {
  @Input() ConversationGuid: string;
  @Input() chatId: string;
  //component variables.
  public messages: Message[] = [];
  public _thread: Thread;
  public newMessage: string;
  public isImage: boolean;
  public allowExtension = [ '.jpg', '.jpeg', '.png' ];

  private currentUserId: number = 0;
  private currentUserFullName: string = '';
  public pageReady: boolean = false;
  public repairStatus: string;

  private subscriptions: Subscription[] = [];

  @ViewChild('upload') upload: ElementRef;
  @ViewChild('div') div: ElementRef;
  private currentUserRole: string;
  hasErrorMessage: boolean = false;
  hasClaimPermission: boolean = false;

  public whatsappAllowedType:string[] = [
    'audio/aac', 
    'audio/mp4', 
    'audio/mpeg', 
    'audio/amr', 
    'audio/ogg', 
    'audio/opus', 
    'application/vnd.ms-powerpoint', 
    'application/msword', 
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', 
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 
    'application/pdf', 
    'text/plain', 
    'application/vnd.ms-excel', 
    'image/jpeg', 
    'image/png', 
    'image/webp', 
    'video/mp4', 
    'video/3gpp'
  ];

  /**
   * thread
   */
  @Input()
  set thread(t: Thread) {
    if (!t) {
      return;
    }
    if (t.objectId !== this._thread?.objectId) {
      this.messages = [];
      this.getMessages();
    }
    this._thread = t;
  }

  /**
   * get thread
   */
  get thread(): Thread {
    return this._thread;
  }

  /**
   *
   * @param Dialog
   */
  constructor(
    private dialog: MatDialog,
    public commonService: CommonService,
    private signalR: SignalRService,
    private messageService: MessagesService
  ) {
    const userProfile$ = this.commonService.userProfileData.subscribe((res) => {
      this.currentUserId = res?.data?.id;
      this.currentUserFullName = res?.data?.firstName + ' ' + res?.data?.lastName;

      const automotiveServices = res?.data?.userPermission?.automotiveServices;
      const automotiveService = automotiveServices?.find(x => x.automotiveServiceName.toLowerCase() === 'addenda repair' || x.automotiveServiceName.toLowerCase() === 'addenda claims');
      
      if (automotiveService?.automotiveServiceName?.toLowerCase() == 'addenda repair') {
        this.currentUserRole = automotiveService?.roleName?.toLowerCase();
        this.commonService.roleName = this.currentUserRole;
      } else {
        const permissionList = automotiveService?.permissions;
        this.hasClaimPermission = [ 'claim.selfapproval', 'claim.submitforapproval' ]
          .some(itemP => permissionList?.map(item => item?.permissionName).includes(itemP));
      }
    });

    const signalR$ = this.signalR.hubReceivedMessage.subscribe((data: any) => {
      if (data?.messageType === 'BidirectionalNotification') {
        try {
          const chatConversation: any = JSON.parse(data.message);
          if ((!this.thread.objectId && chatConversation.CustomerMobileNumber == this.thread.phoneNumber) || (!!this.thread.objectId && chatConversation.ObjectId == this.thread.objectId)) {
            if (this.messages.find(x => x.id == chatConversation.BidirectionalConversation.Id.toString())) {
              this.messages.find(x => x.id == chatConversation.BidirectionalConversation.Id.toString()).status = chatConversation.BidirectionalConversation.Status;

              this.messages = [ ...this.messages ];
            } else {
              const conversation = chatOdataToChatConversation([ chatConversation.BidirectionalConversation ]);
              this.messages.push(Message.ChatToMessageMapper(conversation[0], this.currentUserId));
              setTimeout(() => {
                this.div.nativeElement.scrollTop = this.div.nativeElement.scrollHeight;
              }, 100);
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    });

    const repairDetails$ = this.commonService.repairDetails.subscribe({
      next: (repairDetails: any) => {
        if (repairDetails) {
          this.repairStatus = repairDetails.status;
        }
      }
    });

    this.subscriptions.push(userProfile$);
    this.subscriptions.push(signalR$);
    this.subscriptions.push(repairDetails$);
  }

  /**
   * display file
   */
  displayFile(chatAttachment: ChatAttachment): void {
    const { blobUrl } = chatAttachment;
    window.open(blobUrl, '_blank');
  }

  /**
   * open image dialog
   */
  imageDialog(chatAttachment: ChatAttachment, showDownloadButton: boolean = false): void {
    const { blobUrl, name } = chatAttachment;
    const dialogRef = this.dialog.open(ImgDialogComponent, {
      data: {
        state: '',
        title: name,
        image: blobUrl,
        showDownloadButton: showDownloadButton
      }, 
      autoFocus: false,
      hasBackdrop: false,
      panelClass: 'test'
    });
    dialogRef.afterClosed().subscribe();
  }

  /**
   * ng init
   */
  ngOnInit(): void {
    this.messages = [];
    if (this.thread.objectId) {
      this.getMessages();
    } else {
      this.messages = this.conversationToMessage(this.thread.chatConversations, this.currentUserId);

      setTimeout(() => {
        if (this.ConversationGuid) {
          document.querySelector(`#message-${this.ConversationGuid}`).scrollIntoView()
        }else{
          this.div.nativeElement.scrollTop = this.div.nativeElement.scrollHeight;
        }
      }, 100);
    }

  }

  /**
   * show image dialog
   */
  showImageDialog(message: Message): void {
    const gallery = [];

    message.chatAttachments.map((ch: ChatAttachment) => {
      return ch.blobUrl
    }).forEach((url) => {
      const title = url.substring(0, url.indexOf('|')).trim();
      const imageUrl = url.substring(url.indexOf('|') + 1);

      gallery.push({
        image: imageUrl,
        thumbImage: imageUrl,
        title: title
      });
    });

    const data = { 'imageGallery': true, 'gallery': gallery };
    this.dialog.open(ChqRepairDialogComponent, {
      data:
        data
    });
  }

  /**
   * map conversation to message
   */
  conversationToMessage(chatConversations: ChatConversations[], currentUserId: number): Message[] {
    const msg = [];
    let hasUnreadMessage = false;
    for (const ct in chatConversations) {
      if (chatConversations[ct].sendByUserId !== this.currentUserId?.toString() && chatConversations[ct].status.toLowerCase() !== MessageStatus.read) {
        hasUnreadMessage = true;
      }
      if(chatConversations[ct].errorMessage){
        this.hasErrorMessage = true;
      }
      msg.push(Message.ChatToMessageMapper(chatConversations[ct], currentUserId));
    }

    if (hasUnreadMessage && [ 'bodyshop manager', 'service advisor' ].includes(this.currentUserRole)
    || (this.hasClaimPermission && hasUnreadMessage)) {
      this.updateMessageStatus();
    }

    return msg;
  }

  /**
   * update  message status
   */
  updateMessageStatus(): void {
    this.messageService.updateWhatsAppMessage(this.thread.phoneNumber.replace('+', ''), this.thread.domainId, this.thread.objectId).subscribe({
      next: (resp) => {
        console.log(resp);
      }
    });
  }

  /**
   * get messages
   */
  getMessages(): void {
    if (!this.thread) {
      return;
    }

    this.pageReady = false
    this.messageService.getWhatsAppMessages(this.thread.objectId, this.thread.domainId).subscribe({
      next: (resp: any) => {
        this.messages = [];
        this.messages = this.conversationToMessage(resp.data, this.currentUserId);

        this.pageReady = true;
        setTimeout(() => {
          this.div.nativeElement.scrollTop = this.div.nativeElement.scrollHeight;
        }, 100);
      },
      error: (resp) => {
        this.pageReady = true;
        return resp;
      }
    })
  }

  /**
   * get attachment object
   */
  getAttachmentObject(message: string, file?: Blob): ChatConversations {
    return {
      domainId: this.thread.domainId,
      objectId: this.thread.objectId,
      isGeneralChat: !this.thread.objectId,
      message,
      file,
      isInteractiveToBeInitiated: false,
      recipient: this.thread.phoneNumber,
      haveAttachment: !!file,
      attachment: null,
      status: MessageStatus.sending,
      isCustomerResponse: false,
      errorMessage: null,
      ConversationGuid: null,
    };
  }

  /**
   * post whatsapp message with assignment
   */
  postWhatsAppMessage(conversation: ChatConversations): void {
    this.messageService.postWhatsAppMessageWithAttachment(conversation, this.chatId).subscribe({
      next: (resp) => {
        if (resp) {
          this.messages = [];
          this.messages = this.conversationToMessage(resp.data, this.currentUserId);
          setTimeout(() => {
            this.div.nativeElement.scrollTop = this.div.nativeElement.scrollHeight;
          }, 100);
        }
      }, error: (err) => {
        return err;
      }
    });
  }

  /**
   *
   * @returns void
   */
  send(): void {
    if (!this.newMessage) {
      return;
    }
    const m2 = Message.make('b-thread', this.currentUserFullName, this.newMessage);
    m2.status = MessageStatus.sending;
    const conversation: ChatConversations = this.getAttachmentObject(this.newMessage);

    this.newMessage = '';
    this.messages.push(m2);
    setTimeout(() => {
      this.div.nativeElement.scrollTop = this.div.nativeElement.scrollHeight;
    }, 100);
    this.postWhatsAppMessage(conversation);
  }

  /**
   *
   * @param e
   * @returns
   */
  addOrUpdate(e: Message): void {
    for (const element of this.messages) {
      if (element.id === e.id) {
        // Implement a generic obj.Copy if all the fields need to be copied.
        Message.copy(e, element);
        return;
      }
    }
    this.messages.push(e);
  }

  /**
   * trigger null
   */
  resetValue(): void {
    setTimeout(() => {
      this.upload.nativeElement.value = null;
    });
  }

  /**
   * upload
   */
  uploadAttachment(): void {
    setTimeout(() => {
      this.upload.nativeElement.click();
    });
  }

  /**
   * file upload observable
   */
  fileReaderObservable(file: any): Observable<any> {
    const sequence = new Observable((observer: Observer<any>) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event: any): void => {
        observer.next({ image: reader.result, fileName: file.name, file: file });
        observer.complete();
      }
    });
    return sequence;
  }

  /**
   * handle file upload
   * @param event
   */
  handleUpload(event: any): void {
    const currentFile = event.target.files || event.srcElement.files || event.dataTransfer.files;

    if (currentFile !== null && currentFile !== '' && currentFile.length > 0) {
      const observableList = {};
      for (let i = 0; i < currentFile.length; i++) {
        if (!this.whatsappAllowedType.includes(currentFile[i].type)) {
          const type = currentFile[i].name ? currentFile[i].name.substring(currentFile[i].name.lastIndexOf('.')) : currentFile[i].type;

          const m = Message.errorMessageAttachment('b-thread', 'me', `Cannot allow the file of type ${type}`);
          this.messages.push(cloneDeep(m));
          continue;
        }
        observableList[i] = this.fileReaderObservable(currentFile[i]);
      }
      const keys = Object.keys(observableList);
      if (keys.length === 0) {
        this.commonService.hideLoading();
        this.resetValue();
        return;
      }
      const result = forkJoin(observableList).pipe(
        catchError(error => of(error))
      )
      result.subscribe((response: any) => {
        this.commonService.hideLoading();
        for (const key in keys) {
          const fileObj = response[key]
          const chatAttachment: ChatAttachment = {
            'name': fileObj.fileName,
            'blobUrl': fileObj.image,
            'type': fileObj.fileName.substring(fileObj.fileName.lastIndexOf('.'), fileObj.fileName.length).toLowerCase()
          }
          const conversation: ChatConversations = this.getAttachmentObject('', fileObj.file);
          this.postWhatsAppMessage(conversation);
          const m = Message.makeWithAttachment('b-thread', 'me', '', [ chatAttachment ]);
          this.messages.push(cloneDeep(m));
          setTimeout(() => {
            this.div.nativeElement.scrollTop = this.div.nativeElement.scrollHeight;
          }, 100);
        }



        this.resetValue();
      }, () => {
        this.resetValue();
        this.commonService.hideLoading();
      });
    }
  }

  /**
  * on destroy
  */
  ngOnDestroy(): void {
    if (this.subscriptions) this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    // this.signalR.stopRepairConnection();
  }
}
