import * as dayjs from 'dayjs';

/**
 * An enum of different types of content that can be contained in sms.
 */
export enum MessageContentType {
  PlainText,
  Image,
  EmojiText,
  Video
}

/**
 * message status
 */
export enum MessageStatus {
  sent = 'sent',
  delivered = 'delivered',
  read = 'read',
  sending = 'sending'
}


/**
 * Mock-model for a single sms message in a thread
 */
export class Message {
  id: string;
  threadID: string;
  userID: string;
  contentType: MessageContentType;
  content: any;
  timestamp: number;
  dateFormat: string;
  status?: MessageStatus;
  isCustomerResponse: boolean;
  chatAttachments?: ChatAttachment[]

  // True if the message after this one is by a different user.
  isLocalLast: boolean;

  // True if the message before this one was sent/recieved yesterday.
  isNewDay: boolean;
  errorMessage: string;
  ConversationGuid: string;

  /**
   * 
   * @param threadID 
   * @param userID 
   * @param content 
   * @returns 
   */
  static make(threadID: string, userID: string, content: any): Message {
    const m1 = new Message();
    m1.threadID = threadID;
    m1.userID = userID;
    m1.content = content;
    m1.contentType = MessageContentType.PlainText;
    m1.timestamp = Date.now();
    m1.dateFormat = dayjs().format('ddd, DD MMM YYYY');
    m1.errorMessage = null;
    return m1;
  }

  /**
   * 
   * @param threadID 
   * @param userID 
   * @param content 
   * @returns 
   */
  static makeWithAttachment(threadID: string, userID: string, content: any, chatAttachment: ChatAttachment[]): Message {
    const m1 = new Message();
    m1.threadID = threadID;
    m1.userID = userID;
    m1.content = content;
    m1.contentType = MessageContentType.PlainText;
    m1.chatAttachments = chatAttachment;
    m1.timestamp = Date.now();
    m1.dateFormat = dayjs().format('ddd, DD MMM YYYY');
    m1.errorMessage = null;
    return m1;
  }

  /**
   * maps chatconversation to message object
   * @param chat 
   * @returns 
   */
  static ChatToMessageMapper(chat: ChatConversations, currentUserId: number): Message {
    const m1 = new Message();
    m1.content = chat.message;
    m1.chatAttachments = chat.attachment ? [ chat.attachment ] : [];
    m1.contentType = chat.type;
    m1.id = chat.id.toString();
    m1.status = chat.status;
    m1.userID = chat.sendByUsername;
    m1.isCustomerResponse = chat.isCustomerResponse
    m1.timestamp = new Date(chat.sendOn).getTime();
    m1.dateFormat = dayjs(chat.sendOn).format('ddd, DD MMM YYYY');
    m1.errorMessage = chat.errorMessage ?? null;
    m1.ConversationGuid = chat.ConversationGuid ?? null;
    return m1;
  }

  /**
   * error message during file attachment
   */
  static errorMessageAttachment(threadID: string, userID: string, errorMessage: string): Message {
    const m1 = new Message();
    m1.threadID = threadID;
    m1.userID = userID;
    m1.contentType = MessageContentType.PlainText;
    m1.timestamp = Date.now();
    m1.dateFormat = dayjs().format('ddd, DD MMM YYYY');
    m1.errorMessage = errorMessage;
    return m1;
  }

  // Copy copies over fields that can be updated safely from one message to another
  /**
   * 
   * @param from 
   * @param to 
   */
  static copy(from: Message, to: Message): void {
    to.content = from.content;
    to.timestamp = from.timestamp;
    to.isLocalLast = from.isLocalLast;
    to.isNewDay = from.isNewDay;
  }
}

export class ChatAttachment {
  'name': string | null;
  'blobUrl': string | null;
  'type': string | null;
}

export class ChatConversations {
  'domainId': number;
  'objectId': number;
  'message': string | null;
  'recipient': string | null;
  'sender'?: string | null;
  'id'?: number | null;
  'replyToMessageId'?: number | null;
  'haveAttachment': boolean;
  'isGeneralChat': boolean | null;
  'isInteractiveToBeInitiated': boolean | null;
  'attachment': ChatAttachment;
  'type'?: number;
  'file'?: Blob;
  'communicationChannel'?: number;
  'sendByUserId'?: null;
  'sendByUsername'?: null;
  'status': MessageStatus;
  'sendOn'?: string | null;
  'isCustomerResponse': boolean;
  'errorMessage': string | null;
  'ConversationGuid': string | null;
}

export class ChatConversationsOdata {
  'Message': string | null;
  'CustomerMobileNumber': string | null;
  'Sender'?: string | null;
  'Id'?: number | null;
  'ReplyToMessageId'?: number | null;
  'HaveAttachment': boolean;
  'IsGeneralChat': boolean | null;
  'IsInteractiveToBeInitiated': boolean | null;
  'Attachment': ChatAttachment;
  'ObjectId': number | null;
  'Type'?: number;
  'Status': MessageStatus;
  'CommunicationChannel'?: number;
  'SendByUserId'?: null;
  'SendByUsername'?: null;
  'SendOn'?: string | null;
  'IsCustomerResponse'?: boolean | null;
  'errorMessage'?: string | null;
  'ConversationGuid': string | null;
}

export class ChatJSON {
  'CustomerName': string;
  'Id': number;
  'ChatConversations': ChatConversationsOdata;
}
