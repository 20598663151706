import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  AfterViewInit,
  Output,
  ViewChild,
  SimpleChanges,
  OnChanges,
  OnDestroy
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import {
  ChqWidgetsDropdownConfig,
  ChqWidgetsDropdownModel,
} from 'src/app/model/chq-widgets-dropdown-model';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'chq-dropdown',
  templateUrl: './chq-dropdown.component.html',
  styleUrls: [ './chq-dropdown.component.scss' ],
})
export class ChqDropdownComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() dropdownModel: ChqWidgetsDropdownModel = {
    displayValue: '',
    placeHolder: '',
    options: [],
    name: '',
    label: '',
    selectedOption: {},
    showSelectOption: true,
    disabled: false
  };
  dropdownConfig: ChqWidgetsDropdownConfig;
  @Output() selected: EventEmitter<any> = new EventEmitter();
  @Output() clicked: EventEmitter<any> = new EventEmitter();
  showOptions = false;
  empty = false;
  dropdownOptions: any[] = [];
  public formGroup: FormGroup = this.fb.group([]);
  currentObj: any;
  showLoader: boolean = false;
  issvgClicked: boolean = false;

  /**
   * constructor
   * @param eRef 
   */
  constructor(private eRef: ElementRef, private fb: FormBuilder, private monitorService: MonitorService) {
  }

  @ViewChild('ul') ul: any;

  /**
   * onSelection
   */
  onSelectPress(event): void {
    if(this.issvgClicked){
      setTimeout(() => this.issvgClicked = false, 50);
      return;
    }
    if(event?.srcElement?.localName === 'svg'){
      this.issvgClicked = true;
    }
    const bbox = this.eRef.nativeElement?.getBoundingClientRect();
    this.dropdownConfig.isTouched = true;
    this.dropdownConfig.top = bbox.top + 'px';
    this.dropdownConfig.width = bbox.width + 'px';
    this.dropdownConfig.left = bbox.left + 'px';
    this.showOptions = !this.showOptions;
    setTimeout(() => {
      if (!this.dropdownModel.selectedOption && (this.dropdownConfig.isDirty || this.dropdownConfig.isTouched) && this.formControl.invalid) {
        this.selected.emit({
          name: this.dropdownModel.name, value: '', type: 'select',
          validationMessage: this.dropdownModel.validation.validationMessage(this.formControl.errors)
        });
      }
      this.checkIfInView(this.eRef);
    }, 1);
    this.clicked.emit(this.dropdownModel.name);
  }

  /**
   * returns formControl
   */
  get formControl(): AbstractControl<any, any> {
    return this.formGroup.controls[this.dropdownModel.name];
  }

  /**
   * detects changes in input attributes
   * @param changes 
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes['dropdownModel'] && changes['dropdownModel'].currentValue) {
        if (!changes['dropdownModel'].currentValue.observable) {
          this.dropdownOptions = this.dropdownModel.options;
        }
        if (this.dropdownModel.selectedOption) {
          const selectedValue = this.selectedKeyValues();
          if (selectedValue) {
            this.formControl?.setValue(selectedValue);
          }
        }
      }
    }
  }


  /**
   * close dropdown
   */
  closeDropdown(): void {
    this.monitorService.logEvent('closeDropdown', [ 'ChqDropdownComponent', 'addenda-quote' ]);

    if (!this.showOptions) {
      return;
    }
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showOptions = false;
      setTimeout(() => {
        if ((!this.dropdownModel.selectedOption || (this.dropdownModel.selectedOption && !this.dropdownModel.isMultiSelect &&
          !this.dropdownModel.selectedOption[this.dropdownModel.displayValue])
          || (this.dropdownModel.selectedOption && this.dropdownModel.isMultiSelect
            && this.dropdownModel.selectedOption.length === 0))
          && (this.dropdownConfig.isDirty || this.dropdownConfig.isTouched) && this.formControl.invalid) {
          this.selected.emit({
            name: this.dropdownModel.name, value: '', type: 'select',
            validationMessage: this.dropdownModel.validation.validationMessage(this.formControl.errors)
          });
        }
      }, 10);
    }
  }

  /**
   * document click event handler
   */
  @HostListener('document:click', [ '$event' ])
  clickout(event: Event): void {
    this.monitorService.logEvent('clickout', [ 'ChqDropdownComponent', 'addenda-quote', {
      event
    } ]);
    if (!this.showOptions) {
      return;
    }
    if (!this.eRef.nativeElement.contains(event.target) && !this.issvgClicked) {
      this.showOptions = false;
      setTimeout(() => {
        if ((!this.dropdownModel.selectedOption || (this.dropdownModel.selectedOption && !this.dropdownModel.isMultiSelect &&
          !this.dropdownModel.selectedOption[this.dropdownModel.displayValue])
          || (this.dropdownModel.selectedOption && this.dropdownModel.isMultiSelect
            && this.dropdownModel.selectedOption.length === 0))
          && (this.dropdownConfig.isDirty || this.dropdownConfig.isTouched) && this.formControl.invalid) {
          this.selected.emit({
            name: this.dropdownModel.name, value: '', type: 'select',
            validationMessage: this.dropdownModel.validation.validationMessage(this.formControl.errors)
          });
        }
      }, 10);
    }
    if(this.issvgClicked){
      setTimeout(() => this.issvgClicked = false, 1);
    }
  }

  /**
   * checks if dropdown content is in view
   */
  checkIfInView(element: ElementRef): void {
    const bbox = element.nativeElement.querySelector('.dropdown')?.getBoundingClientRect();
    const childBBox = element.nativeElement.children[0].children[1].children[1]?.getBoundingClientRect();

    const topHeight = bbox.top;
    const bottomheight = childBBox?.bottom;

    // if (!this.dropdownModel.label) {
    //   topHeight = bbox.top + 42;
    //   bottomheight = childBBox?.bottom + 42;
    // }

    if ((childBBox?.bottom + element.nativeElement.offsetHeight) > window.innerHeight) {
      this.dropdownConfig.top = (topHeight) - childBBox?.height + 'px';
    } else {
      this.dropdownConfig.top = (topHeight) + bbox?.height + 'px';
    }
    if (childBBox && childBBox.bottom > window.innerHeight) {
      const container: any = document.querySelector('.selection-container');
      if (container) {
        container.scrollTop = bottomheight;
      }
    }
  }

  /**
   * 
   * @param selectedItem 
   * @returns bool
   */
  isNotNull(selectedItem: any): boolean {
    if (!selectedItem) {
      return false;
    }
    if (this.dropdownModel.isMultiSelect) {
      if (selectedItem.length === 0) {
        return false;
      }
    } else if (
      !this.dropdownModel?.displayValue ||
      !selectedItem[this.dropdownModel?.displayValue]
    ) {
      return false;
    }
    if (this.dropdownConfig) this.dropdownConfig.isDirty = true;
    return true;
  }

  /**
   * render Selected Value
   */
  selectedValues(): any {
    if (this.dropdownModel.isMultiSelect) {
      return this.dropdownModel.selectedOption?.map((option: any) => {
        return option[this.dropdownModel.displayValue];
      })
      //return this.dropdownModel.placeHolder;
    } else {
      return this.dropdownModel.selectedOption[this.dropdownModel.displayValue];
    }
  }

  /**
   * render Selected Value
   */
  selectedKeyValues(): any {
    const field = this.dropdownModel.keyFieldName ? this.dropdownModel.keyFieldName : this.dropdownModel.displayValue;
    if (this.dropdownModel.isMultiSelect) {
      return this.dropdownModel.selectedOption.map((option: any) => {
        return option[field];
      })
      //return this.dropdownModel.placeHolder;
    } else {
      return this.dropdownModel.selectedOption[field];
    }
  }
  /**
   * check if option is selected
  */
  isChecked(item: any): boolean {
    if (!this.dropdownModel.selectedOption) {
      return false;
    }
    const displayField = this.dropdownModel.keyFieldName ? this.dropdownModel.keyFieldName : this.dropdownModel.displayValue;
    const selectedItem = this.dropdownModel.selectedOption.filter((option: any) => {
      return option[displayField] === item[displayField];
    })
    if (selectedItem.length > 0) {
      return true;
    }
    return false;
  }

  /**
   * update selection status
   */
  updateSelection($event: Event, item: any): void {
    $event.stopPropagation();
    const checkbox = $event.target as HTMLInputElement;
    const displayField = this.dropdownModel.keyFieldName ? this.dropdownModel.keyFieldName : this.dropdownModel.displayValue;
    if (checkbox.checked) {
      const selectionArray = [ ...this.dropdownModel.selectedOption, item ];
      const values = selectionArray.map((item: any) => {
        return item[displayField];
      });
      this.formGroup.get(this.dropdownModel.name).setValue(values);
      this.selected.emit({ name: this.dropdownModel.name, value: selectionArray, type: 'select' });
    } else {
      const selectionArray = this.dropdownModel.selectedOption.filter((option: any) => {
        return option[displayField] != item[displayField];
      });
      const values = selectionArray.map((option: any) => {
        return option[displayField];
      });
      this.formGroup.get(this.dropdownModel.name).setValue(values);
      this.selected.emit({ name: this.dropdownModel.name, value: selectionArray, type: 'select' });
    }
  }

  /**
   * onItemSelect 
   */
  onItemSelect(item: any): void {
    this.monitorService.logEvent('onItemSelect', [ 'ChqDropdownComponent', 'addenda-quote', {
      item
    } ]);
    if (this.dropdownModel.isMultiSelect) {
      return;
    }
    this.dropdownConfig.isDirty = true;
    if (item && item[this.dropdownModel.displayValue]) {
      this.formGroup.get(this.dropdownModel.name).setValue(item[this.dropdownModel.displayValue]);
      this.selected.emit({ name: this.dropdownModel.name, value: item, type: 'select' });
    } else {
      this.formGroup.get(this.dropdownModel.name).setValue('');
      this.selected.emit({ name: this.dropdownModel.name, value: '', type: 'select' });
    }
  }


  /**
   * set selected
   */
  setSelectedPrefill(): void {
    const { keyFieldName, displayValue, selectedOption } = this.dropdownModel;
    const selector = keyFieldName ? keyFieldName : displayValue;

    if (selectedOption.length) {
      if(this.dropdownOptions?.length == 0) {
        return;
      }
      const tempArray = [];
      selectedOption.forEach((option) => {
        if (option && option[selector] && !option[displayValue]) {
          const selectedOptions = this.dropdownOptions.filter((option1: any) => {
            return option1[selector] === option[selector];
          });
          if (selectedOptions.length > 0) {
            tempArray.push(selectedOptions[0]);
          }
        }
      });

      if(tempArray.length) {
        this.dropdownModel.selectedOption = tempArray ;
        const displayValues = tempArray.map(q => q[this.dropdownModel.displayValue]);
        this.formGroup.get(this.dropdownModel.name).setValue(displayValues.join(','));
        this.selected.emit({ name: this.dropdownModel.name, value: tempArray, type: 'select' });
      }
    } else {
      if (selectedOption && selectedOption[selector] && !selectedOption[displayValue]) {
        const selectedOptions = this.dropdownOptions.filter((option: any) => {
          return option[selector] === selectedOption[selector];
        });
        if (selectedOptions.length > 0) {
          this.dropdownModel.selectedOption = selectedOptions[0];
          this.formGroup.get(this.dropdownModel.name).setValue(selectedOptions[0][this.dropdownModel.displayValue]);
          this.selected.emit({ name: this.dropdownModel.name, value: selectedOptions[0], type: 'select' });
        }
      }
    }
  }

  /**
   * handle observable
   */
  getObservable(): void {
    if (this.dropdownModel.observable) {
      this.monitorService.logEvent('getObservable', [ 'ChqDropdownComponent', 'addenda-quote' ]);
      if (this.dropdownModel.mode !== 'text') {
        this.showLoader = true;
      }

      this.dropdownModel.observable.subscribe({
        next: (response: any) => {
          if (response === null) {
            if (this.dropdownModel.errorWhenEmpty) {
              this.empty = true;
              this.selected.emit({
                name: this.dropdownModel.name, value: '', type: 'select',
                validationMessage: 'There are no values, please contact the Addenda Admin'
              });
            }
            return;
          }
          this.dropdownOptions = (response.data) ? response.data : (response.value) ? response.value :response;
          if (this.dropdownOptions.length === 0) {
            if (this.dropdownModel.errorWhenEmpty) {
              this.empty = true;
              this.selected.emit({
                name: this.dropdownModel.name, value: '', type: 'select',
                validationMessage: 'There are no values, please contact the Addenda Admin'
              });
            }
          } else {
            this.empty = false;
            if (this.dropdownModel.errorWhenEmpty) {
              this.selected.emit({
                name: this.dropdownModel.name, value: '', type: 'select',
                validationMessage: ''
              });
            }
          }
          for (let i = 0; i < this.dropdownOptions.length; i++) {
            const item = this.dropdownOptions[i];
            if (typeof item === 'string') {
              const obj = {};
              obj[this.dropdownModel.displayValue] = item;
              if (this.dropdownModel.keyFieldName) {
                obj[this.dropdownModel.keyFieldName] = item;
              }
              this.dropdownOptions[i] = obj;
            }
          }

          if (this.dropdownModel.onObservableLoad) {
            this.dropdownModel.onObservableLoad(this.dropdownOptions);
            this.monitorService.logEvent('onObservableLoad', [ 'ChqDropdownComponent', 'addenda-quote' ]);
          }
          this.setSelectedPrefill();
          this.showLoader = false;
        }, error: (err: any) => {
          if (this.dropdownModel.onObservableLoad) {
            this.dropdownModel.onObservableLoad(false);
          }
          this.monitorService.logException(err, SeverityLevel.Error);
          if (this.dropdownModel.errorWhenEmpty) {
            this.empty = true;
            this.selected.emit({
              name: this.dropdownModel.name, value: '', type: 'select',
              validationMessage: 'There are no values, please contact the Addenda Admin'
            });
          }
          this.showLoader = false;
        }
      })
    }
  }

  /**
   * ngOnInit hook
   */
  ngOnInit(): void {
    window.addEventListener('scroll', this.scroll, true);
    this.monitorService.logEvent('ngOnInit', [ 'ChqDropdownComponent', 'addenda-quote' ]);
    let values = '';
    const displayField = this.dropdownModel.displayValue;
    const valueField = this.dropdownModel.keyFieldName ? this.dropdownModel.keyFieldName : this.dropdownModel.displayValue;
    if (this.dropdownModel.selectedOption) {
      if (this.dropdownModel.isMultiSelect && this.dropdownModel.selectedOption.length > 0) {
        values = this.dropdownModel.selectedOption.map((option: any) => {
          return valueField ? option[valueField] : option[displayField];
        });
      } else {
        values = valueField ? this.dropdownModel.selectedOption[valueField] : this.dropdownModel.selectedOption[displayField];
      }
    }
    if (this.dropdownModel.validation?.validationFunction) {
      this.formGroup.addControl(
        this.dropdownModel.name,
        new FormControl(values, this.dropdownModel.validation.validationFunction())
      )
    } else {
      this.formGroup.addControl(
        this.dropdownModel.name,
        //new FormControl('', Validators.required)
        new FormControl('')
      );
    }
    this.dropdownOptions = this.dropdownModel.options;
    this.getObservable();
  }

  /**
   * scroll event
   * @param event 
   */
  scroll = (event: any): void => {
    if(!event?.target?.classList?.contains('dropdown-wrapper')) {
      this.showOptions = false;
    }
  };

  /**
   * ngAfterViewInit hook
   */
  ngAfterViewInit(): void {
    this.currentObj = this.eRef.nativeElement;
    this.monitorService.logEvent('ngAfterViewInit', [ 'ChqDropdownComponent', 'addenda-quote' ]);
    setTimeout(() => {
      this.dropdownConfig = {
        top: '',
        width: '',
        left: '',
        isTouched: false,
        isDirty: false
      }
    }, 0);
  }

  /**
   * onDestroy hook
   */
  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.scroll, true);
    this.monitorService.logEvent('ngOnDestroy', [ 'ChqDropdownComponent', 'addenda-quote' ]);
  }
}
