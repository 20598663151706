<div class="form-field-toggle">
    <div class="toggle-field">
        <input class="form-value" type="checkbox" #input
            [id]="inputId"
            [name]="nameId"
            [readonly]="readonly" 
            [disabled]="disabled" 
            [required]="required"
            [checked]="value"
            (change)="_onInputInteraction($event)">
        <label class="form-label pointer-events" [for]="inputId">
            <div class="toggle-slider" *ngIf="position === 'start'">
                <span class="slider"></span>
            </div>
            <span>
                <ng-content></ng-content>
            </span>
            <div class="toggle-slider" *ngIf="position === 'end'">
                <span class="slider"></span>
            </div>
        </label>
    </div>
    <!-- <label class="form-error">{{getErrorMessage(formGroup, controlName, label) | translate}}</label> -->
</div>

<!-- <ng-template #readOnlyTemplate>
    <div class="read-only">
        <div class="form-value">{{existingValue}}</div>
    </div>
</ng-template> -->