import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CustomValidationResult } from 'src/app/model/chq-validation-model';
import { ChqWidgetsInputModel } from 'src/app/model/chq-widgets-input-model';

interface KeyValueType<T> {
  [k: string]: T;
}

export interface LabourRateForm extends KeyValueType<ChqWidgetsInputModel> {
    mechanicCost: ChqWidgetsInputModel;
    paintCost: ChqWidgetsInputModel;
    interiorCost: ChqWidgetsInputModel;
    electricCost: ChqWidgetsInputModel;
    bodyCost: ChqWidgetsInputModel;
    partsDiscount: ChqWidgetsInputModel;
    labourDiscount: ChqWidgetsInputModel;
    paintDiscount: ChqWidgetsInputModel;
    paintMatDiscount: ChqWidgetsInputModel;
}

export interface LabourRateFormData extends KeyValueType<string | number> {
  paintDiscount: number;
  paintMatDiscount: number;
  labourDiscount: number;
  partsDiscount: number;

  mechanicCost: number;
  bodyCost: number;
  paintCost: number;
  interiorCost: number;
  electricCost: number;
}

export const labourRateForm = (): LabourRateForm => {
  return {
    mechanicCost: {
      placeHolder: 'Mechanic Cost',
      name: 'mechanicCost',
      label: 'Mechanic Cost',
      value: 0,
      type: 'number',
      validation: {
        name: 'mechanicCost',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.required, labourValidators() ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return 'Please enter mechanic cost'
          } else if(error['invalidLabour']) {
            return 'Amount should be less than 99,999'
          }
          return '';
        }
      }
    },
    paintCost: {
      placeHolder: 'Paint Cost',
      name: 'paintCost',
      label: 'Paint Cost',
      value: 0,
      type: 'number',
      validation: {
        name: 'paintCost',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.required, labourValidators() ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return 'Please enter paint cost'
          }/*else if(error['maxlength']) {
            return 'Maximum 8 characters are allowed'
          } */else if(error['invalidLabour']) {
            return 'Amount should be less than 99,999'
          }
          return '';
        }
      }
    },
    interiorCost: {
      placeHolder: 'Interior Cost',
      name: 'interiorCost',
      label: 'Interior Cost',
      value: 0,
      type: 'number',
      validation: {
        name: 'interiorCost',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.required, labourValidators() ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return 'Please enter interior cost'
          } else if(error['maxlength']) {
            return 'Maximum 6 characters are allowed'
          } else if(error['invalidLabour']) {
            return 'Amount should be less than 99,999'
          }
          return '';
        }
      }
    },
    electricCost: {
      placeHolder: 'Electric Cost',
      name: 'electricCost',
      label: 'Electric Cost',
      value: 0,
      type: 'number',
      validation: {
        name: 'electricCost',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.required, labourValidators() ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return 'Please enter electric cost'
          } else if(error['maxlength']) {
            return 'Maximum 6 characters are allowed'
          } else if(error['invalidLabour']) {
            return 'Amount should be less than 99,999'
          }
          return '';
        }
      }
    },
    bodyCost: {
      placeHolder: 'Body Cost',
      name: 'bodyCost',
      label: 'Body Cost',
      value: 0,
      type: 'number',
      maxLength: 8,
      validation: {
        name: 'bodyCost',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.required, labourValidators() ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return 'Please enter body cost'
          } else if(error['maxlength']) {
            return 'Maximum 6 characters are allowed'
          } else if(error['invalidLabour']) {
            return 'Amount should be less than 99,999'
          }
          return '';
        }
      }
    },
    partsDiscount: {
      placeHolder: 'Parts Discount',
      name: 'partsDiscount',
      label: 'Parts Discount',
      value: 0,
      type: 'number',
      maxLength: 7,
      validation: {
        name: 'partsDiscount',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.required, percentValidators() ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return 'Please enter parts discount'
          } else if (error['invalidData']) {
            return 'Value should be between 0 and 99'
          } else if (error['pattern']) {
            return 'Only whole numbers are allowed'
          }
          return '';
        }
      }
    },
    labourDiscount: {
      placeHolder: 'Labour Discount',
      name: 'labourDiscount',
      label: 'Labour Discount',
      value: 0,
      type: 'number',
      validation: {
        name: 'labourDiscount',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.required, percentValidators() ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return 'Please enter labour discount'
          } else if (error['invalidData']) {
            return 'Value should be between 0 and 99'
          } else if (error['pattern']) {
            return 'Only whole numbers are allowed'
          }
          return '';
        }
      }
    },
    paintDiscount: {
      placeHolder: 'Paint Discount',
      name: 'paintDiscount',
      label: 'Paint Discount',
      value: 0,
      type: 'number',
      validation: {
        name: 'paintDiscount',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.required, percentValidators()])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return 'Please enter paint discount'
          } else if (error['invalidData']) {
            return 'Value should be between 0 and 99'
          } else if (error['pattern']) {
            return 'Only whole numbers are allowed'
          }
          return '';
        }
      }
    },
    paintMatDiscount: {
      placeHolder: 'Paint Mat Discount',
      name: 'paintMatDiscount',
      label: 'Paint Mat Discount',
      value: 0,
      type: 'number',
      validation: {
        name: 'paintMatDiscount',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.required, percentValidators() ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return 'Please enter paint mat discount'
          } else if (error['invalidData']) {
            return 'Value should be between 0 and 99'
          } else if (error['pattern']) {
            return 'Only whole numbers are allowed'
          }
          return '';
        }
      }
    },
  }
};


/**
 * custom percentage validator
 * @returns 
 */
export function percentValidators(): ValidatorFn {
  const result: CustomValidationResult = { 'invalidData': false };
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value < 0 || control.value > 99) {
      result['invalidData'] = true;
      return result;
    }
    return null;
  }
}

/**
 * 
 * @returns 
 */
export function labourValidators() : ValidatorFn {
  const result: CustomValidationResult = { 'invalidLabour': false };
  return (control: AbstractControl): ValidationErrors | null => {
    //if (control.value < 1|| control.value > 99999) {
    if (control.value > 99999) {
      result['invalidLabour'] = true;
      return result;
    }
    return null;
  }
}