import { Component, Inject } from '@angular/core';
import { ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { ChqWidgetsInputModel, IControlValue } from 'src/app/model/chq-widgets-input-model';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'app-registered-garage-reject-claim',
  templateUrl: './registered-garage-reject-claim.component.html',
  styleUrls: [ './registered-garage-reject-claim.component.scss' ]
})
export class RegisteredGarageRejectClaimComponent {
  public saveButton: ChqWidgetsButtonModel = {
    label: 'save',
    type: 'disabled',
    onclick: this.save.bind(this),
  };

  public messageModel: ChqWidgetsInputModel = {
    placeHolder: 'notes',
    label: 'notes',
    name: 'notes',
    value: '',
    type: 'textarea',
    maxLength: 500,
    validation: {
      name: 'policeReferenceNumber',
      validationFunction: (): ValidatorFn => {
        return Validators.compose([ Validators.maxLength(500) ])
      },
      validationMessage: (error: ValidationErrors): string => {
        if (error['maxlength']) {
          return 'third_party_reject_estimate_max'
        }
        return '';
      }
    }
  };
  /**
   * constructor
   * @param dialogRef 
   * @param data 
   * @param changeDedectionRef 
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RegisteredGarageRejectClaimComponent>,
    private monitorService: MonitorService,
  ) {
  }

  /**
   * close
   */
  close(): void {
    this.dialogRef.close();
  }

  /**
   * close
   */
  save(): void {
    this.dialogRef.close(this.messageModel.value);
  }
  
  /**
   * on form update
   * @param output o
   */
  onFormUpdate(output: IControlValue): void {
    this.messageModel = {
      ...this.messageModel, value: output.value
    }
    this.validateForm();
  }

  /**
   * validateForm
   */
  validateForm(): void {
    if(this.messageModel.value) {
      this.saveButton.type = 'primary';
    } else {
      this.saveButton.type = 'disabled';
    }
  }

  /**
   * ng on init
   */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'RegisteredGarageRejectClaimComponent', 'addenda-repair' ]);
    if(this.data.notes) {
      this.messageModel = {
        ...this.messageModel, value: this.data.notes
      }
      this.validateForm()
    }

  }
}
