<div class="dialog-container">
    <div mat-dialog-title class="title-wrapper">
        <div class="title-handler">
           <h3>{{data.title}}</h3>
            <span>{{data.state}}</span>
        </div>
       
        <div mat-dialog-close class="btn-close" (click)="close()">
             <mat-icon class="cross" svgIcon="cross" aria-hidden="false"
                aria-label='close icon'></mat-icon>
        </div>
    </div>
    
    <div mat-dialog-content class="img-wrapper">
        <img [src]="data.image" alt="displays downloaded picture"/>
    </div>
    <div mat-dialog-actions *ngIf="data.showDownloadButton">
        <xa-button  (click)="downloadImage(data.image, data.title)" class="btn-download">
            <mat-icon [svgIcon]="'download-arrow'" [color]="'white'"></mat-icon>
            <span> {{'download' | translate }}</span>
        </xa-button>
    </div>
</div>