import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ImageSliderModel } from 'src/app/model/chq-images-slider';
import * as Hammer from 'hammerjs';

@Component({
  selector: 'chq-images-slider',
  templateUrl: './chq-images-slider.component.html',
  styleUrls: [ './chq-images-slider.component.scss' ]
})
export class ChqImagesSliderComponent implements OnChanges {
  @Input() sliderModel: ImageSliderModel[];
  public currentActiveIndex: number = 0;
  public imageLoading = {};

  public SWIPE_ACTION = {
    LEFT: 'swipeleft',
    RIGHT: 'swiperight'
  };

  /**
   * update selected image
   * @param index 
   */
  updateSelectedImage(index: number): void {
    this.currentActiveIndex = index;
  }

  /**
   * on changes
   * @param changes 
   */
  ngOnChanges(): void {
    if (this.sliderModel) {

      for (let i = 0; i < this.sliderModel.length; i++) {
        if (!this.imageLoading[i]) {
          this.imageLoading[i] = false;
        }
      }

      /*setTimeout(()=>{
        const element = document.getElementById('car-images');
        if(element){
          const hammerElement = new Hammer(element);
          hammerElement.on('swipe', (event) => {
            alert(event);
            alert(JSON.stringify(event));
            this.updateSelectedImage(event);
          });
        }
      });*/
    }
  }

  // /**
  //  * swipe
  //  */
  // public swipe(currentIndex: number, action: string): void {
  //   alert(currentIndex);
  //   if (currentIndex > this.sliderModel.length || currentIndex < 0) return;

  //   let nextIndex = 0;

  //   // next
  //   if (action === this.SWIPE_ACTION.RIGHT) {
  //     const isLast = currentIndex === this.sliderModel.length - 1;
  //     nextIndex = isLast ? 0 : currentIndex + 1;
  //   }

  //   // previous
  //   if (action === this.SWIPE_ACTION.LEFT) {
  //     const isFirst = currentIndex === 0;
  //     nextIndex = isFirst ? this.sliderModel.length - 1 : currentIndex - 1;
  //   }

  //   this.currentActiveIndex = nextIndex;
  //   // toggle avatar visibility
  //   //this.sliderModel.forEach((x, i) => x.image = (i === nextIndex));
  // }


  /**
   * previous image click
   */
  prevImageClick(): void {
    if (this.currentActiveIndex > 0) {
      this.currentActiveIndex = this.currentActiveIndex - 1;
    }
  }

  /**
   * next image click
   */
  nextImageClick(): void {
    if (this.currentActiveIndex < this.sliderModel.length - 1) {
      this.currentActiveIndex = this.currentActiveIndex + 1;
    }
  }
}
