import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { menuItem, estimateOperationsMenus } from 'src/app/model/menu';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'app-repair-estimate-operation',
  templateUrl: './repair-estimate-operation.component.html',
  styleUrls: [ './repair-estimate-operation.component.scss' ]
})
export class RepairEstimateOperationComponent {
  public menuItems: menuItem[] = [ ...estimateOperationsMenus ];
  public activeMenu: string;
  public operationToEdit: any = {}
  public editrepairEstimateOperation: boolean = false

  /**
    Constructor for the RepairEstimateOperationComponent.
    @constructor
    @param {any} data - Data passed to the component.
    @param {MatDialogRef} dialogRef - Reference to the dialog box.
    @param {MonitorService} monitorService - Service for monitoring operations.
  */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RepairEstimateOperationComponent>,
    public monitorService: MonitorService
  ) {}

  /**
    Closes the dialog with the given data.
    @param {any} data - The data to be passed back to the dialog caller.
  */
  cancelDialog(data: any): void {
    this.dialogRef.close(data);
  }

  /**
    Lifecycle hook that is called after the component is initialized.
  */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'RepairEstimateOperationComponent', 'addenda-repair' ]);
    this.menuItems[0].active = true;
    this.activeMenu = this.menuItems[0].name
    if(this.data?.operationDetails) {
      this.operationToEdit = this.data.operationDetails;
      this.editrepairEstimateOperation = true;
      this.onMenuItemClick(this.menuItems[0])
    }
  }

  /**
    This function is called when a menu item is clicked.
    @param {menuItem} item - The menu item that was clicked.
    @returns {void}
  */
  onMenuItemClick(item:menuItem): void {
    this.menuItems.forEach(item => item.active = false);
    const selectedMenuIndex = this.menuItems.findIndex(e => e.name == item.name);
    this.menuItems[selectedMenuIndex].active = true;
    this.activeMenu = this.menuItems[selectedMenuIndex].name

    if(this.activeMenu !== 'create-parts-operations') {
      this.operationToEdit = {}
    }
  } 

  /**
    Switch to edit operation and call the onMenuItemClick function with the second menu item.
    @param {string} opeartion - The operation to edit.
    @returns {void}
  */
  switchToEditOperation(opeartion): void {
    this.operationToEdit = opeartion;
    this.onMenuItemClick(this.menuItems[0])
  }

  /**
    Switches to the operation list view.
    @returns {void}
  */
  switchToOperationList(): void {
    this.onMenuItemClick(this.menuItems[1])
  }
}
