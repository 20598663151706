import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MsalGuard, MsalService } from '@azure/msal-angular';
import { SilentRequest } from '@azure/msal-browser';
import { AuthenticationResult } from '@azure/msal-common';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common/common.service';
import { CoreService } from 'src/app/services/core/core.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HomeGuard {
  /**
   * constructor
   * @param router
   */
  constructor(
    private commonService: CommonService,
    private msalGuard: MsalGuard,
    private router: Router,
    private coreService: CoreService,
    private msalService: MsalService
  ) { }

  /**
   * canActivate
   * @returns
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.msalGuard.canActivate(next, state)) {
      return this.checkUserPermission();
    } else {
      return false;
    }
  }

  /**
  * checkUserPermission
  * @returns
  */
  checkUserPermission(): Observable<boolean> {
    return this.commonService.userProfileData.pipe(
      switchMap((res: any) => {
        if (res) {
          return of(this.checkUserData(res));
        } else {
          this.commonService.showLoading();
          return this.coreService.getUserProfile().pipe(
            switchMap((res) => {
              if (res) {
                this.commonService.hideLoading();
                this.commonService.userProfileData.next(res);
                if (res?.data.userProfileThumbnailImageBlobUrl || res?.data.userProfileImageBlobUrl) {
                  this.commonService.avatar.next({
                    image: res?.data.userProfileThumbnailImageBlobUrl ?? res?.data.userProfileImageBlobUrl
                  });
                }
                return of(this.checkUserData(res));
              } else {
                this.commonService.hideLoading();
                return of(true);
              }
            })
          )
        }
      })
    );
  }

  /**
   *
   * @param res
   * @returns
   */
  checkUserData(res: any): boolean {
    const automotiveServices = res?.data?.userPermission?.automotiveServices;
    const userDetail = res?.data?.roleDetail;
    const isRepairLite = userDetail?.some(r => r.automotiveServiceName === 'Addenda Repair Lite')
    this.commonService.setPermission(automotiveServices);

    const repair = automotiveServices?.find(x => x.automotiveServiceName.toLowerCase() === 'addenda repair');
    const quote = automotiveServices?.find(x => x.automotiveServiceName.toLowerCase() === 'carheal quote');
    const claim = automotiveServices?.find(x => x.automotiveServiceName.toLowerCase() === 'addenda claims');
    const fnol = automotiveServices?.find(x => x.automotiveServiceName.toLowerCase() === 'fnol');

    if (repair) {
      if (repair.roleName.toLowerCase() === 'bodyshop manager') {
        if (isRepairLite) {
          this.router.navigate([ 'repair' ]);
          return false;
        }
        return true;
      } else if (repair.roleName.toLowerCase() === 'service desk') {
        if (this.commonService.checkPermission('Addenda Repair', 'outbound.lounge')) {
          this.router.navigate([ 'repair/lounge' ]);
        } else {
          this.router.navigate([ 'repair/workflow-lounge' ]); //workflow.lounge
        }
        return false;
      } else if (repair.roleName.toLowerCase() === 'driver') {
        this.router.navigate([ 'repair/outbound' ]);
        return false;
      } else if (repair.roleName.toLowerCase() === 'technician') {
        this.router.navigate([ 'repair/technician-calendar' ]);
        return false;
      } else {
        this.router.navigate([ 'repair' ]);
        return false;
      }
    } else if (quote) {
      this.router.navigate([ 'quote' ]);
      return false;
    } else if (claim) {
      this.router.navigate([ 'claim' ]);
      return false;
    } else if (fnol) {
      this.router.navigate([ 'fnol' ]);
      return false;
    } else {
      const request = {
        redirectStartPage: '/',
        scopes: [ 'openid', 'profile', `${environment.b2cSettings.caseManagementAppClientId}` ]
      };
      const activeAccount = this.msalService.instance.getActiveAccount();
      this.msalService.acquireTokenSilent(request as SilentRequest).subscribe({
        next: (result: AuthenticationResult) => {
          this.commonService.clearData();
          this.msalService.logoutRedirect(
            {
              account: activeAccount,
              idTokenHint: result.idToken
            });
        }
      });
      return false;
    }
  }
}
