import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  headers = new HttpHeaders();
  validImageExtensions: any = [
    'jpg',
    'jpeg',
    'png'
  ];

  /**
   * constructor
   * @param httpClient 
   */
  constructor(private httpClient: HttpClient) { }

  
  /**
   * upload file
   */
  public uploadFile(endPoint: string, formData?: any, reportProgress: boolean = false,
    observe: any = 'body', header?: Map<string, string>): Observable<any> {
    if(header) {
      [ ...header.entries() ].map(
        ([ key, value ]: [string, string]) => this.headers.append(key, value));
    }
    return this.httpClient.post(endPoint, formData,
      {
        headers: this.headers,
        reportProgress: reportProgress,
        observe: observe
      }
    );
  }

  /**
   * update file
   */
  public updateFile(endPoint: string, formData?: any, reportProgress: boolean = false,
    observe: any = 'body', header?: Map<string, string>): Observable<any> {
    if(header) {
      [ ...header.entries() ].map(
        ([ key, value ]: [string, string]) => this.headers.append(key, value));
    }
    return this.httpClient.put(endPoint, formData,
      {
        headers: this.headers,
        reportProgress: reportProgress,
        observe: observe
      }
    );
  }

  /**
   * downloadFile file
   */
  public downloadFile(endPoint: string, reportProgress: boolean = false, observe: any = 'body', header?: Map<string, string>): Observable<Blob> {
    if(header) {
      [ ...header.entries() ].map(
        ([ key, value ]: [string, string]) => this.headers.append(key, value));
    }
    const url = `${environment.autoApi}/api/v1/${endPoint}`;
    return this.httpClient.get(url, 
      {
        headers: this.headers, 
        responseType: 'blob',
        reportProgress: reportProgress,
        observe: observe
      }
    );
  }

  /**
   * downloadFile file by Url
   */
  public downloadFileByURL(endPoint: string, reportProgress: boolean = false, observe: any = 'body', header?: Map<string, string>): Observable<Blob> {
    if(header) {
      [ ...header.entries() ].map(
        ([ key, value ]: [string, string]) => this.headers.append(key, value));
    }
    return this.httpClient.get(endPoint, 
      {
        headers: this.headers, 
        responseType: 'blob',
        reportProgress: reportProgress,
        observe: observe
      }
    );
  }

  /**
   * get  file extension
   */
  public getFileExtension(filename: string): string | undefined {
    return filename.split('.').pop();
  }

  
  /**
   * validate file 
   */
  public isValidImageFile(filename: string): boolean {
    const regex: RegExp = new RegExp('^.*\\.[a-zA-Z]+$', 'gm');
    if(regex.test(filename)) {
      const extension = filename.split('.').pop();
      return this.validImageExtensions.includes(extension?.toLowerCase());
    }
    return false;
    
  }

  /**
   * convert base64 to file 
   */
  public async dataURLtoFile(dataUrl: any, fileName: string, fileType: string):Promise<File> {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([ blob ], fileName, { type: fileType });
  }

  /**
   * convert file to dataUrl
   */
  fileToDataUrl(uploadedFile): string {
    const blobData = new Blob([uploadedFile], {type: uploadedFile.type });
    const blobUrl = URL.createObjectURL(blobData);
    return blobUrl;
  }

  /**
   * convert file to base64 
   */
  public async blobToBase64(blob: any):Promise<any> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = ():void => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  /**
   * exportToCsv
   * jsonData should be object[]
   * @public
   */
  public exportToCsv(rows: object[], filename: string, headers?: any[]): void{
    const titleKeys = headers ? headers : Object.keys(rows[0])

    const refinedData = []
    refinedData.push(titleKeys)

    rows.forEach((item) => {
      refinedData.push(Object.values(item))  
    })

    let csvContent = ''

    refinedData.forEach((row) => {
      csvContent += row.map(item=> item === null ? '' : (item instanceof Date ? item.toLocaleDateString('en-GB') : item.toString() )).join(',') + '\n'
    })

    console.log(csvContent, refinedData, 'csvFile');

    const blob = new Blob([ csvContent ], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

}
