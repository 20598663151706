import { environment } from 'src/environments/environment';

/**
   * app initializer    
   * @returns promise
*/
export function initializeMsalStorage(): Promise<any> {
  return new Promise((resolve) => {
    const url = new URL(window.location.href);
    const token = url.searchParams.get('token');

    if (token) {
      try {
        const decodedCode = parseJwt(token);
        generateMSALStorage(decodedCode, token);
      } catch (error) {
        console.log('Token not valid');
      }
    }

    resolve({});
  });
}

/**
   * decode acces token
   * @returns promise
 */
function parseJwt(token): any {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

/**
   * generate and set local storage
   * @returns promise
 */
function generateMSALStorage(decodedCode, token): void {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let policyName = environment.externalB2CSignupPolicy['XA'];

  const cidValue = urlParams.get('cid') || urlParams.get('CID');
  const loginPolicy = urlParams.get('lp') || urlParams.get('LP');

  if (cidValue?.toLowerCase() == 'clear') {
    localStorage.removeItem('cid');
    localStorage.setItem('currentLoginParam', '');
    localStorage.setItem('customLoginPolicy', '');
  }

  if (cidValue) {
    localStorage.setItem('currentLoginParam', '');
    localStorage.setItem('customLoginPolicy', '');
    policyName = environment.externalB2CSignupPolicy[cidValue.toUpperCase()]
    localStorage.setItem('cid', cidValue);
  } else if(loginPolicy){
    policyName = loginPolicy;
  } else {
    const loginPolicy = localStorage.getItem('customLoginPolicy');
    if(loginPolicy){
      policyName = loginPolicy;
    }else{
      const localData = localStorage.getItem('cid');
      if (localData) {
        policyName = environment.externalB2CSignupPolicy[localData.toUpperCase()]
      }
    }
  }

  localStorage.setItem('msal.' + environment.b2cSettings.caseManagementAppClientId + '.active-account', decodedCode.oid);

  const accountFilter = {
    homeAccountId: decodedCode.oid + ' - ' + policyName.toLowerCase() + '.' + decodedCode.tid,
    localAccountId: decodedCode.oid
  };
  localStorage.setItem('msal.' + environment.b2cSettings.caseManagementAppClientId + '.active-account-filters', JSON.stringify(accountFilter));

  const accessTokenKey = decodedCode.oid + '-' +
    policyName.toLowerCase() + '.' +
    decodedCode.tid + '-' + environment.b2cSettings.authorityDomain + '-accesstoken' +
    environment.b2cSettings.caseManagementAppClientId + '-' + decodedCode.tid + '-' + environment.b2cSettings.caseManagementApiScope;

  const accessTokenValue = {
    cachedAt: decodedCode.iat,
    clientId: environment.b2cSettings.caseManagementAppClientId,
    credentialType: 'AccessToken',
    environment: environment.b2cSettings.authorityDomain,
    expiresOn: decodedCode.exp,
    extendedExpiresOn: decodedCode.exp,
    homeAccountId: decodedCode.oid + '-' + policyName.toLowerCase() + '.' + decodedCode.tid,
    realm: decodedCode.tid,
    secret: token,
    target: environment.b2cSettings.caseManagementApiScope,
    tokenType: 'Bearer'
  }

  localStorage.setItem(accessTokenKey, JSON.stringify(accessTokenValue));

  const msstsKey = decodedCode.oid + '-' + policyName.toLowerCase() + '.'
    + environment.b2cSettings.authorityDomain + '-' + decodedCode.tid;

  const msstsValue = {
    authorityType: 'MSSTS',
    clientInfo: '<clientInfo>', // get from response from token post api
    homeAccountId: decodedCode.oid + '-' + policyName.toLowerCase() + '.' + decodedCode.tid,
    environment: environment.b2cSettings.authorityDomain,
    realm: decodedCode.tid,
    idTokenClaims: {
      decodedCode
    },
    localAccountId: decodedCode.oid,
    username: decodedCode.emails[0],
    name: decodedCode.name
  };

  localStorage.setItem(msstsKey, JSON.stringify(msstsValue));
}