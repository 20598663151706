export const InvoiceData = {
  'value': [
    {
      'Id': 656,
      'RepairId': 234934328463,
      'TotalEstmate': 102045,
      'OriginalEstimate': 102045,
      'CustomerName': 'Customer Name',
      'Insurer': 'Gargash Enterprise',
      'VehicleMake': 'BMW',
      'VehicleModel': '3 series',
      'LicensePlate': 'N23145',
      'StatusDescription': 'Ready for Invoice',
      'Key': null,
    },
    {
      'Id': 657,
      'RepairId': 234934328463,
      'TotalEstmate': 102045,
      'OriginalEstimate': 102045,
      'CustomerName': 'Customer Name',
      'Insurer': 'Private',
      'VehicleMake': 'Honda',
      'VehicleModel': 'Accord',
      'LicensePlate': 'N23145',
      'StatusDescription': 'Hold',
      'Key': null,
    },
    {
      'Id': 658,
      'RepairId': 234934328463,
      'TotalEstmate': 102045,
      'OriginalEstimate': 102045,
      'CustomerName': 'Customer Name',
      'Insurer': 'Adamjee',
      'VehicleMake': 'Tesla',
      'VehicleModel': 'Model X',
      'LicensePlate': 'N23145',
      'StatusDescription': 'Invoice Ready',
      'Key': null,
    },
    {
      'Id': 659,
      'RepairId': 234934328463,
      'TotalEstmate': 102045,
      'OriginalEstimate': 102045,
      'CustomerName': 'Customer Name',
      'Insurer': 'Private',
      'VehicleMake': 'Nissan',
      'VehicleModel': 'Altima',
      'LicensePlate': 'N23145',
      'StatusDescription': 'Ready for Invoice',
      'Key': null,
    },
    {
      'Id': 660,
      'RepairId': 234934328463,
      'TotalEstmate': 102045,
      'OriginalEstimate': 102045,
      'CustomerName': 'Customer Name',
      'Insurer': 'Adamjee',
      'VehicleMake': 'Nissan',
      'VehicleModel': 'Altima',
      'LicensePlate': 'N23145',
      'StatusDescription': 'Hold',
      'Key': null,
    },
    {
      'Id': 661,
      'RepairId': 234934328463,
      'TotalEstmate': 102045,
      'OriginalEstimate': 102045,
      'CustomerName': 'Customer Name',
      'Insurer': 'Adamjee',
      'VehicleMake': 'ADNIC',
      'VehicleModel': 'Altima',
      'LicensePlate': 'N23145',
      'StatusDescription': 'Ready for Invoice',
      'Key': null,
    },
    {
      'Id': 662,
      'RepairId': 234934328463,
      'TotalEstmate': 102045,
      'OriginalEstimate': 102045,
      'CustomerName': 'Customer Name',
      'Insurer': 'Adamjee',
      'VehicleMake': 'Tesla',
      'VehicleModel': 'Model X',
      'LicensePlate': 'N23145',
      'StatusDescription': 'Invoice Ready',
      'Key': null,
    },
    {
      'Id': 663,
      'RepairId': 234934328463,
      'TotalEstmate': 102045,
      'OriginalEstimate': 102045,
      'CustomerName': 'Customer Name',
      'Insurer': 'Private',
      'VehicleMake': 'Nissan',
      'VehicleModel': 'Altima',
      'LicensePlate': 'N23145',
      'StatusDescription': 'Ready for Invoice',
      'Key': null,
    },
    {
      'Id': 664,
      'RepairId': 234934328463,
      'TotalEstmate': 102045,
      'OriginalEstimate': 102045,
      'CustomerName': 'Customer Name',
      'Insurer': 'Adamjee',
      'VehicleMake': 'Nissan',
      'VehicleModel': 'Altima',
      'LicensePlate': 'N23145',
      'StatusDescription': 'Hold',
      'Key': null,
    },
    {
      'Id': 665,
      'RepairId': 234934328463,
      'TotalEstmate': 102045,
      'OriginalEstimate': 102045,
      'CustomerName': 'Customer Name',
      'Insurer': 'Adamjee',
      'VehicleMake': 'ADNIC',
      'VehicleModel': 'Altima',
      'LicensePlate': 'N23145',
      'StatusDescription': 'Ready for Invoice',
      'Key': null,
    },
    {
      'Id': 666,
      'RepairId': 234934328463,
      'TotalEstmate': 102045,
      'OriginalEstimate': 102045,
      'CustomerName': 'Customer Name',
      'Insurer': 'Gargash Enterprise',
      'VehicleMake': 'BMW',
      'VehicleModel': '3 series',
      'LicensePlate': 'N23145',
      'StatusDescription': 'Ready for Invoice',
      'Key': null,
    },
    {
      'Id': 667,
      'RepairId': 234934328463,
      'TotalEstmate': 102045,
      'OriginalEstimate': 102045,
      'CustomerName': 'Customer Name',
      'Insurer': 'Private',
      'VehicleMake': 'Honda',
      'VehicleModel': 'Accord',
      'LicensePlate': 'N23145',
      'StatusDescription': 'Hold',
      'Key': null,
    },
    {
      'Id': 668,
      'RepairId': 234934328463,
      'TotalEstmate': 102045,
      'OriginalEstimate': 102045,
      'CustomerName': 'Customer Name',
      'Insurer': 'Adamjee',
      'VehicleMake': 'Tesla',
      'VehicleModel': 'Model X',
      'LicensePlate': 'N23145',
      'StatusDescription': 'Invoice Ready',
      'Key': null,
    },
    {
      'Id': 669,
      'RepairId': 234934328463,
      'TotalEstmate': 102045,
      'OriginalEstimate': 102045,
      'CustomerName': 'Customer Name',
      'Insurer': 'Private',
      'VehicleMake': 'Nissan',
      'VehicleModel': 'Altima',
      'LicensePlate': 'N23145',
      'StatusDescription': 'Ready for Invoice',
      'Key': null,
    },
    {
      'Id': 670,
      'RepairId': 234934328463,
      'TotalEstmate': 102045,
      'OriginalEstimate': 102045,
      'CustomerName': 'Customer Name',
      'Insurer': 'Adamjee',
      'VehicleMake': 'Nissan',
      'VehicleModel': 'Altima',
      'LicensePlate': 'N23145',
      'StatusDescription': 'Hold',
      'Key': null,
    },
    {
      'Id': 671,
      'RepairId': 234934328463,
      'TotalEstmate': 102045,
      'OriginalEstimate': 102045,
      'CustomerName': 'Customer Name',
      'Insurer': 'Adamjee',
      'VehicleMake': 'ADNIC',
      'VehicleModel': 'Altima',
      'LicensePlate': 'N23145',
      'StatusDescription': 'Ready for Invoice',
      'Key': null,
    },
    {
      'Id': 672,
      'RepairId': 234934328463,
      'TotalEstmate': 102045,
      'OriginalEstimate': 102045,
      'CustomerName': 'Customer Name',
      'Insurer': 'Adamjee',
      'VehicleMake': 'Tesla',
      'VehicleModel': 'Model X',
      'LicensePlate': 'N23145',
      'StatusDescription': 'Invoice Ready',
      'Key': null,
    },
    {
      'Id': 673,
      'RepairId': 234934328463,
      'TotalEstmate': 102045,
      'OriginalEstimate': 102045,
      'CustomerName': 'Customer Name',
      'Insurer': 'Private',
      'VehicleMake': 'Nissan',
      'VehicleModel': 'Altima',
      'LicensePlate': 'N23145',
      'StatusDescription': 'Ready for Invoice',
      'Key': null,
    },
    {
      'Id': 674,
      'RepairId': 234934328463,
      'TotalEstmate': 102045,
      'OriginalEstimate': 102045,
      'CustomerName': 'Customer Name',
      'Insurer': 'Adamjee',
      'VehicleMake': 'Nissan',
      'VehicleModel': 'Altima',
      'LicensePlate': 'N23145',
      'StatusDescription': 'Hold',
      'Key': null,
    },
    {
      'Id': 675,
      'RepairId': 234934328463,
      'TotalEstmate': 102045,
      'OriginalEstimate': 102045,
      'CustomerName': 'Customer Name',
      'Insurer': 'Adamjee',
      'VehicleMake': 'ADNIC',
      'VehicleModel': 'Altima',
      'LicensePlate': 'N23145',
      'StatusDescription': 'Ready for Invoice',
      'Key': null,
    }
  ]
}