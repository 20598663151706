export class User {
  public email?: string[];
  public familyName?: string;
  public givenName?: string;
  public name?: string;
  public userType?: string;
  public accountId?: string;
  public tenantGuid?: string;
  public accountGuid?: string;
  public isAnonymousUser?: string;
  public tenantId?: string;
  public userInternalId?: string;
  public correlationId?:string;
  public userId?:string;
}