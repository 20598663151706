import { Component, ElementRef, ViewChild } from '@angular/core';
import { ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { Subscription, firstValueFrom } from 'rxjs';
import { RegisteredGarageRejectClaimComponent } from 'src/app/dialogs/registered-garage-reject-claim/registered-garage-reject-claim.component';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { ChqWidgetsInputModel, IControlValue } from 'src/app/model/chq-widgets-input-model';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { ThirdPartyCommonLayoutComponent } from '../common-layout/common-layout.component';
import { HttpHeaders } from '@angular/common/http';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

interface KeyValueType<T> {
  [k: string]: T;
}
export interface EstimateActionForm extends KeyValueType<any> {
  continueRepair: ChqWidgetsInputModel,
  repairStartDate?: ChqWidgetsInputModel,
  repairDescription?: ChqWidgetsInputModel,
  excessPay?: ChqWidgetsInputModel,
  totalAmount?: ChqWidgetsInputModel,
}

@Component({
  selector: 'app-registered-garage-request-estimate-action',
  templateUrl: './registered-garage-request-estimate-action.component.html',
  styleUrls: [ './registered-garage-request-estimate-action.component.scss' ]
})
export class RegisteredGarageRequestEstimateActionComponent {
  @ViewChild(ThirdPartyCommonLayoutComponent) commonLayout: ThirdPartyCommonLayoutComponent;
  private token: string = ''; 
  public claimGuid: string = '';
  public claimDetails: any = {};
  public formModel?: EstimateActionForm
  public panelOpenState: boolean = false
  public rejectNotes: string = ''
  public currency: string = ''
  public isSubmissionSuccess: boolean = false;
  subscriptions: Subscription[] = [];
  public viewRepairDetails: ChqWidgetsButtonModel = {
    label: 'view_repair_details',
    type: 'outline',
    icon: 'report-check',
    onclick: this.handleViewRepairDetails.bind(this)
  }
  public submitButton: ChqWidgetsButtonModel = {
    label: 'submit',
    type: 'disabled',
    onclick: this.handleFormSubmit.bind(this)
  }
  public viewFeedback: ChqWidgetsButtonModel = {
    label: 'view_feedback',
    type: 'outline',
    icon: 'feedback',
    onclick: this.handleViewFeedback.bind(this)
  }
  public uploadAttachmentButtonModel: ChqWidgetsButtonModel = {
    label: 'upload_attachment',
    type: 'outline',
    icon: 'attachment',
  }
  public uploader: ElementRef;
  public file: any[] = [];
  /**
    Sets the content of the uploader element.
    @param {ElementRef} content - The content of the uploader element.
  */
  @ViewChild('uploader') set content(content: ElementRef) {
    if(content) { // initially setter gets called with undefined
      this.uploader = content;
    }
  }
  /**
   * constructor
   */
  constructor(
    private monitorService: MonitorService,
    public dialog: MatDialog,
    private commonService: CommonService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private claimsService: ClaimsService,
  ) { 
    this.token = this.route.snapshot.queryParams['token'];
    const obj:any = atob(this.token);
    const parsedObj = JSON.parse(obj);
    this.claimGuid = parsedObj.ClaimGuid; 
  }
  /**
  * on init 
  */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'RegisteredGarageRequestEstimateActionComponent', 'addenda-claim' ]); 
    const status = localStorage.getItem(this.token);
    if(status === 'submitted'){
      this.isSubmissionSuccess = true;
      return
    }
    this.formModel = this.initForm();

    const claimDetails$ = this.commonService.thirdPartyClaimDetails.subscribe({
      next: (response) =>{
        this.claimDetails = response;
        if(this.claimDetails?.customerDetails) {
          this.claimDetails.customerDetails.customerFullName = '';
          if(this.claimDetails.customerDetails.policyHolderFirstName) this.claimDetails.customerDetails.customerFullName = this.claimDetails.customerDetails.policyHolderFirstName
          if(this.claimDetails.customerDetails.policyHolderLastName) this.claimDetails.customerDetails.customerFullName += ' ' + this.claimDetails.customerDetails.policyHolderLastName
        }
        this.currency = response?.organizationDetail?.currencyName;
        this.formModel.totalAmount.prependText = this.currency
        this.formModel.excessPay.prependText = this.currency
      }
    })

    this.subscriptions.push(claimDetails$);
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this.monitorService.logEvent('ngOnDestroy', [ 'RegisteredGarageRequestEstimateActionComponent', 'addenda-claim' ]);
    if (this.subscriptions) this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
  
  /**
  *initForm
  */
  initForm(): EstimateActionForm {
    return {
      continueRepair: {
        placeHolder: '',
        label: 'do_you_want_to_continue_with_the_repair',
        name: 'continueRepair',
        idField: 'continueRepair',
        value: '',
        type: 'radio',
        options: [ { label: 'Yes', id: 1 }, { label: 'No', id: 2 } ],
      },
      repairStartDate: {
        placeHolder: 'repair_start_date',
        label: 'repair_start_date',
        name: 'repairStartDate',
        type: 'date',
        value: '',
        required: true,
        validation: {
          name: 'repairStartDate',
          validationFunction: (): ValidatorFn => {
            return Validators.compose([ Validators.required ])
          },
          validationMessage: (error: ValidationErrors): string => {
            if (error['required']) {
              return 'repair_start_date_required'
            }
            return '';
          }
        }
      },
      repairDescription: {
        placeHolder: 'repair_description',
        label: 'repair_description',
        name: 'repairDescription',
        value: '',
        type: 'text',
        required: true,
        validation: {
          name: 'repairDescription',
          validationFunction: (): ValidatorFn => {
            return Validators.compose([ Validators.required, Validators.maxLength(200) ])
          },
          validationMessage: (error: ValidationErrors): string => {
            console.log('repairDescription error', error)
            if (error['required']) {
              return 'repair_description_required'
            }
            if (error['maxlength']) {
              return 'repair_description_max_length'
            }
            return '';
          }
        }
      },
      excessPay: {
        placeHolder: 'excess_payable_anticipated',
        label: 'excess_payable_anticipated',
        name: 'excessPay',
        value: 0,
        type: 'number',
        required: true,
        validation: {
          name: 'excessPay',
          validationFunction: (): ValidatorFn => {
            return Validators.compose([ Validators.required, Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/), Validators.maxLength(8) ])
          },
          validationMessage: (error: ValidationErrors): string => {
            console.log('excessPay error', error)
            if (error['pattern']) {
              return 'amount_two_decimal_places'
            }
            if (error['maxlength']) {
              return 'amount_max_length'
            }
            return '';
          }
        }
      },
      totalAmount: {
        placeHolder: 'total_amount',
        label: 'total_amount_with_tax',
        name: 'totalAmount',
        value: 0,
        type: 'number',
        required: true,
        validation: {
          name: 'totalAmount',
          validationFunction: (): ValidatorFn => {
            return Validators.compose([ Validators.required, Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/), Validators.maxLength(8) ])
          },
          validationMessage: (error: ValidationErrors): string => {
            console.log('totalAmount error', error)
            if (error['required']) {
              return 'total_amount_required'
            }
            if (error['pattern']) {
              return 'amount_two_decimal_places'
            }
            if (error['maxlength']) {
              return 'amount_max_length'
            }
            return '';
          }
        }
      }
    }
  }
  /**
   * on form update
   * @param output o
   */
  async onFormUpdate(output: IControlValue): Promise<void> {
    if(output.name === 'continueRepair' && output.value === 'No') {
      const dialogRef = this.dialog.open(RegisteredGarageRejectClaimComponent, {
        data: {
          'notes': this.rejectNotes
        },
        height: 'auto',
        autoFocus: true
      });
        
      dialogRef.afterClosed().subscribe({
        next: (response: any) => {
          if(response) {
            this.formModel[output.name].value = output.value;
            this.rejectNotes = response;
            this.validateForm();
          } else {
            this.formModel[output.name] = { ...this.formModel[output.name], value: '' }
            this.validateForm();
          }
        }
      });
    } else {
      this.formModel[output.name].value = output.value;
      this.validateForm();
    }
    
    if(output.name === 'continueRepair' && output.value === 'Yes') { 
      this.rejectNotes = ''
    }
  }

  /**
  * validateForm
  */
  validateForm(): void {
    let buttonType: 'primary' |'disabled' = 'disabled'
    if(this.isFormValid()) {
      buttonType = 'primary'
    }
    this.submitButton.type = buttonType
  }
  
  /**
  * isFormValid
  */
  isFormValid(): boolean {
    let valid = false
    if(this.formModel.continueRepair?.value === 'Yes') {
      if(
        this.formModel?.repairStartDate?.value && this.formModel?.repairStartDate?.formControl?.valid
        && this.formModel?.excessPay?.formControl?.value && this.formModel?.excessPay?.formControl?.valid
        && this.formModel?.repairDescription?.formControl?.value && this.formModel?.repairDescription?.formControl?.valid
        && this.formModel?.totalAmount?.formControl?.value && this.formModel.totalAmount?.formControl?.valid
      ) {
        valid = true
      }
    } else if(this.formModel.continueRepair?.value === 'No') {
      if(this.rejectNotes) {
        valid = true
      }
    }
    return valid
  }
  /**
  * handleViewFeedback
  */
  handleViewFeedback(): void {
    const dialogRef = this.dialog.open(RegisteredGarageRejectClaimComponent, {
      data: {
        'notes': this.rejectNotes
      },
      height: 'auto',
      autoFocus: true
    });
      
    dialogRef.afterClosed().subscribe({
      next: (response: any) => {
        if(response) {
          this.rejectNotes = response;
        }
      }
    });
  }

  /**
  Handles the click event for the "Add File" button.
  @param {Event} event - The click event.
  @returns {void}
  */
  handleAddFileClick(event:any): void {
    this.uploader.nativeElement.click()
  }

  /**
   * checks file size
   * @param blob 
   * @returns boolean
   */
  checkFileSize(img: Blob): boolean {
    this.monitorService.logEvent('checkFileSize', [ 'RegisteredGarageRequestEstimateActionComponent', 'addenda-claim', {
      fileSize: img.size
    } ]);
    if (img.size > this.commonService.maxFileSize) {
      return false;
    }
    return true;
  }

  /**
   * checkx filename
   * @param filename 
   * @returns 
   */
  public isValidImageFile(filename: string): boolean {
    const regex: RegExp = new RegExp('^.*\\.[a-zA-Z]+$', 'gm');
    this.monitorService.logEvent('isValidImageFile', [ 'RegisteredGarageRequestEstimateActionComponent', 'addenda-claim', {
      fileName: filename
    } ]);
    if (regex.test(filename)) {
      const extension = filename.split('.').pop();
      return this.commonService.allowFilesExtension.includes(extension?.toLowerCase());
    }
    return false;
  }
  
  /**
   * handle file upload
   * @param event 
  */
  handleUpload(event: any): void {
    const currentFile = event.target.files || event.srcElement.files;
    this.monitorService.logEvent('handleUpload', [ 'RegisteredGarageRequestEstimateActionComponent', 'addenda-claim', {
      event
    } ]);
    if (currentFile !== null && currentFile !== '' && currentFile.length > 0) {
      if (!this.checkFileSize(currentFile[0])) {
        const message = this.translateService.instant('file_size_5mb_error_message');
        this.commonService.showToast(0, message);
        this.uploader.nativeElement.value = null;
        return;
      }
      if (!this.isValidImageFile(currentFile[0].name)) {
        const message = this.translateService.instant('upload_type_not_supported');
        this.commonService.showToast(0, message);
        this.uploader.nativeElement.value = null;
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(currentFile[0]);
      reader.onload = (_event: any): void => {
        this.file.push(currentFile[0]);
      }
    }
  }

  /**
    Removes a file from the component's state.
    @param {any} event - The event object that triggered the function.
    @returns {void}
  */
  handleFileRemove(index: any): void {
    this.commonService.openDeleteDialog(
      '', 
      this.translateService.instant('attachment_delete_confirmation'), 
      this.translateService.instant('delete'), 
      this.translateService.instant('cancel')
    )
      .afterClosed().subscribe((data) => {
        if (data) {
          this.monitorService.logEvent('handleFileRemove', [ 'RegisteredGarageRequestEstimateActionComponent', 'addenda-claim' ]);
          this.file.splice(index, 1);
        }
      });
      
  }

  /**
   * handleViewRepairDetails
   */
  handleViewRepairDetails(): void { 
    this.monitorService.logEvent('handleViewRepairDetails', [ 'RegisteredGarageRequestEstimateActionComponent', 'addenda-claim' ]);
    this.commonLayout.handleViewRepairDetails()
  }

  /**
     * handleViewRepairDetails
     */
  handleFormSubmit(): void { 
    this.monitorService.logEvent('handleFormSubmit', [ 'RegisteredGarageRequestEstimateActionComponent', 'addenda-claim' ]);
    if(!this.isFormValid()) {
      return null
    }
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${this.commonLayout.idToken}` );
    if(this.formModel.continueRepair?.value === 'Yes') {
      this.commonService.showLoading();
      const date: any = this.formModel.repairStartDate.value || ''
      const formData = new FormData();
      formData.append('ClaimGuid', this.claimGuid );
      formData.append('TotalAmount', this.formModel.totalAmount.value + '' );
      formData.append('StartDate', dayjs(date).format('YYYY-MM-DD'));
      formData.append('ExcessPayable', this.formModel.excessPay.value + '' );
      formData.append('Description', this.formModel.repairDescription.value + '' );
      formData.append('Currency', this.currency );
      if(this.file && this.file.length > 0){
        this.file.forEach((fileObj) => {
          formData.append('Attachments', fileObj);
        });
      }
      firstValueFrom(this.claimsService.submitRegisteredGarageEstimate(formData, headers)).then((response) => {
        this.commonService.hideLoading();
        if (response) {
          this.isSubmissionSuccess = true;
          localStorage.setItem(this.token, 'submitted');
        }
      }).catch((err) => {
        this.commonService.hideLoading();
        this.monitorService.logException(err, SeverityLevel.Error);
      });
    } else if(this.formModel.continueRepair?.value === 'No') {
      const payload = {
        'claimGuid': this.claimGuid,
        'assignmentType': 2,
        'garageSourceEventType': 2,
        'reason': this.rejectNotes
      }
      this.commonService.showLoading();
      firstValueFrom(this.claimsService.submitGarageAssignmentAction(payload, headers)).then((response) => {
        this.commonService.hideLoading();
        if (response) {
          this.isSubmissionSuccess = true;
          localStorage.setItem(this.token, 'submitted');
        }
      }).catch((err) => {
        this.commonService.hideLoading();
        this.monitorService.logException(err, SeverityLevel.Error);
      });
    }
    
  }
}
