<div *ngIf="isWhatsApp"
     class="notification-container">
    <div>
        <div class="logo">
            <mat-icon [svgIcon]="'carhealicon'"></mat-icon>
        </div>
        <div class="content">
            <div class="title">Whatsapp Notification</div>
            <div class="name">Repair {{ notificationData.RepairNumber }}</div>
            <div class="message"
                 [innerHtml]="notificationData.NotificationMessage"></div>
        </div>
    </div>
    <div class="right-action">
        <div class="action">
            <a *ngIf="notificationData.ObjectGuid; else generalCommButton"
               href="repair/booking/{{notificationData.ObjectGuid}}"
               class="xa-button">
                View Details
            </a>

            <ng-template #generalCommButton>
                <a href="javascript:void(0)"
                   class="xa-button"
                   (click)="openGeneralComm()">
                    View Details
                </a>
            </ng-template>
        </div>
        <div class="time">
            <span class="now">{{ notificationData.BidirectionalConversation.SendOn | timesince}}</span>
        </div>
    </div>
</div>


<div *ngIf="!isWhatsApp"
     class="notification-container">
    <div>
        <div class="logo">
            <mat-icon [svgIcon]="'carhealicon'"></mat-icon>
        </div>
        <div class="content">
            <div class="title">Notification</div>
            <div class="name">{{ notificationData?.title }}</div>
            <div class="message">{{ notificationData?.Message }}</div>
        </div>
    </div>
    <div class="right-action">
        <div class="action">
            <a href="repair/booking/{{notificationData?.ObjectGuid}}"
               class="xa-button">
                View Details
            </a>
        </div>
        <!-- <div class="time">
            <span class="now">{{ notificationData?.SendOn | timesince}}</span>
        </div> -->
    </div>
</div>
