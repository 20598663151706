import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { MatCalendar, yearsPerPage } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/** Custom header component for datepicker. */
@Component({
  selector: 'custom-header',
  templateUrl: './chq-date-timepicker.component.html',
  styleUrls: [ './chq-date-timepicker.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChqDatetimepickerComponent<D> implements OnDestroy {
  private destroyed = new Subject<void>();

  /**
   * constructer
   * @param _calendar 
   * @param _dateAdapter 
   * @param _dateFormats 
   * @param cdr 
   */
  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef,
  ) {
    _calendar.stateChanges?.pipe(takeUntil(this.destroyed)).subscribe(() => cdr.markForCheck());
  }

  /**
   * date parser
   * @param date 
   * @returns 
   */
  public parseDate(date:string):Date{
    const dateList = date.split('/');
    const normalDate = dateList[2] + '/' + dateList[1] + '/' + dateList[0];
    return new Date(normalDate);
  }

  /**
   * onDestroyhook
   */
  ngOnDestroy():void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  /**
   * getPeriod date
   */
  get periodLabel():Date {
    return this.parseDate(this._dateAdapter
      .format(this._calendar.activeDate, this._dateFormats.display.dateInput))
  }

  /**
   * get disabled state
   */
  get disabled():(boolean |null){
    return this._calendar.maxDate && this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1) > this._calendar.maxDate;
  }

  /**
   * get current state
   */
  get currentDate():Date{
    return this.parseDate(this._dateAdapter
      .format(this._calendar.activeDate, this._dateFormats.display?.dateInput));
  }

  /**
   * set currentYear view
   */
  showYear():void{
    this._calendar.currentView = 'multi-year'
  }

  /**
   * handles previous clicks
   */
  previousClicked():void {
    this._calendar.activeDate = this._calendar.currentView == 'month' ?
      this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1) :
      this._dateAdapter.addCalendarYears(this._calendar.activeDate, this._calendar.currentView == 'year' ? -1 : -yearsPerPage);
  }
  /** 
   * Handles user clicks on the next button. 
   * 
  */
  nextClicked():void {
    this._calendar.activeDate = this._calendar.currentView == 'month' ?
      this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1) :
      this._dateAdapter.addCalendarYears(this._calendar.activeDate, this._calendar.currentView == 'year' ? 1 : yearsPerPage);
  }
   
}