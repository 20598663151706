import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'alert-dialog',
  templateUrl: './img-dialog.component.html',
  styleUrls: [ './img-dialog.component.scss' ]
})
export class ImgDialogComponent {

  /**
   * constructor
   * @param dialogRef 
   * @param data 
   */
  constructor(public dialogRef: MatDialogRef<ImgDialogComponent>,
    private monitorService: MonitorService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  /**
   * close dialog
   */
  close(): void {
    this.monitorService.logEvent('close', [ 'ImgDialogComponent', 'addenda-quote' ]);
    this.dialogRef.close(true);
  }

  /**
   * download image from base64
   * @param url 
   */
  downloadImage(base64String: string, fileName: string): void {
    this.commonService.downloadBase64File(base64String, fileName);
  }

}
