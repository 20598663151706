export const RepairStatusMapper = {
  'draft': 'repair/booking/{{repairGUID}}',
  'vehicledetails': 'repair/booking/{{repairGUID}}',
  'uploaddocuments': 'repair/booking/{{repairGUID}}/document',
  'uploadphotos': 'repair/booking/{{repairGUID}}/photos',
  'repairestimation': 'repair/booking/{{repairGUID}}/estimate',
  'pendingapproval': 'repair/booking/{{repairGUID}}/estimate',
  'approved': 'repair/booking/{{repairGUID}}/estimate',
  'reject': 'repair/booking/{{repairGUID}}/estimate',
  'rejected': 'repair/booking/{{repairGUID}}/estimate',
  'closed': 'repair/booking/{{repairGUID}}/invoices',
  'complete': 'repair/booking/{{repairGUID}}/invoices',
  'partsorderedpartially': 'repair/booking/{{repairGUID}}/parts',
  'partsordered': 'repair/booking/{{repairGUID}}/parts',
  'repairinprogress': 'repair/booking/{{repairGUID}}/repair-stages',
  'readytoinvoice': 'repair/booking/{{repairGUID}}/invoices',
  'invoiced': 'repair/booking/{{repairGUID}}/invoices',
  'paid': 'repair/booking/{{repairGUID}}/invoices',
  'others': 'repair/booking/{{repairGUID}}',
}

export const CaseStatusMapper = {
  'draft': 'quote/case/{{caseGUID}}',
  'vehicledetails': 'quote/case/{{caseGUID}}/vehicle',
  'uploaddocuments': 'quote/case/{{caseGUID}}/document',
  'uploadphotos': 'quote/case/{{caseGUID}}/photos',
  'aianalysis': 'quote/case/{{caseGUID}}/ai',
  'repairestimation': 'quote/case/{{caseGUID}}/estimate',
  'approved': 'quote/case/{{caseGUID}}/estimate',
  'reject': 'quote/case/{{caseGUID}}/estimate',
  'rejected': 'quote/case/{{caseGUID}}/estimate',
  'closed': 'quote/case/{{caseGUID}}/estimate',
  'complete': 'quote/case/{{caseGUID}}/estimate',
  'others': 'quote/case/{{caseGUID}}/estimate'
}

export const RepairStatusToPermissionMapper = {
  'draft': 'customer.detail',
  'vehicledetails': 'customer.detail',
  'uploaddocuments': 'documents',
  'uploadphotos': 'documents',
  'repairestimation': 'estimate.create',
  'pendingapproval': 'estimate.create',
  'approved': 'estimate.create',
  'reject': 'estimate.create',
  'rejected': 'estimate.create',
  'closed': 'invoice',
  'complete': 'invoice',
  'partsorderedpartially': 'parts',
  'partsordered': 'parts',
  'repairinprogress': 'repairStages.create',
  'readytoinvoice': 'invoice',
  'invoiced': 'invoice',
  'paid': 'invoice',
  'others': 'customer.detail'
}