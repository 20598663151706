<div class="header">
    <div class="header-container">
        <label class="year" (click)="showYear()">{{currentDate | date:'yyyy' }}</label>
        <label class="month-date">{{currentDate | date:'EEE, MMM dd' }}</label>
    </div>
    <div class="bar-container">
        <button mat-icon-button (click)="previousClicked()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <span class="header-label">{{periodLabel | date:'MMMM yyyy' }}</span>
        <button mat-icon-button [disabled]="disabled" (click)="nextClicked()">
            <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
    </div>
    
</div>