import { ChatAttachment, ChatConversations, ChatConversationsOdata } from './message';


const chatOdataAttachmentToChatAttachment = (attachment: any): ChatAttachment => {
  return {
    'name': attachment.Name,
    'blobUrl': attachment.BlobUrl,
    'type': attachment.Type
  };
}

/**
   * convert chat odata to chat conversation
   */
export const chatOdataToChatConversation = (chatConversationList: ChatConversationsOdata[]): ChatConversations[] => {
  const chatConversations: ChatConversations[] = [];
  for (const ct in chatConversationList) {
    const chat = chatConversationList[ct];
    chatConversations.push({
      'message': chat.Message,
      'recipient': chat.CustomerMobileNumber,
      'sender': chat.Sender,
      'id': chat.Id,
      'domainId': 0,
      'objectId': 0,
      'replyToMessageId': chat.ReplyToMessageId,
      'haveAttachment': chat.HaveAttachment,
      'isGeneralChat': chat.IsGeneralChat,
      'isInteractiveToBeInitiated': chat.IsInteractiveToBeInitiated,
      'attachment': chat.Attachment ? chatOdataAttachmentToChatAttachment(chat.Attachment) : null,
      'type': chat.Type,
      'communicationChannel': chat.CommunicationChannel,
      'sendByUserId': chat.SendByUserId,
      'sendByUsername': chat.SendByUsername,
      'status': chat.Status,
      'sendOn': chat.SendOn,
      'isCustomerResponse': chat.IsCustomerResponse,
      'errorMessage': chat.errorMessage,
      'ConversationGuid': chat.ConversationGuid,
    });
  }
  return chatConversations;
}

export class Thread {
  name: string;
  id: string;
  snippet: string;
  userIDs: string[];
  objectId: number;
  domainId: number;
  phoneNumber: string;
  unreadCount: number;
  orgName: string;
  repairId: string;
  userName: string;
  licenseNumber: string;
  chatConversations: ChatConversations[];


  /**
   * 
   * @param id 
   * @param name 
   * @param userIDs 
   * @returns 
   */
  static make(id: string, name: string, userIDs: string[], phoneNumber: string, objectId?: number, domainId?: number): Thread {
    const t = new Thread();
    t.id = id;
    t.name = name;
    t.phoneNumber = phoneNumber;
    t.userIDs = userIDs;
    t.snippet = 'some snippet';
    t.unreadCount = 0;
    t.domainId = domainId;
    t.objectId = objectId;
    return t;
  }

  /**
   * 
   * @param username 
   * @param org
   * @param license 
   * @returns 
   */
  static update(t: Thread, username: string, org: string, license: string, repairId: string, chatConversations: ChatConversationsOdata[], currentUserId: number = 0, unreadCount: number = 0): Thread {
    t.orgName = org;
    t.licenseNumber = license;
    t.repairId = repairId;
    t.userName = username;
    t.chatConversations = chatOdataToChatConversation(chatConversations);
    // t.unreadCount = t.chatConversations.filter((x) => {
    //   return x.status?.toLowerCase() !== 'read' && x.sendByUserId !== currentUserId.toString();
    // }).length
    t.unreadCount = unreadCount
    return t;
  }



  // Copy copies over only fields that can be updated safely from one thread to another.
  /**
   * 
   * @param from 
   * @param to 
   */
  static copy(from: Thread, to: Thread): void {
    to.name = from.name;
    to.snippet = from.snippet;
    to.unreadCount = from.unreadCount;
  }
}
