<div class="form-field-numeric">
    <label for="" class="form-label pointer-events">
        {{label | translate}}
        <span class="required" *ngIf="required && label">*</span> 
    </label>
    <div class="text-field">
        <input class="form-value" autocomplete="off" inputmode="numeric"
            #input
            type="number"
            [id]="inputId"
            [name]="nameId"
            [min]="min"
            [max]="max"
            onkeydown="javascript: return (['Backspace','Delete','ArrowLeft','ArrowRight', 'Period'].includes(event.key) || ['Backspace','Delete','ArrowLeft','ArrowRight', 'Period'].includes(event.code)) ? true : !isNaN(Number(event.key)) && (event.key!=='Space' || event.code!=='Space')"
            placeholder="{{placeholder || label | translate}}" 
            [readonly]="readonly" 
            [disabled]="disabled" 
            [required]="required"
            [value]="value"
            (keyup)="_onInputInteraction();onTouched()">
        <p class="append" *ngIf="textAddon && textAddon !== '' ">{{textAddon}}</p>
        <span class="counter" *ngIf="showCounter">
            <mat-icon svgIcon="dropdown-up"  (click)="increment()"></mat-icon>
            <mat-icon svgIcon="dropdown" (click)="decrement()"></mat-icon>
        </span>
    </div>
</div>