<div class="wrapper" [ngClass]="{'chart-wrapper': isObservableLoading}">
    <chq-custom-loader [showLoading]="isObservableLoading" *ngIf="isObservableLoading">
    </chq-custom-loader>
    <xa-button type="outline" (click)="onBackClick();" size="sm"
        *ngIf="currentDrillDownLevel > 0 && drillDownText && !isObservableLoading">
        <mat-icon svgIcon="arrow-back-left" class="back-icon" aria-hidden="false" aria-label='back icon'></mat-icon>
        <span>Back / {{drillDownText}}</span>
    </xa-button>
    <div class="e-charts-container"
        [ngClass]="{'d-none': isObservableLoading || options?.chart?.plugin !== eCharts.eCharts}">
        <div [style.height]="eChartOptions?.wrapperStyle?.height" [style.width]="eChartOptions?.wrapperStyle?.width"
            *ngIf="!isObservableLoading && options?.chart?.plugin === eCharts.eCharts" echarts
            (chartClick)="handleDataSelection($event);" (chartLegendSelectChanged)="handleLegendSelectedChange($event);"
            [options]="eChartOptions" class="demo-chart"></div>
        <button type="button" size="md" appearanceType="outline" class="custom-icon-wrapper e-charts-custom xa-button"
            *ngIf="options?.chart?.plugin === eCharts.eCharts && options?.chart?.type !== 'Gauge'"
            (click)="handleCustomButtonClick(pieEChartOptions);" [innerHTML]="sanitizedHTML"></button>

        <div *ngIf="!isObservableLoading && options?.chart?.type === 'Gauge'" class="legends-container">
            <div [ngClass]="{'active': legend.active}" (click)="gaugeLegendClick(legend);" class="legend"
                *ngFor="let legend of eChartOptions?.legends">
                <mat-icon [svgIcon]="legend.icon"></mat-icon>
                <label>{{legend.title}}</label>
                <span [innerHTML]="legend.value"></span>
            </div>
        </div>
    </div>
    <div class="dashboard-no-data-found" *ngIf="xaChartOptions?.series.length === 0">
        <div class="title">{{xaChartOptions?.title?.text}}</div>
        <div class="subtitle">{{xaChartOptions?.subtitle?.text}}</div>
        <mat-icon class="no-data-svg" [svgIcon]="'estimate-no-data'"></mat-icon>
        <p class="no-data-text">
            {{ 'no_result_dashboard' | translate }}. <br />
            {{ 'no_repair_type_dashboard' | translate }}
        </p>
    </div>

    <!-- <div *ngIf="!isObservableLoading && options?.chart?.type === 'donut' && showTabs && false" class="donut-label-container">
        <ng-container *ngFor="let item of xaChartOptions?.labels; let i = index">
            <button type="button"
                >{{item}}</button>
        </ng-container>
    </div> -->

    <apx-chart
        *ngIf="!isObservableLoading  && options?.chart?.plugin !== eCharts.eCharts && xaChartOptions?.series.length !== 0 && chartInitialized"
        #chartRef [series]="xaChartOptions?.series" [chart]="xaChartOptions?.chart" [labels]="xaChartOptions?.labels"
        [responsive]="xaChartOptions?.responsive" [colors]="xaChartOptions?.colors" [stroke]="options?.stroke"
        [dataLabels]="xaChartOptions?.dataLabels" [plotOptions]="xaChartOptions?.plotOptions"
        [annotations]="xaChartOptions?.annotations" [legend]="xaChartOptions?.legend"
        [markers]="xaChartOptions?.markers" [noData]="options?.noData" [fill]="xaChartOptions?.fill"
        [tooltip]="xaChartOptions?.tooltip" [responsive]="xaChartOptions?.responsive" [xaxis]="xaChartOptions?.xaxis"
        [yaxis]="xaChartOptions?.yaxis" [forecastDataPoints]="options?.forecastDataPoints" [grid]="xaChartOptions?.grid"
        [states]="xaChartOptions?.states" [title]="xaChartOptions?.title" [subtitle]="xaChartOptions?.subtitle"
        [theme]="xaChartOptions?.theme" [autoUpdateSeries]="xaChartOptions?.autoUpdateSeries"></apx-chart>
</div>
