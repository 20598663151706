<div class="dialog-container">
    <div class="title-wrapper">
        <div class="title-handler">
           <h4>{{ 'terms_and_conditions' | translate }}</h4>
        </div>
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'" class="cross" style="fill:black;" (click)="close();"></mat-icon>
        </div>
    </div>
    
    <div mat-dialog-content class="content-wrapper">
        <div class="terms" *ngFor="let term of terms; let i=index">
             <div class="terms-item">
                <strong>{{i+1}}. {{term.title}}: </strong> {{term.description}}
             </div>
        </div>  
    </div>
</div>