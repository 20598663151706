import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CommonService } from '../services/common/common.service';
import { catchError, map, Observable, Subject, throwError, timeout } from 'rxjs';
import { UIErrorMessages, UIMessages } from '../model/uiMessages';
import { AuthService } from '../services/auth/auth.service';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  refreshTokenInProgress = false;
  uiMessagesHandled: UIMessages = {
    'AnnonVehicles': true,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'generate-otp': true,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '/users/Mobile': true
  }
  isUserUnauthorized: boolean = false;
  uiMessageConfig: UIErrorMessages = {
    401: 'Session expired',
    500: 'Sorry something went wrong. Please try again',
    404: 'Sorry something went wrong. Please try again',
    503: 'Sorry something went wrong. Please try again',
    405: 'Sorry something went wrong. Please try again',
    524: 'Sorry something went wrong. Please try again',
    300: 'Sorry something went wrong. Please try again',
  };
  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  /**
   * constructor
   * @param commonService 
   * @param router 
   * @param authService 
   * @param defaultTimeout 
   */
  constructor(
    private commonService: CommonService,
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number,
    private authService: AuthService,
    private msalService: MsalService,
    private router: Router
  ) { }

  /**
   * get error messages
   * @param request 
   * @returns 
   */
  getErrorMessages(request: any): boolean {
    const keys = Object.keys(this.uiMessagesHandled);
    for (const k in keys) {
      if (request.url.indexOf(keys[k]) !== -1) {
        return true;
      }
    }
    return false;
  }

  /**
   * error message
   * @param request 
   * @param error 
   * @returns 
   */
  errorMessages(request: any, error: any): void {
    if (request.url.indexOf('PlatformConfiguration') !== -1) {
      return;
    }
    if (this.getErrorMessages(request) && error.status != 401 && error.status != 403) {
      return;
    }
    if (this.isUserUnauthorized) {
      return;
    }
    let message: any = this.uiMessageConfig[error.status];
    if (!message) {
      message = error.error?.ErrorMessages ? error.error : error.error?.errorMessages ? error.error : error.error?.errorlst ? error.error
        : error.error?.message ? error.error?.message : error.error?.Message ? error.error?.Message : error.error;
    }

    if (typeof message == 'object' && message) {
      if (message.errorMessages) {
        message = message.errorMessages[0].error;
      } else if (message.errorlst) {
        message = message.errorlst[0].Error || message.errorlst[0].error;
      } else if (message.ErrorMessages) {
        message = message.ErrorMessages[0].Error;
      } else {
        message = message.msg;
      }
    }

    if (error.status === 401) {
      if (error.error === 'Signed out because your account is signed in from another device') {
        const alertDlg = this.commonService.openAlertDialog('', 'Signed out because your account is signed in from another device', true);
        this.isUserUnauthorized = true;
        alertDlg.afterClosed().subscribe({
          next: () => {
            this.authService.signOut();
          }
        })
      } else if (location.href.includes('claim/assignment')) {
        const alertDlg = this.commonService.openAlertDialog('', 'Your session has expired.Kindly authenticate again to continue.', true);
        this.isUserUnauthorized = true;
        alertDlg.afterClosed().subscribe({
          next: () => {
            this.router.navigateByUrl(`claim/verify-otp${location.search}`);
          }
        })
      } else {
        const alertDlg = this.commonService.openAlertDialog('', 'Your session has expired. You will be redirected to the Login page', true);
        this.isUserUnauthorized = true;
        alertDlg.afterClosed().subscribe({
          next: () => {
            this.authService.signOut();
          }
        })
      }

      return;
    }

    if (request.url.indexOf('BidirectionalConversation') !== -1 && request.method === 'POST' && error.status === 500) {
      this.commonService.showToast(error.status, error.error.errorlst[0].error);
      return;
    }

    if (error.status === 403) {
      message = 'You don\'t have access rights';
      if (request.url.indexOf('quote') !== -1) {
        this.commonService.accessRight.next(false);
      } else if (request.url.indexOf('repair') !== -1) {
        this.commonService.accessRightRepair.next(false);
      }
      this.router.navigate([ 'accessDenied' ]);
    }

    if (error.status === 400 && request.url.indexOf('vehicleDetailsByVIN') != -1) {
      this.commonService.showInfoToast(5000, 'The vehicle was not found in the database, please add manually');
      return
    }

    if (!message || (error.status != 400 && error.status != 401 && error.status != 403)) {
      message = 'Sorry something went wrong. Please try again';
    }

    if (error.status === 404) {
      this.router.navigate([ 'notFound' ]);
    }

    if (message?.toLowerCase().indexOf('vehicle already exists') === -1 && error.status != 0) {
      if (request.url.indexOf('dashboard') !== -1 && window.location.pathname !== '/home') {
        //TO need to show message when dashbaord API is called and route has been changed
      } else {
        this.commonService.showToast(error.status, message);
      }

    }
  }

  /**
   * handleResponseError
   * @param error 
   * @param request 
   * @param next 
   * @returns 
   */
  handleResponseError(error: any, request?: any): Observable<any> {
    return throwError(() => {
      this.errorMessages(request, error);
      return error;
    });
  }

  /**
   * 
   * @param request 
   * @returns 
   */
  addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    if (this.authService.loggedIn && this.authService.currentUser) {
      return request.clone({
        setHeaders: {
          'Authorization': `Bearer ${this.authService.currentUser.token}`
        }
      });
    }
    return request;
  }

  /**
   * 
   * @param request 
   * @returns 
   */
  addTenantHeader(request: HttpRequest<any>): HttpRequest<any> {
    const tenantInfo = localStorage.getItem('tenant');
    if(tenantInfo && !request.url.includes('PlatformConfiguration') && !request.url.includes('application-feature')) {
      const tenant = JSON.parse(tenantInfo);
      return request.clone({
        setHeaders: {
          'Tenantid': `${tenant.TenantID}`,
          'Accountid': `${tenant.AccountID}`,
          'Orgid': `${tenant.orgID}`
        }
      });
    }
    return request;
  }

  /**
   * 
   * @param request 
   * @returns 
   */
  addDomainHeader(request: HttpRequest<any>): HttpRequest<any> {
    if (request.url.toLowerCase().includes('/inspection')) {
      let domainId = '2100';
      if (this.router.url.includes('repair')) {
        domainId = '3100';
      } else if (this.router.url.includes('claim')) {
        domainId = '4100';
      }
      return request.clone({
        setHeaders: {
          'domainId': domainId
        }
      });
    }
    return request;
  }

  /**
   * intercept http request
   * @param req 
   * @param next 
   * @returns 
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const timeoutValue = this.defaultTimeout;
    const timeoutValueNumeric = Number(timeoutValue);
    const authReq = req.clone({
      headers: req.headers.set('Ocp-Apim-Subscription-Key', environment.subscriptionKey)

    });
    const tenantReq = this.addTenantHeader(authReq);
    const domainReq = this.addDomainHeader(tenantReq);
    const request = this.addAuthHeader(domainReq);
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      timeout(timeoutValueNumeric),
      catchError((error) => {
        const el: any = document.getElementById('custom-loader');
        if (el) {
          el.style.display = 'none';
        }
        this.commonService.hideLoading();
        if (error.name === 'TimeoutError' && !error.status) {
          error.status = 524;
          error.url = req.url;
        }        
        return this.handleResponseError(error, authReq);
      }));
  }
}