<div class="form-field-checkbox">
    <div class="checkbox-field">
        <input class="form-value" type="checkbox" #input
            [id]="inputId"
            [name]="nameId"
            [readonly]="readonly" 
            [disabled]="disabled" 
            [required]="required"
            [checked]="value"
            [indeterminate]="indeterminate" 
            (change)="_onInputInteraction($event)">
        <label class="form-label pointer-events" [for]="inputId">
            <span class="checkbox-mark" *ngIf="position === 'start'"></span>
            <span>
                <ng-content></ng-content>
            </span>
            <span class="checkbox-mark" *ngIf="position === 'end'"></span>
        </label>
    </div>
</div>