import { Component, Host, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
let nextUniqueId = 0;

@Component({
  selector: 'xa-checkbox-group',
  template: '<ng-content></ng-content>',
  styleUrls: [ './xa-checkbox-group.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XaCheckboxGroup),
      multi: true
    }
  ]
})
export class XaCheckboxGroup implements ControlValueAccessor {
  public _model: any;
  _uniqueId: string = `xa-checkbox-item-${nextUniqueId++}`;
  public onChange: (m: any) => void = Function;
  public onTouched: (m: any) => void = Function;
  /**
   * get model
   */
  get model(): string {
    return this._model;
  }

  /**
   * write value
   * @param value 
   */
  writeValue(value: any): void {
    this._model = value;
  }

  /**
   * register on change
   * @param fn 
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * register on touch
   * @param fn 
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * set value
   * @param value 
   */
  set(value: any): void {
    this._model = value;
    this.onChange(this._model);
  }

  /**
   * add and remove value
   * @param value 
   */
  addOrRemove(value: any): void {
    if (this.contains(value)) {
      this.remove(value);
    } else {
      this.add(value);
    }
  }




  /**
   * check value
   * @param value 
   * @returns 
   */
  contains(value: any): boolean {
    if (this._model instanceof Array) {
      return this._model.indexOf(value) > -1;
    } else if (this._model) {
      return this._model === value;
    }

    return false;
  }

  /**
   * add value
   * @param value 
   */
  public add(value: any): void {
    if (!this.contains(value)) {
      if (this._model instanceof Array) {
        this._model.push(value);
      } else {
        this._model = [ value ];
      }
      this.onChange(this._model);
    }
  }

  /**
   * remove value
   * @param value 
   * @returns 
   */
  public remove(value: any): void {
    const index = this._model.indexOf(value);
    if (!this._model || index < 0) {
      return;
    }

    this._model.splice(index, 1);
    this.onChange(this._model);
  }

}

@Component({
  selector: 'xa-checkbox-item',
  template: `
  <div class="form-field-checkbox-group">
    <div class="checkbox-field" (click)="toggleCheck()">
        <input class="form-value" type="checkbox" #input
            [id]="inputId"
            [name]="nameId"
            [disabled]="disabled" 
            [checked]="isChecked()">
        <label class="form-label pointer-events">
            <span class="checkbox-mark"></span>
            <span style="width:100%">
                <ng-content></ng-content>
            </span>
        </label>
    </div>
  </div>
  `,
  styleUrls: [ './xa-checkbox-group.component.scss' ],
})

export class XaCheckboxItem {
  @Input() value: any;
  @Input() disabled?: boolean = false;

  _uniqueId: string = `xa-checkbox-group-${nextUniqueId++}`;
  /**
   * constructor
   * @param checkboxGroup 
   */
  constructor(@Host() public checkboxGroup: XaCheckboxGroup) {
  }

  /**
   * toggle check
   */
  toggleCheck(): void {
    if (!this.disabled) {
      this.checkboxGroup.addOrRemove(this.value);
    }
  }

  /**
   * check if checkbox is checked
   * @returns 
   */
  isChecked(): boolean {
    return this.checkboxGroup.contains(this.value);
  }

  /**
   * get generated id
   */
  get inputId(): string {
    return `${this._uniqueId}-input`;
  }

  /**get name */
  get nameId(): string {
    return `${this._uniqueId}`;
  }

}
