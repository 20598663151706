<div class="messaging-list-wrapper">
    <div class="action-wrapper">
        <form [formGroup]="searchForm">
            <div class="input-wrapper">
                <mat-icon [svgIcon]="'search'"
                          color="dark-gray"></mat-icon>
                <input formControlName="search"
                       #searchField
                       placeholder="Search..." />
            </div>
        </form>

        <div class="icon-wrapper"
             *ngIf="false"
             (click)="showNewRepair = true">
            <mat-icon [svgIcon]="'add'"></mat-icon>
        </div>
    </div>
    <div class="communication-list">
        <dx-scroll-view [scrollByThumb]="true"
                        [showScrollbar]="'onHover'"
                        reachBottomText="Loading..."
                        (onReachBottom)="updateBottomContent($event)">
            <div *ngFor="let chat of chatList"
                 class="communication-wrapper"
                 (click)="itemClicked(chat, chat.unreadCount > 0, chat.id)">
                <div class="communication-detail">
                    <div class="detail"
                         *ngIf="chat.chatConversations?.length > 0"
                         id="{{chat.name || chat.phoneNumber}}">
                        <span class="name">{{chat.name || chat.phoneNumber}}</span>
                        <span class="message"> {{chat.chatConversations[chat.chatConversations?.length -1].message}}</span>
                    </div>
                </div>
                <div class="time-wrapper"
                     *ngIf="chat.chatConversations?.length > 0">
                    <div class="time">{{chat.chatConversations[chat.chatConversations?.length -1].sendOn | date :'dd/MM'}}</div>
                    <span class="indicator"
                          *ngIf="chat.unreadCount > 0">{{ chat.unreadCount }}</span>
                </div>
            </div>
            <div *ngIf="chatList?.length === 0 && pageReady"
                 class="communication-wrapper">
                <div class="communication-detail">
                    <div class="detail">
                        <span class="message">{{ 'no_result' | translate }}</span>
                    </div>
                </div>
            </div>
        </dx-scroll-view>

        <div *ngIf="chatList?.length === 0 && !pageReady"
             class="spinner-wrapper">
            <mat-spinner [strokeWidth]="5"
                         [diameter]="50"
                         class="inner-loading"></mat-spinner>
        </div>
    </div>
</div>

<ng-container *ngIf="false">
    <div *ngIf="showNewRepair"
         class="messaging-repair-backdrop"> </div>

    <div class="messaging-repair-container"
         [ngClass]="{'hidden': !showNewRepair}">
        <div class="header">
            <div class="title">
                {{ 'general_communication' | translate }}
            </div>
            <div class="action">
                <button (click)="closeNewMessage()">
                    <mat-icon [svgIcon]="'cross'"
                              color="white"></mat-icon>
                </button>
            </div>
        </div>
        <div class="content">
            <p class="title">Select Repair ID</p>
            <div class="repair-wrapper">
                <div class="detail">
                    Repair ID 4353243242 Last
                </div>
                <div class="right-container">
                    <div class="time">16/05</div>
                    <mat-icon [svgIcon]="'arrow-right'"
                              color="dark-gray"></mat-icon>
                </div>
            </div>
        </div>
    </div>
</ng-container>