import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'app-gt-motive-estimation-dialog',
  templateUrl: './gt-motive-estimation-dialog.component.html',
  styleUrls: [ './gt-motive-estimation-dialog.component.scss' ]
})
export class GtMotiveEstimationDialogComponent {
  
  public confirmButton: ChqWidgetsButtonModel;
  
  /**
   * constructor
   * @param dialogRef 
   * @param data 
   */
  constructor(public dialogRef: MatDialogRef<GtMotiveEstimationDialogComponent>,
      private monitorService:MonitorService,
      @Inject(MAT_DIALOG_DATA) public data: any) { }
  
  /**
     * close dialog
     */
  continue(): void {
    this.dialogRef.close(true);
    this.monitorService.logEvent('close', [ 'GtMotiveEstimationDialogComponent', 'addenda-quote' ]);
  }
}
