<section class="expandable-container">
  <table aria-describedby="expandable table" mat-table [dataSource]="dataSource" multiTemplateDataRows
    class="main-table">
    <ng-container matColumnDef="{{column}}" *ngFor="let column of columnDef">
      <th mat-header-cell *matHeaderCellDef>
        <ng-container *ngIf="(column === 'customer' || column === 'totals'), else elseTemplate">
          <div class="header-cell">
            <div class="icon-wrapper">
              <mat-icon [svgIcon]="'customer'" color="white" *ngIf="column === 'customer'"></mat-icon>
              <mat-icon [svgIcon]="'calculate'" color="white" *ngIf="column === 'totals'"></mat-icon>
            </div>
            <span>{{column}}</span>
          </div>
        </ng-container>
        <ng-template #elseTemplate>
          <div class="header-cell">
            <span [ngClass]="{'today': columnsToDisplay[column].isToday}"> {{ columnsToDisplay[column].title }} </span>
            <span class="bold" [ngClass]="{'today': columnsToDisplay[column].isToday}"> {{
              columnsToDisplay[column].subTitle }} </span>
          </div>
        </ng-template>
      </th>
      <td mat-cell *matCellDef="let row" (click)="handleCellClicked(row, column, $event)">
        <ng-container *ngIf="column === 'customer', else elseTemplate">
          <div class="expandable-col">
            <div class="expandable-icon" (click)="expandedElement = expandedElement === row ? null : row"
              *ngIf="row.child">
              <mat-icon [svgIcon]="'plus'" *ngIf="expandedElement !== row"></mat-icon>
              <mat-icon [svgIcon]="'minus'" *ngIf="expandedElement === row"></mat-icon>
            </div>
            <div class="icon-group">
              <div class="icon-wrapper">
                <mat-icon [svgIcon]="'customer'" [attr.color]="'primary'"></mat-icon>
              </div>
              <span class="min-content">{{row[column]}}</span>
            </div>
          </div>
        </ng-container>
        <ng-template #elseTemplate>
          <div class="detail-container" [ngStyle]="{'background-color': column !== 'totals' ? row['color'] : ''}"
            [ngClass]="{'cell': (column !== 'customer' && column !== 'totals'), 'row-total': column === 'totals'}"
            *ngIf="row[column]">
            <div class="count">
              {{row[column]['count']}}
            </div>
            <div class="amount">
              <div class="values">
                <span class="icon"> E </span>
                <span class="currency">{{row[column]['estimate'] |
                  xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</span>
              </div>
              <div class="values">
                <span class="icon"> L </span>
                <span class="currency">{{row[column]['labour'] |
                  xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</span>
              </div>
            </div>
          </div>
        </ng-template>
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <ng-container *ngIf="column === 'customer', else elseTemplate">
          <div class="header-cell">
            <div class="icon-wrapper">
              <mat-icon [svgIcon]="'calculate'" color="white"></mat-icon>
            </div>
            <span>Totals</span>
          </div>
        </ng-container>
        <ng-template #elseTemplate>
          <div class="detail-container"
            [ngClass]="{'cell-total': (column !== 'customer' && column !== 'totals'), 'all-total': column === 'totals'}">
            <div class="count">
              {{ columnTotalCount[column] }}
            </div>
            <div class="amount">
              <div class="values">
                <span class="icon"> E </span>
                <span class="currency">{{ columnTotalEstimateAmount[column] |
                  xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position }}</span>
              </div>
              <div class="values">
                <span class="icon"> L </span>
                <span class="currency">{{ columnTotalLabourAmount[column] |
                  xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position }}</span>
              </div>
            </div>
          </div>
        </ng-template>
      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let row" [attr.colspan]="columnDef.length">
        <div class="element-detail" [@detailExpand]="row === expandedElement ? 'expanded' : 'collapsed'">
          <table aria-describedby="expanded table" class="expanded-table">
            <th></th>
            <tr class="expanded-table-row" *ngFor="let item of row['child']">
              <td *ngFor="let column of columnDef">
                <div class="expanded-table-cell">
                  <ng-container *ngIf="column === 'customer', else elseTemplate">
                    <span>{{item[column]}}</span>
                  </ng-container>
                  <ng-template #elseTemplate>
                    <div class="detail-container" *ngIf="item[column]" [ngClass]="{'row-total': column === 'totals'}">
                      <div *ngIf="column === 'totals'" class="count">
                        {{ item[column].count }}
                      </div>
                      <div *ngIf="column === 'totals'" class="amount">
                        <div class="values">
                          <span class="icon"> E </span>
                          <span class="currency">{{item[column]['estimate'] |
                            xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</span>

                        </div>
                        <div class="values">
                          <span class="icon"> L </span>
                          <span class="currency">{{item[column]['labour'] |
                            xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</span>

                        </div>
                      </div>

                      <div *ngIf="column !== 'totals'" class="amount">
                        <div class="values">
                          <span class="icon"> E </span>
                          <a href="/repair/booking/{{item[column].repairGuid}}"><span
                              class="currency">{{item[column]['estimate'] |
                              xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</span>
                          </a>
                        </div>
                        <div class="values">
                          <span class="icon"> L </span>
                          <a href="/repair/booking/{{item[column].repairGuid}}"><span
                              class="currency">{{item[column]['labour'] |
                              xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnDef; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: columnDef;" [class.expanded-row]="expandedElement === row"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    <tr class="mat-row-no-data" *matNoDataRow>
      <td class="mat-cell-no-data" colspan="9999">No data available</td>
    </tr>
    <tr mat-footer-row *matFooterRowDef="columnDef; sticky: true"></tr>
  </table>
</section>