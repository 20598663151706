import { Injectable } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { AccountInfo, AuthenticationResult, EventMessage, InteractionStatus, SilentRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { User } from '../../model/user-account.model';
import { UserService } from '../user/user.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { CommonService } from '../common/common.service';
import { CoreService } from '../core/core.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private storage: any;
  currentUser: any;
  currentLoggedInUser: User | undefined;

  loggedIn: boolean = false;
  redirectUrl: string | undefined;

  authHeaders: Headers | undefined;
  private readonly destroying$ = new Subject<void>();
  private b2cGraphUserAccount: any;
  idToken: string = '';
  correlationId: string = '';

  /**
   * constructor
   * @param msalService
   * @param msalBroadcastService
   * @param userService
   */
  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private userService: UserService,
    private commonService: CommonService,
    private coreService: CoreService
  ) {
    this.storage = localStorage;
    if (this.retrieve('currentUser')) {
      this.currentUser = JSON.parse(this.retrieve('currentUser'));
      this.loggedIn = true;
    }
  }

  /**
   * signOut
   */
  signOut(): void {
    this.commonService.showLoading();
    this.coreService.clearToken().subscribe();
    this.loggedIn = false;
    const request = {
      redirectStartPage: '/',
      scopes: [ 'openid', 'profile', `${environment.b2cSettings.caseManagementAppClientId}` ]
    };

    const activeAccount = this.msalService.instance.getActiveAccount();
    this.msalService.acquireTokenSilent(request as SilentRequest).subscribe({
      next: (result: AuthenticationResult) => {
        this.commonService.hideLoading();
        this.commonService.clearData();
        this.msalService.logoutRedirect(
          {
            account: activeAccount,
            idTokenHint: result.idToken
          });
      },
      error: () => {
        this.commonService.hideLoading();
      }
    });
  }

  /**
   * set local account
   */
  setLocalAccountManually(account: AccountInfo): void {
    this.setLocalAccount(account, '');
  }

  /**
   * handleAuthorizationConfig
   */
  handleAuthorizationConfig(callback: (result: AuthenticationResult) => void): void {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this.destroying$)
      ).subscribe(() => {
        this.configureAccount();
      });

    this.msalService.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
        // Perform actions related to user accounts here
        if (result !== null) {
          localStorage.setItem('correlationId', result.correlationId);
          this.correlationId = result.correlationId;
          callback(result);
          if (this.b2cGraphUserAccount) {
            this.setLocalAccount(this.b2cGraphUserAccount, this.correlationId);
          }
        }
      },
      error: (err) => {
        console.log(err);
      }
    });

    this.msalBroadcastService.msalSubject$
      .pipe(
        takeUntil(this.destroying$)
      ).subscribe((result: EventMessage) => {
        if (result.eventType === 'msal:acquireTokenSuccess') {
          const payload: any = result.payload;
          this.idToken = payload.idToken;
        }
        if (result.error) {
          const activeAccount = this.msalService.instance.getActiveAccount();
          if (activeAccount && this.idToken) {
            this.msalService.logoutRedirect({
              account: activeAccount,
              idTokenHint: this.idToken
            })
          } else {
            this.msalService.loginRedirect();
          }
        }
      });
  }

  /**
   * configureAccount
   */
  configureAccount(): void {
    const activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }

    this.b2cGraphUserAccount = this.msalService.instance.getActiveAccount();
    if (this.b2cGraphUserAccount) {
      this.correlationId = localStorage.getItem('correlationId');
      if (this.correlationId === 'undefined') {
        this.correlationId = '';
      }
      this.setLocalAccount(this.b2cGraphUserAccount, this.correlationId);
    }
  }

  /**
   * setLocalAccount
   * @param account
   */
  private setLocalAccount(account: AccountInfo, correlationId: string): void {
    const userAccount = this.extractTokenFromAccount(account.idTokenClaims);
    userAccount.correlationId = correlationId;
    this.loggedIn = true;
    this.currentLoggedInUser = userAccount;
    this.userService.setLocalUserAccount(userAccount);
  }

  /**
   *
   * @param key
   * @param value
   */
  store(key: string, value: any): void {
    this.storage.setItem(key, JSON.stringify(value));
  }

  /**
   * retrieve
   * @param key
   * @returns
   */
  public retrieve(key: string): any {
    const item = this.storage.getItem(key);
    if (item && item !== 'undefined') {
      return JSON.parse(this.storage.getItem(key));
    }
    return;
  }

  /**
   * extractTokenFromAccount
   * @param decodedIdToken
   * @returns
   */
  private extractTokenFromAccount(decodedIdToken: any): User {
    const user = new User();
    if (decodedIdToken) {
      if (Object.prototype.hasOwnProperty.call(decodedIdToken, 'family_name')) {
        user.familyName = decodedIdToken.family_name;
      }

      if (Object.prototype.hasOwnProperty.call(decodedIdToken, 'given_name')) {
        user.givenName = decodedIdToken.given_name;
      }

      if (Object.prototype.hasOwnProperty.call(decodedIdToken, 'name')) {
        user.name = decodedIdToken.name;
      }

      if (Object.prototype.hasOwnProperty.call(decodedIdToken, 'extension_UserType')) {
        user.userType = decodedIdToken.extension_UserType;
      }

      if (Object.prototype.hasOwnProperty.call(decodedIdToken, 'extension_AccountId')) {
        user.accountId = decodedIdToken.extension_AccountId;
      }

      if (Object.prototype.hasOwnProperty.call(decodedIdToken, 'extension_IsAnonymousUser')) {
        user.isAnonymousUser = decodedIdToken.extension_IsAnonymousUser;
      }

      if (Object.prototype.hasOwnProperty.call(decodedIdToken, 'extension_TenantGuid')) {
        user.tenantGuid = decodedIdToken.extension_TenantGuid;
      }

      if (Object.prototype.hasOwnProperty.call(decodedIdToken, 'extension_UserInternalId')) {
        user.userInternalId = decodedIdToken.extension_UserInternalId;
      }

      if (Object.prototype.hasOwnProperty.call(decodedIdToken, 'extension_AccountGuid')) {
        user.accountGuid = decodedIdToken.extension_AccountGuid;
      }

      if (Object.prototype.hasOwnProperty.call(decodedIdToken, 'extension_TenantId')) {
        user.tenantId = decodedIdToken.extension_TenantId;
      }
      if (Object.prototype.hasOwnProperty.call(decodedIdToken, 'email')) {
        user.email = decodedIdToken.email;
      }
      if (Object.prototype.hasOwnProperty.call(decodedIdToken, 'extension_XAUserGuid')) {
        user.userId = decodedIdToken['extension_XAUserGuid'];
      }
    }
    return user;
  }

  /**
   * reset password
   * @returns
   */
  resetPasswordRoute(): string {
    return this.buildRoute();
  }

  /**
   * buildRoute
   * @returns
   */
  public buildLogoutRoute(idToken:string, loginPolicy:string, correlationId:string): string {
    const logoutUrl = loginPolicy.includes('https://') ? loginPolicy : `https://${environment.b2cSettings.authorityDomain}/${environment.b2cSettings.b2cOAuthDomain}/${loginPolicy}`
    const url = new URL(
      `${logoutUrl}/oauth2/v2.0/logout`
    );
    url.searchParams.append('post_logout_redirect_uri', `${environment.baseUrl}/landing`);
    url.searchParams.append('client-request-id', correlationId);
    url.searchParams.append('id_token_hint', idToken);
    return url.href;
  }

  /**
   * buildRoute
   * @returns
   */
  public buildRoute(): string {
    const url = new URL(
      `https://${environment.b2cSettings.authorityDomain}/${environment.b2cSettings.b2cOAuthDomain}/oauth2/v2.0/authorize`
    );
    url.searchParams.append('p', environment.b2cSettings.resetPasswordPolicySettings.policy);
    url.searchParams.append('client_id', environment.b2cSettings.caseManagementAppClientId);
    url.searchParams.append('nonce', environment.b2cSettings.resetPasswordPolicySettings.nonce);
    url.searchParams.append('redirect_uri', environment.baseUrl);
    url.searchParams.append('scope', environment.b2cSettings.resetPasswordPolicySettings.scope);
    url.searchParams.append('response_type', environment.b2cSettings.resetPasswordPolicySettings.response_type);
    if (environment.b2cSettings.resetPasswordPolicySettings.promptLogin) {
      url.searchParams.append('prompt', 'login');
    }
    return url.href;
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }
}
