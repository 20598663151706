import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'xa-timer',
  templateUrl: './xa-timer.component.html',
  styleUrls: [ './xa-timer.component.scss' ]
})
export class XaTimerComponent implements OnInit, OnChanges {
  @Input() seconds: number = 0;
  @Input() showDay = true;
  @Input() showHour = true;
  @Input() showMinutes = true;
  @Input() showSeconds = true;
  @Input() timerStatus: string;
  @Input() statusStarted: string; // expected status for running
  @Input() statusPaused: string; // expected status for paused
  @Input() statusStopped: string; // expected status for paused
  pausedTime: number;
  public displayTime: string = '0d 0h 0m 0s';
  intervalId: any;
  isPaused: boolean = false;

  /**
   * Constructor
   */
  constructor(
    private cdr: ChangeDetectorRef
  ) {
  }

  /**
   *   ngOnInit() {
   * @public
   */
  ngOnInit(): void {
    this.updateDisplayTime(this.seconds);
    this.start();
    if (this.timerStatus !== undefined && (this.timerStatus === this.statusPaused || this.timerStatus === this.statusStopped)) {
      this.stop();
    }
  }

  /**
   *   ngOnInit() {
   * @public
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['seconds']?.currentValue > 0) {
      this.updateDisplayTime(changes['seconds'].currentValue)
    }
    if (changes['timerStatus'] && !changes['timerStatus'].firstChange) {
      if (this.timerStatus === this.statusStarted) {
        this.start();
      } else if (this.timerStatus === this.statusPaused) {
        this.stop();
      }
    }
  }



  /**
   * updateDisplayTime
   * @public
   */
  updateDisplayTime(totalSeconds: number): void {
    // Check if totalSeconds is a valid number
    if (isNaN(totalSeconds)) {
      console.error('Invalid totalSeconds value:', totalSeconds);
      return;
    }
    const days = Math.floor(totalSeconds / (3600 * 24));
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);
    let displayTime = '';
    if (this.showDay) {
      displayTime += `${days || 0}d `;
    }
    if (this.showHour) {
      displayTime += `${hours || 0}h `;
    }
    if (this.showMinutes) {
      displayTime += `${minutes || 0}m `;
    }
    if (this.showSeconds) {
      displayTime += `${seconds || 0}s`;
    }

    // Trim any trailing space
    this.displayTime = displayTime.trim();

    if (this.seconds > 0) {
      this.pausedTime = this.seconds;
    }

    this.cdr.detectChanges();
  }

  /**
   * startTimer
   * @public
   */
  public start(): void {
    this.stop(); // Stop any existing timer
    if (this.isPaused && this.pausedTime !== undefined) {
      this.isPaused = false;
      this.intervalId = setInterval(() => {
        this.pausedTime++;
        this.updateDisplayTime(this.pausedTime);
      }, 1000);
    } else {
      this.intervalId = setInterval(() => {
        this.seconds++;
        this.updateDisplayTime(this.seconds);
      }, 1000);
    }
  }

  /**
   * stopTimer
   * @public
   */
  public stop(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
      this.isPaused = true;
    }
  }

  /**
   * resume Timer
   * @public
   */
  public resume(): void {
    if (this.isPaused) {
      this.start();
    }
  }
}
