import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common/common.service';
import { CoreService } from 'src/app/services/core/core.service';

@Injectable({
  providedIn: 'root',
})
export class RepairGuard implements CanActivate {
  /**
   * constructor
   * @param router
   */
  constructor(
    private commonService: CommonService,
    private msalGuard: MsalGuard,
    private router: Router,
    private coreService: CoreService
  ) { }

  /**
   * canActivate
   * @returns
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.msalGuard.canActivate(next, state)) {
      return this.fetchPermissionData(next);
    } else {
      this.router.navigate([ 'home' ]);
      return false;
    }
  }

  /**
  * checkUserPermission
  * @returns
  */
  fetchPermissionData(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.commonService.userProfileData.pipe(
      switchMap((res: any) => {
        if (res) {
          return of(this.checkPermission(route.data, res.data));
        } else {
          this.commonService.showLoading();
          return this.coreService.getUserProfile().pipe(
            switchMap((res) => {
              this.commonService.hideLoading();
              if (res) {
                this.commonService.userProfileData.next(res);
                if (res?.data.userProfileThumbnailImageBlobUrl || res?.data.userProfileImageBlobUrl) {
                  this.commonService.avatar.next({
                    image: res?.data.userProfileThumbnailImageBlobUrl ?? res?.data.userProfileImageBlobUrl
                  });
                }

                return of(this.checkPermission(route.data, res.data));
              } else {
                this.router.navigate([ 'home' ]);
                return of(false);
              }
            })
          )
        }
      })
    );
  }

  /**
   * 
   * @param userData 
   * @returns 
   */
  checkPermission(routeData, userData): boolean {
    const automotiveServices = userData?.userPermission?.automotiveServices;
    this.commonService.setPermission(automotiveServices);
    const automotiveService = automotiveServices?.find(x => x.automotiveServiceName.toLowerCase() === 'addenda repair');

    if (automotiveService) {
      if (!routeData.permissionKey || this.commonService.hasPermission(routeData.permissionKey, automotiveService.permissions)) {
        return true;
      } else {
        this.router.navigate([ 'accessDenied' ]);
        return false;
      }
    } else {
      this.router.navigate([ 'home' ]);
      return false;
    }
  }
}
