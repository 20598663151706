<div class="messaging-container">
    <div class="header">
        <div class="left">
            <div class="action">
                <xa-button type='fab-basic'
                           *ngIf="isGeneralCommunication && currentThread"
                           (click)="goBack()">
                    <mat-icon svgIcon="arrow-left"></mat-icon>
                </xa-button>

            </div>
            <div class="title">
                {{ (selectedRepair || 'general_communication') | translate }}
            </div>
        </div>
        <div class="action">
            <xa-button type='fab'
                       (click)="close()">
                <mat-icon svgIcon="cross"
                          color="white"></mat-icon>
            </xa-button>
        </div>
    </div>
    <div class="content">
        <chat-list *ngIf="isGeneralCommunication && !currentThread"
                   [ngClass]="{'hidden': currentThread}"
                   [ConversationGuid]="ConversationGuid"
                   (itemSelected)="itemSelected($event);"></chat-list>
        <chat-thread *ngIf="currentThread"
                    [ConversationGuid]="ConversationGuid"
                    [chatId]="chatId"
                     [thread]="currentThread"></chat-thread>
    </div>
</div>