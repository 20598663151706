import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root',
})
  
export class MonitorService {
  appInsights: ApplicationInsights;
  
  /**
   * constructor
   * @param commonService 
   */
  constructor(private commonService: CommonService, private authService:AuthService) {
    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: environment.appInsights.connectionString,
        enableAutoRouteTracking: true, // option to log all route changes
        appId: 'xaspi-fnol-mobile-web',
        disableTelemetry: !!navigator.webdriver
      }
    });
    this.appInsights.loadAppInsights();
  }

  /**
   * logPageView
   * @param logPageView 
   */
  logPageView(name?: string, url?: string):void { // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }

  /**
   * logEvent
   * @param logPageView 
   */
  logEvent(name: string, properties?: { [key: string]: any }, logginInfo?:any):void {
    if(!properties){
      properties = {};
    }
    const appName = properties[1];
    const component = properties[0];
    const method = name;
    let extraInfo = null;
    if(properties[2]){
      extraInfo = properties[2];
    }
    let logObject = { component, method, extraInfo };
    try{
      logObject['trackingId'] = this.authService.currentLoggedInUser.correlationId;
    }catch(e){
      logObject['userId'] = '';
    }

    logObject = { ...logObject, ...logginInfo };
    
    this.appInsights.trackEvent({ name: appName }, logObject);
  }

  /**
  * logEvent
  * @param logPageView 
  */
  logMetric(name: string, average: number, properties?: { [key: string]: any }):void {
    if(!properties){
      properties = {};
    }
    try{
      properties['trackingId'] = this.authService.currentLoggedInUser.correlationId;
    }catch(e){
      properties['userId'] = '';
    }
   
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  /**
  * logEvent
  * @param logPageView 
  */
  logException(exception: Error, severityLevel?: number):void {
    const properties: {[key: string]: any } = { severityLevel };
    try{
      properties['trackingId'] = this.authService.currentLoggedInUser.correlationId;
    }catch(e){
      properties['userId'] = '';
    }
    this.appInsights.trackException({ exception: exception, properties });
  }

  
  /**
  * logEvent
  * @param logPageView 
  */
  logTrace(message: string, properties?: { [key: string]: any }):void {
    this.appInsights.trackTrace({ message: message }, properties);
  }
}