import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as dayjs from 'dayjs';
import * as saveAs from 'file-saver';
import * as html2pdf from 'html2pdf.js';
import { catchError, forkJoin, of } from 'rxjs';
import { STATUS_TEXT_DICT } from 'src/app/config/constants/claims.constants';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
@Component({
  selector: 'app-fraud-detection',
  templateUrl: './fraud-detection.component.html',
  styleUrls: [ './fraud-detection.component.scss' ]
})
export class FraudDetectionComponent implements OnInit {

  @ViewChild('elementRef', { read: ElementRef, static: true }) elementRef: ElementRef;
  private claimId: string = '';
  private inspectionId: number;
  public claimDetails: any;
  public fraudDetectionVehicle: any;

  public pdfUrl: string = '';
  public isPDFLoading: boolean = false;
  public pdfFormat: boolean = false;
  public fraudDetectionReasons: any[];
  public inspectionFraudDetails: any[];
  public claimHistory: any[];
  public claimStatus: string = '';
  public fraudReasons: any;

  /**
   * constructor
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService,
    private claimsService: ClaimsService,
    private monitorService: MonitorService,
    public dialogRef: MatDialogRef<FraudDetectionComponent>) {

    this.claimId = data.claimId;
    this.inspectionId = data.inspectionId;
  }


  /**
   * CHecks if current browser is mac or not
   * @returns boolean
   */
  get isiOS(): boolean {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  }

  /**
   * 
   */
  ngOnInit(): void {
    this.commonService.showLoading();
    const promiseApi: any = {};
    promiseApi.getClaimDetails = this.claimsService.getClaimDetail(this.claimId);
    promiseApi.fraudDetectionDuplicateVehicle = this.claimsService.getFraudDetectionVehicle(this.claimId, '1', this.inspectionId);

    const serverDetails = forkJoin(promiseApi).pipe(
      catchError(error => of(error))
    );
    serverDetails.subscribe({
      next: (resultMap: any) => {
        if (resultMap.getClaimDetails) {
          this.claimDetails = resultMap.getClaimDetails;


          if (this.claimDetails) {
            this.claimStatus = STATUS_TEXT_DICT[this.claimDetails?.claimStatus];

            if (this.claimDetails.fraudReason) {
              this.fraudReasons = JSON.parse(this.claimDetails.fraudReason) || [];
            }
            
            if (this.claimDetails.claimHistory) {
              this.claimHistory = this.claimDetails.claimHistory.reason;
            }
          }
        }

        if (resultMap.fraudDetectionDuplicateVehicle) {
          this.fraudDetectionVehicle = resultMap.fraudDetectionDuplicateVehicle[1];
          if (this.fraudDetectionVehicle) {
            this.fraudDetectionReasons = this.fraudDetectionVehicle.data?.fraudDetectedReasons;
            this.inspectionFraudDetails = this.fraudDetectionVehicle.data?.inspectionFraudDetails;
          }

        }

        this.commonService.hideLoading();
      }
    }
    );
  }

  /**
   * @returns {void}
  */
  getClaimDetails(): void {
    this.commonService.showLoading();
    this.claimsService.getClaimDetail(this.claimId).subscribe({
      next: (response: any) => {
        if (response) {
          this.claimDetails = response;
        }
        this.commonService.hideLoading();
      },
      error: () => {
        this.commonService.hideLoading();
      }
    });
  }

  /**
   * download pdf
   */
  downloadPdf(): void {
    this.pdfFormat = true;
    const params = {
      'ClaimGuid': this.claimId,
      'InspectionId': this.inspectionId
    }
    this.commonService.showLoading();
    this.claimsService.downloadFraudDetectionPdf(params);

    setTimeout(() => {
      this.commonService.hideLoading()
    }, 6000);
  }

  /**
   * custom font loader
   */
  async loadFont(src, name, style, pdf, fontName): Promise<void> {
    const result = this.commonService.imageReaderObservable(src);
    await result.toPromise().then((result) => {
      const base64String = result.image;
      const callAddFont = (): void => {
        pdf?.addFileToVFS(fontName, base64String.split('base64,')[1]);
        pdf?.addFont(fontName, name, style);
      };
      callAddFont();
    });
  }

  /**
   * generate pdf function
   */
  // public generatePDF(): void {
  //   this.monitorService.logEvent('generatePDF', [ 'FraudDetectionComponent', 'addenda-claims' ]);
  //   this.isPDFLoading = true;
  //   document.getElementsByTagName('meta')[1].setAttribute('content', 'width=1536');
  //   const html = this.elementRef.nativeElement as HTMLElement;
  //   const scroller = document.querySelector('body') as HTMLElement;
  //   const pdfWrapper = document.querySelector('.margin-pdf') as HTMLElement;
  //   scroller.style.overflowY = 'hidden';
  //   if (pdfWrapper) {
  //     pdfWrapper.style.display = 'block';
  //   }
  //   html.style.display = 'flex';
  //   const createdDate = dayjs(new Date()).format('MMDDYYYY');
  //   const fileName = this.claimDetails?.claimNo;
  //   let option: any = {
  //     margin: [ 0, 0, 15, 0 ],
  //     filename: `${fileName}_Fraud_Detection_Report_${createdDate}.pdf`,
  //     image: { quality: 1, type: 'svg' },
  //     html2canvas: { scale: 4, useCORS: true, dpi: 260, letterRendering: true, allowTaint: true, },
  //     jsPDF: { unit: 'mm', format: 'letter', orientation: 'p', compress: true },
  //   };

  //   if (this.fraudDetectionReasons?.length <= 3) {
  //     option = {
  //       ...option, pagebreak: { mode: 'css', before: '#container' }
  //     }
  //   } 
  //   const observableList = { header: null, footer: null, logo: null };
  //   observableList.footer = this.commonService.imageReaderObservable('assets/img/addenda-claims.png');

  //   const result = forkJoin(observableList).pipe(
  //     catchError(error => of(error))
  //   );

  //   result.subscribe({
  //     next: (result: any) => {
  //       try {
  //         html2pdf().from(this.elementRef.nativeElement).set(option).toPdf().get('pdf').then(async (pdf) => {
  //           try {
  //             document.getElementsByTagName('meta')[1].setAttribute('content',
  //               'user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, height=device-height');
  //             await this.loadFont('assets/fonts/Inter-Bold.ttf', 'inter-medium', 'bold', pdf, 'Inter-Bold.ttf');
  //             await this.loadFont('assets/fonts/Inter-Regular.ttf', 'inter-regular', 'normal', pdf, 'Inter-Regular.ttf');

  //             setTimeout(() => {
  //               if (this.isiOS) {
  //                 const blob = new Blob(pdf.output('blob', { filename: option.filename }), { type: 'application/octet-stream' });
  //                 saveAs(blob, option.filename);
  //               }
  //               this.pdfUrl = pdf.output('bloburl', { filename: option.filename });
  //               window.open(this.pdfUrl, '_blank');
  //             });


  //           } catch (e) {
  //             this.commonService.showToast(0, 'Pdf generation failed.');
  //             document.getElementsByTagName('meta')[1].setAttribute('content',
  //               'user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, height=device-height');
  //             if (pdfWrapper) {
  //               pdfWrapper.style.display = 'none';
  //             }
  //             html.style.display = 'none';
  //             this.isPDFLoading = false;
  //             this.monitorService.logEvent('generatePDF', [ 'FraudDetectionComponent', 'addenda-claims', {
  //               message: 'Pdf generation failed.'
  //             } ]);
  //             setTimeout(() => {
  //               scroller.style.overflowY = 'auto';
  //             });
  //           }
  //         }).then(() => {
  //           this.monitorService.logEvent('generatePDF', [ 'FraudDetectionComponent', 'addenda-claims', {
  //             message: 'Pdf generation successful.'
  //           } ]);
  //           if (pdfWrapper) {
  //             pdfWrapper.style.display = 'none';
  //           }
  //           setTimeout(() => {
  //             html.style.display = 'none';
  //             this.isPDFLoading = false;
  //             scroller.style.overflowY = 'auto';
  //           });

  //         }).save();
  //       } catch (e) {
  //         this.commonService.showToast(0, 'Pdf generation failed.');
  //         document.getElementsByTagName('meta')[1].setAttribute('content',
  //           'user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, height=device-height');
  //         if (pdfWrapper) {
  //           pdfWrapper.style.display = 'none';
  //         }
  //         html.style.display = 'none';
  //         this.isPDFLoading = false;
  //         setTimeout(() => {
  //           scroller.style.overflowY = 'auto';
  //         });
  //         this.monitorService.logEvent('generatePDF', [ 'FraudDetectionComponent', 'addenda-claims', {
  //           message: 'Pdf generation failed.'
  //         } ]);
  //       }
  //     },
  //     error: () => {
  //       this.commonService.showToast(0, 'Pdf generation failed.');
  //       document.getElementsByTagName('meta')[1].setAttribute('content',
  //         'user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, height=device-height');
  //       if (pdfWrapper) {
  //         pdfWrapper.style.display = 'none';
  //       }
  //       html.style.display = 'none';
  //       this.isPDFLoading = false;
  //       setTimeout(() => {
  //         scroller.style.overflowY = 'auto';
  //       });
  //       this.monitorService.logEvent('generatePDF', [ 'FraudDetectionComponent', 'addenda-claims', {
  //         message: 'Pdf generation failed.'
  //       } ]);
  //     }
  //   });
  // }

  /**
    @returns {void} function to close Summary popup
  */
  onClose(): void {
    this.dialogRef.close();
  }
}
