<div class="add-job-container fx-col">
    <div class="header">
        <h3>{{'add_job' | translate}}</h3>
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'"
                      (click)="cancelDialog(false);"></mat-icon>
        </div>
    </div>
    <div class="content fx-1">
        <form>
            <div class="jobName">
                <chq-input #operationType
                           [inputModel]="formModel.name"
                           (controlOutput)="onFormUpdate($event)"></chq-input>
            </div>
            <div *ngIf="data.isDynamicJob">
                <chq-input class="col repair-type"
                           [inputModel]="formModel.jobRepairTypes"
                           (controlOutput)="onFormUpdate($event)"></chq-input>
            </div>
            <div class="vector"></div>
            <div class="colorPicker">
                <label class="label-toggle">{{'job_color_picker' | translate}}</label>
                <div class="color-picker-input">
                    <div *ngIf="!currentJobColor[0].value">
                        <mat-icon class="colorpicker-icon"
                                  #ignoredButton
                                  (click)="toggle=!toggle"
                                  [svgIcon]="'colorpicker'"></mat-icon>
                        <input #ignoredInput
                               class="remove-background-color"
                               [cpIgnoredElements]="[ignoredButton, ignoredInput]"
                               [(cpToggle)]="toggle"
                               [(colorPicker)]="colorList[0].value"
                               [cpOKButton]="true"
                               [cpOKButtonText]="'Save'"
                               [cpOKButtonClass]="'okBtnClass'"
                               [cpSaveClickOutside]="false"
                               (colorPickerClose)="onFormUpdate($event,'color')"
                               [cpOutputFormat]="'hex'"
                               (colorPickerSelect)="currentJobColor[0].value=$event" />
                    </div>
                    <input *ngIf="currentJobColor[0].value"
                           [style.background]="currentJobColor[0].value"
                           [(colorPicker)]="currentJobColor[0].value"
                           [cpOKButton]="true"
                           [cpOKButtonText]="'Save'"
                           [cpOKButtonClass]="'okBtnClass'"
                           [cpSaveClickOutside]="false"
                           [cpDisableInput]="false"
                           (colorPickerClose)="onFormUpdate($event,'color')"
                           [cpOutputFormat]="'hex'" />
                </div>
            </div>
            <div class="vector"></div>
            <p class="process-header">{{'select_repair_process' | translate}}</p>
            <div class="fx-row justify-space-between toggle-container">
                <div class="toggle fx-row align-center">
                    <label class="label-toggle">{{'photos' | translate}}</label>
                    <chq-toggle [checked]="this.data?.jobsData?.options?.isUploadPhotosRequired || false"
                                [name]="'isUploadPhotosRequired'"
                                (ToggleChange)="onToggleChange($event)"></chq-toggle>
                </div>
                <div class="toggle fx-row align-center"
                     *ngIf="this.data?.isBodyOrPaint">
                    <label class="label-toggle">{{'damage_detection' | translate}}</label>
                    <chq-toggle [ngClass]="{'disable':isAIdisabled}"
                                [checked]="formModel.isAIAnalysisEnabled.value"
                                [disabled]="isAIdisabled"
                                [name]="'isAIAnalysisEnabled'"
                                (ToggleChange)="onToggleChange($event)"></chq-toggle>
                </div>
                <div class="toggle fx-row align-center">
                    <label class="label-toggle">{{'timer' | translate}}</label>
                    <chq-toggle [checked]="this.data?.jobsData?.options?.isTimerEnabled || false"
                                [name]="'isTimerEnabled'"
                                (ToggleChange)="onToggleChange($event)"></chq-toggle>
                </div>
            </div>
            <div class="fx-row justify-space-between align-center photos-menu"
                 *ngIf="this.data?.jobsData?.options?.isUploadPhotosRequired || isPhotosRequired">
                <chq-input class="photo-count"
                           [inputModel]="formModel.minNumOfPhotos"
                           (controlOutput)="onFormUpdate($event)"></chq-input>
                <chq-input [ngClass]="{'disable': this.formModel.minNumOfPhotos.value < 8 }"
                           class="margin-top"
                           [inputModel]="formModel.isSPIEnabled"
                           (controlOutput)="onFormUpdate($event)"></chq-input>
                <chq-input class="margin-top"
                           [inputModel]="formModel.isBulkUploadEnabled"
                           (controlOutput)="onFormUpdate($event)"></chq-input>
            </div>
            <div class="fx-row justify-space-between toggle-container">
                <div class="toggle fx-row align-center">
                    <label class="label-toggle">{{'max_no_of_days' | translate}}</label>
                    <chq-toggle [checked]="this.isMaxNumberOfDaysEnabled"
                                [name]="'isMaxNumberOfDaysEnabled'"
                                (ToggleChange)="onToggleChange($event)"></chq-toggle>
                </div>
            </div>
            <div class="fx-row justify-space-between align-center photos-menu"
                 *ngIf="isMaxNumberOfDaysEnabled">
                <chq-input class="photo-count"
                           [inputModel]="formModel.maxNumberOfDays"
                           (controlOutput)="onFormUpdate($event)"></chq-input>
            </div>
        </form>
        <div class="footer-button fx-row justify-end">
            <chq-button *ngIf="this.data.jobsData?.jobGuid"
                        class="dltbtn"
                        [buttonModel]="deleteButtonModel"></chq-button>
            <chq-button [buttonModel]="saveButtonModel"></chq-button>
        </div>
    </div>
</div>