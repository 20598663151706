<div class="rating-option"
     [ngClass]="{'not-selected': notSelected === true}">
    <input #input
           type="radio"
           [id]="inputId"
           [checked]="checked"
           [disabled]="disabled"
           [name]="name"
           [value]="value"
           [required]="required"
           (change)="_onInputInteraction($event)">
    <label class="form-label"
           [for]="inputId">
        <div class="icon-wrapper">
            <mat-icon *ngIf="iconType === 'svg'"
                      [svgIcon]="iconName"
                      aria-hidden="false"
                      [style]="style"
                      [attr.color]="color"></mat-icon>

            <mat-icon *ngIf="iconType === 'material'"
                      aria-hidden="false"
                      [style]="style"
                      [attr.color]="color">{{ iconName }}</mat-icon>

            <img *ngIf="iconType === 'img'"
                 [style]="style"
                 [src]="iconName"
                 [alt]="iconName" />
        </div>
        <ng-content></ng-content>
    </label>
</div>