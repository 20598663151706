<div class="main-wrapper-third-party">
    <header class="main-header">
      <img [src]="logoUrl" *ngIf="logoUrl" alt="logo"/>
      <mat-icon [svgIcon]="'xa-logo-white'" *ngIf="!logoUrl"></mat-icon>
    </header>
    <div class="container">
      <div class="card">
        <ng-content></ng-content>
      </div>
    </div>
</div>
<chq-pdf-generator  *ngIf="claimDetails" class="margin-pdf" #elementRef [isClaim]="isClaimModule" [claimDetail]="claimDetails" [damageParts]='damageParts' [damageRecommendations]="damageRecommendationList"  [uploadSteps]="pdfImageSteps"></chq-pdf-generator>