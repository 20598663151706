<div class="approval-form">
    <div class="wrapper">
        <div class="wrapper-header">
            <h3>{{'approve_or_decline_the_request' | translate}}</h3>
            <span>{{'select_approve/reject_option' | translate}}</span>
        </div>
        <div class="radio-container">
            <div class="radio-wrapper" [ngClass]="{'selected': checkedStatus === 'Approved'}">
                <mat-icon [svgIcon]="'check'" aria-hidden="false" aria-label="edit icon"></mat-icon>
                <label>{{'approve' | translate}}</label>
                <chq-input
                    class="company-input col-3"
                    [inputModel]="approveRadio"  (controlOutput)="onFormUpdate($event)"
                ></chq-input>
            </div>
            <div class="radio-wrapper" [ngClass]="{'selected': checkedStatus === 'declined'}">
                <mat-icon [svgIcon]="'cross'" aria-hidden="false" aria-label="edit icon"></mat-icon>
                <label>{{'decline' | translate}}</label>
                <chq-input
                    class="company-input col-3"
                    [inputModel]="declineRadio"  (controlOutput)="onFormUpdate($event)"
                ></chq-input>
            </div>
        </div>
        <div class="content" *ngIf="checkedStatus === 'declined'">
            <chq-input [inputModel]="notesModel" (controlOutput)="onFormUpdate($event);"  tabindex="-1"></chq-input>
        </div>
        <div class="button-container">
            <chq-button [buttonModel]="cancelButtonModel"></chq-button>
            <chq-button [buttonModel]="nextButtonModel"></chq-button>
        </div>       
    </div>
</div>
