import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

export const b2cAuthorityDomain = environment.b2cSettings.authorityDomain;

// this checks if the app is running on IE
export const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

/**
 * Enter here the user flows and custom policies for your B2C application,
 * To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */

export const b2cPolicies = {
  authorities: {
    signUpSignIn: {
      authority: `https://${b2cAuthorityDomain}/${environment.b2cSettings.b2cOAuthDomain}/`
    }
  },
  authorityDomain: b2cAuthorityDomain
};

/**
  * Msal logs
  */
export function loggerCallback(logLevel: LogLevel, message: string): void {
  //console.log('msal-provider:- ' + message);
}

/**
  * Msal instance factory settings
  */
export function MSALInstanceFactory(): IPublicClientApplication {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  let policyName = environment.externalB2CSignupPolicy['XA'];

  let cidValue = urlParams.get('cid') || urlParams.get('CID');

  const loginPolicy = urlParams.get('lp') || urlParams.get('LP');

  if (cidValue?.toLowerCase() == 'clear') {
    localStorage.removeItem('cid');
    localStorage.setItem('currentLoginParam', '');
    localStorage.setItem('customLoginPolicy', '');
  }

  if (cidValue) {
    localStorage.setItem('currentLoginParam', '');
    localStorage.setItem('customLoginPolicy', '');
    policyName = environment.externalB2CSignupPolicy[cidValue.toUpperCase()]
    localStorage.setItem('cid', cidValue);
  } else if(loginPolicy){
    policyName = loginPolicy;
  } else {
    const loginPolicy = localStorage.getItem('customLoginPolicy');
    if(loginPolicy){
      policyName = loginPolicy;
    }else{
      const localData = localStorage.getItem('cid');
      if (localData) {
        cidValue = localData;
        policyName = environment.externalB2CSignupPolicy[localData.toUpperCase()]
      }
    }
  }

  const authority = b2cPolicies.authorities.signUpSignIn.authority + policyName;
  localStorage.setItem('activePolicy', policyName);
  let logoutRedirectURL = environment.baseUrl;
  if(cidValue?.toLowerCase() === 'xa360' || loginPolicy) {
    const tenantData = localStorage.getItem('tenant');
    if(!tenantData) {
      localStorage.setItem('cid', '');
      window.location.href = `${environment.baseUrl}/landing`;
    }
    logoutRedirectURL = `${environment.baseUrl}/landing`;
  }
  return new PublicClientApplication({
    auth: {
      clientId: environment.b2cSettings.caseManagementAppClientId,
      authority: authority,
      redirectUri: environment.baseUrl,
      postLogoutRedirectUri: logoutRedirectURL,
      knownAuthorities: [ b2cPolicies.authorityDomain ]
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      allowRedirectInIframe: true,
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Trace, // change to loglevel.error after testing
        piiLoggingEnabled: true
      }
    }
  });
}

/**
 * a
 * @returns
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  if(!location.href.includes('approval?token=') && 
  !location.href.includes('verify-otp') && 
  !location.href.includes('claim/assignment') && 
  !location.href.includes('claim/estimate') && 
  !location.href.includes('claim/invoice') && 
  !location.href.includes('landing')){
    protectedResourceMap.set(environment.autoCoreApi, [ environment.b2cSettings.caseManagementApiScope ]);
    protectedResourceMap.set(environment.caseMgmtUrl, [ environment.b2cSettings.caseManagementApiScope ]);
    protectedResourceMap.set(environment.autoApi, [ environment.b2cSettings.caseManagementApiScope ]);
    protectedResourceMap.set(environment.communicationApi, [ environment.b2cSettings.caseManagementApiScope ]);
    protectedResourceMap.set(environment.mediaManagementUrl, [ environment.b2cSettings.caseManagementApiScope ]);
    protectedResourceMap.set(environment.xaAggregationApi, [ environment.b2cSettings.caseManagementApiScope ]);
    protectedResourceMap.set(environment.bookingMgmtUrl, [ environment.b2cSettings.caseManagementApiScope ]);
    protectedResourceMap.set(environment.fnolApiUrl, [ environment.b2cSettings.caseManagementApiScope ]);
  }

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

/**
 * MSALGuardConfigFactory
 * @returns
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [ ...[ environment.b2cSettings.caseManagementApiScope ] ],
    },
  };
}

export const MSALProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true
  },
  {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory
  },
  {
    provide: MSAL_GUARD_CONFIG,
    useFactory: MSALGuardConfigFactory
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory
  },
  MsalService,
  MsalGuard,
  MsalBroadcastService
];
