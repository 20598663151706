import { NgModule } from '@angular/core';
import { HasPermissionDirective } from './has-permission/has-permission.directive';
import { ClaimRolesDirective } from './claim-roles.directive';
import { ChqGooglePlacesAutocompleteDirective } from './chq-google-places-autocomplete.directive';
import { CanDeactivateGuard } from 'src/app/guards/deactivate-guard/deactivate-guard.guard';
import { CheckPermissionDirective } from './check-permission/check-permission.directive';



@NgModule({
  declarations: [
    HasPermissionDirective,
    ClaimRolesDirective,
    ChqGooglePlacesAutocompleteDirective,
    CheckPermissionDirective
  ],
  imports: [
  ],
  exports: [
    HasPermissionDirective,
    ClaimRolesDirective,
    ChqGooglePlacesAutocompleteDirective,
    CheckPermissionDirective
  ],
  providers: [
    CanDeactivateGuard
  ]
})
export class DirectiveModule { }
