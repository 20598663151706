import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { WidgetsModule } from '../widgets/widgets.module';
import { SideMenuComponent } from '../shared/side-menu/side-menu.component';
import { HeaderComponent } from '../shared/header/header.component';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DirectiveModule } from '../helper/directive/directive.module';

@NgModule({
  declarations: [ LayoutComponent, SideMenuComponent, HeaderComponent ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    WidgetsModule,
    MatSidenavModule,
    MatTabsModule,
    MatToolbarModule,
    MatMenuModule,
    TranslateModule,
    ScrollingModule,
    MatProgressSpinnerModule,
    DirectiveModule
  ],
  exports: [ LayoutComponent, RouterModule ],
})
export class LayoutModule { }
