/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, ElementRef, Input, ViewChild, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

let nextUniqueId = 0;
@Component({
  selector: 'xa-toggle',
  templateUrl: './xa-toggle.component.html',
  styleUrls: [ './xa-toggle.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XaToggle),
      multi: true
    },
  ]
})
export class XaToggle {
  @Input() position?: string = 'start';
  @Input() readonly?: boolean;
  @Input() disabled?: boolean;
  @Input() required?: boolean;
  value: any;
  @ViewChild('input') _inputElement: ElementRef<HTMLInputElement>;
  _uniqueId: string = `xa-toggle-${nextUniqueId++}`;

  _controlValueAccessorChangeFn: (value: any) => void = () => {};
  onTouched: () => any = () => {};

  /**
   * Sets the value value. Implemented as part of ControlValueAccessor.
   * @param value
   */
  writeValue(value: any): void {
    this.value = value;
    if(!value && this._inputElement) {
      this._inputElement.nativeElement.checked = false;
    }
  }

  /**
   * Registers a callback to be triggered when the value value changes.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnChange(fn: (value: any) => void): void {
    this._controlValueAccessorChangeFn = fn;
  }

  /**
   * Registers a callback to be triggered when the control is touched.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /** Triggered when the radio button receives an interaction from the user. */
  _onInputInteraction(event: Event): void {
    // We always have to stop propagation on the change event.
    // Otherwise the change event, from the input element, will bubble up and
    // emit its event object to the `change` output.
    event.stopPropagation();
    const newValue = this._inputElement.nativeElement.checked;
    
    this._controlValueAccessorChangeFn(newValue);
  }

  /**
   * get generated id
   */
  get inputId(): string {
    return `${this._uniqueId}-input`;
  }

  /**get name */
  get nameId(): string {
    return `${this._uniqueId}`;
  }
}

