import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FileService } from '../file/file.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  /**
   * constructor
   * @param http 
  */
  constructor(private http: HttpClient,
    private fileService:FileService) { } 

  /**
    Retrieves payment information for a given repair ID.
    @param {string} repairId - The ID of the repair for which payment information is being retrieved.
    @returns {Observable} - An Observable that emits an object containing an array of payment information.
  */
  public getPayments(invoiceNumber: string) : Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/payment/${invoiceNumber}`;
    return this.http.get(url);
  }

  /**
  * Function to fetch case status
   * @param params
   * @returns 
   */
  public getPaymentTypes(): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/lookup?type=paymentType`;
    return this.http.get<any>(url);
  }

  /**
    Deletes a payment with the given ID.
    @param {string} paymentId - The ID of the payment to be deleted.
    @returns {Observable} - An Observable that emits the result of the deletion.
  */
  public deletePayment(paymentId: string, invoiceGUID: string): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/payment/${invoiceGUID}/delete/${paymentId}`; 
    return this.http.delete<any>(url);
  }

  /**

    Adds a payment for a repair.
    @param {string} repairId - The ID of the repair.
    @param {object} data - The payment data.
    @returns {Observable} - An Observable that emits the result of the payment addition.
  */
  public addPayment(data): Observable<any> {  
    const url = `${environment.caseMgmtUrl}/api/payment`;
    const formData = new FormData();
    formData.append('InvoiceNumber', data.invoiceNumber);
    formData.append('Amount', data.amount);
    formData.append('PaymentDate', data.paymentDate);
    formData.append('PaymentTypeId', data.paymentTypeId); 
    formData.append('Description', data.description);
    if(data.files && data.files.length > 0){
      data.files.forEach((file) => {
        formData.append('attachment', file);
      });
    }
    return this.fileService.uploadFile(url, formData, false);
  }
  
}
