import { Component, Inject, OnInit, ViewContainerRef } from '@angular/core';
import { ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Cmyk, ColorPickerService } from 'ngx-color-picker';
import { firstValueFrom } from 'rxjs';
import { SettingsAddJobsForm } from 'src/app/helper/form/settings-job-tasks.helper';
import { AddSettingsJobsFormDataModel } from 'src/app/model/chq-settings-jobs-tasks-model';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { CommonService } from 'src/app/services/common/common.service';
import { JobSettingsService } from 'src/app/services/job-settings/job-settings.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { SettingsService } from 'src/app/services/settings/settings.service';

@Component({
  selector: 'app-add-job-dialog',
  templateUrl: './add-job-dialog.component.html',
  styleUrls: [ './add-job-dialog.component.scss' ],
})
export class AddJobDialogComponent implements OnInit {
  public formModel?: SettingsAddJobsForm;

  public saveButtonModel: ChqWidgetsButtonModel = {
    label: 'Save',
    type: 'disabled',
    icon: '',
    onclick: this.submitForm.bind(this)
  }

  public deleteButtonModel: ChqWidgetsButtonModel = {
    label: '',
    type: 'outline-danger',
    icon: 'delete',
    onclick: this.deleteJob.bind(this)
  }

  public toggle: boolean = false;
  public rgbaText: string = 'rgba(165, 26, 214, 0.2)';
  public presetValues: string[] = [];
  public selectedColor: string = '';
  public cmykColor: Cmyk = new Cmyk(0, 0, 0, 0);
  nameRX: RegExp = new RegExp(/^[a-z\d\s-_\\/]+$/i);
  minNumofPhtosRX: RegExp = new RegExp(/^[0-9]+$/);

  public colorList = [
    { key: 'male', value: '#4488ff', friendlyName: 'Male Color' },
    { key: 'bluejeans', value: '#5bbfea', friendlyName: 'Blue Jeans' },
    { key: 'flame', value: '#e45a33', friendlyName: 'Flame' },
    { key: 'orange', value: '#fa761e', friendlyName: 'Orange' },
    { key: 'infrared', value: '#ef486e', friendlyName: 'Infrared' },
    { key: 'female', value: '#ff44aa', friendlyName: 'Female Color' },
    { key: 'paleyellow', value: '#ffd165', friendlyName: 'Pale Yellow' },
    { key: 'gargoylegas', value: '#fde84e', friendlyName: 'Gargoyle Gas' },
    { key: 'androidgreen', value: '#9ac53e', friendlyName: 'Android Green' },
    { key: 'carribeangreen', value: '#05d59e', friendlyName: 'Carribean Green' },
    { key: 'cyancornflower', value: '#1089b1', friendlyName: 'Cyan Cornflower' },
    { key: 'warmblack', value: '#06394a', friendlyName: 'Warm Black' },
  ];

  public currentJobColor = [
    { key: '', value: '', friendlyName: '' },
  ]

  isPhotosRequired: boolean = false;
  isAIdisabled: boolean = true;
  isMaxNumberOfDaysEnabled: boolean = false;

  /**
   Constructor for the RepairEstimateOperationComponent.
   @constructor
   @param {any} data - Data passed to the component.
   @param {MatDialogRef} dialogRef - Reference to the dialog box.
   @param {MonitorService} monitorService - Service for monitoring operations.
 */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddJobDialogComponent>,
    public monitorService: MonitorService,
    public vcRef: ViewContainerRef, private cpService: ColorPickerService,
    private commonService: CommonService,
    private jobsService: JobSettingsService,
    private settingService: SettingsService
  ) { this.presetValues = this.getColorValues(); }

  newAddJobsForm = (): SettingsAddJobsForm => {
    return {
      name: {
        placeHolder: 'Job Name',
        label: 'Job Name',
        name: 'name',
        maxLength: 50,
        value: this.data?.jobsData?.name ?? '',
        type: 'text',
        validation: {
          name: 'name',
          validationFunction: (): ValidatorFn => {
            return Validators.compose([ Validators.required, Validators.minLength(1), Validators.pattern(/^[a-z\d\s-_\\/]+$/i) ])
          },
          validationMessage: (error: ValidationErrors): string => {
            if (error['required']) {
              return 'Please enter job name'
            }
            if (error['minLength']) {
              return 'Name should have at least 1 character'
            }
            if (error['pattern']) {
              return 'Only alphanumeric characters are allowed'
            }
            return '';
          }
        }
      },
      color: {
        placeHolder: '',
        label: 'Job Color Picker',
        name: 'color',
        value: this.data?.jobsData?.color ?? '',
        type: 'text',
        validation: {
          name: 'color',
          validationFunction: (): ValidatorFn => {
            return Validators.compose([ Validators.required ])
          },
          validationMessage: (error: ValidationErrors): string => {
            if (error['required']) {
              return 'Please select color'
            }
            return '';
          }
        }
      },
      isUploadPhotosRequired: {
        placeHolder: '',
        label: '',
        name: 'isUploadPhotosRequired',
        value: this.data?.jobsData?.options?.isUploadPhotosRequired ?? false,
        type: 'checkbox'
      },
      isAIAnalysisEnabled: {
        placeHolder: '',
        label: '',
        name: 'isAIAnalysisEnabled',
        value: this.data?.jobsData?.options?.isAIAnalysisEnabled ?? false,
        type: 'checkbox',
        disabled: true
      },
      isTimerEnabled: {
        placeHolder: '',
        label: '',
        name: 'isTimerEnabled',
        value: this.data?.jobsData?.options?.isTimerEnabled ?? false,
        type: 'checkbox'
      },
      minNumOfPhotos: {
        placeHolder: 'Number of Photos',
        name: 'minNumOfPhotos',
        label: 'Number of Photos',
        value: this.data?.jobsData?.options?.uploadImagesOptions?.minNumOfPhotos ?? null,
        type: 'number',
        maxLength: 2,
        validation: {
          name: 'minNumOfPhotos',
          validationFunction: (): ValidatorFn => {
            return Validators.compose([ Validators.required, Validators.min(1), Validators.max(20), Validators.pattern('^[0-9]+$') ])
          },
          validationMessage: (error: ValidationErrors): string => {
            if (error['required']) {
              return 'number_of_photos_required'
            }
            if (error['min']) {
              return 'photos_should_have_min_1_number'
            }
            if (error['max']) {
              return 'photos_should_have_max_20_number'
            }
            if (error['pattern']) {
              return 'pattern_numeric'
            }
            return '';
          }
        },

      },
      isSPIEnabled: {
        placeHolder: '',
        label: '',
        name: 'isSPIEnabled',
        idField: 'isSPIEnabled',
        value: 'Smart Photo',
        type: 'checkbox',
        options: [ {
          label: 'Smart Photo', id: 1,
          checked: this.data ? this.data.jobsData ? this.data.jobsData.options ?
            this.data.jobsData.options.uploadImagesOptions ? this.data.jobsData.options.uploadImagesOptions.isSPIEnabled
              : null : null : null : null
        } ],
      },
      isBulkUploadEnabled: {
        placeHolder: '',
        label: '',
        name: 'isBulkUploadEnabled',
        idField: 'isBulkUploadEnabled',
        value: 'Bulk Upload',
        type: 'checkbox',
        options: [ {
          label: 'Bulk Upload', id: 1,
          checked: this.data?.jobsData?.options?.uploadImagesOptions?.isBulkUploadEnabled ?? null
        } ]
      },
      jobRepairTypes: {
        placeHolder: 'select_job_repair_types',
        label: 'repair_types',
        name: 'jobRepairTypes',
        value: [],
        type: 'select',
        displayValue: 'name',
        idField: 'repairName',
        fieldValue: 'id',
        errorWhenEmpty: true,
        isMultiSelect: true,
        options: [],
        validation: {
          name: 'name',
          validationFunction: (): ValidatorFn => {
            return Validators.compose([ Validators.required ])
          },
          validationMessage: (error: ValidationErrors): string => {
            if (error['required']) {
              return 'Please select at least one repair type'
            }

            return '';
          }
        }
      },
      isMaxNumberOfDaysEnabled: {
        placeHolder: '',
        label: '',
        name: 'isMaxNumberOfDaysEnabled',
        value: this.data?.jobsData?.options?.isMaxNumberOfDaysEnabled ?? false,
        type: 'checkbox'
      },
      maxNumberOfDays: {
        placeHolder: 'number_of_days',
        name: 'maxNumberOfDays',
        label: 'number_of_days',
        value: this.data?.jobsData?.options?.maxNumberOfDays ?? null,
        type: 'number',
        validation: {
          name: 'maxNumberOfDays',
          validationFunction: (): ValidatorFn => {
            return Validators.compose([])
          },
          validationMessage: (error: ValidationErrors): string => {
            if (error['required']) {
              return 'number_of_days_required'
            } if (error['min']) {
              return 'photos_should_have_min_1_number'
            } if (error['max']) {
              return 'photos_should_have_max_100_number'
            } if (error['pattern']) {
              return 'pattern_numeric'
            } return '';
          }
        }
      },
    }
  };

  /**
    Closes the dialog with the given data.
    @param {any} data - The data to be passed back to the dialog caller.
  */
  cancelDialog(data: any): void {
    this.dialogRef.close(data);
  }

  /**
   * on init
   */
  ngOnInit(): void {
    this.formModel = this.newAddJobsForm();
    this.currentJobColor[0].value = (this.data?.jobsData?.color)
      ? this.data.jobsData.color
      : ''

    if (this.data.isDynamicJob) {
      this.populateRepairDropdown();
    }
    if(this.data?.jobsData?.options?.isMaxNumberOfDaysEnabled) {
      this.isMaxNumberOfDaysEnabled = true;
    }
    this.checkForFormValidation();
  }

  /**
   * 
   */
  public async populateRepairDropdown(): Promise<void> {
    const repair = await firstValueFrom(this.settingService.getRepairTypes());
    const repairTypes = repair?.data?.repairServiceTypes;

    if (repairTypes && repairTypes.length > 0) {
      repairTypes.forEach((item, i) => {
        repairTypes[i] = { ...repairTypes[i], dropdownItemIcon: this.getIcon(item.name) }
      });

      this.formModel.jobRepairTypes = { ...this.formModel.jobRepairTypes, options: repairTypes }
    }

    const existingRepairTypes = this.data?.jobsData?.repairServiceTypeTasks.map(x => x.id);
    this.formModel.jobRepairTypes = { ...this.formModel.jobRepairTypes, selectedOption: repairTypes.filter(x => existingRepairTypes.indexOf(x.id) !== -1) };

  }

  /**
   * get icon
   */
  getIcon(item: string): string {
    switch (item) {
    case 'Body':
      return 'car';
    case 'Maintenance':
      return 'house';
    case 'Tires':
      return 'tire';
    case 'Tyres':
      return 'tire';
    case 'Paint':
      return 'paintbrush';
    case 'Others':
      return 'gears';
    case 'Electric':
    case 'Electrical':
      return 'bolt-car';
    case 'Diagnosis':
      return 'diagnosis';
    case 'Mechanical':
      return 'mechanical';
    default:
      return '';
    }
  }

  /**
  * on toggle change
  */
  onToggleChange(e): void {
    if (e) {
      this.formModel[e.name].value = e.checked
      if (e.checked == true && e.name == 'isUploadPhotosRequired') {

        this.isPhotosRequired = true;
        this.setUploadPhotoValidation();

      } else if (e.checked == false && e.name == 'isUploadPhotosRequired') {
        this.isPhotosRequired = false
        this.formModel.minNumOfPhotos = { ...this.formModel.minNumOfPhotos, value: null }

        const SPIOptions = this.formModel.isSPIEnabled.options
        SPIOptions[0].checked = false
        this.formModel.isSPIEnabled = { ...this.formModel.isSPIEnabled, options: [ ...SPIOptions ] }

        const bulkUploadOptions = this.formModel.isBulkUploadEnabled.options
        bulkUploadOptions[0].checked = false
        this.formModel.isBulkUploadEnabled = { ...this.formModel.isBulkUploadEnabled, options: [ ...bulkUploadOptions ] }
      } else if(e.name == 'isMaxNumberOfDaysEnabled') {
        if(e.checked == true) {
          this.isMaxNumberOfDaysEnabled = true;
          setTimeout(() => {
            this.formModel.maxNumberOfDays?.formControl?.setValidators([ Validators.required, Validators.min(1), Validators.max(100), Validators.pattern('^[0-9]+$') ])
          }, 10)
        } else {
          this.isMaxNumberOfDaysEnabled = false;
          setTimeout(() => {
            this.formModel.maxNumberOfDays?.formControl?.setValidators([])
          }, 10)
        }
      }
      this.checkForFormValidation();
    }
  }

  /**
  * on toggle change
  */
  setUploadPhotoValidation(): void {
    this.formModel.minNumOfPhotos = {
      ...this.formModel.minNumOfPhotos,
      validation: {
        name: 'minNumOfPhotos',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.required, Validators.min(1), Validators.max(20), Validators.pattern('^[0-9]+$') ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return 'number_of_photos_required'
          } if (error['min']) {
            return 'photos_should_have_min_1_number'
          } if (error['max']) {
            return 'photos_should_have_max_20_number'
          } if (error['pattern']) {
            return 'pattern_numeric'
          } return '';
        }
      }
    };
  }

  /**
Updates the form model and form data based on the output received.
@param {IControlValue} output - The output received from the form control.
@returns {void}
*/
  onFormUpdate(output, name?): void {
    if (output) {
      console.log('output :>> ', output);
      if (output.name === 'jobRepairTypes') {
        this.formModel[output.name] = {
          ...this.formModel[output.name], selectedOption: output.value
        };
      } else if (output.type === 'number') {
        this.formModel[output.name].value = parseInt(output.value);
      } else if (name == 'color') {
        this.formModel.color.value = output
      } else {
        this.formModel[output.name].value = output.value;
        if (Number(this.formModel.minNumOfPhotos.value) < 8) {
          this.formModel.isSPIEnabled.options = [
            { label: 'Smart Photo', checked: false, disabled: true }
          ]
        } else {
          if (output.name == 'isSPIEnabled' || output.name == 'minNumOfPhotos') {
            this.formModel.isSPIEnabled.options = [
              { label: 'Smart Photo', disabled: false }
            ]
          }
          if (output.name == 'isSPIEnabled' || this.data.jobsData?.options?.uploadImagesOptions?.isSPIEnabled) {
            this.formModel.isSPIEnabled.options = [
              { label: 'Smart Photo', disabled: false, checked: output.value }
            ]
          }
        }
      }
      this.checkForFormValidation();
    }
  }

  /**
* check for form validation and set button type
* @returns {void}
*/
  checkForFormValidation(): void {
    if (this.isJobsFormValid()) {
      this.saveButtonModel.type = 'primary';
    } else {
      this.saveButtonModel.type = 'disabled';
    }
  }

  /**
   * check for form validation
   * @returns {void}
   */
  isJobsFormValid(): boolean {
    let isValid = true;
    const minNumOfPhotos = this.formModel.minNumOfPhotos.value;
    const color = this.formModel.color.value
    if (this.isPhotosRequired || this.formModel.isUploadPhotosRequired.value == true) {
      if (minNumOfPhotos == null || minNumOfPhotos == '' || minNumOfPhotos == 0 || Number(minNumOfPhotos) > 20 || !this.formModel.minNumOfPhotos.value.toString().match(this.minNumofPhtosRX)) {
        isValid = false;
        this.isAIdisabled = true;
        this.formModel.isAIAnalysisEnabled.value = false;
      } else {
        this.isAIdisabled = false;
      }
    }
    if (color == '' || this.formModel.name.value == '' || !this.formModel.name.value.toString().match(this.nameRX)) {
      isValid = false
    }

    if (this.data.isDynamicJob && this.formModel.jobRepairTypes?.selectedOption?.length === 0) {
      isValid = false;
    }

    if(this.isMaxNumberOfDaysEnabled && (!this.formModel.maxNumberOfDays.value || !(this.formModel.maxNumberOfDays?.formControl?.valid))) {
      isValid = false;
    }

    return isValid;
  }

  /**
    * submitForm
  */
  submitForm(): void {
    if (!this.isJobsFormValid()) {
      return;
    }

    let uploadImagesOptions = null;
    if (Boolean(this.formModel.isUploadPhotosRequired.value) === true) {
      uploadImagesOptions = {
        minNumOfPhotos: this.formModel.minNumOfPhotos.value ? parseInt(this.formModel.minNumOfPhotos.value.toString()) : 0,
        isSPIEnabled: Boolean(this.formModel.isSPIEnabled.options[0].checked ?? false),
        isBulkUploadEnabled: Boolean(this.formModel.isBulkUploadEnabled.options[0].checked ?? false)
      };
    }


    const jobsData: AddSettingsJobsFormDataModel =
    {
      name: this.formModel.name.formControl.value,
      color: this.formModel.color.value.toString(),
      options: {
        isUploadPhotosRequired: Boolean(this.formModel.isUploadPhotosRequired.value),
        isAIAnalysisEnabled: Boolean(this.formModel.isAIAnalysisEnabled.value),
        isTimerEnabled: Boolean(this.formModel.isTimerEnabled.value),
        uploadImagesOptions: uploadImagesOptions,
        isMaxNumberOfDaysEnabled: this.isMaxNumberOfDaysEnabled
      },
    }

    if (this.data.isDynamicJob) {
      jobsData.repairServiceTypeIds = this.formModel.jobRepairTypes.selectedOption.map(x => x.id);
    }

    if(this.formModel.maxNumberOfDays.value) {
      jobsData.options.maxNumberOfDays = this.formModel.maxNumberOfDays.value
    }

    this.commonService.showLoading();
    if (this.data != null && this.data.jobsData != null) {
      this.jobsService.editJob(
        jobsData, this.data.jobsData.jobGuid
      ).subscribe({
        next: () => {
          this.dialogRef.close(true)
          this.commonService.hideLoading();
        },
        error: (err: any) => {
          this.commonService.hideLoading();
          this.monitorService.logException(err);
        }
      });
    } else {
      this.jobsService.addNewJob(
        jobsData
      ).subscribe({
        next: () => {
          this.dialogRef.close(true)
          this.commonService.hideLoading();
        },
        error: (err: any) => {
          this.commonService.hideLoading();
          this.monitorService.logException(err);
        }
      });
    }
  }

  /**
    * getColorValues
  */
  getColorValues(): any {
    return this.colorList.map(c => c.value);
  }

  /**
    * onChangeColorCmyk
  */
  public onChangeColorCmyk(color: string): Cmyk {
    const hsva = this.cpService.stringToHsva(color);
    if (hsva) {
      const rgba = this.cpService.hsvaToRgba(hsva);
      return this.cpService.rgbaToCmyk(rgba);
    }
    return new Cmyk(0, 0, 0, 0);
  }

  /**
    * onChangeColorHex8
  */
  public onChangeColorHex8(color: string): string {
    const hsva = this.cpService.stringToHsva(color, true);
    if (hsva) {
      return this.cpService.outputFormat(hsva, 'rgba', null);
    }
    return '';
  }

  /**
* on Delete Job
*/
  deleteJob(): void {
    const dialogRef = this.commonService.openConfirmationOkDialog('Confirm Deleting',
      'Are you sure you also want to delete all the tasks included in the job?');
    this.monitorService.logEvent('deleteJob', [ 'AddJobDialogComponent', 'addenda-repair' ]);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.commonService.showLoading();
        this.jobsService.deleteJobsById(this.data.jobsData.jobGuid).subscribe({
          next: () => {
            this.dialogRef.close(result)
            this.commonService.hideLoading();
          },
          error: () => {
            this.commonService.hideLoading();
          }
        })
      }
    });
  }

}
