import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';

@Pipe({
  name: 'xaCurrency'
})
export class XaCurrencyPipe implements PipeTransform {

  /**
   * return the currency value
   * @param value 
   * @param args 
   * @returns 
   */
  transform(value: number, currencyCode?: string, digitsInfo?: string, locale?: string, position?: 'start' | 'end' | string | boolean ): string | number | null {
    locale = locale || 'en';
    const symbol = getCurrencySymbol(currencyCode, 'narrow');
    const currency = formatCurrency(value, locale, getCurrencySymbol(currencyCode, 'narrow'), currencyCode, digitsInfo);
    if(position === 'end') {
      return `${currency.split(symbol)[1]} ${symbol?.trim()}`
    }
    return `${symbol?.trim()} ${currency.split(symbol)[1]}`
  }

}
