import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, firstValueFrom } from 'rxjs';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { ChqWidgetsInputModel, IControlValue } from 'src/app/model/chq-widgets-input-model';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { ThirdPartyCommonLayoutComponent } from '../common-layout/common-layout.component';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { HttpHeaders } from '@angular/common/http';

interface KeyValueType<T> {
  [k: string]: T;
}
export interface AssignmentActionForm extends KeyValueType<any> {
  acceptRepair: ChqWidgetsInputModel
}

@Component({
  selector: 'app-registered-garage-assignment-action',
  templateUrl: './registered-garage-assignment-action.component.html',
  styleUrls: [ './registered-garage-assignment-action.component.scss' ]
})
export class RegisteredGarageAssignmentActionComponent {
  @ViewChild(ThirdPartyCommonLayoutComponent) commonLayout: ThirdPartyCommonLayoutComponent;
  private token: string = ''; 
  public claimGuid: string = '';
  public claimDetails: any = {};
  public isSubmissionSuccess: boolean = false;
  public formModel?: AssignmentActionForm
  subscriptions: Subscription[] = [];
  public viewRepairDetails: ChqWidgetsButtonModel = {
    label: 'view_repair_details',
    type: 'outline',
    icon: 'report-check',
    onclick: this.handleViewRepairDetails.bind(this)
  }
  public submitButton: ChqWidgetsButtonModel = {
    label: 'submit',
    type: 'disabled',
    onclick: this.handleFormSubmit.bind(this)
  }

  /**
   * constructor
   */
  constructor(
    public commonService: CommonService,
    private monitorService: MonitorService,
    private route: ActivatedRoute,
    private claimsService: ClaimsService,
  ) {
    this.token = this.route.snapshot.queryParams['token'];
    const obj:any = atob(this.token);
    const parsedObj = JSON.parse(obj);
    this.claimGuid = parsedObj.ClaimGuid; 
  }

  /**
  * on init 
  */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'RegisteredGarageAssignmentActionComponent', 'addenda-claim' ]); 
    const status = localStorage.getItem(this.token);
    if(status === 'submitted'){
      this.isSubmissionSuccess = true;
      return
    }
    this.formModel = this.initForm();

    const claimDetails$ = this.commonService.thirdPartyClaimDetails.subscribe({
      next: (response) =>{
        this.claimDetails = response;
        if(this.claimDetails?.customerDetails) {
          this.claimDetails.customerDetails.customerFullName = '';
          if(this.claimDetails.customerDetails.policyHolderFirstName) this.claimDetails.customerDetails.customerFullName = this.claimDetails.customerDetails.policyHolderFirstName
          if(this.claimDetails.customerDetails.policyHolderLastName) this.claimDetails.customerDetails.customerFullName += ' ' + this.claimDetails.customerDetails.policyHolderLastName
        }
      }
    })

    this.subscriptions.push(claimDetails$);
  }
  
  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this.monitorService.logEvent('ngOnDestroy', [ 'RegisteredGarageAssignmentActionComponent', 'addenda-claim' ]);
    if (this.subscriptions) this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  /**
  *initForm
  */
  initForm(): AssignmentActionForm {
    this.monitorService.logEvent('initForm', [ 'RegisteredGarageAssignmentActionComponent', 'addenda-claim' ]);
    return {
      acceptRepair: {
        placeHolder: '',
        label: 'do_you_want_to_accept_this_repair_from_insurance_company',
        name: 'acceptRepair',
        idField: 'acceptRepair',
        value: '',
        type: 'radio',
        options: [ { label: 'Yes', id: 1 }, { label: 'No', id: 2 } ],
      }
    }
  }

  /**
  * validateForm
  */
  validateForm(): void {
    if(this.isFormValid()) {
      this.submitButton.type = 'primary'
    } else {
      this.submitButton.type = 'disabled'
    }
  }

  /**
  * validateForm
  */
  isFormValid(): boolean {
    if(this.formModel.acceptRepair.value) {
      return true;
    } 
    return false
  }

  /**
   * on form update
   * @param output o
   */
  async onFormUpdate(output: IControlValue): Promise<void> {
    this.formModel[output.name].value = output.value;
    this.validateForm();
  }

  /**
   * handleViewRepairDetails
   */
  handleViewRepairDetails(): void { 
    this.monitorService.logEvent('handleViewRepairDetails', [ 'RegisteredGarageAssignmentActionComponent', 'addenda-claim' ]);
    this.commonLayout.handleViewRepairDetails()
  }


  /**
     * handleViewRepairDetails
     */
  handleFormSubmit(): void { 
    this.monitorService.logEvent('handleFormSubmit', [ 'RegisteredGarageAssignmentActionComponent', 'addenda-claim' ]);
    if(!this.isFormValid()) {
      return null
    }
    this.commonService.showLoading();
    const response = this.formModel.acceptRepair.value === 'Yes' ? 1 : 2
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${this.commonLayout.idToken}` );
    const payload = {
      'claimGuid': this.claimGuid,
      'assignmentType': response,
      'garageSourceEventType': 1,
      'reason': null
    }
    firstValueFrom(this.claimsService.submitGarageAssignmentAction(payload, headers)).then((response) => {
      this.commonService.hideLoading();
      if (response) {
        this.isSubmissionSuccess = true;
        localStorage.setItem(this.token, 'submitted');
      }
    }).catch((err) => {
      this.commonService.hideLoading();
      this.monitorService.logException(err, SeverityLevel.Error);
    });
  }
  
}
