import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common/common.service';

@Directive({
  selector: '[chqPermission]'
})
export class HasPermissionDirective {

  /**
   *
   */
  @Input('chqPermission') set hasPermission(permissionKey: string) {
    const router = this.router.url;
    if (!router.includes('quote') && !router.includes('claim') && !router.includes('fnol')) {
      this.commonService.userProfileData.subscribe((res) => {
        this.viewContainer.clear();
        if (res?.data) {
          const automotiveServices = res?.data?.userPermission?.automotiveServices;
          const automotiveService = automotiveServices?.find(x => x.automotiveServiceName.toLowerCase() === 'addenda repair');
          if (automotiveService) {
            if (permissionKey === 'technicianCalendar') {
              if (automotiveService.permissions.find(x => x.permissionName === 'technician.calendar.view')) {
                this.viewContainer.createEmbeddedView(this.templateRef);
              } else {
                this.viewContainer.clear();
              }
            } else {
              if (this.noPermissionRoles[permissionKey]?.includes(automotiveService.roleName.toLowerCase())) {
                this.viewContainer.createEmbeddedView(this.templateRef);
              } else {
                this.viewContainer.clear();
              }
            }
          } else {
            this.viewContainer.createEmbeddedView(this.templateRef);
          }
        }
      });
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  private noPermissionRoles = {
    newBooking: [ 'bodyshop manager', 'service advisor' ],
    viewBooking: [ 'bodyshop manager', 'service advisor', 'technician' ],
    bookingJournal: [ 'bodyshop manager', 'service advisor' ],
    repairAddParts: [ 'bodyshop manager', 'service advisor', 'technician' ],
    repairStages: [ 'bodyshop manager', 'service advisor', 'technician' ],
    technician: [ 'technician' ],
    workflow: [ 'bodyshop manager', 'service advisor' ],
    notification: [ 'bodyshop manager', 'service advisor', 'technician' ],
    whatsappCommunication: [ 'bodyshop manager', 'service advisor' ],
    whatsappCommunicationWrite: [ 'bodyshop manager', 'service advisor' ],
    notes: [ 'bodyshop manager', 'service advisor' ]
  }

  /**
   *
   * @param templateRef
   * @param viewContainer
   * @param authService
   */
  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private commonService: CommonService,
    private router: Router
  ) { }
}
