import { Component, Input, OnChanges, SimpleChanges, ViewContainerRef } from '@angular/core';
import { XAButtonColors, XAButtonSizes, XAButtonTypes } from './xa-button.model';

@Component({
  selector: 'xa-button',
  templateUrl: './xa-button.component.html',
  styleUrls: [ './xa-button.component.scss' ]
})
export class XaButton implements OnChanges {

  @Input() type:string = XAButtonTypes.default;
  @Input() color:string = XAButtonColors.primary;
  @Input() size:string = XAButtonSizes.md;
  @Input() borderType: string = 'default';
  @Input() disabled:boolean;

  /**
   * 
   * @param _vcr reference
   */
  constructor(private _vcr: ViewContainerRef) {
  }

  /**
   * oninput
   */
  ngOnChanges(changes:SimpleChanges):void{

    if(changes && changes['disabled']){
      this._vcr.element.nativeElement.style.pointerEvents = this.disabled ? 'none': '';
    }
  }

}
