<apx-chart
    #chartRef
    [series]="series"
    [chart]="chart"
    [labels]="labels"
    [responsive]="responsive"
    [colors]="colors"
    [stroke]="stroke"
    [dataLabels]="dataLabels"
    [plotOptions]="plotOptions"
    [annotations]="annotations"
    [legend]="legend"
    [markers]="markers"
    [noData]="noData"
    [fill]="fill"
    [tooltip]="tooltip"
    [responsive]="responsive"
    [xaxis]="xaxis"
    [yaxis]="yaxis"
    [forecastDataPoints]="forecastDataPoints"
    [grid]="grid"
    [states]="states"
    [title]="title"
    [subtitle]="subtitle"
    [theme]="theme"
    [autoUpdateSeries]="autoUpdateSeries"
></apx-chart>
