<div class="page-container  mh-0 mt-0 fx-col">
    <div class="main-container">
        <div class="no-data-content fx-col align-center">
            <img src="assets/img/something-wrong.png" alt="">
            <span class="nm-text">{{'oops_something_wrong' | translate}}</span>
            <span class="tiny-text">
                {{'go_back_to_previous_screen' | translate}}
            </span>
        </div>
    </div>
    <div class="footer-full-wrapper">
        <div class="footer-image-wrapper">
            <div class="image-wrapper">
                <img src="assets/img/addenda.png" alt=""/>
            </div>
        </div>
    </div>
</div>