<router-outlet></router-outlet>
<div class="loading" *ngIf="commonService.isLoading">
    <div class="overlay"></div>
    <div class="spinner">
        <div class="loader"></div>
    </div>
</div>
<div class="notification-container local-notification" *ngIf="commonService.displayNotification">
    <div class="notification-wrapper">
        <app-notification [message]="commonService.notificationMessage" (closedialog)="commonService.hideNotification()"></app-notification>
    </div>
</div>