<div class="image-preview">
    <div class="step-container"
         (click)="selectStep(step)"
         *ngFor="let step of previewSteps">
        <div class="step-image">
            <ng-container *ngIf="step.contentType !=='application/pdf'">
                <img *ngIf="step.image else captureImage"
                     (load)="imageLoading[step?.stepName]=true"
                     [src]='step.image' />
                <ng-template #captureImage>
                    <img *ngIf="(step.thumbnailUrl ?? step.url) else sampleImage"
                         (load)="imageLoading[step?.stepName]=true"
                         [src]='step.thumbnailUrl ?? step.url' />
                </ng-template>
                <ng-template #sampleImage>
                    <img [src]="step?.placeHolderUrl || '/assets/icons/additional-image.svg'"
                         (load)="imageLoading[step?.stepName]=true" />
                </ng-template>
                <chq-custom-loader [showLoading]="!imageLoading[step?.stepName] || (!aiAnalysis && step?.uploadInProgress)"></chq-custom-loader>
            </ng-container>
            <ng-container *ngIf="step.contentType ==='application/pdf'">
                <mat-icon class='pdf-icon'
                          [svgIcon]="'pdf-file'"
                          aria-hidden="false"
                          aria-label="pdf-file icon"></mat-icon>
            </ng-container>
        </div>
        <div class="step-desc">
            <div class="step-text">
                <div class="step-title">
                    <label>{{step.stepName | translate}}</label>
                </div>
                <div class="step-status"
                     *ngIf="!aiAnalysis">
                    <label *ngIf="(step.uploadStatus ==='success' || step.url) else uploadProgress">{{ 'uploaded' |
                        translate }}</label>
                    <ng-template #uploadProgress>
                        <label *ngIf="step?.uploadInProgress; else skipped">{{ 'upload_inprogress' | translate
                            }}</label>
                    </ng-template>
                    <ng-template #skipped>
                        <label *ngIf="step?.uploadStatus === 'skipped'; else notUploaded">{{ 'skipped' | translate
                            }}</label>
                    </ng-template>
                    <ng-template #notUploaded>
                        <label>{{ 'not_uploaded' | translate }}</label>
                    </ng-template>
                </div>
                <div class="step-status"
                     *ngIf="aiAnalysis">
                    <label>{{(step.analysisStatus ==='Analysis Failed' ? 'not_available':step.analysisStatus) |
                        translate}}</label>
                </div>
            </div>
            <div class="step-status-icon">
                <mat-icon [svgIcon]="'clock'"
                          *ngIf="aiAnalysis && step.analysisStatus ==='Analysis in Progress' else navail"></mat-icon>
                <ng-template #navail>
                    <mat-icon *ngIf="(aiAnalysis && step.analysisStatus ==='Analysis Failed') else checked"
                              [svgIcon]="'xmark'"
                              aria-hidden="false"
                              aria-label="checked icon"></mat-icon>
                </ng-template>
                <ng-template #checked>
                    <mat-icon *ngIf="(step.uploadStatus ==='success' || step.url) else unchecked"
                              [svgIcon]="'checked'"
                              aria-hidden="false"
                              aria-label="checked icon"></mat-icon>
                </ng-template>
                <ng-template #unchecked>
                    <mat-icon [svgIcon]="'unchecked'"
                              aria-hidden="false"
                              aria-label="unchecked icon"></mat-icon>
                </ng-template>
                <!-- <mat-icon [svgIcon]="'refresh'" (click)="refreshData();" class="fill-danger" *ngIf="aiAnalysis && step.analysisStatus ==='Analysis in Progress'"></mat-icon> -->
            </div>
        </div>

    </div>
</div>
