<div *ngIf="pageReady" class="dialog-container">
    <div class="header">
        <h3 *ngIf="!allCommunicationSent">{{ 'send_message' | translate }}</h3>
        <div *ngIf="allCommunicationSent" style="padding: 8px;padding-right: 30px;">
            {{ 'status_already_send' | translate }}
        </div>
        <div class="close-icon" (click)="cancel();">
            <mat-icon [svgIcon]="'cross'"></mat-icon>
        </div>
    </div>
    <div mat-dialog-content class="email-dialog-body">
        <div *ngIf="!allCommunicationSent" class="form-container">
            <div class="input-wrapper">
                <chq-input [inputModel]="communicationOptions" (controlOutput)="onFormUpdate($event)"></chq-input>
                <div *ngIf="communicationTouched && emailRequest.communicationTypeIds.length === 0" class="invalid">
                    {{ 'select_one_communication' | translate}} </div>
            </div>
            <div class="input-wrapper">
                <div class="div-wrapper">
                    <chq-input [inputModel]="emailForm.email" #emailRef (controlOutput)="onFormUpdate($event)">
                    </chq-input>
                    <!-- <span class="cc-span" *ngIf="!showCC" (click)="showCC=!showCC">CC</span> -->
                </div>
            </div>
            <div class="input-wrapper" *ngIf="showCC">
                <div class="div-wrapper">
                    <chq-input [inputModel]="emailForm.cc" (controlOutput)="onFormUpdate($event)">
                    </chq-input>
                </div>
            </div>
            <div class="input-wrapper" *ngIf="showCC">
                <div class="div-wrapper">
                    <chq-input [inputModel]="emailForm.ccExtra" (controlOutput)="onFormUpdate($event)">
                    </chq-input>
                </div>
            </div>
            <div class="input-wrapper" style="margin-bottom: 34px;">
                <div class="div-wrapper flex">
                    <chq-input [inputModel]="dropdownModel" #codeRef (controlOutput)="onFormUpdate($event)">
                    </chq-input>
                    <div class="input-container">
                        <chq-input [inputModel]="emailForm.phone" #phoneRef
                            (controlOutput)="onFormUpdate($event)"></chq-input>
                    </div>
                </div>
            </div>
            <div class="input-wrapper label-wrapper" *ngIf="data.subject">
                <label class="title">{{'subject' | translate}} </label>
                <label [innerHTML]="data.subject"></label>
            </div>
            <div class="input-wrapper">
                <label class="title">{{'message' | translate}}</label>
                <div class="div-wrapper">
                    <div class="message" [innerHTML]="data.body">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!allCommunicationSent" class="btn-container">
        <chq-button [buttonModel]="sendButton" class="right"></chq-button>
    </div>
</div>