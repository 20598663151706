import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import { CommonService, Localization } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

export type TableCellData = {
  id: string;
  result: any;
};

export type TableRowData = TableCellData[];

export type ColumnHeader = {
  text?: string;
  tooltip?: string;
  style?: string;
  icon?: string;
  iconColor?:string;
  class?: string;
  type?: string;
};

export type ColumnConfig = {
  id: string;
  width?: string;
  style?: string;
  header: ColumnHeader;
  widget?: {
    type?: string;
    config?: any;
    dateFormat?: string
  };
  cellStyle?: string;
  sortable?: boolean;
  sticky?: boolean;
  stickyEnd?: boolean;
  toggle?: boolean;
  disableColumns?: boolean;
  selector?: any;
  childColumns?: any
};

export type TableConfig = {
  columnSpacing?: string;
  columns: ColumnConfig[];
  data?: any;
};

export type CellClickedCallback = (
  row: TableRowData,
  col: Partial<ColumnConfig>,
  event?: PointerEvent
) => void;

export type SortChangedCallback = (
  sort: Sort
) => void;

export type CellSelectCallback = (
  selectAll: boolean
) => void;

export type CellSelectChangeCallback = (
  event: any,
  row?: TableRowData
) => void;

@Component({
  selector: 'xa-table',
  templateUrl: './xa-table.component.html',
  styleUrls: [ './xa-table.component.scss' ],
})
export class XaTable implements OnInit, OnChanges, AfterViewInit {
  @Input() config?: TableConfig;
  @Input() tableData?: TableRowData[];
  @Input() i18n?: Localization;
  @Input() cellClicked?: CellClickedCallback;
  @Input() sortChanged?: SortChangedCallback;
  @Input() selectAllRows: CellSelectCallback;
  @Input() onDropdownSelect: CellSelectChangeCallback;
  @Input() pageChanged: boolean;
  @ViewChild(MatSort) sort!: MatSort | null;
  @ViewChild('masterCheckbox') masterCheckbox: MatCheckbox;

  dataSource = new MatTableDataSource<TableRowData>();
  columns$: BehaviorSubject<ColumnConfig[]> = new BehaviorSubject<
    ColumnConfig[]
  >([]);
  displayedColumns: string[] | undefined;
  selection = new SelectionModel<any>(true, []);
  allSelected: boolean = false;
  userDetail: any;
  currency: string = 'Rs.';

  actionIndex: number = 0;
  /**
   * return true if the master checkbox is checked
   */
  // get allSelected(): boolean {
  //   if (this.tableData?.length > 0 ) {
  //     return this.selection.selected.length === this.dataSource.data.length;
  //   } else {
  //     return false;
  //   }
  // }

  /**
   * oninit
   */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'XaTable', 'addenda-quote' ]);
    this.userDetail = this.commonService.userProfileData.value?.data?.userId;
    this.columns$.subscribe(() => {
      this.displayedColumns = this.getDisplayedColumnNames();
    });
  }

  /**
   * ngOnChanges
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.pageChanged) {
      this.resetPage();
    }

    if (changes['config']?.currentValue) {
      this.columns$.next(this.config?.columns ?? []);
    }

    if (changes['tableData']?.currentValue) {
      const columns = this.columns$.value;
      const data = this.getTableData(
        changes['tableData']?.currentValue,
        columns
      );
      this.dataSource = new MatTableDataSource(data);
    }
  }

  /**
   * constructor
   */
  constructor(private monitorService: MonitorService,
    private commonService: CommonService) {

  }

  /**
   * ngAfterViewInit
   */
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.monitorService.logEvent('ngAfterViewInit', [ 'XaTable', 'addenda-quote' ]);
  }
  /**
   * custom sort
   * @param sort
   */
  sortData(sort: Sort): void {
    if (this.sortChanged) {
      this.sortChanged(sort);
    }
  }

  /**
   * getColumnWidthPlusSpacing
   * @param col
   * @returns
   */
  getColumnWidthPlusSpacing(col: ColumnConfig): string {
    if (col?.width) {
      const columnSpacing = this.config?.columnSpacing ?? '5px';
      return `calc(${col?.width} + ${columnSpacing})`;
    }
    return 'auto';
  }

  /**
   * getDisplayedColumnNames
   * @returns
   */
  getDisplayedColumnNames(): string[] {
    const columns: ColumnConfig[] = this.columns$.value;
    if (columns?.length) {
      const validColumnNames = columns.map(c => c.id);
      return validColumnNames;
    }
    return [];
  }

  /**
   * @function handleCellClicked
   * @param row
   * @param column
   */
  handleCellClicked(row: TableRowData, col: Partial<ColumnConfig>, event: any = null): void {
    event.stopPropagation()
    if (this.cellClicked) {
      this.cellClicked(row, col, event);
    }
  }

  /**
   * getFieldFromRecord
   * @param field
   * @param record
   * @returns
   */
  getFieldFromRecord(field: string, record: any): any {
    if (Array.isArray(record)) {
      return record.find(v => v.id === field)?.result;
    } else if (Array.isArray(field)) {
      const data = field.map((x) => { return record[x] });
      if (data) return { value: data.join(' ') };
    } else if (Array.isArray(record[field])) {
      const data = record[field].map((x) => {
        if(x?.Name)
          return x?.Name?.toLowerCase()
        else return x
      });
      if (data) return { value: data };
    } else {
      return { value: record[field] };
    }
  }

  /**
   * getTableData
   * @param data
   * @param columnConfigs
   * @returns
   */
  getTableData(data: any, columnConfigs: ColumnConfig[]): TableRowData[] {
    const result: TableRowData[] = new Array<TableRowData>();
    data?.forEach((record: any) => {
      const newRow: TableRowData = new Array<TableCellData>();
      columnConfigs.forEach((column, columnIndex: number) => {
        if (column?.selector?.field) {
          const field: any = this.getFieldFromRecord(
            column.selector.field,
            record
          );
          newRow.push({ id: column.id, result: field });
        } else {
          newRow.push({ id: column.id, result: null });
        }
      });
      result.push(newRow);
    });
    return result;
  }

  /**
   * select/unselect table rows
   */
  toggleMasterSelection(checkedAll: boolean): void {
    this.allSelected = checkedAll;
    if (this.allSelected) {
      this.dataSource.data.forEach((row) => {
        this.selection.select(row[0]);
      });
    } else {
      this.selection.clear();

    }
    this.selectAllRows(this.allSelected);
  }

  /**
   * Reset all the filters checkboxes
   */
  resetPage(): void {
    if (this.masterCheckbox) {
      this.masterCheckbox.checked = false;
      this.selection.clear();
    }
  }

  /**
   * check status
   * @param row
   * @returns
   */
  checkStatus(row: any): boolean {
    return row.find((x: any) => x.id.toLowerCase() === 'status').result?.value?.toLowerCase() === 'draft';
  }

  /**
  * check status
  * @param row
  * @returns
  */
  checkStyle(row: any): string {
    let fontWeight;
    row.find((x: any) => x.id.toLowerCase() === 'employeeguid')?.result?.value?.toLowerCase() === this.userDetail ?
      fontWeight = '700' : 'normal';
    return fontWeight;
  }

  /**
   * select multiple row
   */
  selectMultiple(selected: Array<any>): void {
    this.dataSource.data.forEach((row) => {
      selected.forEach((x) => {
        const cell = row.find(y => x.id === y.id);
        if (cell.result.value === x.result.value) this.selection.select(cell)
      })
    });
  }

  /**
   * select single row
   */
  selectSingle(selected: any): void {
    const data = this.dataSource.data.find(x => x.find(y => y.id == selected.id)).find(x => x.id === selected.id);
    this.selection.select(data);
  }

  /**
   * @function ondropdownChange
   * @param event
   *
   */
  ondropdownChange(event: any, cell: TableCellData, row: TableRowData): void {
    this.onDropdownSelect(event, row);
    if (event.value.label && event.value.value) {
      cell.result.value = { ...cell.result.value, selectedOption: { label: event.value.label, value: event.value.value } };
    }
  }

  /**
   * change value of action
   * @param row
   */
  changeValue(rowV: any, i: number, row: any): void {
    row.forEach((a, ind) => {
      if(a.id === 'action'){
        this.actionIndex = ind;
      }
    });
    row[this.actionIndex].result = { ...row[this.actionIndex].result, value: row[this.actionIndex].result?.value === 'edit' ? null : 'edit' };
  }

  /**
   *
   */
  removeSpacing(str: string): string {
    return str?.replace(/\s/g, '');
  }
}
