import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
@Component({
  selector: 'app-logout-ext',
  templateUrl: './logout-ext.component.html',
  styleUrls: [ './logout-ext.component.scss' ]
})
export class LogoutExtComponent implements OnInit {

  /**
   * constructor
   * @param authService 
   */
  constructor(private monitorService:MonitorService, private authService: AuthService) {
     
  }

  /**
   * on init
   */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'LogoutExtComponent', 'addenda-quote' ]);
    this.authService.signOut();    
  }
}