import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';


@Pipe({
  name: 'orderBy'
})
export class OrderPipe implements PipeTransform {

  /**
   * trim whitespace
   * @param value 
   * @returns 
   */
  transform = orderBy;
  
}
