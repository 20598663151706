import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FileService } from '../file/file.service';

@Injectable({
  providedIn: 'root'
})
export class MediaCollectionService {
  
  /**
   * constrictor
   * @param httpClient 
   * @param fileService 
   */
  constructor(private httpClient: HttpClient, private fileService: FileService) {
   
  }

  /**
   * get media template list
   * @returns 
   */
  getMediaTemplate(): Observable<any> {
    return this.httpClient.get<any>(`${environment.mediaManagementUrl}/api/MediaTemplate`);
  }

  /**
   * get media template detail
   * @returns 
   */
  getMediaTemplateById(id: string): Observable<any> {
    const url = `${environment.mediaManagementUrl}/api/MediaTemplate/${id}`;
    return this.httpClient.get<any>(url);
  }

  /**
   * get media template details
  */
  getMediaDocumentData(collectionId:number, objectId:number, domainId:number): Observable<any> {
    const url = `${environment.mediaManagementUrl}/api/MediaDocuments/details/${collectionId}/${objectId}/${domainId}`;
    return this.httpClient.get<any>(url);
  }

  /**
   * delete media template 
  */
  deleteMediaTemplate(mediaGuid:string, mediaPartGuid:string, objectId:number, domainId:number): Observable<any> {
    const url = `${environment.mediaManagementUrl}/api/MediaDocuments/${mediaGuid}/${mediaPartGuid}/${objectId}/${domainId}`;
    return this.httpClient.delete<any>(url);
  }

  /**
   * upload document
   * @returns 
   */
  uploadDocument(file: File, mediaCollection: any):Observable<any> {
    const { mediaCollectionId, domainId, objectId, mediaTemplateId, mediaPartTemplateId } = mediaCollection;
    const formData = new FormData();
    formData.append('MediaCollectionID', mediaCollectionId);
    formData.append('DomainId', domainId);
    formData.append('ObjectId', objectId);
    formData.append('MediaTemplateId', mediaTemplateId);
    formData.append('MediaPartTemplateId', mediaPartTemplateId);
    formData.append('file', file);
    const url = `${environment.mediaManagementUrl}/api/MediaDocuments?MediaPartTemplateId=${mediaPartTemplateId}`;
    return this.fileService.uploadFile(url, formData);
  }

  /**
   * upload document
   * @returns 
   */
  uploadDocumentOCR(file: File, mediaCollection: any):Observable<any> {
    const { mediaCollectionId, domainId, objectId, mediaTemplateId, mediaPartTemplateId } = mediaCollection;
    const formData = new FormData();
    formData.append('MediaCollectionID', mediaCollectionId);
    formData.append('DomainId', domainId);
    formData.append('ObjectId', objectId);
    formData.append('MediaTemplateId', mediaTemplateId);
    formData.append('MediaPartTemplateId', mediaPartTemplateId);
    formData.append('file', file);
    const url = `${environment.mediaManagementUrl}/api/MediaDocuments/documemtOCRTag`;
    return this.fileService.uploadFile(url, formData);
  }

  /**
   * upload document
   * @returns 
   */
  updateDocument(file: File, mediaCollection: any):Observable<any> {
    const { objectId, mediaGuid, mediaPartGuid } = mediaCollection;
    const formData = new FormData();
    formData.append('ObjectId', objectId);
    formData.append('MediaGuid', mediaGuid);
    formData.append('MediaPartGuid', mediaPartGuid);
    formData.append('file', file);
    const url = `${environment.mediaManagementUrl}/api/MediaDocuments?MediaPartGuid=${mediaPartGuid}`;
    return this.fileService.updateFile(url, formData);
  }

  /**
   * get media template detail
   * @returns 
   */
  getAllDocumentDownloadURL(objectId: string, domainId:number): Observable<any> {
    const url = `${environment.mediaManagementUrl}/api/document-zip/${objectId}/${domainId}`;
    return this.httpClient.get<any>(url);
  }
}
