
<div class="dashboard-view-container">
    <div class="header">
        <h3>{{modalTitle}}</h3>
        <span style="display: flex;gap: 16px;padding-bottom: 16px;">
            <chq-button [buttonModel]="exportButtonModel"></chq-button>
            <div class="close-icon">
                <mat-icon [svgIcon]="'cross'" (click)="cancelDialog();"></mat-icon>
            </div>
        </span>
    </div>
    <div class="content">
        <!-- <table class="table">
            <thead>
                <tr>
                    <th *ngFor="let column of columnNames">{{column}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of tableContent">
                    <td *ngFor="let column of columnNames">
                        <a *ngIf="columnNamesConvertToLink[column];else noclick" (click)="navigate(column, item[column])" class="link">{{item[column]}}</a>
                        <ng-template #noclick>
                            {{item[column]}}
                        </ng-template>
                    </td>
                </tr>
            </tbody>
        </table> -->

        <div class="table-section fx-1">
            <div class="table-container">
                <div class="table-wrapper">
                    <xa-table 
                    [config]="tableConfig" 
                    [tableData]="tableContent" 
                    [cellClicked]="handleCellClick"
                    [sortChanged]="handleSortChange" 
                    [pageChanged]="pageChanged" [i18n]="i18n"
                    ></xa-table>
                </div>
                <div class="pagination-wrapper" *ngIf="tableContent?.length > 0 && (pagination.count > pagination.pageSize)">
                    <xa-paginator #paginator (paginationChange)="handlePaginationChange($event)"
                    [pagination]="pagination">
                    </xa-paginator>
                </div>        
            </div>
        </div>
    </div>
</div>
