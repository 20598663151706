<div class="form-field-text">
    <label *ngIf="label" class="form-label pointer-events" for="inputId">
      {{label | translate}}
      <span class="required" *ngIf="required">*</span>    
    </label>
    <div class="text-field">
      <input class="form-value" autocomplete="off" #input
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        [id]="inputId"
        [name]="nameId"
        placeholder="{{placeholder || label | translate}}" 
        [readonly]="readonly" 
        [disabled]="disabled" 
        [required]="required"
        [value]="value"
        (keyup)="_onInputInteraction();onTouched()" />
    </div>
  </div>