<div class="wrapper">
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title> {{sectionControl?.label}} </mat-panel-title>
            <mat-panel-description *ngIf="sectionControl?.value">
                {{sectionControl?.value}}
            </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-content></ng-content>
    </mat-expansion-panel>
</div>