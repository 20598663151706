<div [id]="inputId" class="recommendation-container">
    <div class="recommendation-label" *ngIf="label">
        {{label | translate}}
    </div>
    <div class="recommendation-value-container">
        <div class="recommendation-value-wrapper">
            <div class="recommendation-values" *ngFor="let item of recommendationValues" 
            [ngStyle]="{'background': item.showBackground ? item.color : '', 'color': item.showBackground ? 'var(--xa-white)' : ''}" 
            (click)="onSelect(item)" (mouseover)='onMouseOver(item)' (mouseout)='onMouseOut()'>
                <div class="value">
                    {{item.value}}
                </div>
            </div>
        </div>
    </div>
</div>
