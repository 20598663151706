<div class="loading" *ngIf="isPDFLoading ">
    <div class="overlay"></div>
    <div class="spinner">
        <mat-spinner [strokeWidth]="5" [diameter]="50"></mat-spinner>
    </div>
</div>
<div class="fraud-detection-container fx-col">
    <div class="fraud-detection-header fx-row justify-space-between">
        <div class="header-1 fx-row">
            <div class="header fx-col">
                <div>
                    {{'fraud_report' | translate }}
                </div>
                <div>
                    {{'claim' | translate }} #{{ claimDetails?.claimNo }}
                </div>
            </div>
            <div class="header fx-col">
                <div>
                    {{'date' | translate }}
                </div>
                <div>
                    {{ claimDetails?.customerDetails?.claimIntimationDate | date: 'dd-MM-yyyy' }}
                </div>
            </div>
            <div class="header fx-col">
                <div>
                    <xa-button [type]="'outline'" (click)="downloadPdf()" class="btn-download">
                        <mat-icon [svgIcon]="'download-arrow'" [color]="'white'"></mat-icon>
                        <span> {{'download_detection_report' | translate }}</span>
                    </xa-button>
                </div>
            </div>
        </div>
        <div class="header-2 fx-row">
            <div>
                <mat-icon [svgIcon]="'cross'" (click)="onClose()"></mat-icon>
            </div>
        </div>
    </div>
    <div class="fraud-detection-body">
        <div class="claimant-details-wrapper">
            <div class="claimant-details-header fx-row">
                <div class="claimant-details-header-text">
                    {{'claimant_details' | translate }}
                </div>
                <div class="fraud-status">
                    <div class="status">{{ this.claimDetails?.fraudStatus }}</div>
                </div>
            </div>
            <div class="claimant-details-body fx-row">
                <div class="claimant-details fx-col">
                    <div class="fx-row">
                        <div class="claimant-property">{{ 'claimant_name' | translate }}</div>
                        <div class="claimant-value-wrapper">
                            <div class="claimant-value">{{ claimDetails?.customerDetails?.policyHolderFirstName }}
                                {{claimDetails?.customerDetails?.policyHolderLastName }}</div>
                            <div class="empty"></div>
                        </div>
                    </div>
                    <div class="fx-row">
                        <div class="claimant-property">{{ 'date_of_notification' | translate }}</div>
                        <div class="claimant-value-wrapper">
                            <div class="claimant-value">
                                {{ claimDetails?.customerDetails?.claimIntimationDate | date: 'dd-MM-yyyy' }}
                            </div>
                            <div class="empty"></div>
                        </div>
                    </div>
                    <div class="fx-row">
                        <div class="claimant-property">{{ 'loss_date' | translate }}</div>
                        <div class="claimant-value-wrapper">
                            <div class="claimant-value">
                                {{ claimDetails?.lossDetails?.lossDate | date: 'dd-MM-yyyy' }}
                            </div>
                            <div class="empty"></div>
                        </div>
                    </div>
                    <div class="fx-row">
                        <div class="claimant-property">{{ 'place_of_loss' | translate }}</div>
                        <div class="claimant-value-wrapper">
                            <div class="claimant-value">
                                {{ claimDetails?.lossDetails?.lossLocation }}
                            </div>
                            <div class="empty"></div>
                        </div>

                    </div>
                    <div class="fx-row">
                        <div class="claimant-property">{{ 'claim_status' | translate }}</div>
                        <div class="claimant-value-wrapper">
                            <div class="claimant-value">{{ claimStatus}}</div>
                            <div class="empty"></div>
                        </div>
                    </div>
                </div>
                <div class="claimant-details fx-col">
                    <div class="fx-row">
                        <div class="claimant-property">{{ 'policy_number' | translate }}</div>
                        <div class="claimant-value-wrapper">
                            <div class="claimant-value">{{ claimDetails?.customerDetails?.policyNo }}</div>
                            <div class="empty"></div>
                        </div>
                    </div>
                    <div class="fx-row">
                        <div class="claimant-property">{{ 'address' | translate }}</div>
                        <div class="claimant-value-wrapper">
                            <div class="claimant-value">{{ claimDetails?.customerDetails?.policyHolderAddress1 }}</div>
                            <div class="empty"></div>
                        </div>
                    </div>
                    <div class="fx-row">
                        <div class="claimant-property">{{ 'birth_date' | translate }}</div>
                        <div class="claimant-value-wrapper">
                            <div class="claimant-value">{{ claimDetails?.customerDetails?.dateOfBirth | date: 'dd-MM-yyyy' }}</div>
                            <div class="empty"></div>
                        </div>
                    </div>
                    <div class="fx-row">
                        <div class="claimant-property">{{ 'phone_number' | translate }}</div>
                        <div class="claimant-value-wrapper">
                            <div class="claimant-value">{{ claimDetails?.customerDetails?.policyMobileCountryCode }} {{
                                claimDetails?.customerDetails?.policyHolderMobileNumber }}</div>
                            <div class="empty"></div>
                        </div>
                    </div>
                    <div class="fx-row">
                        <div class="claimant-property">{{ 'email' | translate }}</div>
                        <div class="claimant-value-wrapper">
                            <div class="claimant-value">{{ claimDetails?.customerDetails?.policyHolderEmail }}</div>
                            <div class="empty"></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="other-details fx-row">
            <div class="vehicle-reasons">
                <div class="vehicle-details-wrapper">
                    <div class="vehicle-details-header fx-row">
                        <div>
                            {{ 'vehicle_detail' | translate }}
                        </div>
                    </div>
                    <div class="vehicle-details-body fx-row">
                        <div class="vehicle-details fx-col vehicle-details-border">
                            <div class="fx-row">
                                <div class="vehicle-property">{{ 'make' | translate }}</div>
                                <div class="vehicle-value-wrapper">
                                    <div class="vehicle-value">{{ claimDetails?.vehicleMetaData?.make }}</div>
                                    <div class="empty"></div>
                                </div>
                            </div>
                            <div class="fx-row">
                                <div class="vehicle-property">{{ 'model' | translate }}</div>
                                <div class="vehicle-value-wrapper">
                                    <div class="vehicle-value">{{ claimDetails?.vehicleMetaData?.model }}</div>
                                    <div class="empty"></div>
                                </div>
                            </div>
                        </div>
                        <div class="vehicle-details fx-col pl-24 vehicle-details-right">
                            <div class="fx-row">
                                <div class="vehicle-property">{{ 'plate_reg_number' | translate }}</div>
                                <div class="vehicle-value-wrapper">
                                    <div class="vehicle-value">{{ claimDetails?.vehicleMetaData?.plateNo }}</div>
                                    <div class="empty"></div>
                                </div>
                            </div>
                            <div class="fx-row">
                                <div class="vehicle-property">{{ 'chassis_vin' | translate }}</div>
                                <div class="vehicle-value-wrapper">
                                    <div class="vehicle-value">{{ claimDetails?.vehicleMetaData?.vinNum }}</div>
                                    <div class="empty"></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="reason-details">
                    <div class="reason-details-header fx-row">
                        <div>
                            {{ 'fraud_suspicion_reasons_identified' | translate }}
                        </div>
                    </div>
                    <div class="reason-details-body fx-col">
                        <ul>
                            <li *ngFor="let item of fraudDetectionReasons">
                                {{ item }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="detection-details">
                <div class="detection-details-wrapper">
                    <div class="detection-details-header fx-row">
                        <div class="fx-row justify-space-between warning">
                            <mat-icon [svgIcon]="'warning'" [color]="'white'"></mat-icon>
                            <span>{{ 'detection_observed' | translate }}</span>
                        </div>
                    </div>
                    <div class="detection-details-body fx-col">
                        <div class="history fx-col">
                            <div class="history-header">
                                {{ 'history' | translate }}
                            </div>
                            <div class="history-body" *ngFor="let item of this.claimHistory">
                                {{ item }}
                            </div>
                        </div>
                        <div class="car-angle-wrapper">

                            <div class="car-angle fx-col" *ngFor="let item of inspectionFraudDetails">
                                <div class="car-angle-header">
                                    {{ item.angleName }}
                                </div>
                                <div class="car-angle-body">
                                    {{ 'body_angle' | translate }}
                                </div>
                                <div class="car-fraud-status-wrapper">
                                    <div class="car-fraud-status fx-row">
                                        <div class="status" *ngFor="let item1 of item.fraudDetections">
                                            {{ item1 }}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="document-intelligence">
                            <div class="fraud-reasons fx-col" *ngFor="let item of fraudReasons">
                                <div class="fraud-reason-header">
                                    {{ item.AngleName }}
                                </div>
                                <div class="document-fraud-status-wrapper">
                                    <div class="document-fraud-status fx-row">
                                        <div class="status" *ngFor="let item1 of item.FraudDetections">
                                            {{ item1 }}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="pdf-report d-none">
    <div class="fraud-detection-container fx-col" #elementRef>
        <div class="fraud-detection-header fx-row justify-space-between">
            <div class="header-pdf fx-row justify-space-between">
                <div class="header fx-col">
                    <div>
                        {{'fraud_report' | translate }}
                    </div>
                    <div>
                        {{'claim' | translate }} #{{ claimDetails?.claimNo }}
                    </div>
                </div>
                <div class="header fx-col">
                    <div>
                        {{'date' | translate }}
                    </div>
                    <div>
                        {{ claimDetails?.customerDetails?.claimIntimationDate | date: 'dd-MM-yyyy' }}
                    </div>
                </div>
            </div>
        </div>
        <div class="fraud-detection-body">
            <div class="claimant-details-wrapper">
                <div class="claimant-details-header fx-row">
                    <div class="claimant-details-header-text">
                        {{'claimant_details' | translate }}
                    </div>
                    <div class="fraud-status">
                        <div class="status">{{ this.claimDetails?.fraudStatus }}</div>
                    </div>
                </div>
                <div class="claimant-details-body fx-row">
                    <div class="claimant-details fx-col">
                        <div class="fx-row">
                            <div class="claimant-property">{{ 'claimant_name' | translate }}</div>
                            <div class="claimant-value-wrapper">
                                <div class="claimant-value">{{ claimDetails?.customerDetails?.policyHolderFirstName }}
                                    {{claimDetails?.customerDetails?.policyHolderLastName }}</div>
                                <div class="empty"></div>
                            </div>
                        </div>
                        <div class="fx-row">
                            <div class="claimant-property">{{ 'date_of_notification' | translate }}</div>
                            <div class="claimant-value-wrapper">
                                <div class="claimant-value">
                                    {{ claimDetails?.customerDetails?.claimIntimationDate | date: 'dd-MM-yyyy' }}
                                </div>
                                <div class="empty"></div>
                            </div>
                        </div>
                        <div class="fx-row">
                            <div class="claimant-property">{{ 'loss_date' | translate }}Loss Date</div>
                            <div class="claimant-value-wrapper">
                                <div class="claimant-value">
                                    {{ claimDetails?.lossDetails?.lossDate | date: 'dd-MM-yyyy' }}
                                </div>
                                <div class="empty"></div>
                            </div>
                        </div>
                        <div class="fx-row">
                            <div class="claimant-property">{{ 'place_of_loss' | translate }}</div>
                            <div class="claimant-value-wrapper">
                                <div class="claimant-value">
                                    {{ claimDetails?.lossDetails?.lossLocation }}
                                </div>
                                <div class="empty"></div>
                            </div>

                        </div>
                        <div class="fx-row">
                            <div class="claimant-property">{{ 'claim_status' | translate }}</div>
                            <div class="claimant-value-wrapper">
                                <div class="claimant-value">{{ claimStatus }}</div>
                                <div class="empty"></div>
                            </div>
                        </div>
                    </div>
                    <div class="claimant-details fx-col">
                        <div class="fx-row">
                            <div class="claimant-property">{{ 'policy_number' | translate }}</div>
                            <div class="claimant-value-wrapper">
                                <div class="claimant-value">{{ claimDetails?.customerDetails?.policyNo }}</div>
                                <div class="empty"></div>
                            </div>
                        </div>
                        <div class="fx-row">
                            <div class="claimant-property">{{ 'address' | translate }}</div>
                            <div class="claimant-value-wrapper">
                                <div class="claimant-value">{{ claimDetails?.customerDetails?.policyHolderAddress1 }}
                                </div>
                                <div class="empty"></div>
                            </div>
                        </div>
                        <div class="fx-row">
                            <div class="claimant-property">{{ 'birth_date' | translate }}</div>
                            <div class="claimant-value-wrapper">
                                <div class="claimant-value">{{ claimDetails?.customerDetails?.dateOfBirth | date: 'dd-MM-yyyy' }}</div>
                                <div class="empty"></div>
                            </div>
                        </div>
                        <div class="fx-row">
                            <div class="claimant-property">{{ 'phone_number' | translate }}</div>
                            <div class="claimant-value-wrapper">
                                <div class="claimant-value">{{ claimDetails?.customerDetails?.policyMobileCountryCode }}
                                    {{ claimDetails?.customerDetails?.policyHolderMobileNumber }}</div>
                                <div class="empty"></div>
                            </div>
                        </div>
                        <div class="fx-row">
                            <div class="claimant-property">{{ 'email' | translate }}</div>
                            <div class="claimant-value-wrapper">
                                <div class="claimant-value">{{ claimDetails?.customerDetails?.policyHolderEmail }}</div>
                                <div class="empty"></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="other-details fx-col">
                <div class="vehicle-reasons fx-col" style="width:100%">
                    <div class="vehicle-details-wrapper">
                        <div class="vehicle-details-header fx-row">
                            <div>
                                {{ 'vehicle_detail' | translate }}
                            </div>
                        </div>
                        <div class="vehicle-details-body fx-row">
                            <div class="vehicle-details fx-col">
                                <div class="fx-row">
                                    <div class="vehicle-property">{{ 'make' | translate }}</div>
                                    <div class="vehicle-value-wrapper">
                                        <div class="vehicle-value">{{ claimDetails?.vehicleMetaData?.make }}</div>
                                        <div class="empty"></div>
                                    </div>
                                </div>
                                <div class="fx-row">
                                    <div class="vehicle-property">{{ 'model' | translate }}</div>
                                    <div class="vehicle-value-wrapper">
                                        <div class="vehicle-value">{{ claimDetails?.vehicleMetaData?.model }}</div>
                                        <div class="empty"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="vehicle-details fx-col vehicle-details-right">
                                <div class="fx-row">
                                    <div class="vehicle-property">{{ 'plate_reg_number' | translate }}</div>
                                    <div class="vehicle-value-wrapper">
                                        <div class="vehicle-value">{{ claimDetails?.vehicleMetaData?.plateNo }}</div>
                                        <div class="empty"></div>
                                    </div>
                                </div>
                                <div class="fx-row">
                                    <div class="vehicle-property">{{ 'chassis_vin' | translate }}</div>
                                    <div class="vehicle-value-wrapper">
                                        <div class="vehicle-value">{{ claimDetails?.vehicleMetaData?.vinNum }}</div>
                                        <div class="empty"></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="reason-details" >
                        <div class="reason-details-header fx-row">
                            <div>
                                {{ 'fraud_suspicion_reasons_identified' | translate }}
                            </div>
                        </div>
                        <div class="reason-details-body fx-col">
                            <ul>
                                <li *ngFor="let item of fraudDetectionReasons">
                                    {{ item }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container id="container">
                <div class="empty-margin-block"></div> 
                <div class="detection-details  fx-col" [ngClass]="fraudDetectionReasons?.length <=2 ? 'detection-details-pdf': ''">
                    <div class="detection-details-wrapper" >
                        <div class="detection-details-header fx-row">
                            <div class="fx-row justify-space-between warning">
                                <mat-icon [svgIcon]="'warning'" [color]="'white'"></mat-icon>
                                <span>{{ 'detection_observed' | translate }}</span>
                            </div>
                        </div>
                        <div class="detection-details-body fx-col">
                            <div class="history fx-col">
                                <div class="history-header">
                                    {{ 'history' | translate }}
                                </div>
                                <div class="history-body" *ngFor="let item of this.claimHistory">
                                    {{ item }}
                                </div>
                            </div>
                            <div class="car-angle-wrapper fx-row justify-space-between flex-wrap-pdf">
                                <div class="car-angle fx-col" *ngFor="let item of inspectionFraudDetails">
                                    <div class="car-angle-header">
                                        {{ item.angleName }}
                                    </div>
                                    <div class="car-angle-body">
                                        {{ 'body_angle' | translate }}
                                    </div>
                                    <div class="car-fraud-status-wrapper">
                                        <div class="car-fraud-status fx-row">
                                            <div class="status" *ngFor="let item1 of item.fraudDetections">
                                                {{ item1 }}
                                            </div>
                                        </div>
    
                                    </div>
                                </div>
                            </div>
                            <div class="document-intellingence">
                                <div class="car-angle fx-col" *ngFor="let item of fraudReasons">
                                    <div class="car-angle-header">
                                        {{ item.Key }}
                                    </div>
                                    <div class="history-body" >
                                        {{ item.Values }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

        </div>
    </div>
</div>