import { Component, Input } from '@angular/core';
import { DynamicFormBase } from 'src/app/config/core/dynamic-form/dynamic-form-base';
import { CommonService } from 'src/app/services/common/common.service';


@Component({
  selector: 'xa-section',
  templateUrl: './xa-section.component.html',
  styleUrls: [ './xa-section.component.scss' ]
})
export class XaSection {

  @Input() sectionControl:DynamicFormBase<any>
  /**
   * constructor
   */
  constructor(
    private commonService: CommonService,
  ) {
  }
}

