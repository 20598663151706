import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: [ './logout.component.scss' ]
})
export class LogoutComponent implements OnInit {

  /**
   * constructor
   * @param authService 
   */
  constructor(private authService: MsalService, private monitorService:MonitorService) {
  }

  /**
   * on init
   */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'LogoutComponent', 'addenda-quote' ]);
    this.authService.logoutRedirect({
      // If in iframe, dont perform redirect to AAD
      onRedirectNavigate: () => {
        return !BrowserUtils.isInIframe();
      }
    });
  }

}