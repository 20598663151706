<div class="button-wrapper">
    <div class="button-toggle">
        <div (click)="triggerMenuItemClick(menu);" *ngFor="let menu of menuItems" class="menu-wrapper"
            [ngClass]="{'selected': menu.active}">
            <div class="menu-block" [style.background]="menu.color">
                <span class="head">{{menu.displayName |number:'1.0-0' }}%</span> <span class="label-name">{{
                    menu.name}}</span>
            </div>
        </div>
    </div>
</div>