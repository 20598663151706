import { Platform } from '@angular/cdk/platform';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { ChqLabourRateComponent } from '../labour-rate/labour-rate.component';
import { Modules } from 'src/app/model/chq-upload-model';


@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: [ './toast.component.scss' ]
})
export class ToastComponent {
  message: string = '';
  action: string = '';
  showInfo: boolean = false;
  private caseId: string = '';
  private objectId: string = '';
  private from: string = '';
  labourData: any;

  /**
   * constructor
   * @param data 
   * @param platform 
   */
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
    public platform: Platform,
    private monitorService: MonitorService,
    private dialog: MatDialog,
    private commonService: CommonService) {
    this.message = data['message'];
    if (this.message?.indexOf('show-info') != -1) {
      this.message = this.message?.substring(this.message.indexOf('|') + 1);
      this.showInfo = true;
    } else if (data['action']) {
      this.from = data['action'].from;
      this.objectId = data['action'].objectId;
      this.action = data['action'].link;
      this.labourData = data['action'].labourData;
    }
  }

  /**
   * dismiss
   */
  dismiss(): void {
    this.data.preClose();
  }

  /**
  * open labour rate popup
   */
  openLabourRatePopup(): void {
    this.monitorService.logEvent('openLabourRatePopup', [ 'ToastComponent', 'addenda-quote' ]);

    const dialogRef = this.dialog.open(ChqLabourRateComponent, {

      data: (this.from == Modules.repair) ? { 'repairId': this.objectId, 'from': this.from } : this.objectId,
      autoFocus: false,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        this.commonService.emitFromDialogComponent(result);
      }
    });
  }
}
