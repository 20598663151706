export interface menuItem {
  name: string;
  displayName?: string;
  icon?: string;
  iconActive?: string;
  url?: string;
  image?: string;
  active?: boolean;
  title?: string;
  subTitle?: string;
  index?: number;
  childMenuLinks?: string[];
  subMenuItems?: menuItem[];
  defaultChildMenu?: string;
  childMenu?: menuItem[];
  parent?: string;
  automotiveService?: string | string[];
  permission?: boolean;
  color?: string;
  permissionName?: string;
  disabled?: boolean;
  service?: string;
  userLevelPermission?: string[]
}

export interface partialUrlsMatchItem {
  partialUrl: string;
  title?: string;
  subTitle?: string;
  icon?: string;
  currentParent?: string;
  currentChild?: string;
}

export const menuItems: menuItem[] = [
  {
    name: 'home',
    icon: 'home',
    url: '/home',
    defaultChildMenu: 'dashboard',
    title: 'dashboard',
    subTitle: 'home',
    automotiveService: 'Addenda Repair',
    childMenu: [ {
      name: 'dashboard',
      parent: 'home',
      url: '/home/dashboard/general',
      title: 'dashboard',
      subTitle: 'home',
      permission: true,
      childMenuLinks: [ '/home/dashboard/general', '/home/dashboard/production-value', '/home/dashboard/sales-&-operations' ]
    } ]
  },
  {
    name: 'repair',
    icon: 'repair',
    url: '/repair',
    defaultChildMenu: 'booking_journal',
    title: 'booking_journal',
    subTitle: 'repair',
    permission: true,
    automotiveService: 'Addenda Repair',
    childMenu: [
      {
        name: 'booking_journal',
        parent: 'repair',
        url: '/repair/booking-journal',
        title: 'booking_journal',
        subTitle: 'repair',
        permissionName: 'booking.journal',
        permission: true
      },
      {
        name: 'workflow',
        parent: 'repair',
        url: '/repair/workflow',
        title: 'workflow',
        subTitle: 'repair',
        permissionName: 'workflow',
        permission: true
      },
      {
        name: 'outbound',
        parent: 'repair',
        url: '/repair/outbound',
        title: 'outbound',
        subTitle: 'repair',
        permissionName: 'outbound',
        permission: true
      },
      {
        name: 'customers',
        parent: 'repair',
        url: '/repair/customers/company',
        title: 'customer_list',
        subTitle: 'repair',
        permissionName: 'customers',
        permission: true
      },
      {
        name: 'outbound_lounge',
        parent: 'repair',
        url: '/repair/lounge',
        title: 'outbound_lounge',
        subTitle: 'repair',
        permissionName: 'outbound.lounge',
        permission: true
      },
      {
        name: 'workflow_lounge',
        parent: 'repair',
        url: '/repair/workflow-lounge',
        title: 'workflow_lounge',
        subTitle: 'repair',
        permissionName: 'workflow.lounge',
        permission: true
      },
      {
        name: 'technician_calendar',
        parent: 'repair',
        url: '/repair/technician-calendar',
        title: 'technician_calendar',
        subTitle: 'repair',
        permissionName: 'technician.calendar',
        permission: true
      }
    ]
  },
  {
    name: 'claim',
    icon: 'claim',
    url: '/claim',
    defaultChildMenu: 'claim_dashboard',
    title: 'claim_dashboard',
    subTitle: 'claim',
    permission: true,
    automotiveService: 'Addenda Claims',
    service: 'My Claims',
    userLevelPermission: [ 'claim.dashboard', 'claim.listing' ],
    childMenu: [
      {
        name: 'claim_dashboard',
        parent: 'claim',
        url: '/claim',
        title: 'claim_dashboard',
        subTitle: 'claim',
        permissionName: 'dashboard',
        service: 'My Claims',
        userLevelPermission: [ 'claim.dashboard' ],
        permission: true
      },
      {
        name: 'my_claim',
        parent: 'claim',
        url: '/claim/my-claim',
        title: 'my_claim',
        subTitle: 'claim',
        permissionName: 'claim-list',
        service: 'My Claims',
        userLevelPermission: [ 'claim.listing' ],
        permission: true
      },
      {
        name: 'workflow',
        parent: 'claim',
        url: '/claim/workflow',
        title: 'workflow',
        subTitle: 'claim',
        permissionName: 'workflow',
        service: 'My Claims',
        userLevelPermission: [ 'workflow.listing' ],
        permission: true
      },
      {
        name: 'outbound',
        parent: 'claim',
        url: '/claim/outbound',
        title: 'outbound',
        subTitle: 'claim',
        permissionName: 'outbound',
        service: 'My Claims',
        userLevelPermission: [ 'outbound.listing' ],
        permission: true
      },
      {
        name: 'garages',
        parent: 'claim',
        url: '/claim/garages',
        title: 'garages',
        subTitle: 'claim',
        permissionName: 'generalgarage.listing',
        service: 'General Garage Listing',
        userLevelPermission: [ 'claim.listing' ],
        permission: true
      }
    ]
  },
  {
    name: 'repairer_view',
    icon: 'repair',
    url: '/repairer-view/dashboard/general',
    defaultChildMenu: 'repair_dashboard',
    title: 'repair_dashboard',
    subTitle: 'repairer_view',
    permission: true,
    automotiveService: 'Addenda Claims', //TODO Change Automotive Service
    service: 'Repair View',
    userLevelPermission: [ 'repairer.dashboard' ],
    childMenu: [
      {
        name: 'repair_dashboard',
        parent: 'repairer_view',
        url: '/repairer-view/dashboard/general',
        title: 'repair_dashboard',
        subTitle: 'repairer_view',
        permissionName: 'dashboard', //TODO Change permission
        service: 'Repair View',
        userLevelPermission: [ 'repairer.dashboard' ],
        permission: true
      },
      {
        name: 'workflow',
        parent: 'repairer_view',
        url: '/repairer-view/workflow',
        title: 'workflow',
        subTitle: 'repairer_view',
        permissionName: 'workflow', //TODO Change permission
        service: 'Repair View',
        userLevelPermission: [ 'workflow.listing' ],
        permission: true
      },
      {
        name: 'outbound',
        parent: 'repairer_view',
        url: '/repairer-view/outbound',
        title: 'outbound',
        subTitle: 'repairer_view',
        permissionName: 'outbound', //TODO Change permission
        service: 'Repair View',
        userLevelPermission: [ 'outbound.listing' ],
        permission: true
      },
    ]
  },
  {
    name: 'quote',
    icon: 'quote',
    url: '/quote',
    defaultChildMenu: 'all_cases',
    title: 'all_cases',
    subTitle: 'quote',
    permission: true,
    automotiveService: 'Carheal Quote',
    childMenu: [
      {
        name: 'all_cases',
        parent: 'quote',
        url: '/quote',
        title: 'all_cases',
        subTitle: 'quote',
        permissionName: 'case.listing',
        permission: true
      },
      {
        name: 'add_new_case',
        parent: 'quote',
        url: '/quote/case/0',
        title: 'add_new_case',
        subTitle: 'quote',
        permissionName: 'case.create',
        permission: true
      }
    ]
  },
  {
    name: 'FNOL',
    icon: 'claim',
    url: '/fnol',
    defaultChildMenu: 'file_claim',
    title: 'submit_claim',
    subTitle: 'FNOL',
    permission: true,
    automotiveService: 'Fnol',
    childMenu: [
      {
        name: 'file_claim',
        parent: 'FNOL',
        url: '/fnol',
        title: 'file_claim',
        subTitle: 'FNOL',
        permissionName: 'FNOL',
        automotiveService: 'Fnol',
        permission: true
      },
      {
        name: 'claim_transactions',
        parent: 'FNOL',
        url: '/fnol/claim-list',
        title: 'claim_transactions',
        subTitle: 'FNOL',
        permissionName: 'claim.listing',
        automotiveService: 'Fnol',
        permission: true
      }
    ]
  },
  {
    name: 'settings',
    icon: 'settings',
    url: '/settings',
    defaultChildMenu: 'operations',
    title: 'operation_settings',
    subTitle: 'settings',
    permission: true,
    automotiveService: [ 'Addenda Repair', 'Addenda Claims' ],
    childMenu: [
      {
        name: 'operations',
        parent: 'settings',
        url: '/settings',
        title: 'operation_settings',
        subTitle: 'settings',
        automotiveService: 'Addenda Repair',
        permission: true
      },
      {
        name: 'employees',
        parent: 'settings',
        url: '/settings/employees',
        title: 'employee_settings',
        subTitle: 'settings',
        automotiveService: 'Addenda Repair',
        permission: true
      }, {
        name: 'teams',
        parent: 'settings',
        url: '/settings/teams',
        title: 'teams',
        subTitle: 'settings',
        automotiveService: 'Addenda Repair',
        permission: true
      },
      {
        name: 'partners',
        parent: 'settings',
        url: '/settings/partners',
        title: 'partners',
        subTitle: 'settings',
        automotiveService: 'Addenda Repair',
        permission: true
      },
      {
        name: 'garages',
        parent: 'settings',
        url: '/claimSetting',
        title: 'garages_settings',
        subTitle: 'settings',
        permissionName: 'claim-Settings',
        automotiveService: 'Addenda Claims',
        permission: true
      },
    ]
  },
]

export const repairMenus: menuItem[] = [
  {
    name: 'Booking',
    displayName: 'booking',
    icon: '',
    url: 'Booking', // '/repair/booking/{bookingId}',
    title: '',
    subTitle: ''
  },
  {
    name: 'Repair',
    displayName: 'repair',
    icon: '',
    url: 'Repair', //'/repair/booking/{bookingId}',
    title: '',
    subTitle: '',
    disabled: false,
    childMenuLinks: [ 'parts', 'invoices', 'repair-stages', 'invoicesEdit', ]
  }
];

export const claimListMenus: menuItem[] = [
  {
    name: 'all_claims',
    displayName: 'all_claims',
    icon: '',
    url: 'allclaims', // '/repair/booking/{bookingId}',
    title: '',
    subTitle: ''
  },
  {
    name: 'assigned',
    displayName: 'assigned',
    icon: '',
    url: 'assigned', // '/repair/booking/{bookingId}',
    title: '',
    subTitle: ''
  },
];

export const menuRepairItems: menuItem[] = [
  {
    name: 'Parts',
    displayName: 'parts',
    icon: '',
    url: '/repair/booking/{bookingId}/parts',
    title: '',
    subTitle: '',
    permissionName: 'parts'
  },
  {
    name: 'Repair Section',
    displayName: 'repair_stage',
    icon: '',
    url: '/repair/booking/{bookingId}/repair-stages',
    //defaultChildMenu: 'Documents',
    title: '',
    subTitle: '',
    permissionName: 'repairStages.create'
  },
  {
    name: 'Invoices',
    displayName: 'invoices',
    icon: '',
    url: '/repair/booking/{bookingId}/invoices',
    //defaultChildMenu: 'Documents',
    title: '',
    subTitle: '',
    permissionName: 'invoice',
    childMenuLinks: [ 'invoicesEdit' ]
  },
];

export const customerMenus: menuItem[] = [
  {
    name: 'Personal Info',
    displayName: 'customer_details',
    icon: '',
    url: '/repair/booking/{bookingId}',
    title: '',
    subTitle: '',
    permissionName: 'customer.detail'
  },
  {
    name: 'Upload Documents',
    displayName: 'documents',
    icon: '',
    url: '/repair/booking/{bookingId}/document',
    defaultChildMenu: 'Documents',
    title: '',
    subTitle: '',
    permissionName: 'documents'
  },
  {
    name: 'Upload Images',
    displayName: 'photos',
    icon: '',
    url: '/repair/booking/{bookingId}/photos',
    defaultChildMenu: 'Documents',
    title: '',
    subTitle: '',
    permissionName: 'photos'
  },
  {
    name: 'Estimate',
    displayName: 'estimate',
    icon: '',
    url: '/repair/booking/{bookingId}/estimate',
    defaultChildMenu: 'Documents',
    title: '',
    subTitle: '',
    permissionName: 'estimate.create',
    childMenuLinks: [ 'addenda-estimate' ]
  }
];

export const waterfallMenus: menuItem[] = [
  {
    name: 'Workflow',
    icon: '',
    url: '/repair/workflow',
    title: '',
    subTitle: ''
  },
  {
    name: 'Outbound',
    icon: '',
    url: '/repair/outbound',
    title: '',
    subTitle: ''
  }
];

export const operationsMenus: menuItem[] = [
  {
    name: 'booking_type',
    icon: '',
    url: '/settings',
    title: '',
    subTitle: ''
  },
  {
    name: 'repair_type',
    icon: '',
    url: '/settings/repair-type',
    defaultChildMenu: 'settings',
    title: 'operation_settings',
    subTitle: 'Settings / Operations',
    disabled: false
  },

  {
    name: 'jobs_tasks',
    icon: '',
    url: '/settings/jobs-tasks',
    defaultChildMenu: 'settings',
    title: '',
    subTitle: '',
    disabled: true
  },
  {
    name: 'scheduling',
    icon: '',
    url: '/settings/scheduling',
    defaultChildMenu: 'settings',
    title: '',
    subTitle: '',
    disabled: false
  },
  {
    name: 'calendar_workload_settings',
    icon: '',
    url: '/settings/calendar',
    defaultChildMenu: 'settings',
    title: '',
    subTitle: '',
    disabled: false
  },
  {
    name: 'target_settings',
    icon: '',
    url: '/settings/target',
    defaultChildMenu: 'settings',
    title: '',
    subTitle: '',
    disabled: false
  },
  {
    name: 'permission_settings',
    icon: '',
    url: '/settings/permission',
    defaultChildMenu: 'settings',
    title: '',
    subTitle: '',
    disabled: false
  }
];

export const estimateOperationsMenus: menuItem[] = [
  {
    name: 'create-parts-operations',
    displayName: 'create_parts_operations',
    url: 'create-parts-operations',
  },
  {
    name: 'operation-list',
    displayName: 'operation_list',
    url: 'operation-list',
  }
];

export const customerListMenus: menuItem[] = [
  {
    name: 'company',
    icon: '',
    url: '/repair/customers/company',
    title: '',
    subTitle: ''
  },
  {
    name: 'individual',
    icon: '',
    url: '/repair/customers/individual',
    title: '',
    subTitle: ''
  }
];

export const customerDetailsMenus: menuItem[] = [
  {
    name: 'repair_orders',
    icon: '',
    url: '/repair/customers/{userType}/{customerId}/{id}/repair-order',
    title: '',
    subTitle: ''
  },
  {
    name: 'estimates',
    icon: '',
    url: '/repair/customers/{userType}/{customerId}/{id}/estimates',
    title: '',
    subTitle: ''
  }
];

export const partialUrlsMatch: partialUrlsMatchItem[] = [
  {
    partialUrl: '/settings/employees/edit',
    title: 'employee_settings',
    subTitle: 'Settings / Employees',
    icon: 'settings',
    currentParent: 'settings',
    currentChild: 'employees'
  },
  {
    partialUrl: '/fnol/claim-detail/',
    title: 'claim_details',
    subTitle: 'fnol_Employees',
    currentParent: 'FNOL',
    currentChild: 'claim_transactions'
  }
];

export const claimMenuItems: menuItem[] = [
  {
    name: 'Customer Details',
    displayName: 'Customer Details',
    icon: '',
    url: '/claim/claim-detail/{claimId}/customer-view',
    title: '',
    subTitle: ''
  },
  {
    name: 'Loss Details',
    displayName: 'Loss Details',
    icon: '',
    url: '/claim/claim-detail/{claimId}/loss-detail',
    title: '',
    subTitle: ''
  },
  {
    name: 'Documents',
    displayName: 'Documents',
    icon: '',
    url: '/claim/claim-detail/{claimId}/document',
    defaultChildMenu: 'Documents',
    title: '',
    subTitle: '',
  },
  {
    name: 'Images',
    displayName: 'Images',
    icon: '',
    url: '/claim/claim-detail/{claimId}/photos',
    defaultChildMenu: 'Images',
    title: '',
    subTitle: ''
  },
  {
    name: 'Garages',
    displayName: 'garages',
    icon: '',
    url: '/claim/claim-detail/{claimId}/garages',
    defaultChildMenu: 'Garages',
    title: '',
    subTitle: '',
    childMenuLinks: [ 'garage-details' ]
  }
];

export const fnolClaimDetailMenuItems: menuItem[] = [
  {
    name: 'Customer Details',
    displayName: 'Customer Details',
    icon: '',
    url: '/fnol/claim-detail/{claimId}/customer-view',
    title: '',
    subTitle: ''
  },
  {
    name: 'Loss Details',
    displayName: 'Loss Details',
    icon: '',
    url: '/fnol/claim-detail/{claimId}/loss-detail',
    title: '',
    subTitle: ''
  },
  {
    name: 'Documents',
    displayName: 'Documents',
    icon: '',
    url: '/fnol/claim-detail/{claimId}/document',
    defaultChildMenu: 'Documents',
    title: '',
    subTitle: '',
  },
  {
    name: 'Images',
    displayName: 'Images',
    icon: '',
    url: '/fnol/claim-detail/{claimId}/photos',
    defaultChildMenu: 'Images',
    title: '',
    subTitle: ''
  }
];

export enum ServiceType {
  Part = 'Part',
  Paint = 'Paint',
  Labour = 'Labour'
}

export const garagesMenus: menuItem[] = [
  {
    name: 'All',
    displayName: 'All',
    url: 'all',
  },
  {
    name: 'Multi-brand',
    displayName: 'Multibrand Workshops',
    url: 'Multi-brand',
  },
  {
    name: 'OEM Dealer',
    displayName: 'OEM Dealer Workshops',
    url: 'OEM-Dealer',
  }
];

export const claimSettingsMenus: menuItem[] = [
  {
    name: 'garages',
    displayName: 'garages',
    url: 'claimSetting',
  },
  {
    name: 'fraud_detection',
    displayName: 'fraud_detection',
    url: 'claimSetting/fraud-detection',
    // title: 'operation_settings',
    // subTitle: 'settings / garages',
  }
];

export const fnolMenus: menuItem[] = [
  {
    name: 'Documents',
    displayName: 'documents',
    title: 'add_documents',
    subTitle: 'FNOL'
  },
  {
    name: 'Vehicle',
    displayName: 'vehicle',
    title: 'add_vehicle',
    subTitle: 'FNOL'
  },
  {
    name: 'Photos',
    displayName: 'photos',
    title: 'add_photos',
    subTitle: 'FNOL'
  },
  {
    name: 'Submit',
    displayName: 'submit',
    title: 'submit_claim',
    subTitle: 'FNOL',
    disabled: true
  }
]

export const customerSurveyorMenus: menuItem[] = [
  {
    name: 'customer_images',
    displayName: 'customer_images',
    url: 'customer'
  },
  {
    name: 'surveyor_images',
    displayName: 'surveyor_images',
    url: 'surveyor',
    disabled: true
  }
]

