<div class="delivery-service-container">

    <div class="header">
        <h3>{{'claim_details' | translate}}</h3>
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'" (click)="cancelDialog(false);"></mat-icon>
        </div>
    </div>

    <div class="content">
        <div class="row input-row">
            <div class="col-12">
                <chq-input [inputModel]="formViewModel.CustomerDetails.OrganizationName" class="disable-formedit"></chq-input>
            </div>
        </div>
        <div class="row input-row">
            <div class="col-6">
                <chq-input [inputModel]="formViewModel.VehicleMetaData.Make" class="disable-formedit"></chq-input>
            </div>
            <div class="col-6">
                <chq-input [inputModel]="formViewModel.VehicleMetaData.Model" class="disable-formedit"></chq-input>
            </div>
        </div>
        <div class="row input-row">
            <div class="col-12">
                <chq-input [inputModel]="formViewModel.CustomerDetails.FullName" class="disable-formedit"></chq-input>
            </div>
        </div>
        <div class="row input-row">
            <div class="col-3">
                <chq-input [inputModel]="formViewModel.CustomerDetails.CountryCode" class="disable-formedit"></chq-input>
            </div>
            <div class="col-9">
                <chq-input [inputModel]="formViewModel.CustomerDetails.MobileNumber" class="disable-formedit"></chq-input>
            </div>
        </div>
        <div class="row input-row">
            <div class="col-12">
                <chq-input [inputModel]="formViewModel.CustomerDetails.PolicyNo" class="disable-formedit"></chq-input>
            </div>
        </div>
        <div class="row input-row">
            <div class="col-12">
                <chq-input [inputModel]="formViewModel.ClaimNo" class="disable-formedit"></chq-input>
            </div>
        </div>
        <div class="row input-row">
            <div class="col-12 d-flex" style="justify-content: center;gap: 10px">
                <chq-button [buttonModel]="rejectButtonModel" class="col-6" [hidden]="approveRejectCompleted"></chq-button>
                <chq-button [buttonModel]="approveButtonModel" class="col-6" [hidden]="approveRejectCompleted"></chq-button>
                <chq-button [buttonModel]="repairButtonModel" class="col-6" [hidden]="showApprovedOrRejected !== 'Approved'"></chq-button>
                <span class="text-danger" [hidden]="showApprovedOrRejected !== 'Rejected'">Status : Rejected</span>
                <span class="text-danger" [hidden]="showApprovedOrRejected !== 'Reassigned'">Status : Rejected</span>
            </div>
        </div>
    </div>

    
</div>