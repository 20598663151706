import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AddVehicleData, UpdateVehicleCaseData } from 'src/app/model/chq-new-case-form.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  private apiUrl: string = 'vehicles';

  // eslint-disable-next-line require-jsdoc
  constructor(private http: HttpClient) {
  }

  /**
   * 
   * @param yearId 
   * @param makeId 
   * @returns 
   */
  getVehicleModel(makeId:number, estimateId?: number): Observable<any> {
    return this.http.get(`${environment.autoApi}/api/v1/VehicleMakes/VehicleModels/${makeId}/${estimateId}?$orderBy=modelDescription asc`);
  }

  /**
   * 
   * @returns 
   */
  getVehicleYearlist(): Observable<any> {
    return this.http.get(`${environment.autoApi}/api/v1/Vehicles/Lookups/ModelYearList/`);
  }

  /**
   * new api for vehicle year
  */
  getVehicleYearByModel(modelId: number, estimateId?: number): Observable<any> {
    //https://apim-dih-dev01.azure-api.net/AutoAPI/api/v{version}/VehicleMakes/VehicleModelYears/{modelId}
    return this.http.get(`${environment.autoApi}/api/v1/VehicleMakes/VehicleModelYears/${modelId}/${estimateId}`);
  }

  /**
   * 
   * @param id 
   * @returns 
   */
  getVehicleMake(): Observable<any> {
    return this.http.get(`${environment.autoApi}/api/v1/VehicleMakes`);
  }

  /**
   * getVehicleList
   * @returns 
   */
  getVehicleList(): Observable<any> {
    return this.http.get(`${environment.autoCoreApi}/odata/VehicleMake`);
  }

  /**
   * get vin detail from gt motive
   * @param vin 
   */
  getVinDecoder(vin: string): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Vehicles/vehicleDetailsByVIN/${vin}`;
    return this.http.get(url);
  }

  /**
   * 
   * @param vehicleFormData -- old api
   * @returns 
   */
  createVehicle(vehicleFormData: AddVehicleData): Observable<any> {
    const url = `${environment.autoApi}/api/v1/${this.apiUrl}/AddVehicle`;
    return this.http.post(url, vehicleFormData);
  }

  /**
   * new api for adding vehicle
   * addvehicleinspection removed from backend
   */
  addVehicle(vehicleFormData: AddVehicleData, domainID: number, caseId:number): Observable<any> {
    const headers = new HttpHeaders()
      .set('domainID', domainID.toString())
      .set('objectId', caseId.toString())
    //  //https://apim-dih-dev01.azure-api.net/AutoAPI/api/v1/Vehicles/addvehicleinspection
    const url = `${environment.autoApi}/api/v1/${this.apiUrl}/addvehicleinspection`;
    return this.http.post(url, vehicleFormData, { headers: headers });
  }
  
  /**
   * Update case with vehicle details
   * @param vehicleUpdateCaseData 
   * @returns 
   */
  updateVehicleCase(vehicleUpdateCaseData: any, caseId: string): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/cases/${caseId}/vehicle`;
    return this.http.put(url, vehicleUpdateCaseData);
  }

  /**
   * Update repair with vehicle details and tariff
   * @param vehicleUpdateCaseData 
   * @param caseId 
   * @returns 
   */
  updateVehicleRepair(vehicleUpdateCaseData: any, repairId: string): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/repair/${repairId}/vehicle`;
    return this.http.put(url, vehicleUpdateCaseData);
  }
}
