import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common/common.service';

@Directive({
  selector: '[chqClaimRoles]'
})
export class ClaimRolesDirective {
  private noPermissionRoles = {
    claimMaker: [ 'claim.submitted' ],
    claimChecker: [ 'claim.submitforapproval' ],
    claimManager: [ 'claim.selfapproval' ],
    claimList: [ 'claim.submitted', 'claim.submitforapproval', 'claim.selfapproval' ],
    claimEditor: [ 'claim.submitted', 'claim.submitforapproval', 'claim.selfapproval' ],
    claimMissingInformation: [ 'claim.requestformissingdocument' ],
    claimGarageAssign: [ 'claim.garageassignment' ],
    claimDashboard: [ 'claim.dashboard' ],
    claimAssignBack: [ 'claim.assignback' ],
    claimHistory: [ 'claim.submitforapproval', 'claim.selfapproval' ],
    claimViewEstimate: [ 'estimate.view' ],
    claimViewInvoice: [ 'invoice.view' ],
    claimRequestEstimate: [ 'estimate.request' ],
    claimRequestInvoice: [ 'invoice.request' ],
    claimSurveyor: [ 'claim.surveyor.assign' ],
    claimGarageNavigate: [ 'claim.submitforapproval', 'claim.selfapproval' ],
    claimWhatsappMessageView: [ 'claim.submitforapproval', 'claim.selfapproval' ]
  }

  /**
   *
   */
  @Input('chqClaimRoles') set hasClaim(permissionKey: string) {
    this.commonService.userProfileData.subscribe((res) => {
      this.viewContainer.clear();
      if (res?.data) {
        const automotiveServices = res?.data?.userPermission?.automotiveServices;
        const automotiveService = automotiveServices?.find(x => x.automotiveServiceName.toLowerCase() === 'addenda claims');

        const permissionList: any = automotiveService?.permissions;

        if (automotiveService && permissionList && permissionList?.length > 0) {
          const anyPermission: boolean = this.noPermissionRoles[permissionKey]?.some(itemP => permissionList.map(item => item.permissionName).includes(itemP));

          if (anyPermission) {
            this.viewContainer.createEmbeddedView(this.templateRef);
          } else {
            this.viewContainer.clear();
          }
        } else {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      }
    });
  }

  /**
   *
   * @param templateRef
   * @param viewContainer
   * @param authService
   */
  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private commonService: CommonService,
    private router: Router
  ) { }

}
