import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChqWidgetsInputModel } from 'src/app/model/chq-widgets-input-model';
import { CommonService } from 'src/app/services/common/common.service';
import { CommentDialogComponent } from '../comment-dialog/comment-dialog.component';

@Component({
  selector: 'app-estimate-notes',
  templateUrl: './estimate-notes.component.html',
  styleUrls: [ './estimate-notes.component.scss' ]
})
export class EstimateNotesComponent {

  /**
   * constructor
   * @param dialogRef
   * @param data
   * @param changeDedectionRef
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CommentDialogComponent>,
    public commonService:CommonService

  ) {}
  public notesModel:ChqWidgetsInputModel = {
    placeHolder: 'Notes',
    label: 'Notes',
    name: 'notes',
    value: '',
    type: 'textarea',
    maxLength: 100
  };

  /**
   * close
   */
  close():void{
    this.dialogRef.close();
  }
}
