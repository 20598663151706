import { Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { ChqWidgetsInputModel, IControlValue } from 'src/app/model/chq-widgets-input-model';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'app-reject-claim-dialog',
  templateUrl: './reject-claim-dialog.component.html',
  styleUrls: [ './reject-claim-dialog.component.scss' ]
})
export class RejectClaimDialogComponent {
  public sendButton: ChqWidgetsButtonModel;
  isUpdate: boolean = false;
  public claimGuid:string = '';

  public messageModel: ChqWidgetsInputModel = {
    placeHolder: 'message',
    label: 'message',
    name: 'message',
    value: '',
    type: 'textarea',
    maxLength: 100
  };
  public reasonModel: ChqWidgetsInputModel = {
    placeHolder: 'select_dot',
    label: 'select_the_reason',
    name: 'select_the_reason',
    value: 0,
    type: 'select',
    displayValue: 'name',
    idField: 'reason',
    fieldValue: 'id',
    options: [],
    errorWhenEmpty: true
  };

  /**
   * constructor
   * @param dialogRef 
   * @param data 
   * @param changeDedectionRef 
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RejectClaimDialogComponent>,
    private monitorService: MonitorService,
    public commonService: CommonService,
    private claimService: ClaimsService,
    private translateService: TranslateService,
  ) {
  }

  /**
   * handle close
   */
  continue(): void {
    this.monitorService.logEvent('continue', [ 'RejectClaimDialogComponent', 'addenda-claim' ]);
    //this.dialogRef.close({ 'comment': this.messageModel.value });

    const dialogRef = this.commonService.openConfirmationOkDialog(this.translateService.instant('confirm_action'), this.translateService.instant('msg_reject_claim'));
    dialogRef.afterClosed().subscribe((action: boolean) => {
      if (action) {
        this.commonService.showLoading();
        this.claimService.updateClaimStatus(this.claimGuid, 'Rejected', this.reasonModel.selectedOption?.name).subscribe({
          next: (response) => {
            if(response) {
              this.dialogRef.close({ 'comment': this.messageModel.value });
            }
            this.commonService.hideLoading();
          },
          error: () => {
            this.commonService.hideLoading();
          }
        });
      } 
    });
  }

  /**
   * on form update
   * @param output o
   */
  onFormUpdate(output: IControlValue): void {

    if (output.type === 'select') {
      this.reasonModel = {
        ...this.reasonModel, selectedOption: output.value
      };
    } else {
      this.messageModel = {
        ...this.messageModel, value: output.value
      }
    }

    if(this.checkForFormValidation()) {
      this.sendButton.type = 'primary';
    } else {
      this.sendButton.type = 'disabled';
    }
  }

  /**
   * check for validation
   */
  checkForFormValidation(): boolean {
    if(!this.reasonModel.selectedOption || (this.reasonModel.selectedOption && this.reasonModel.selectedOption == 'Select')) {
      return false;
    }
    return true;
  }

  /**
   * close
   */
  close(): void {
    this.dialogRef.close();
  }


  /**
   * ng on init
   */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'RejectClaimDialogComponent', 'addenda-repair' ]);
    this.sendButton = {
      label: 'send',
      type: 'disabled',
      onclick: this.continue.bind(this),
      icon: 'send'
    }

    this.claimGuid = this.data.claimGuid;

    this.getRejectionList();
  }

  /**
   * get rejection detail 
   */
  getRejectionList(): void {
    this.commonService.showLoading();
    this.claimService.getLookup('CLMReason').subscribe((response: any) => {
      if (response) {
        console.log(response);
        this.reasonModel = {
          ...this.reasonModel, options: response.data
        }
        
      } 
      this.commonService.hideLoading();
    })
  }
}
