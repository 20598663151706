<div class="page-container  mh-0 mt-0 fx-col">
    <div class="main-container">
        <div class="no-data-content fx-col align-center">
            <img src="assets/img/no-data.png" alt="">
            <span>{{'contact_to_admin' | translate}}</span>
        </div>
    </div>
    <div class="footer-full-wrapper">
        <div class="footer-image-wrapper">
            <div class="image-wrapper">
                <img src="assets/img/addenda.png" alt=""/>
            </div>
        </div>
    </div>
</div>