import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sum'
})
export class SumPipe implements PipeTransform {

  maxPoint = 100000000;

  /**
     * 
     * @param items 
     * @param attr 
     * @returns 
     */
  transform(items: any[], attr: string): any {
    const total = items.reduce((a, b) => (a + this.parseFloatingPoint(b[attr])), 0)
    return total/this.maxPoint;
  }

  /**
   * handle floating point
   */
  parseFloatingPoint(g:any):number{
    if(typeof g === 'string'){
      g = parseFloat(g);
    }
    return parseFloat(g) * this.maxPoint;
  }
}