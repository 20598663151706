interface actions {
  title?: string;
  icon?: string;
  prop?: string;
  action: (params: any) => void
}

interface keyValue {
  displayValue: string;
  keyValue: string;
}

export interface SearchSectionModel {
  title: string;
  icon: string;
  keyValue: keyValue[];
  data: any[],
  count: number,
  actions?: actions[],
  searchKey?: string
}

export interface SearchFilterParams {
  searchKey: string;
  sectionName?: string;
  pageSize: number;
  pageNumber: number
}

const iconMapper = {
  'customers': 'customer',
  'vehicles': 'vehicle',
  'quotes': 'quote',
  'repairs': 'repair',
  'claims': 'claim'
}

export const rowProperties = {
  'customers': [ { displayValue: 'Name', keyValue: 'name' }, { displayValue: 'Phone Number', keyValue: 'phoneNumber' },
    { displayValue: 'Email', keyValue: 'email' } ],
  'vehicles': [ { displayValue: 'Make', keyValue: 'make' }, { displayValue: 'Model', keyValue: 'model' },
    { 'displayValue': 'VIN', 'keyValue': 'vin' }, { displayValue: 'Plate', keyValue: 'plate' },
    { displayValue: 'Name', keyValue: 'name' }, { displayValue: 'Phone Number', keyValue: 'phoneNumber' },
    { displayValue: 'Email', keyValue: 'email' } ],
  'quotes': [ { displayValue: 'ID', keyValue: 'id' }, { displayValue: 'Customer Name', keyValue: 'name' },
    { displayValue: 'Phone Number', keyValue: 'phoneNumber' },
    { displayValue: 'Email', keyValue: 'email' }, { displayValue: 'Make', keyValue: 'make' },
    { displayValue: 'Model', keyValue: 'model' }, { 'displayValue': 'VIN', 'keyValue': 'vin' },
    { 'displayValue': 'Date', 'keyValue': 'date' }, { 'displayValue': 'Status', 'keyValue': 'status' } ],
  'repairs': [ { displayValue: 'ID', keyValue: 'repairNumber' }, { displayValue: 'Customer Name', keyValue: 'name' },
    { displayValue: 'Phone Number', keyValue: 'phoneNumber' },
    { displayValue: 'Email', keyValue: 'email' }, { displayValue: 'Make', keyValue: 'make' },
    { displayValue: 'Model', keyValue: 'model' }, { 'displayValue': 'VIN', 'keyValue': 'vin' },
    { 'displayValue': 'Date', 'keyValue': 'date' }, { 'displayValue': 'Status', 'keyValue': 'status' } ],
  'claims': [ { displayValue: 'ID', keyValue: 'claimNo' }, { displayValue: 'Insurer Name', keyValue: 'customerName' },
    { displayValue: 'Phone Number', keyValue: 'phone' },
    { displayValue: 'Email', keyValue: 'email' }, { displayValue: 'Make', keyValue: 'vehicleMake' },
    { displayValue: 'Model', keyValue: 'vehicleModel' }, { displayValue: 'VIN', keyValue: 'vinNum' },
    { displayValue: 'Policy Type', keyValue: 'policyType' },
    { displayValue: 'Policy Number', keyValue: 'policyNo' },
    { displayValue: 'Police Reference Number', keyValue: 'policeReferenceNumber' }, { displayValue: 'Status', keyValue: 'status' }
  ]
}

export const actions = {
  'customers': [ { icon: 'calendar', }, { icon: 'calculate' } ],
  'vehicles': [ { icon: 'calendar', }, { icon: 'calculate' } ],
  'quotes': [ { icon: 'calendar', }, { icon: 'view' } ],
  'repairs': [ { icon: 'view' } ],
  'claims': [ { icon: 'view' } ]
}

export const caseToSearchSectionMapper = (searchResult: any, searchKey: string, isExternal?: boolean): SearchSectionModel[] => {
  const searchModelList: SearchSectionModel[] = [];
  let filteredAction: any = JSON.parse(JSON.stringify(actions));
  if (searchResult) {
    const properties = Object.keys(searchResult);
    if (isExternal) {
      filteredAction = {
        'quotes': [ { icon: 'view' } ],
        'repairs': [ { icon: 'view' } ]
      }
    }
    for (const k in properties) {
      const property = properties[k];
      const searchModel: SearchSectionModel = {
        title: property,
        count: searchResult[property]?.totalCount || 0,
        icon: iconMapper[property],
        keyValue: rowProperties[property],
        data: searchResult[property]?.items || [],
        actions: filteredAction[property] || [],
        searchKey
      };
      searchModelList.push(searchModel);
    }

  }

  return searchModelList;
}

export const checkIfCountExceeds = (searchResult: any): boolean => {
  if (searchResult) {
    const properties = Object.keys(searchResult);
    for (const k in properties) {
      const property = properties[k];
      if (searchResult[property]?.totalCount > 3) {
        return true;
      }
    }
  }
  return false;
}