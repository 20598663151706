import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trim'
})
export class TrimPipe implements PipeTransform {

  /**
   * trim whitespace
   * @param value 
   * @returns 
   */
  transform(value: string): string {
    if(value) {
      return value.trim();
    }
    return '';
  }

}
