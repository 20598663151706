import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { Thread } from 'src/app/widgets/chat/models/thread';


@Component({
  selector: 'chat',
  templateUrl: './chat.component.html',
  styleUrls: [ './chat.component.scss' ]
})
export class ChatComponent implements OnInit, OnChanges {
  //Input properties
  @Input() ConversationGuid: string;
  @Input() isGeneralCommunication: boolean = false;
  @Input() thread: Thread;

  //Chat output events
  @Output() closeNav: EventEmitter<any> = new EventEmitter();

  //Component internal properties
  public currentThread: Thread;
  public chatList: Array<Thread>;
  public selectedRepair: string;
  isItemSelected: boolean = false;
  chatId = null

  /**
   * constructor
   * @param dialogRef 
   * @param data 
   * @param changeDedectionRef 
   */
  constructor(
    private monitorService: MonitorService,
    public commonService: CommonService

  ) {
  }

  /**
   * ng on init
   */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'CommentDialogComponent', 'addenda-repair' ]);
    if (this.thread) {
      this.currentThread = this.thread;
      this.selectedRepair = 'discussion_via_whatsapp';
    } else {
      this.currentThread = null;
    }
    this.chatList = [ Thread.make('a-thread', 'john t', [ 'other-user' ], '') ];
  }

  /**
   * onchanges
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['thread']) {
      this.currentThread = this.thread;
    }
  }

  /**
   * display Thread
   */
  displayThread($event: Thread): void {
    this.currentThread = $event;
  }

  /**
   * 
   */
  close(): void {
    this.closeNav.emit(this.isItemSelected);
  }

  /**
   * 
   */
  goBack(): void {
    this.currentThread = null;
    this.selectedRepair = '';
  }

  /**
   * 
   */
  itemSelected({ thread, hasUnreadCount, chatId }): void {
    if(hasUnreadCount){
      this.isItemSelected = true;
    }
    this.chatList = null;
    this.currentThread = thread;
    this.selectedRepair = thread.name || thread.phoneNumber;
    this.chatId = chatId
  }
}
