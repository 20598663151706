import { HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, firstValueFrom } from 'rxjs';
import { RegisteredGarageRejectClaimComponent } from 'src/app/dialogs/registered-garage-reject-claim/registered-garage-reject-claim.component';
import { ChqWidgetsInputModel, IControlValue } from 'src/app/model/chq-widgets-input-model';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { ThirdPartyCommonLayoutComponent } from '../common-layout/common-layout.component';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import * as dayjs from 'dayjs';
interface KeyValueType<T> {
  [k: string]: T;
}
export interface RegisteredGarageInvoiceForm extends KeyValueType<any> {
  invoiceDate: ChqWidgetsInputModel,
  insuranceCompanyAmount: ChqWidgetsInputModel,
  excessPayableCustomer: ChqWidgetsInputModel
  serviceAdvisorName : ChqWidgetsInputModel
  notes: ChqWidgetsInputModel,
  totalInvoiceAmount: ChqWidgetsInputModel,
}

@Component({
  selector: 'app-registered-garage-request-invoice-action',
  templateUrl: './registered-garage-request-invoice-action.component.html',
  styleUrls: [ './registered-garage-request-invoice-action.component.scss' ]
})
export class RegisteredGarageRequestInvoiceActionComponent {
  @ViewChild(ThirdPartyCommonLayoutComponent) commonLayout: ThirdPartyCommonLayoutComponent;
  private token: string = ''; 
  public claimGuid: string = '';
  public claimDetails: any = {};
  public formModel?: RegisteredGarageInvoiceForm
  public panelOpenState: boolean = false
  public rejectNotes: string = ''
  public currency: string = ''
  public isSubmissionSuccess: boolean = false;
  subscriptions: Subscription[] = [];
  public viewRepairDetails: ChqWidgetsButtonModel = {
    label: 'view_repair_details',
    type: 'outline',
    icon: 'report-check',
    onclick: this.handleViewRepairDetails.bind(this)
  }
  public submitButton: ChqWidgetsButtonModel = {
    label: 'submit',
    type: 'disabled',
    onclick: this.handleFormSubmit.bind(this)
  }
  public uploadAttachmentButtonModel: ChqWidgetsButtonModel = {
    label: 'upload_attachment',
    type: 'outline',
    icon: 'attachment',
  }
  public uploader: ElementRef;
  public file: any[] = [];
  /**
    Sets the content of the uploader element.
    @param {ElementRef} content - The content of the uploader element.
  */
  @ViewChild('uploader') set content(content: ElementRef) {
    if(content) { // initially setter gets called with undefined
      this.uploader = content;
    }
  }
  /**
   * constructor
   */
  constructor(
    private monitorService: MonitorService,
    public dialog: MatDialog,
    private commonService: CommonService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private claimsService: ClaimsService,
  ) { 
    this.token = (this.route.snapshot?.queryParams) ? this.route.snapshot.queryParams['token'] : '';
    if(this.token != '') {
      const obj:any = atob(this.token);
      const parsedObj = JSON.parse(obj);
      this.claimGuid = parsedObj.ClaimGuid; 
    }
  }
  /**
  * on init 
  */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'RegisteredGarageRequestInvoiceActionComponent', 'addenda-claim' ]); 
    const status = localStorage.getItem(this.token);
    if(status === 'submitted'){
      this.isSubmissionSuccess = true;
      return
    }
    this.formModel = this.initForm();

    const claimDetails$ = this.commonService.thirdPartyClaimDetails.subscribe({
      next: (response) =>{
        this.claimDetails = response;
        if(this.claimDetails?.customerDetails) {
          this.claimDetails.customerDetails.customerFullName = '';
          if(this.claimDetails.customerDetails.policyHolderFirstName) this.claimDetails.customerDetails.customerFullName = this.claimDetails.customerDetails.policyHolderFirstName
          if(this.claimDetails.customerDetails.policyHolderLastName) this.claimDetails.customerDetails.customerFullName += ' ' + this.claimDetails.customerDetails.policyHolderLastName
        }
        this.currency = response?.organizationDetail?.currencyName;
        this.formModel.excessPayableCustomer.prependText = this.currency
        this.formModel.insuranceCompanyAmount.prependText = this.currency
        this.formModel.totalInvoiceAmount.prependText = this.currency
      }
    })

    this.subscriptions.push(claimDetails$);
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this.monitorService.logEvent('ngOnDestroy', [ 'RegisteredGarageRequestInvoiceActionComponent', 'addenda-claim' ]);
    if (this.subscriptions) this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
  
  /**
  *initForm
  */
  initForm(): RegisteredGarageInvoiceForm {
    return {
      invoiceDate: {
        placeHolder: 'invoice_date',
        label: 'invoice_date',
        name: 'invoiceDate',
        type: 'datetime',
        value: dayjs().toDate(),
        required: true,
        maxDate: dayjs().toDate(),
        validation: {
          name: 'invoiceDate',
          validationFunction: (): ValidatorFn => {
            return Validators.compose([ Validators.required ])
          },
          validationMessage: (error: ValidationErrors): string => {
            if (error['required']) {
              return 'invoice_date_required'
            }
            return '';
          }
        }
      },
      serviceAdvisorName: {
        placeHolder: 'service_advisor_name',
        label: 'service_advisor_name',
        name: 'serviceAdvisorName',
        value: '',
        type: 'text',
        required: true,
        validation: {
          name: 'serviceAdvisorName',
          validationFunction: (): ValidatorFn => {
            return Validators.compose([ Validators.required, Validators.maxLength(200) ])
          },
          validationMessage: (error: ValidationErrors): string => {
            if (error['required']) {
              return 'service_advisor_name_required'
            }
            return '';
          }
        }
      },
      notes: {
        placeHolder: 'notes',
        label: 'notes',
        name: 'notes',
        value: '',
        type: 'text',
        required: true,
        validation: {
          name: 'notes',
          validationFunction: (): ValidatorFn => {
            return Validators.compose([ Validators.required, Validators.maxLength(200) ])
          },
          validationMessage: (error: ValidationErrors): string => {
            console.log('repairDescription error', error)
            if (error['required']) {
              return 'notes_are_required'
            }
            if (error['maxlength']) {
              return 'notes_max_length'
            }
            return '';
          }
        }
      },
      excessPayableCustomer: {
        placeHolder: 'excess_payable_customer',
        label: 'excess_payable_customer',
        name: 'excessPayableCustomer',
        value: 0,
        type: 'number',
        required: true,
        validation: {
          name: 'excessPayableCustomer',
          validationFunction: (): ValidatorFn => {
            return Validators.compose([ Validators.required, Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/) ])
          },
          validationMessage: (error: ValidationErrors): string => {
            if (error['required']) {
              return 'excess_payable_customer_required'
            }
            if (error['pattern']) {
              return 'amount_two_decimal_places'
            }
            return '';
          }
        }
      },
      insuranceCompanyAmount: {
        placeHolder: 'insurance_company_amount',
        label: 'insurance_company_amount',
        name: 'insuranceCompanyAmount',
        value: 0,
        type: 'number',
        required: true,
        validation: {
          name: 'insuranceCompanyAmount',
          validationFunction: (): ValidatorFn => {
            return Validators.compose([ Validators.required, Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/) ])
          },
          validationMessage: (error: ValidationErrors): string => {
            if (error['required']) {
              return 'excess_payable_customer_required'
            }
            if (error['pattern']) {
              return 'amount_two_decimal_places'
            }
            return '';
          }
        }
      },
      totalInvoiceAmount: {
        placeHolder: 'total_invoice_amount',
        label: 'total_invoice_amount',
        name: 'totalInvoiceAmount',
        value: 0,
        type: 'number',
        required: true,
        validation: {
          name: 'totalInvoiceAmount',
          validationFunction: (): ValidatorFn => {
            return Validators.compose([ Validators.required, Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/) ])
          },
          validationMessage: (error: ValidationErrors): string => {
            if (error['required']) {
              return 'total_invoice_amount_required'
            }
            if (error['pattern']) {
              return 'amount_two_decimal_places'
            }
            return '';
          }
        }
      }
      
    }
  }
  /**
   * on form update
   * @param output o
   */
  async onFormUpdate(output: IControlValue): Promise<void> {
    this.formModel[output.name].value = output.value;
    this.validateForm();
  }

  /**
  * validateForm
  */
  validateForm(): void {
    let buttonType: 'primary' |'disabled' = 'disabled'
    if(this.isFormValid()) {
      buttonType = 'primary'
    }
    this.submitButton.type = buttonType
  }
  
  /**
  * isFormValid
  */
  isFormValid(): boolean {
    let valid = false
    if(
      this.formModel?.invoiceDate?.value && this.formModel?.invoiceDate?.formControl?.valid
      && this.formModel?.insuranceCompanyAmount?.formControl?.value && this.formModel?.insuranceCompanyAmount?.formControl?.valid
      && this.formModel?.serviceAdvisorName?.formControl?.value && this.formModel?.serviceAdvisorName?.formControl?.valid
      && this.formModel?.excessPayableCustomer?.formControl?.value && this.formModel.excessPayableCustomer?.formControl?.valid
      && this.formModel?.notes?.formControl?.value && this.formModel.notes?.formControl?.valid
      && this.formModel?.totalInvoiceAmount?.formControl?.value && this.formModel.totalInvoiceAmount?.formControl?.valid
    ) {
      valid = true
    }
    return valid
  }

  /**
  Handles the click event for the "Add File" button.
  @param {Event} event - The click event.
  @returns {void}
  */
  handleAddFileClick(event:any): void {
    this.uploader.nativeElement.click()
  }

  /**
   * checks file size
   * @param blob 
   * @returns boolean
   */
  checkFileSize(img: Blob): boolean {
    this.monitorService.logEvent('checkFileSize', [ 'RegisteredGarageRequestInvoiceActionComponent', 'addenda-claim', {
      fileSize: img.size
    } ]);
    if (img.size > this.commonService.maxFileSize) {
      return false;
    }
    return true;
  }

  /**
   * checkx filename
   * @param filename 
   * @returns 
   */
  public isValidImageFile(filename: string): boolean {
    const regex: RegExp = new RegExp('^.*\\.[a-zA-Z]+$', 'gm');
    this.monitorService.logEvent('isValidImageFile', [ 'RegisteredGarageRequestInvoiceActionComponent', 'addenda-claim', {
      fileName: filename
    } ]);
    if (regex.test(filename)) {
      const extension = filename.split('.').pop();
      return this.commonService.allowFilesExtension.includes(extension?.toLowerCase());
    }
    return false;
  }
  
  /**
   * handle file upload
   * @param event 
  */
  handleUpload(event: any): void {
    const currentFile = event.target.files || event.srcElement.files;
    this.monitorService.logEvent('handleUpload', [ 'RegisteredGarageRequestInvoiceActionComponent', 'addenda-claim', {
      event
    } ]);
    if (currentFile !== null && currentFile !== '' && currentFile.length > 0) {
      if (!this.checkFileSize(currentFile[0])) {
        const message = this.translateService.instant('file_size_5mb_error_message');
        this.commonService.showToast(0, message);
        this.uploader.nativeElement.value = null;
        return;
      }
      if (!this.isValidImageFile(currentFile[0].name)) {
        const message = this.translateService.instant('upload_type_not_supported');
        this.commonService.showToast(0, message);
        this.uploader.nativeElement.value = null;
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(currentFile[0]);
      reader.onload = (_event: any): void => {
        this.file.push(currentFile[0]);
      }
    }
  }

  /**
    Removes a file from the component's state.
    @param {any} event - The event object that triggered the function.
    @returns {void}
  */
  handleFileRemove(index: any): void {
    this.commonService.openDeleteDialog(
      '', 
      this.translateService.instant('attachment_delete_confirmation'), 
      this.translateService.instant('delete'), 
      this.translateService.instant('cancel')
    )
      .afterClosed().subscribe((data) => {
        if (data) {
          this.monitorService.logEvent('handleFileRemove', [ 'RegisteredGarageRequestInvoiceActionComponent', 'addenda-claim' ]);
          this.file.splice(index, 1);
        }
      });
      
  }

  /**
   * handleViewRepairDetails
   */
  handleViewRepairDetails(): void { 
    this.monitorService.logEvent('handleViewRepairDetails', [ 'RegisteredGarageRequestInvoiceActionComponent', 'addenda-claim' ]);
    this.commonLayout.handleViewRepairDetails()
  }

  /**
     * handleViewRepairDetails
     */
  handleFormSubmit(): void { 
    this.monitorService.logEvent('handleFormSubmit', [ 'RegisteredGarageRequestInvoiceActionComponent', 'addenda-claim' ]);
    if(!this.isFormValid()) {
      return null
    }
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${this.commonLayout.idToken}` );
    this.commonService.showLoading();
    const date: any = this.formModel.invoiceDate.value || ''
    const formData = new FormData();
    formData.append('ClaimGuid', this.claimGuid );
    formData.append('TotalAmount', this.formModel.totalInvoiceAmount.value + '' );
    formData.append('CompanyAmount', this.formModel.insuranceCompanyAmount.value + '' );
    formData.append('InvoiceDate', dayjs(date).format('YYYY-MM-DD HH:mm:ss'));
    formData.append('CustomerAmount', this.formModel.excessPayableCustomer.value + '' );
    formData.append('Notes', this.formModel.notes.value + '' );
    formData.append('AssigneeName', this.formModel.serviceAdvisorName.value + '' );
    formData.append('Currency', this.currency );

    if(this.file && this.file.length > 0){
      this.file.forEach((fileObj) => {
        formData.append('Attachments', fileObj);
      });
    }
    firstValueFrom(this.claimsService.submitRegisteredGarageInvoice(formData, headers)).then((response) => {
      this.commonService.hideLoading();
      if (response) {
        this.isSubmissionSuccess = true;
        localStorage.setItem(this.token, 'submitted');
      }
    }).catch((err) => {
      this.commonService.hideLoading();
      this.monitorService.logException(err, SeverityLevel.Error);
    });
  }
}
