import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { XaTabComponent } from './xa-tab.component';

@Component({
  selector: 'xa-tabs',
  templateUrl: './xa-tabs.component.html',
  styleUrls: [ './xa-tabs.component.scss' ]
})
export class XaTabsComponent implements AfterContentInit {
  @Input() mode: string = 'default';
  @Input() tabPosition: string = 'top';
  @Input() hideContent: boolean = false;

  @Output() tabChanged: EventEmitter<any> = new EventEmitter();
  
  @ContentChildren(XaTabComponent) tabs: QueryList<XaTabComponent>;
  
  /**
     * 
     */
  ngAfterContentInit():void {
    // get all active tabs
    const activeTabs = this.tabs.filter(tab=>tab.active);
    
    // if there is no active tab set, activate the first
    if(activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }else{
      this.selectTab(activeTabs[0])
    }
  }
  
  /**
   * 
   * @param tab 
   */
  selectTab(tab: any): void{
    if(tab) {
      const prevTabTitle = this.tabs.toArray().find(tab => tab.active)?.tabTitle;
      const newTabTitle = tab.tabTitle;
    
      // deactivate all tabs
      this.tabs.toArray().forEach(tab => tab.active = false);
    
      // activate the tab the user has clicked on.
      tab.active = true;

      this.tabChanged.emit({ prevTabTitle: prevTabTitle, newTabTitle: newTabTitle });
    }
  }
}