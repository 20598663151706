
<div class="verify-otp" *ngIf="!isLinkExpired">
    <div class="page-wrapper">
    <div class="bg-image">
        <img src="assets/img/otp-screen-bg.png" class="bg" />
        <img src="assets/img/otp-screen-bg-sm.png" class="bg-sm" />
        <img src="assets/img/addenda-logo.png" class="logo" />
    </div>
    <div class="verify-otp-wrapper">
        <div class="content-wrapper">
            <div class="center-wrapper">
                <div class="btn-back">
                    <label class="header-title">{{"enter_otp_code" | translate }}</label>
                </div>
                <div class="wrapper">
                    <div class="container">
                        <div class="splash-desc" [ngClass]="{'info-label':isScroll === false}">
                            <label>
                                {{"otp_msg_email" | translate }}
                            </label>
                        </div>


                        <div class="number_block" *ngIf="selectedEmail">
                            <label>{{'your_email' | translate }}</label>
                            <div class="number-container">
                                <span class="mobile">{{selectedEmail}}</span>
                            </div>
                        </div>
                        <div class="input_block" [formGroup]="form">
                            <div>
                                <div style="margin-bottom: 8px;">
                                    <label class="lable_title">{{"enter_otp" | translate }}</label>
                                </div>
                                <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)"
                                    [config]="otpConfig"></ng-otp-input>
                                <div *ngIf="correct_otp ===3" class="wrong_otp">
                                    {{errorMessages}}
                                </div>
                                <div *ngIf="correct_otp === 2" class="correct_otp">
                                    {{'success!' | translate }}
                                </div>
                            </div>
                        </div>
                        <div class="btn-container">
                            <button mat-button mat-raised-button (click)="verifyOtp();">
                                {{'continue' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bottom_text" *ngIf="correct_otp !== 2">
                {{ 'otp_counter' | translate: {second: seconds} }} &nbsp;
                <span class="resend-sms" (click)="resend_otp()"
                    *ngIf="resend">{{'resend' | translate }}</span><span *ngIf="resend">&nbsp;({{resendCounter}})</span>
            </div>
        </div>
    </div>
    </div>
</div>
<div class="link-expired" *ngIf="isLinkExpired">
    <div class="link-expired-wrapper">
        <h3>Sorry!</h3>
        <label>This link has been expired.</label>
    </div>
</div>