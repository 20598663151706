<div class="image-slider">
    <div class="header">
        <h3>Damage</h3>
        <span>PHOTOS</span>
    </div>
    <div *ngIf="!sliderModel" class="image-fluid fade">
        <img src='assets/img/front-right.png' alt="Car"/>
    </div>
    <div class="image-fluid fade">
        <div class="image-wrapper" id="car-images">
            <div class="navigation prev-button" *ngIf="sliderModel?.length > 1">
                <mat-icon aria-hidden="false" aria-label="Example chevron left icon" fontIcon="chevron_left"
                    (click)="prevImageClick()"></mat-icon>
            </div>
            <div *ngFor="let image of sliderModel;let i = index" [ngClass]="{'d-none':currentActiveIndex !== i}">
                <img [src]="image" (load)="imageLoading[i] = true" alt="Image"/>
                <chq-custom-loader [showLoading]="!imageLoading[i]"></chq-custom-loader>
            </div>
            <div class="navigation next-button" *ngIf="sliderModel?.length > 1">
                <mat-icon aria-hidden="false" aria-label="Example chevron right icon" fontIcon="chevron_right"
                    (click)="nextImageClick()"></mat-icon>
            </div>
        </div>
    </div>
    <div class="selector-wrapper">
        <div class="image-selector" *ngFor="let image of sliderModel;let i = index" (click)="updateSelectedImage(i);">
            <mat-icon *ngIf="currentActiveIndex !== i" [svgIcon]="'indicator'" aria-hidden="false"
                aria-label="indicator icon"></mat-icon>
            <mat-icon *ngIf="currentActiveIndex === i" [svgIcon]="'indicatorBlack'" aria-hidden="false"
                aria-label="selected indicator icon"></mat-icon>
        </div>
    </div>

</div>