<div class="tabs-wrapper"
     [ngClass]="tabPosition"
     [attr.mode]="mode">
    <ul class="tabs"
        [ngClass]="tabPosition">
        <li *ngFor="let tab of tabs"
            class="tab-item"
            (click)="selectTab(tab)"
            [class.active]="tab.active"
            [ngClass]="{'disabled' : tab.disabled}">
            {{tab.tabTitle | translate}}
        </li>
    </ul>
    <div *ngIf="!hideContent" class="tab-content-wrapper">
        <ng-content></ng-content>
    </div>
</div>