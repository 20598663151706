import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FNOLService {

  /**
   * constructor
   * @param http 
   */
  constructor(
    private http: HttpClient
  ) {
  }

  /**
 * createFNOL
 * @returns Observable
 */
  public createFNOL(body: any): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/v2/FNOL`;
    const headers = new HttpHeaders({ 'version': 'V2' });
    return this.http.post(url, body, { headers });
  }

  /**
   * create vehicle
   */
  createVehicle(body:any):Observable<any>{
    const vehicleType = body.vehicleTypeId || '';
    const url = `${environment.autoApi}/api/v1/Vehicles/AddVehicle?bodyTypeId=${vehicleType}`;
    return this.http.post(url, body);
  }

  /**
   * update vehicle
   */
  updateVehicle(body:any):Observable<any>{
    const url = `${environment.autoApi}/api/v1/Vehicles/UpdateVehicle`;
    return this.http.put(url, body);
  }

  /**
 * delete vehicle
 */
  deleteVehicle(id:number):Observable<any>{
    const url = `${environment.autoApi}/api/v2/Vehicles/${id}`;
    const headers = new HttpHeaders({ 'version': 'V2' });
    return this.http.delete(url, { headers });
  }

  /**
   * map fnol inspection
   */
  mapFnolInspection(body:any):Observable<any>{
    const url = `${environment.fnolApiUrl}/api/v2/FNOL/vehicle-inspection`;
    const headers = new HttpHeaders({ 'version': 'V2' });
    return this.http.put(url, body, { headers });
  }

  /**
   * get vehicle list
   */
  getVehicleList():Observable<any>{
    const url = `${environment.autoApi}/api/v1/Vehicles/GetVehicleListByUser`;
    const headers = new HttpHeaders({ 'version': 'V2' });
    return this.http.get(url);
  }

  /**
   * get application feature
   */
  applicationFeature():Observable<any>{
    const url = `${environment.autoCoreApi}/api/application-feature`;
    const headers = new HttpHeaders({ 'applicationId': '1' });
    return this.http.get(url, { headers });
  }

  /**
   * inspection vehicle mapper
   */
  inspectionVehicleMapper(body:any):Observable<any>{
    const url = `${environment.autoApi}/api/v1/Inspection/InspectionVehicle`;
    const headers = new HttpHeaders({ 'inspectionId': body.inspectionId, 'vehicleId': body.vehicleId });
    return this.http.put(url, body, { headers });
  }

  /**
 * complete FNOL
 * @returns Observable
 */
  public completeFNOL(body: any): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/FNOL/Completed`;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = new HttpHeaders({ 'X-Application-Id': environment.platformConfiguration.ApplicationId });
    return this.http.put(url, body, { headers });
  }
  
  /**
   * get claim statuses
   */
  public getClaimStatuses():Observable<any>{
    const url = `${environment.fnolApiUrl}/api/lookup/FNOLClaimStatus`;
    return this.http.get(url);
  }
}
