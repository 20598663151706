import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JobSettingsService {
  private apiUrl: string = 'job';

  /**
     * constructor
     * @param http 
    */
  constructor(private http: HttpClient) { }

  /**
   * getJobs
   * @returns {Observable<any>}
   */
  getJobs(): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/${this.apiUrl}`;
    return this.http.get(url);
  }

  /**
 * get job by jobGuid
 */
  getJobsById(jobGuid: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/${this.apiUrl}/${jobGuid}`;
    return this.http.get(url);
  }

  /**
   * Add new Job
   * @returns Observable
   */
  public addNewJob(jobFormData: object): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/${this.apiUrl}`;
    return this.http.post(url, jobFormData);
  }

  /**
* delete job by jobGuid
*/
  deleteJobsById(jobGuid: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/${this.apiUrl}/${jobGuid}`;
    return this.http.delete(url);
  }

  /**
   Edits a repair operation in the case management system.
   @param {object} data - The data to be edited.
   @returns {Observable} - An Observable that emits the edited data.
 */
  editJob(data: object, jobGuid: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/${this.apiUrl}/${jobGuid}`;
    return this.http.put(url, data);
  }

  /**
  * Add new Task
  * @returns Observable
  */
  public addNewTask(taskFormData: object, jobGuid: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/${this.apiUrl}/${jobGuid}/task`;
    return this.http.post(url, taskFormData);
  }

  /**
   Edits a repair operation in the case management system.
   @param {object} data - The data to be edited.
   @returns {Observable} - An Observable that emits the edited data.
 */
  editTask(data: object, jobGuid: any, taskGuid: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/${this.apiUrl}/${jobGuid}/task/${taskGuid}`;
    return this.http.put(url, data);
  }

  /**
* delete task by id
*/
  deleteTaskById(jobGuid: any, taskGuid: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/${this.apiUrl}/${jobGuid}/task/${taskGuid}`;
    return this.http.delete(url);
  }

  /**
   * getJobTaskSetting
   * @returns 
   */
  getJobTaskSetting(): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/${this.apiUrl}/jobtasksetting`;
    return this.http.get(url, { observe: 'response' });
  }

  /**
   * 
   * @returns 
   */
  public postJobTaskSetting(repairWorkflowTypeId: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/${this.apiUrl}/jobtasksetting/${repairWorkflowTypeId}`;
    return this.http.post(url, null);
  }

}
