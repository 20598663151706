<div class="message-container">
  <div class="header">
      <h3>Notes</h3>
      <div class="close-icon">
          <mat-icon [svgIcon]="'cross'" (click)="close();"></mat-icon>
      </div>
  </div>
  <div class="content">
      <label>NOTES</label>
      <div class='notes'>
       <p>{{data.notes}}</p>
      </div>
  </div>
</div>
