import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {


  /**
   * constructor
   * @param http 
   */
  constructor(
    private http: HttpClient
  ) {
  }

  /**
   * get notify template
   * @returns 
   */
  public getNotifyTemplate(repairId, domainId, templateId): Observable<any> {
    // https://apim-dih-dev01.azure-api.net/xaaggregationapi-dev/repaircommunicationtemplate/e48f74ba-e44a-4add-9780-350caadbddfe/3100/1
    const url = `${environment.xaAggregationApi}/repaircommunicationtemplate/${repairId}/${domainId}/${templateId}`;
    return this.http.get(url);
  }

  /**
   * send notification
   * @returns 
   */
  public sendNotify(repairGuid, data): Observable<any> {
    const localTime = new Date().toString();
    const headers = new HttpHeaders().append('localtime', localTime)
    const url = `${environment.caseMgmtUrl}/api/message/${repairGuid}/send-message`;
    return this.http.post(url, data, { headers });
  }

}
