import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { CommonService, Localization } from 'src/app/services/common/common.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { FileService } from 'src/app/services/file/file.service';
import { ApiPagination, defaultPagination } from 'src/app/widgets/xa-paginator/xa-paginator.component';
import { CellClickedCallback, TableCellData, TableConfig, TableRowData } from 'src/app/widgets/xa-table/xa-table.component';

@Component({
  selector: 'dashboard-view-details',
  templateUrl: './dashboard-view-details.component.html',
  styleUrls: [ './dashboard-view-details.component.scss' ]
})
export class DashboardViewDetailsComponent implements OnInit{

  i18n: Localization;

  public tableConfig: TableConfig = { columns: [] };

  payload: any = {
    dashboardSubType: '',
    filters: []
  }
  dashboardName:string
  columnNames = []
  columnNamesObj = {}
  columnNamesConvertToLink = {}

  tableContent: any = []
  responseContent: any = []
  PageNumber: number = 1
  columnNamesToHide = []

  modalTitle = ''

  public exportButtonModel: ChqWidgetsButtonModel = {
    label: 'Export CSV',
    type: 'primary',
    icon: '',
    onclick: this.exportToExcel.bind(this)
  }
  pageChanged: boolean;
  MaxRecordCount: number = 100
  public pagination: ApiPagination = { ...defaultPagination, pageSize: this.MaxRecordCount };

  /**
   * Creates an instance of DashboardViewDetailsComponent.
   * @constructor
   * @param {*} data
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DashboardViewDetailsComponent>,
    private commonService: CommonService,
    private dashboardService: DashboardService,
    private fileService: FileService,
    private router: Router
  ){
    
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void{
    if(this.data){
      this.i18n = this.commonService.geti18nInfo();
      this.dashboardName = this.data.dashboardName;
      this.payload.dashboardSubType = this.data.dashboardSubType;
      this.modalTitle = this.commonService.insertSpaceBeforeCapitalLetters(this.data.dashboardSubType);
      this.payload.filters = this.data.filters;
      this.columnNames = Object.keys(this.data.columnNames);
      this.columnNamesObj = this.data.columnNames;
      this.columnNamesConvertToLink = this.data.columnNamesConvertToLink ?? {};
      this.columnNamesToHide = [];
      this.columnNames.forEach((item)=>{
        const key = item.split('_')[1]
        const isHide = item.split('_')[0].indexOf('hide') !== -1
        if(isHide) this.columnNamesToHide.push(key);
        const columnsConfig = {
          id: key,
          sortable: false,
          style: isHide ? 'display: none' : '',
          header: {
            text: this.commonService.insertSpaceBeforeCapitalLetters(this.data.columnNames[item]),
            tooltip: this.commonService.insertSpaceBeforeCapitalLetters(this.data.columnNames[item]),
            style: isHide ? 'display: none; max-width: 0' : ''
          },
          cellStyle: this.columnNamesConvertToLink[key] ? 'text-decoration: underline; color: var(--xa-primary);' : ( isHide ? 'display: none; max-width: 0': ''),
          selector: {
            field: key,
          },
          widget: {
            type: 'text',
            config: {
              style: this.columnNamesConvertToLink[key] ? 'text-decoration: underline; color: var(--xa-primary);' : '',
            }
          }
        }

        this.tableConfig.columns.push(columnsConfig);
      })

      this.getDashboardExport();
    }
  }

  /**
   * getDashboardExport
   * @param {*} data
   * @returns {value}
   */
  getDashboardExport(): void{
    this.commonService.showLoading();
    const payload = { ...this.payload, filters: [ ...this.payload.filters, { name: 'PageNumber', value: this.pagination.currentPage }, { name: 'IsExport', value: true }, { name: 'MaxRecordCount', value: this.MaxRecordCount } ] }
    this.dashboardService.getDashboardExport(this.dashboardName, payload ).subscribe({
      next: (res) => {
        this.commonService.hideLoading();
        if(res.success){
          this.pagination.count = res.data.data.numberOfPages * this.MaxRecordCount;
          this.pagination.maxPageButtons = res.data.data.numberOfPages;
          delete res.data.data.numberOfPages;
          this.tableContent = (Object.values(res.data.data))[0];
          this.pageChanged = true;
        }
      }
    })
  }

  /**
   * exportToExcel
   */
  exportToExcel(): void{
    const rowsData = [];
    this.tableContent.forEach((item) =>{
      const rowData = {};
      Object.keys(item).forEach((keys) => {
        if(this.columnNamesToHide.indexOf(keys) === -1) {
          rowData[keys] = item[keys];
        }
      })
      rowsData.push(rowData);
    })
    const headers = [];
    Object.keys(this.columnNamesObj).forEach((key) => {
      if(this.columnNamesToHide.indexOf(key.split('_')[1]) ===-1){
        headers.push(this.commonService.insertSpaceBeforeCapitalLetters(this.columnNamesObj[key]).toUpperCase())
      }
    })
    this.columnNamesToHide.forEach(item=> delete headers[item])

    this.fileService.exportToCsv(rowsData, `${this.commonService.insertSpaceBeforeCapitalLetters(this.payload.dashboardSubType)}.csv`, headers)
  }

  /**
   * navigate
   * @param {*} key
   * @param {*} value
   */
  navigate(key, row): void{
    // eslint-disable-next-line no-prototype-builtins
    if(!key || !row || !this.columnNamesConvertToLink.hasOwnProperty(key)) return;
    const idToValue = [ ...this.columnNamesConvertToLink[key].matchAll(/\[\[(.*?)\]\]/g) ][0][1];
    const link = this.columnNamesConvertToLink[key].replace(`[[${idToValue}]]`, row.filter(item=> item.id === idToValue)[0].result.value);
    this.router.navigate([]).then((result) => { window.open(link, '_blank'); });
    //this.cancelDialog();
    //this.router.navigate([ link ]);
  }

  /**
   * handleCellClick
   * @param {*} event
   */
  handleCellClick: CellClickedCallback = (row: TableRowData, column: Partial<TableCellData>, event: PointerEvent): void =>{
    const key = column.id;
    this.navigate(key, row)
  }

  /**
   * handleSortChange
   * @param {*} event
   */
  handleSortChange(sort: Sort): void{
    //console.log(sort, 'handleSortChange')
  }
  
  /**
   * close Modal
   */
  cancelDialog(): void {
    this.dialogRef.close();
  }


  /**
   * handlePaginationChange
   * @param {*} event
   */
  handlePaginationChange(event): void{
    this.pagination.currentPage = event.currentPage;
    this.getDashboardExport();
  }
}
