import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { ChqWidgetsInputModel } from 'src/app/model/chq-widgets-input-model';
import { AiAnalysisService } from 'src/app/services/ai-analysis/ai-analysis.service';
import { CommonService } from 'src/app/services/common/common.service';
import { ChqInputComponent } from 'src/app/widgets/chq-input/chq-input.component';


@Component({
  selector: 'ai-analysis-dialog',
  templateUrl: './ai-analysis-dialog.component.html',
  styleUrls: [ './ai-analysis-dialog.component.scss' ],

})
export class AiAnalysisDialogComponent {
  isLoading = true;
  @ViewChild('parts') parts: ChqInputComponent;
  @ViewChild('damage') damage: ChqInputComponent;
  @ViewChild('recommendation') recommendation: ChqInputComponent;
  /**
   * constructor
   */
  constructor(private aiService:AiAnalysisService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AiAnalysisDialogComponent>,
    private commonService: CommonService
  ) {
    this.editMode = data.editMode;
    if(!this.editMode) {
      this.sendButton.label = 'Add'
    }
    this.commonService.showLoading();
    forkJoin([ this.aiService.getParts(), this.aiService.getDamageType(), this.aiService.getrecommendation() ]).subscribe({
      next: (res) => {
        const [ parts, damageType, recommendations ] = res
        this.partsDropdown = { ...this.partsDropdown, options: parts };
        this.damageDropdown = { ...this.damageDropdown, options: damageType }
        this.recommendationDropdown = { ...this.recommendationDropdown, options: recommendations }

        this.partsDropdown = { ...this.partsDropdown, selectedOption: this.getIdByName(data.partName, 'part') }
        this.damageDropdown = { ...this.damageDropdown, selectedOption: this.getIdByName(data.damageName, 'damage') }
        this.recommendationDropdown =
        { ...this.recommendationDropdown, selectedOption: this.getIdByName(data.damageRecommendation, 'recommendation') }

        this.checkForFormValidation()
      },
      complete: ()=> {
        this.isLoading = false;
        this.commonService.hideLoading();
      }
    })
  }
  editMode = true
  public sendButton: ChqWidgetsButtonModel = {
    label: 'Save',
    type: 'primary',
    onclick: ()=> {
      this.saveModified()
    }
  };

  /**
   * handle close
   */
  cancel(): void {
    this.dialogRef.close();
  }

  public partsDropdown: ChqWidgetsInputModel = {
    placeHolder: 'SELECT PART NAME',
    label: 'SELECT PART NAME',
    name: 'parts_Name',
    value: 0,
    type: 'select',
    displayValue: 'name',
    fieldValue: 'id',
    options: [ ],
  };
  public damageDropdown: ChqWidgetsInputModel = {
    placeHolder: 'DAMAGE TYPE',
    label: 'DAMAGE TYPE',
    name: 'damage_type',
    value: 0,
    type: 'select',
    displayValue: 'name',
    fieldValue: 'id',
    options: [ ],
  };
  public recommendationDropdown: ChqWidgetsInputModel = {
    placeHolder: 'RECOMMENDATION',
    label: 'RECOMMENDATION',
    name: 'recommendation',
    value: 0,
    type: 'select',
    displayValue: 'name',
    fieldValue: 'id',
    options: [ ],
  };


  /**
   * Save
   */
  saveModified(): void {
    this.checkForFormValidation()
    // Save analysis
    const requestPayload = {
      'inspectionID': this.data.inspectionID,
      'inspectionItemID': this.data.inspectionItemID,
      'standardPartID': this.partsDropdown.selectedOption.id,
      'damageclassificationID': this.damageDropdown.selectedOption.id,
      'damageRecommendationID': this.recommendationDropdown.selectedOption.id,
      'damageID': this.data.damageId
    }

    if(!this.editMode) {delete requestPayload.damageID}
    this.commonService.showLoading();
    this.aiService.addUpdateDamageAnalysis(requestPayload).subscribe({
      next: (res) => {
        this.commonService.hideLoading();
        this.dialogRef.close(true);
      }, error: () => {
        this.commonService.hideLoading();
      }
    
    })

  }

  /**
   * on data update
   */
  onFormUpdate($event): void {
    if($event.name === 'parts_Name') {
      this.partsDropdown = { ...this.partsDropdown, selectedOption: $event.value }
    }
    if($event.name === 'damage_type') {
      this.damageDropdown = { ...this.damageDropdown, selectedOption: $event.value }
    }
    if($event.name === 'recommendation') {
      this.recommendationDropdown = { ...this.recommendationDropdown, selectedOption: $event.value }
    }
    this.checkForFormValidation();
  }

  /**
* check for form validation and set button type
* @returns {void}
*/
  checkForFormValidation(): void {
    if(this.editMode) {
      if (this.partsDropdown.selectedOption.id && this.damageDropdown.selectedOption.id && this.recommendationDropdown.selectedOption.id ) {
        this.sendButton.type = 'primary';
      } else {
        this.sendButton.type = 'disabled';
      }
    } else {
      if (this.partsDropdown.selectedOption && this.damageDropdown.selectedOption && this.recommendationDropdown.selectedOption ) {
        this.sendButton.type = 'primary';
      } else {
        this.sendButton.type = 'disabled';
      }
    }
  }

  /**
   * Get Id by Name
   */
  getIdByName(nameToFind: string, type: 'part' | 'damage' | 'recommendation'): number | null {
    let foundObject:any;
    if(type === 'part') {
      foundObject = this.partsDropdown.options.find(item => item.name === nameToFind);
    }
    if(type === 'damage') {
      foundObject = this.damageDropdown.options.find(item => item.name === nameToFind);
    }
    if(type === 'recommendation') {
      foundObject = this.recommendationDropdown.options.find(item => item.name === nameToFind);
    }
    return foundObject ? foundObject : null;
  }

  /**
   * hide dropdown
   */
  hideDropdown(name: any): void {
    if (name === 'parts_Name') {
      this.damage.closeDropdown();
      this.recommendation.closeDropdown();
    } else if (name === 'damage_type') {
      this.parts.closeDropdown();
      this.recommendation.closeDropdown();
    } else {
      this.parts.closeDropdown();
      this.damage.closeDropdown();
    }
  }
}
