import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { Modules } from 'src/app/model/chq-upload-model';

@Injectable({
  providedIn: 'root'
})
export class LabourRateService {

  // eslint-disable-next-line require-jsdoc
  constructor(private http: HttpClient) {
  }

  /**
   * Get labour data
   * @param caseId 
   * @returns 
   */
  getLabourData(tariffId: number, vehicleMake: string, registrationDate: string, caseCreationDate: string, modelName?: string): Observable<any> {
    const payload = {
      tariffId,
      vehicleMake,
      registrationDate,
      calculationDate: caseCreationDate,
      modelName
    }
    return this.http.post(`${environment.autoCoreApi}/api/Tariff/get-by-vehicle-details`, payload);
  }

  /*getLabourData(orgId: number, vehicleMake: string, vehicleYear: string): Observable<any> {
    return this.http.get(`${environment.autoCoreApi}/api/Tariff/${orgId}/${vehicleMake}/${vehicleYear}`);
  }*/

  /**
   * Update labour data
   * @param objectId 
   * @param data 
   * @param from 
   * @returns 
   */
  updateLabourData(objectId: string, data: any, from: string = 'cases'): Observable<any> {
    return this.http.put(`${environment.caseMgmtUrl}/api/${from}/${objectId}/tariff`, data);
  }
  /**
   * 
   * @param userId 
   * @returns 
   */
  getPermission(userId: string): Observable<any> {
    if (userId == '5a5cb19a-41e7-4dbf-9c0f-59d83b3bcc6b') {
      return of(true);
    }
    return of(false);
    //return this.http.get(`${environment.autoApi}/api/v1/Vehicles/Lookups/ModelList?ModelYear=${yearId}&MakeId=${makeId}`);
  }
}
