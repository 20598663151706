<div class="left-menu" [ngClass]="{'side-menu-collapse': !sideMenuExpanded}">
    <div class="menu-image">
        <img alt="org-logo" [src]="orgLogoUrl" (error)="avatar='';" (load)="orgImageLoading=false" *ngIf="orgLogoUrl else name" />
        <ng-template #name>
            <label for="" *ngIf="orgName" class="org-name">{{orgName}}</label>
        </ng-template>
        <chq-custom-loader [showLoading]="orgImageLoading"></chq-custom-loader>
    </div>
    <div class="menu-item-wrapper">
        <ng-container *ngFor="let item of menuItems">
            <div class="menu-item" [ngClass]="{'active': item.name === currentParent}" *ngIf="item.permission">
                <div class="item-wrapper">
                    <div class="text-icon" (click)="navigate(item)"
                        [ngClass]="{'hasActiveChild': (item.name === currentParent) && (item.childMenu && item.childMenu.length > 0)}">
                        <mat-icon [svgIcon]="item.icon" color="white" aria-hidden="false"></mat-icon>
                        <div class="item-text">
                            <span>{{item.name | translate}}</span>
                        </div>
                    </div>

                    <div *ngIf="(item.name === currentParent) && (item.childMenu && item.childMenu.length > 0)"
                        class="child-menu-wrapper">
                        <ng-container *ngFor="let child of item.childMenu">
                            <div *ngIf="child.permission" class="child-menu-item"
                                [ngClass]="{'active': (child.name === currentChild)}" (click)="navigate(child)">
                                <span>{{child.name | translate}}</span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<div class="backdrop" *ngIf="sideMenuExpanded && showBackdrop" (click)="collapseSideMenu()"></div>