export const RepairEstimateOperationTableConfig = {
  columns: [
    {
      id: 'Selection',
      width: '60px',
      header: {
        text: '',
        tooltip: '',
        style: 'visibility: hidden'
      },
      selector: {
        field: 'OperationGuid',
      },
      cellStyle: 'justify-content:center;padding:0',
      widget: {
        type: 'checkbox'
      }
    },
    {
      id: 'Part',
      sortable: false,
      header: {
        text: 'part_operation',
        tooltip: '',
      },
      selector: {
        field: 'OperationName',
      },
      widget: {
        type: 'text',
      }
    },
    {
      id: 'Code',
      sortable: false,
      selector: {
        field: 'Code',
      },
      header: {
        text: 'code',
        tooltip: '',
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'Information',
      sortable: false,
      selector: {
        field: 'Information',
      },
      header: {
        text: 'information',
        tooltip: '',
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'RepairType',
      sortable: false,
      selector: {
        field: 'RepairServiceType',
      },
      header: {
        text: 'repair_type',
        tooltip: '',
        // style: 'text-align: right;'
      },
      // cellStyle: 'justify-content: end;',
      widget: {
        type: 'text',
      },
    },
    {
      id: 'Price',
      sortable: false,
      selector: {
        field: 'Price',
      },
      header: {
        text: 'Price',
        tooltip: '',
      },
      widget: {
        type: 'number',
      },
    },
    {
      id: 'LabourTime',
      sortable: false,
      selector: {
        field: 'LabourTime',
      },
      header: {
        text: 'labour_time',
        tooltip: '',
        style: ''
      },
      cellStyle: '',
      widget: {
        type: 'text',
      },
    },
    {
      id: 'EditAction',
      width: '30px',
      sortable: false,
      style: 'border-right: 0',
      selector: {
        field: 'OperationGuid',
      },
      header: {
        text: '',
        tooltip: '',
        style: 'visibility: hidden'
      },
      cellStyle: 'justify-content: center; border-right: 0',
      widget: {
        type: 'icon',
        config: {
          svgIcon: 'edit',
          style: 'width: 18px; height: 18px;'
        }
      },
    },
    {
      id: 'DeleteAction',
      width: '30px',
      sortable: false,
      selector: {
        field: 'OperationGuid',
      },
      header: {
        text: '',
        tooltip: '',
        style: 'visibility: hidden'
      },
      cellStyle: 'justify-content: center',
      widget: {
        type: 'icon',
        config: {
          svgIcon: 'delete',
          style: 'fill: var(--xa-dark-red2) !important; width: 15px; height: 15px;'
        }
      },
    }
  ]
}