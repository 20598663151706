import { ValidatorFn, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CustomValidationResult } from 'src/app/model/chq-validation-model';
import { ChqWidgetsInputModel } from 'src/app/model/chq-widgets-input-model';

interface KeyValueType<T> {
  [k: string]: T;
}

export interface AddEstimateOperationForm extends KeyValueType<ChqWidgetsInputModel> {
  operationType: ChqWidgetsInputModel;
  operation: ChqWidgetsInputModel;
  code: ChqWidgetsInputModel;
  repairType: ChqWidgetsInputModel;
  information: ChqWidgetsInputModel;
  quantity?: ChqWidgetsInputModel;
  price?: ChqWidgetsInputModel;
  labourTime?: ChqWidgetsInputModel;
  labourAmount?: ChqWidgetsInputModel;
  saveOperation?: ChqWidgetsInputModel 
}

export const newEstimateOperationForm = (): AddEstimateOperationForm => {
  return {
    operationType: {
      placeHolder: 'select_operation_type',
      name: 'operationType',
      label: 'operation_type',
      options: [ {} ],
      displayValue: 'name',
      fieldValue: 'id',
      type: 'select',
      value: '',
      required: true,
      showSelectedOption: false,
      validation: {
        name: 'operationType',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.required ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return 'please_select_operation_type'
          }
          return '';
        }
      },
    },
    operation: {
      placeHolder: 'part_operation',
      label: 'part_operation',
      name: 'operation',
      value: 0,
      type: 'text',
      maxLength: 50,
      required: true,
      validation: {
        name: 'operation',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.required, Validators.maxLength(50), Validators.pattern(/^[a-z\d\s-_\\/]+$/i) ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return 'please_enter_part_operation'
          }
          if (error['maxlength']) {
            return 'part_operation_should_have_not_more_than_50_characters'
          }
          if (error['pattern']) {
            return 'part_operation_only_alphanumberic_characters'
          }
          return '';
        }
      }
    },
    code: {
      placeHolder: 'code',
      label: 'code',
      name: 'code',
      value: '',
      type: 'text',
      maxLength: 50,
      required: true,
      validation: {
        name: 'code',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.required, Validators.maxLength(50), Validators.pattern(/^[a-z\d\s-_\\/]+$/i) ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return 'please_enter_code'
          }
          if (error['maxlength']) {
            return 'code_should_have_not_more_than_50_characters'
          }
          if (error['pattern']) {
            return 'part_operation_only_alphanumberic_characters'
          }
          return '';
        }
      }
    },
    repairType: {
      placeHolder: 'select_repair_type',
      name: 'repairType',
      label: 'repair_type',
      options: [ {} ],
      displayValue: 'name',
      fieldValue: 'name',
      type: 'select',
      value: '',
      required: true,
      validation: {
        name: 'repairType',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.required ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return 'please_select_repair_type'
          }
          return '';
        }
      },
    },
    information: {
      placeHolder: 'information',
      label: 'information',
      name: 'information',
      value: '',
      type: 'textarea',
      maxLength: 150,
      validation: {
        name: 'information',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.maxLength(150), Validators.pattern(/^[a-z\d\s-_\\/]+$/i) ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['maxlength']) {
            return 'information_should_have_not_more_than_150_characters'
          }
          if (error['pattern']) {
            return 'part_operation_only_alphanumberic_characters'
          }
          return '';
        }
      }
    },
    saveOperation: {
      'placeHolder': '',
      'label': '',
      'name': 'saveOperation',
      'idField': 'saveOperation',
      'value': 1,
      'type': 'checkbox',
      'options': [ { label: 'save_operation', id: 1, value: 1, checked: false } ]
    }
  }
};

export const newQuantityWidget = () : ChqWidgetsInputModel => {
  return {
    placeHolder: 'quantity',
    name: 'quantity',
    label: 'quantity',
    value: 0,
    type: 'number',
    maxLength: 3,
    validation: {
      name: 'quantity',
      validationFunction: (): ValidatorFn => {
        return Validators.compose([ Validators.maxLength(3) ])
      },
      validationMessage: (error: ValidationErrors): string => {
        if (error['maxlength']) {
          return 'quantity_should_not_be_more_than_3_characters'
        }
        return '';
      }
    }
  }
}

export const newLabourTimeWidget = () : ChqWidgetsInputModel => {
  return {
    placeHolder: 'labour_time',
    name: 'labourTime',
    label: 'labour_time_hours',
    value: 0,
    type: 'number',
    maxLength: 4,
    validation: {
      name: 'labourTime',
      validationFunction: (): ValidatorFn => {
        return Validators.compose([ Validators.maxLength(4) ])
      },
      validationMessage: (error: ValidationErrors): string => {
        if (error['maxlength']) {
          return 'labour_time_not_be_more_than_4_characters'
        }
        return '';
      }
    }
  }
}

export const newLabourAmountWidget = (translateService: TranslateService, currencySymbol) : ChqWidgetsInputModel => {
  return {
    placeHolder: 'labour_amount',
    name: 'labourAmount',
    label: translateService.instant('labour_amount_currency')?.replace('{{currency}}', currencySymbol) || '',
    value: 0,
    type: 'number',
    maxLength: 7,
    validation: {
      name: 'labourTime',
      validationFunction: (): ValidatorFn => {
        return Validators.compose([ Validators.maxLength(7) ])
      },
      validationMessage: (error: ValidationErrors): string => {
        if (error['maxlength']) {
          return 'labour_amount_not_be_more_than_7_characters'
        }
        return '';
      }
    }
  }
}

export const newPriceWidget = (translateService: TranslateService, currencySymbol) : ChqWidgetsInputModel => {
  return {
    placeHolder: 'price',
    name: 'price',
    label: translateService.instant('price_currency')?.replace('{{currency}}', currencySymbol) || '',
    value: 0,
    type: 'number',
    maxLength: 10,
    validation: {
      name: 'price',
      validationFunction: (): ValidatorFn => {
        return Validators.compose([ priceValidators() ])
      },
      validationMessage: (error: ValidationErrors): string => {
        if (error['invalidPrice']) {
          return 'price_max_length_validation'
        }
        return '';
      }
    }
  }
}

/**
 * 
 * @returns 
 */
export function priceValidators() : ValidatorFn {
  const result: CustomValidationResult = { 'invalidPrice': false };
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value > 9999999.99) {
      result['invalidPrice'] = true;
      return result;
    }
    return null;
  }
}

