<div class="estimate-preview-container fx-col">
    <div class="header">
        <h3>{{'review_estimation' | translate}}</h3>
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'"
                      (click)="cancelDialog();"></mat-icon>
        </div>
    </div>
    <div class="content fx-col fx-1">
        <div class="estimate-preview-details-container">
            <chq-estimate-details [estimateResponse]="data?.estimateResponse"
                                  [editEstimate]="false"
                                  serviceType="repair"
                                  [i18n]="i18n"
                                  [repairGuid]="data?.repairGuid"
                                  [estimateGuid]="data?.estimateResponse?.estimateGuid"
                                  [estimateId]="data?.estimateResponse?.id"
                                  (isFormValid)="isFormValid($event)"
                                  [isGroupEdit]="true"
                                  [isHideHeaders]="true" #estimateDetailsPreviewPopup> </chq-estimate-details>
        </div>
    </div>
    <div class="footer-button">
        <xa-button type="outline" [disabled]="!formIsValid" color="gray" (click)="handleSaveIconClick('save')">Save</xa-button>
        <xa-button [disabled]="!formIsValid" (click)="handleSaveIconClick('calculateEstimate')">Calculate estimate</xa-button>
    </div>
</div>