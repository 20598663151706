/* eslint-disable indent */
/* eslint-disable require-jsdoc */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-empty-function */
import { AfterContentInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, Directive, DoCheck, ElementRef, EventEmitter, Inject, InjectionToken, Input, OnDestroy, OnInit, Optional, Output, QueryList, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';

export const XA_RATING = new InjectionToken<XaRating>('XaRating');

let nextUniqueId = 0;
export class XaRatingChange {
  /**
   * constructor
   * @param source 
   * @param value 
   */
  constructor(
    /** The rating option that emits the change event. */
    public source: XaRatingOption,
    /** The value of the rating button. */
    public value: any,
  ) {}
}
@Directive({
  selector: 'xa-rating',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XaRating),
      multi: true,
    },
    { provide: XA_RATING, useExisting: XaRating }
  ]
  
})

export class XaRating implements AfterContentInit, OnDestroy, ControlValueAccessor {

  //private variables
  private _value: any | null;
  private _name: string = `xa-rating-group-${nextUniqueId++}`
  private _selected: XaRatingOption | null = null;
  private _isInitialized: boolean = false;
  private _labelPosition: 'before' | 'after' = 'after';
  private _disabled: boolean = false;
  private _required: boolean = false;
  private _buttonChanges: Subscription;

  /** The method to be called in order to update ngModel */
  _controlValueAccessorChangeFn: (value: any) => void = () => {};

  /**
   * onTouch function registered via registerOnTouch (ControlValueAccessor).
   */
  onTouched: () => any = () => {};

  /**
   * Event emitted when the group value changes.
   */
  @Output() readonly changes: EventEmitter<XaRatingChange> = new EventEmitter<XaRatingChange>();

  /** Child radio buttons. */
  @ContentChildren(forwardRef(() => XaRatingOption), { descendants: true })
    _ratings: QueryList<XaRatingOption>;


  /** Name of the radio button group. All radio buttons inside this group will use this name. */
  @Input()
  get name(): string {
    return this._name;
  }
  /**
   * set name
   */
  set name(value: string) {
    this._name = value;
    this._updateRatingNames();
  }

  /** Whether the labels should appear after or before the radio-buttons. Defaults to 'after' */
  @Input()
  get labelPosition(): 'before' | 'after' {
    return this._labelPosition;
  }
  /**
   * set label position
   */
  set labelPosition(v) {
    this._labelPosition = v === 'before' ? 'before' : 'after';
    this._markRatingsForCheck();
  }

  /**
   * Value for the radio-group. Should equal the value of the selected radio button if there is
   * a corresponding radio button with a matching value. If there is not such a corresponding
   * radio button, this value persists to be applied in case a new radio button is added with a
   * matching value.
   */
  @Input()
  get value(): any {
    return this._value;
  }
  /**
   * set value
   */
  set value(newValue: any) {
    if (this._value !== newValue) {
      // Set this before proceeding to ensure no circular loop occurs with selection.
      this._value = newValue;

      this._updateSelectedRatingFromValue();
      this._checkSelectedRating();
    }
  }

  /**
   * check for selected radio
   */
  _checkSelectedRating(): void {
    if (this._selected && !this._selected.checked) {
      this._selected.checked = true;
    }
  }

  /**
   * The currently selected radio button. If set to a new radio button, the radio group value
   * will be updated to match the new selected button.
   */
  @Input()
  get selected(): any {
    return this._selected;
  }
  /**
   * set selection
   */
  set selected(selected: XaRatingOption | null) {
    this._selected = selected;
    this.value = selected ? selected.value : null;
    this._checkSelectedRating();
  }

  /** Whether the radio group is disabled */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  /**
   * set disabled
   */
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._markRatingsForCheck();
  }

  /** Whether the radio group is required */
  @Input()
  get required(): boolean {
    return this._required;
  }
  /**
   * set required
   */
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this._markRatingsForCheck();
  }

  /**
   * constructor
   */
  constructor(private _changeDetector: ChangeDetectorRef) {}
  /**
    * Initialize properties once content children are available.
    * This allows us to propagate relevant attributes to associated buttons.
    */
  ngAfterContentInit(): void {
    this._isInitialized = true;
    this._buttonChanges = this._ratings.changes.subscribe(() => {
      if (this.selected && !this._ratings.find(rating => rating === this.selected)) {
        this._selected = null;
      }
    });
  }

  /**
   * ng destroy
   */
  ngOnDestroy(): void {
    this._buttonChanges?.unsubscribe();
  }

  /**
   * Mark this group as being "touched" (for ngModel). Meant to be called by the contained
   * radio buttons upon their blur.
   */
  _touch() {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  private _updateRatingNames(): void {
    if (this._ratings) {
      this._ratings.forEach((rating) => {
        rating.name = this.name;
        rating._markForCheck();
      });
    }
  }

  /** Updates the `selected` radio button from the internal _value state. */
  private _updateSelectedRatingFromValue(): void {
    // If the value already matches the selected rating, do nothing.
    const isAlreadySelected = this._selected !== null && this._selected.value === this._value;

    if (this._ratings && !isAlreadySelected) {
      this._selected = null;
      this._ratings.forEach((rating) => {
        rating.checked = this.value === rating.value;
        if (rating.checked) {
          this._selected = rating;
        }
      });
    }
  }

  /** Dispatch change event with current selection and group value. */
  _emitChangeEvent(): void {
    if (this._isInitialized) {
      this.changes.emit(new XaRatingChange(this._selected, this._value));
    }
  }

  _markRatingsForCheck() {
    if (this._ratings) {
      this._ratings.forEach(rating => rating._markForCheck());
    }
  }

  /**
   * Sets the model value. Implemented as part of ControlValueAccessor.
   * @param value
   */
  writeValue(value: any) {
    this.value = value;
    this._changeDetector.markForCheck();
  }

  /**
   * Registers a callback to be triggered when the model value changes.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnChange(fn: (value: any) => void) {
    this._controlValueAccessorChangeFn = fn;
  }

  /**
   * Registers a callback to be triggered when the control is touched.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  /**
   * Sets the disabled state of the control. Implemented as a part of ControlValueAccessor.
   * @param isDisabled Whether the control should be disabled.
   */
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this._changeDetector.markForCheck();
  }
}


abstract class XaRatingOptionBase {
  abstract disabled: boolean;
  constructor(public _elementRef: ElementRef) {}
}

@Component({
  selector: 'xa-rating-option',
  templateUrl: './xa-rating.component.html',
  styleUrls: [ './xa-rating.component.scss' ],  
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class XaRatingOption extends XaRatingOptionBase implements OnInit, AfterViewInit, OnDestroy {
  private _uniqueId: string = `xa-rating-option-${++nextUniqueId}`;
  private _labelPosition: 'before' | 'after';

  @Input() id: string = this._uniqueId;
  @Input() name: string;
    //input
  @Input() iconType:'img' | 'svg' | 'material';
  @Input() iconName:string;

  @Input() style:any;
  @Input() color:string;
  
  @Input()
  get checked(): boolean {
    return this._checked;
  }
  set checked(value: BooleanInput) {
    const newCheckedState = coerceBooleanProperty(value);
    if (this._checked !== newCheckedState) {
      this._checked = newCheckedState;
      if (newCheckedState && this.xaRating && this.xaRating.value !== this.value) {
        this.xaRating.selected = this;
      } else if (!newCheckedState && this.xaRating && this.xaRating.value === this.value) {
        // When unchecking the selected radio button, update the selected radio
        // property on the group.
        this.xaRating.selected = null;
      }

      if (newCheckedState) {
        // Notify all radio buttons with the same name to un-check.
        this._ratingDispatcher.notify(this.id, this.name);
      }
      this._changeDetector.markForCheck();
    }
  }

  @Input()
  get value(): any {
    return this._value;
  }
  set value(value: any) {
    if (this._value !== value) {
      this._value = value;
      if (this.xaRating !== null) {
        if (!this.checked) {
          // Update checked when the value changed to match the radio group's value
          this.checked = this.xaRating.value === value;
        }
        if (this.checked) {
          this.xaRating.selected = this;
        }
      }
    }
  }

  @Input()
  get labelPosition(): 'before' | 'after' {
    return this._labelPosition || (this.xaRating && this.xaRating.labelPosition) || 'after';
  }
  set labelPosition(value) {
    this._labelPosition = value;
  }

  @Input()
  get disabled(): boolean {
    return this._disabled || (this.xaRating !== null && this.xaRating.disabled);
  }
  set disabled(value: BooleanInput) {
    this._setDisabled(coerceBooleanProperty(value));
  }

  @Input()
  get required(): boolean {
    return this._required || (this.xaRating && this.xaRating.required);
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
  }

  /**
   * Event emitted when the checked state of this radio button changes.
   * Change events are only emitted when the value changes due to user interaction with
   * the radio button (the same behavior as `<input type-"radio">`).
   */
  @Output() readonly changes: EventEmitter<XaRatingChange> = new EventEmitter<XaRatingChange>();

  /** The parent radio group. May or may not be present. */
  private xaRating: XaRating;

  get inputId(): string {
    return `${this.id || this._uniqueId}-input`;
  }

  private _checked: boolean = false;
  private _disabled: boolean;
  private _required: boolean;
  private _value: any = null;

  public notSelected = false;

  private _removeUniqueSelectionListener: () => void = () => {};

  @ViewChild('input') _inputElement: ElementRef<HTMLInputElement>;

  constructor(
    @Optional() @Inject(XA_RATING) xaRating: XaRating,
    elementRef: ElementRef,
    private _changeDetector: ChangeDetectorRef,
    private _focusMonitor: FocusMonitor,
    private _ratingDispatcher: UniqueSelectionDispatcher
  ) {
    super(elementRef);
    this.xaRating = xaRating;
  }

  /**
   * Marks the rating button as needing checking for change detection.
   */
  _markForCheck() {
    this._changeDetector.markForCheck();
  }

  ngOnInit() {
    if (this.xaRating) {
      // If the radio is inside a radio group, determine if it should be checked
      this.checked = this.xaRating.value === this._value;

      if (this.checked) {
        this.xaRating.selected = this;
      }

      if(this.xaRating.value && !this.checked){
        this.notSelected = true;
      }

      // Copy name from parent radio group
      this.name = this.xaRating.name;
    }

    this._removeUniqueSelectionListener = this._ratingDispatcher.listen((id, name) => {
      if (id !== this.id && name === this.name) {
        this.checked = false;
        if(this.xaRating.value && !this.checked){
          this.notSelected = true;
        }else{
          this.notSelected = false;
        }
        this._changeDetector.markForCheck();
      }
    });
  }

  ngAfterViewInit() {
    this._focusMonitor.monitor(this._elementRef, true).subscribe((focusOrigin) => {
      if (!focusOrigin && this.xaRating) {
        this.xaRating._touch();
      }
    });
  }

  ngOnDestroy() {
    this._focusMonitor.stopMonitoring(this._elementRef);
    this._removeUniqueSelectionListener();
  }

  /** Dispatch change event with current value. */
  private _emitChangeEvent(): void {
    this.changes.emit(new XaRatingChange(this, this._value));
  }


  _onInputClick(event: Event) {
    // We have to stop propagation for click events on the visual hidden input element.
    // By default, when a user clicks on a label element, a generated click event will be
    // dispatched on the associated input element. Since we are using a label element as our
    // root container, the click event on the `radio-button` will be executed twice.
    // The real click event will bubble up, and the generated click event also tries to bubble up.
    // This will lead to multiple click events.
    // Preventing bubbling for the second event will solve that issue.
    event.stopPropagation();
  }

  /** Triggered when the radio button receives an interaction from the user. */
  _onInputInteraction(event: Event) {
    // We always have to stop propagation on the change event.
    // Otherwise the change event, from the input element, will bubble up and
    // emit its event object to the `change` output.
    event.stopPropagation();

    if (!this.checked && !this.disabled) {
      const groupValueChanged = this.xaRating && this.value !== this.xaRating.value;
      this.checked = true;
      this._emitChangeEvent();

      if (this.xaRating) {
        this.xaRating._controlValueAccessorChangeFn(this.value);
        if (groupValueChanged) {
          this.xaRating._emitChangeEvent();
        }
      }
    }
  }

  /** Triggered when the user clicks on the touch target. */
  _onTouchTargetClick(event: Event) {
    this._onInputInteraction(event);

    if (!this.disabled) {
      // Normally the input should be focused already, but if the click
      // comes from the touch target, then we might have to focus it ourselves.
      this._inputElement.nativeElement.focus();
    }
  }

  /** Sets the disabled state and marks for check if a change occurred. */
  protected _setDisabled(value: boolean) {
    if (this._disabled !== value) {
      this._disabled = value;
      this._changeDetector.markForCheck();
    }
  }
}
