
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { UploadStepModel } from 'src/app/model/chq-upload-model';
import * as html2pdf from 'html2pdf.js'
import * as dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import { catchError, forkJoin, from, Observable, of } from 'rxjs';
import jsPDF, { jsPDFOptions } from 'jspdf';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { DamageReport } from 'src/app/model/chq-damage-report';
import * as saveAs from 'file-saver';


@Component({
  selector: 'chq-pdf-generator',
  templateUrl: './chq-pdf-generator.component.html',
  styleUrls: [ './chq-pdf-generator.component.scss' ]
})
export class ChqPdfGeneratorComponent {
  @ViewChild('elementRef', { read: ElementRef, static: true }) elementRef: ElementRef;
  @Input() caseDetail: any;
  @Input() damageParts: DamageReport[];
  @Input() isRepair: boolean;
  @Input() damageRecommendations: any[];
  @Input() isClaim: boolean;
  @Input() isFNOL:boolean;
  @Input() claimDetail: any;

  @Input() uploadSteps: UploadStepModel[] = [];
  public aiAnalysisResult: any = [ {
    part: 'FB: 0.98%',
    damage: 'Scratch: 0.53%, 0.58%',
    severity: 'Repair',
    partName: 'Bonnet',
    damageType: 'Scratch'
  },
  {
    part: 'B: 0.99%',
    damage: 'No Damage: 0.95%',
    severity: 'N/A',
    partName: 'Bumper',
    damageType: 'No Damage'
  },
  {
    part: 'RFF: 0.98%',
    damage: 'No Damage: 0.95%',
    severity: 'Repair',
    partName: 'Fender',
    damageType: 'No Damage'
  },
  {
    part: 'RFD: 0.99%',
    damage: 'No Damage: 0.95%',
    severity: 'N/A',
    partName: 'Doors',
    damageType: 'No Damage'
  },
  {
    part: 'RFD: 0.99%',
    damage: 'No Damage: 0.95%',
    severity: 'N/A',
    partName: 'Grill',
    damageType: 'No Damage'
  },
  {
    part: 'FB_G: 0.54%',
    damage: 'No Damage: 0.95%',
    severity: 'N/A',
    partName: 'Windshield',
    damageType: 'No Damage'
  },
  {
    part: 'FS: 0.99%',
    damage: 'No Damage: 0.95%',
    severity: 'N/A',
    partName: 'Headlamp',
    damageType: 'No Damage'
  },
  {
    part: 'RHL: 0.98%',
    damage: 'No Damage: 0.95%',
    severity: 'N/A',
    partName: 'Wheel',
    damageType: 'No Damage'
  },
  {
    part: 'RFW: 0.99%',
    damage: 'No Damage: 0.95%',
    severity: 'N/A',
    partName: 'Doormirror',
    damageType: 'No Damage'
  },
  {
    part: 'RRW: 0.96%',
    damage: 'No Damage: 0.95%',
    severity: 'N/A',
    partName: 'Doormirror',
    damageType: 'No Damage'
  },
  {
    part: 'RDM: 0.98%',
    damage: 'No Damage: 0.95%',
    severity: 'N/A',
    partName: 'Doormirror',
    damageType: 'No Damage'
  } ];

  images = [
  ];
  currentRepairCategory: any;
  pdfUrl: string = '';

  @Input() isExternal: boolean = false;
  /**
   * constructor
   */
  constructor(private commonService: CommonService, private cd: ChangeDetectorRef,
    private monitorService: MonitorService) {

  }


  isPDFLoading: boolean = false;

  /**
   * CHecks if current browser is mac or not
   * @returns boolean
   */
  get isiOS(): boolean {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  }

  /**
   * get initials
   */
  get initials(): string {
    return `${this.caseDetail?.caseDetail?.firstName?.substring(0, 1).toUpperCase()}
              ${this.caseDetail?.caseDetail?.lastName?.substring(0, 1).toUpperCase()}`
  }
  /**
   * get currentRepairCategory
   */
  getRepairCategory(step: UploadStepModel): void {
    this.currentRepairCategory = this.damageParts.find(el => el.inspectionItemId === step.index)
  }

  /**
   * custom font loader
   */
  async loadFont(src, name, style, pdf, fontName): Promise<void> {
    const result = this.commonService.imageReaderObservable(src);
    await result.toPromise().then((result) => {
      const base64String = result.image;
      const callAddFont = (): void => {
        pdf.addFileToVFS(fontName, base64String.split('base64,')[1]);
        pdf.addFont(fontName, name, style);
      };
      callAddFont();
    });


  }

  /**
   * generate pdf function
   */
  public generatePDF(): void {
    this.monitorService.logEvent('generatePDF', [ 'ChqPdfGeneratorComponent', 'addenda-quote' ]);
    this.isPDFLoading = true;
    document.getElementsByTagName('meta')[1].setAttribute('content', 'width=1536');
    const html = this.elementRef.nativeElement as HTMLElement;
    const scroller = document.querySelector('body') as HTMLElement;
    const pdfWrapper = document.querySelector('.margin-pdf') as HTMLElement;
    scroller.style.overflowY = 'hidden';
    if (pdfWrapper) {
      pdfWrapper.style.display = 'block';
    }
    html.style.display = 'flex';
    const createdDate = this.isClaim || this.isFNOL ? dayjs(new Date()).format('MMDDYYYY') : dayjs(this.caseDetail.createdDate).format('MMDDYYYY');
    const fileName = this.isClaim ? this.claimDetail.claimNo : (this.isFNOL? 'FNOL': (this.caseDetail.caseNumber || this.caseDetail.repairNumber));
    let option = {
      margin: [ 32, 0, 20, 0 ],
      filename: `${fileName}_Damage Analysis Report_${createdDate}.pdf`,
      pagebreak: { mode: [ 'css' ] },
      image: { quality: 1, type: 'png' },
      html2canvas: { scale: 2, useCORS: true, dpi: 160, letterRendering: true, allowTaint: true, },
      jsPDF: { unit: 'mm', format: 'letter', orientation: 'p', compress: true }
    };

    const observableList = { header: null, footer: null, logo: null };
    observableList.header = from(
      html2canvas(this.elementRef.nativeElement.getElementsByClassName('header')[0] as HTMLElement, <any>{
        scale: 3,
        height: 150,
        useCORS: true, dpi: 260,
        letterRendering: true
      }));

    if (!this.caseDetail?.organizationDetail && !this.claimDetail?.organizationDetail) {
      this.commonService.showToast(0, 'Pdf generation failed.');
      if (pdfWrapper) {
        pdfWrapper.style.display = 'none';
      }
      html.style.display = 'none';
      this.isPDFLoading = false;
      document.getElementsByTagName('meta')[1].setAttribute('content',
        'user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, height=device-height');
      this.monitorService.logEvent('generatePDF', [ 'ChqPdfGeneratorComponent', 'addenda-quote', {
        message: 'Pdf generation failed.'
      } ]);
      this.elementRef.nativeElement.getElementsByClassName('header')[0].style.display = 'flex';
      this.elementRef.nativeElement.getElementsByClassName('footer-header')[0].style.display = 'flex';
      setTimeout(() => {
        scroller.style.overflowY = 'auto';
      });
      return;
    }

    if (!this.isClaim) {
      if (this.caseDetail?.organizationDetail?.logoOfOrg) {
        observableList.logo = this.commonService.imageReaderObservable(this.caseDetail?.organizationDetail?.logoOfOrg)
      } else {
        delete observableList.logo;
      }

      observableList.footer = this.commonService.imageReaderObservable(`assets/img/${this.caseDetail?.caseNumber ? 'addenda-quote.png' : 'addenda-repair.png'}`);
    } else {
      if (this.claimDetail?.organizationDetail?.logoOfOrg) {
        observableList.logo = this.commonService.imageReaderObservable(this.claimDetail?.organizationDetail?.logoOfOrg)
      } else {
        delete observableList.logo;
      }

      observableList.footer = this.commonService.imageReaderObservable('assets/img/addenda-claims.png');
    }


    const result = forkJoin(observableList).pipe(
      catchError(error => of(error))
    );

    result.subscribe((result) => {
      try {
        const organizationDetail = this.isClaim || this.isFNOL ? this.claimDetail.organizationDetail : this.caseDetail?.organizationDetail;
        const canvas = result.logo;
        const footer = result.footer;
        const header = result.header;
        const canvasDataURL = canvas?.image;
        const headerCanvasURL = header?.toDataURL('image/png', 1.0);
        const footerDataUrl = footer?.image;
        this.elementRef.nativeElement.getElementsByClassName('header')[0].style.display = 'none';
        this.elementRef.nativeElement.getElementsByClassName('footer-header')[0].style.display = 'none';
        const optionPdf: any = option.jsPDF;
        const jsPdfNew = new jsPDF(optionPdf);
        const wd = jsPdfNew.internal.pageSize.getWidth() / 2 - 10;
        let topAdditionalMargin = 0;
        const splitForMargin = jsPdfNew.splitTextToSize(organizationDetail?.address || '', wd - 14);
        for (let i = 0, length = splitForMargin.length; i < length; i++) {
          if (i > 0) {
            topAdditionalMargin += 3;
          }

        }
        option = { ...option, margin: [ 32 + topAdditionalMargin, 0, 20, 0 ], }
        html2pdf().from(this.elementRef.nativeElement).set(option).toPdf().get('pdf').then(async (pdf) => {
          try {
            const totalPages = pdf.internal.getNumberOfPages();
            document.getElementsByTagName('meta')[1].setAttribute('content',
              'user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, height=device-height');
            await this.loadFont('assets/fonts/Inter-Bold.ttf', 'inter-medium', 'bold', pdf, 'Inter-Bold.ttf');
            await this.loadFont('assets/fonts/Inter-Regular.ttf', 'inter-regular', 'normal', pdf, 'Inter-Regular.ttf');

            for (let i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(8);
              const sideWidth = pdf.internal.pageSize.getWidth() / 2 - 10;
              const splitText = pdf.splitTextToSize(organizationDetail?.address || '', sideWidth - 14);
              if (headerCanvasURL) {
                pdf.addImage(headerCanvasURL, 'png', 0, 0, pdf.internal.pageSize.getWidth(), 36 + (splitText.length - 1) * 3.5);
              }
              pdf.setFont('inter-medium', 'bold');
              pdf.setFontSize(12);
              let height = 0;
              pdf.text(organizationDetail?.nameOfOrganization, 6, 8).setFont('inter-regular', 'normal');
              pdf.setFontSize(8);
              for (let i = 0, length = splitText.length; i < length; i++) {
                // loop thru each line and increase
                if (i > 0) {
                  height += 3;
                }
                pdf.text(splitText[i], 6, 12 + height)

              }

              if (organizationDetail?.city || organizationDetail?.country) {
                // eslint-disable-next-line max-len
                const cityandCountry = `${organizationDetail?.city ? `${organizationDetail?.city},` : ''}${organizationDetail?.country}`;
                pdf.text(`${cityandCountry}`, 6, 15.5 + height);
              }
              pdf.setFont('inter-medium', 'bold');
              pdf.text('License Number:', 6, 19 + height);
              pdf.setFont('inter-regular', 'normal');
              pdf.text(organizationDetail?.companyLicenseNumber || '', 32, 19 + height);
              pdf.setFont('inter-medium', 'bold');
              pdf.text('Registry Number:', 6, 22.5 + height);
              pdf.setFont('inter-regular', 'normal');
              pdf.text(organizationDetail?.registryNumber || '', 32, 22.5 + height);
              pdf.setFont('inter-medium', 'bold');
              pdf.text('Contact Person:', 6, 26 + height);
              pdf.setFont('inter-regular', 'normal');
              const name = organizationDetail?.contactPerson || '';
              pdf.text(name, 32, 26 + height);
              pdf.setFont('inter-medium', 'bold');
              pdf.text('Tel:', 6, 29.5 + height);
              pdf.setFont('inter-regular', 'normal');
              pdf.text(organizationDetail?.phoneNumber || '', 12, 29.5 + height);
              pdf.setFont('inter-medium', 'bold');
              pdf.text(' | Email:', 37, 29.5 + height);
              pdf.setFont('inter-regular', 'normal');
              pdf.text(organizationDetail?.emailAddress || '', 49, 29.5 + height);

              if (canvasDataURL) {
                pdf.addImage(canvasDataURL, 'png', pdf.internal.pageSize.getWidth() - 30, 4, 24, 24);
              }

              const module = this.isClaim ? 'claim' : (this.isFNOL ? 'FNOL': (this.caseDetail?.caseNumber ? 'Quote' : 'Repair'));

              pdf.text(`This was generated by Addenda ${module}`, 6, pdf.internal.pageSize.getHeight() - 8);
              if(!this.isFNOL){
                pdf.addImage(footerDataUrl, 'PNG', pdf.internal.pageSize.getWidth() / 2 - 25, pdf.internal.pageSize.getHeight() - 14, 50, 9);
              }
              pdf.setFont('inter-regular', 'normal');
              pdf.text('Page: ' + i + '/' + totalPages + '',
                pdf.internal.pageSize.getWidth() - 25, pdf.internal.pageSize.getHeight() - 8);
            }

            setTimeout(() => {
              if (this.isiOS) {
                const blob = new Blob(pdf.output('blob', { filename: option.filename }), { type: 'application/octet-stream' });
                saveAs(blob, option.filename);
              }
              this.pdfUrl = pdf.output('bloburl', { filename: option.filename });
              window.open(this.pdfUrl, '_top');
            });


          } catch (e) {
            this.commonService.showToast(0, 'Pdf generation failed.');
            document.getElementsByTagName('meta')[1].setAttribute('content',
              'user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, height=device-height');
            if (pdfWrapper) {
              pdfWrapper.style.display = 'none';
            }
            html.style.display = 'none';
            this.isPDFLoading = false;
            this.monitorService.logEvent('generatePDF', [ 'ChqPdfGeneratorComponent', 'addenda-quote', {
              message: 'Pdf generation failed.'
            } ]);
            setTimeout(() => {
              scroller.style.overflowY = 'auto';
            });
            this.elementRef.nativeElement.getElementsByClassName('header')[0].style.display = 'flex';
            this.elementRef.nativeElement.getElementsByClassName('footer-header')[0].style.display = 'flex';
          }
        }).then(() => {
          this.monitorService.logEvent('generatePDF', [ 'ChqPdfGeneratorComponent', 'addenda-quote', {
            message: 'Pdf generation successful.'
          } ]);
          if (pdfWrapper) {
            pdfWrapper.style.display = 'none';
          }
          setTimeout(() => {
            html.style.display = 'none';
            this.isPDFLoading = false;
            scroller.style.overflowY = 'auto';
          });

          this.elementRef.nativeElement.getElementsByClassName('header')[0].style.display = 'flex';
          this.elementRef.nativeElement.getElementsByClassName('footer-header')[0].style.display = 'flex';
        }).save();
      } catch (e) {
        this.commonService.showToast(0, 'Pdf generation failed.');
        document.getElementsByTagName('meta')[1].setAttribute('content',
          'user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, height=device-height');
        if (pdfWrapper) {
          pdfWrapper.style.display = 'none';
        }
        html.style.display = 'none';
        this.isPDFLoading = false;
        setTimeout(() => {
          scroller.style.overflowY = 'auto';
        });
        this.monitorService.logEvent('generatePDF', [ 'ChqPdfGeneratorComponent', 'addenda-quote', {
          message: 'Pdf generation failed.'
        } ]);
        this.elementRef.nativeElement.getElementsByClassName('header')[0].style.display = 'flex';
        this.elementRef.nativeElement.getElementsByClassName('footer-header')[0].style.display = 'flex';
      }

    }, () => {
      this.commonService.showToast(0, 'Pdf generation failed.');
      document.getElementsByTagName('meta')[1].setAttribute('content',
        'user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, height=device-height');
      if (pdfWrapper) {
        pdfWrapper.style.display = 'none';
      }
      html.style.display = 'none';
      this.isPDFLoading = false;
      setTimeout(() => {
        scroller.style.overflowY = 'auto';
      });
      this.monitorService.logEvent('generatePDF', [ 'ChqPdfGeneratorComponent', 'addenda-quote', {
        message: 'Pdf generation failed.'
      } ]);
      this.elementRef.nativeElement.getElementsByClassName('header')[0].style.display = 'flex';
      this.elementRef.nativeElement.getElementsByClassName('footer-header')[0].style.display = 'flex';
    });
  }

}
