import { ValidatorFn, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { CustomValidationResult } from 'src/app/model/chq-validation-model';
import { ChqWidgetsInputModel } from 'src/app/model/chq-widgets-input-model';
import { TranslateService } from '@ngx-translate/core'; 
import * as dayjs from 'dayjs';
interface KeyValueType<T> {
  [k: string]: T;
}

export interface AddPaymentForm extends KeyValueType<ChqWidgetsInputModel> {
  amount: ChqWidgetsInputModel;
  paymentDate: ChqWidgetsInputModel;
  paymentMethod: ChqWidgetsInputModel;
  paymentStatus: ChqWidgetsInputModel;
  description: ChqWidgetsInputModel;
}

export const newPaymentForm = (translateService: TranslateService): AddPaymentForm => {
  return {
    amount: {
      placeHolder: 'payment_amount',
      label: 'payment_amount',
      name: 'amount',
      value: 0,
      type: 'number',
      required: true,
      validation: {
        name: 'paymentAmount',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.min(1) ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return translateService.instant('payment_amount_required')
          }

          if (error['pattern']) {
            return translateService.instant('invalid_number');
          }

          if (error['min']) {
            return translateService.instant('greater_than_0');
          }

          if (error['invalidPaymentAmount']) {
            return error['invalidPaymentAmount']
          }

          return '';
        }
      }
    },
    paymentDate: {
      placeHolder: 'payment_date',
      label: 'payment_date',
      name: 'paymentDate',
      value: new Date(),
      type: 'datetime',
      minDate: new Date(),
      maxDate: new Date()
    },
    paymentMethod: {
      placeHolder: 'select_payment_method',
      name: 'paymentMethod',
      label: 'payment_method',
      options: [ {} ],
      displayValue: 'name',
      fieldValue: 'id',
      type: 'select',
      value: '',
      required: true,
      validation: {
        name: 'paymentMethod',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.required ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return translateService.instant('select_payment_method')
          }
          return '';
        }
      },
    },
    paymentStatus: {
      placeHolder: 'payment_status',
      label: 'payment_status',
      name: 'paymentStatus',
      value: '',
      type: 'text',
      disabled: true
    },
    description: {
      placeHolder: 'add_description',
      label: 'add_description',
      name: 'description',
      value: '',
      type: 'textarea',
      maxLength: 100,
      validation: {
        name: 'addDescription',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.maxLength(100) ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['maxlength']) {
            return translateService.instant('payment_description_max_length_error')
          }
          return '';
        }
      }
    }
  }
};

/**
 * 
 * @returns 
 */
export function paymentAmountValidators(translateService: TranslateService, maxAmount: number) : ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const result: CustomValidationResult = { 'invalidPaymentAmount': '' };

    if(control.value <= 0){
      result['min'] = translateService.instant('invalid_amount');
      return result;
    }

    if (control.value > maxAmount) {
      result['invalidPaymentAmount'] = translateService.instant('payment_amount_max_length_error').replace('{{maxAmount}}', maxAmount.toFixed(2));
      return result;
    }
    return null;
  }
}

