import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'app-confirm-dialog-big',
  templateUrl: './confirm-dialog-big.component.html',
  styleUrls: [ './confirm-dialog-big.component.scss' ]
})
export class ConfirmDialogBigComponent {
  public message: string = '';

  public yesButton: ChqWidgetsButtonModel = {
    label: 'yes',
    type: 'primary',
    onclick: this.handleYes.bind(this)
  }

  public noButton: ChqWidgetsButtonModel = {
    label: 'no',
    type: 'outline',
    onclick: this.handleNo.bind(this)
  }

  /**
   * constructor
   * @param dialogRef 
   * @param data 
   * @param changeDedectionRef 
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmDialogBigComponent>,
    private monitorService: MonitorService,
    public commonService: CommonService,
    private translateService: TranslateService,
  ) {
    if(data.okText) {
      this.yesButton.label = data.okText
    }
    if(data.cancelText) {
      this.noButton.label = data.cancelText
    }
  }

  /**
   * on init
   */
  ngOnInit(): void {
    this.message = this.data.message;
  }

  /**
   * handle clicked on No
   */
  handleNo(): void {
    this.monitorService.logEvent('handleNo', [ 'ConfirmDialogBigComponent', 'addenda-claim' ]);
    this.dialogRef.close(false);
  }

  /**
   * handle clicked on Yes
   */
  handleYes(): void {
    this.monitorService.logEvent('handleYes', [ 'ConfirmDialogBigComponent', 'addenda-claim' ]);
    this.dialogRef.close(true);
  }


  /**
   * close
   */
  close(): void {
    this.dialogRef.close();
  }
}
