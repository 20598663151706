import { Pipe, PipeTransform } from '@angular/core';

enum Operator{
    notEqual = 0,
    equal = 1
}

@Pipe({ name: 'keyFilter' })
export class FilterByKeyPipe implements PipeTransform {
  /**
     * Pipe filters the list of elements based on the search text provided
     *
     * @param items list of elements to search in
     * @param searchText search string
     * @returns list of elements filtered by search text or []
     */
  transform(items: any[], filter: { key:string, value: any, operator?:Operator }): any[] {
    if (!items) {
      return [];
    }
    if (!filter) {
      return items;
    }

    if(typeof filter.value === 'string'){
      filter.value = filter.value.toLowerCase();
    }

    if(!filter.operator && filter.operator !== 0){
      filter.operator = Operator.equal;
    }
   
    return items.filter((it) => {
      if(typeof filter.value === 'string' || Object.prototype.toString.call(filter.value) === '[object Array]'){
        if(filter.operator === Operator.equal){
          return filter.value.includes(it[filter.key]);
        }else{
          return !filter.value.includes(it[filter.key]);
        }
      }
      if(filter.operator === Operator.equal){
        return it[filter.key] === filter.value;
      }else{
        return it[filter.key] !== filter.value;
      }
    });
  }
}