<div class="table-container">
    <div class="header" *ngIf="!isRepairModule">
        <h3>Repair estimate</h3>
        <div class="pdf-button">
            <chq-button *ngIf="chqPdfModel" [buttonModel]="chqPdfModel">
            </chq-button>
        </div>
    </div>

    <div class="category">
        <div class="category-text">
            Parts
        </div>
    </div>
    <table class="main-table">
        <thead>
            <th width="20px"></th>
            <th width="40%">Description</th>
            <th>Code</th>
            <th>Units</th>
            <th class="price">Price/Unit({{i18n.currencySymbol}})</th>
            <th class="total-price">Total Price({{i18n.currencySymbol}})</th>
        </thead>
        <tbody>
            <tr *ngFor="let row of parts" [ngClass]="{'child-data': row.isChildItem === true}">
                <td width="20px">
                    <span [innerHTML]="displayNotes(row)"></span>
                </td>
                <td>
                    {{row.description}}
                </td>
                <td>
                    {{row.code}}
                </td>
                <td>
                    {{row.quantity}}
                </td>
                <td>
                    {{row.cost | xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}
                </td>
                <td class="total">
                    {{row.total | xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}
                </td>
            </tr>
        </tbody>
    </table>
    <table>
        <tbody>
            <tr class="no-border">
                <td>
                    Total Parts Cost
                </td>
                <td class="total">
                    {{totalDamageCost | xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}
                </td>
            </tr>
        </tbody>
    </table>
    <div class="category">
        <div class="category-text">
            Labour
        </div>
    </div>
    <table class="main-table">
        <thead>
            <th></th>
            <th width="40%">Description</th>
            <th>Code</th>
            <th>Hours</th>
            <th class="price">Price/Unit({{i18n.currencySymbol}})</th>
            <th class="total-price">Total Price({{i18n.currencySymbol}})</th>
        </thead>
        <tbody>
            <tr *ngFor="let row of labourCost" [ngClass]="{'child-data': row.isChildItem === true }">
                <td width="20px">
                    <span [innerHTML]="displayNotes(row)"></span>
                </td>
                <td>
                    {{row.description}}
                </td>
                <td>
                    {{row.code}}
                </td>
                <td>
                    {{row.quantity}} Hours
                </td>
                <td>
                    {{row.cost | xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}
                </td>
                <td class="total">
                    {{row.total | xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}
                </td>
            </tr>
        </tbody>
    </table>
    <table>
        <tbody>
            <tr class="no-border">
                <td>
                    Total Labour Cost
                </td>
                <td class="total">
                    {{totalLabourCost | xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}
                </td>
            </tr>
        </tbody>
    </table>
    <div class="category">
        <div class="category-text">
            Paint
        </div>
        <div class="paint-type">
            <div>
                Paint System: {{ this.estimateResponse?.estimateSummary?.paintSystem }}
            </div>
            <div *ngIf="this.estimateResponse?.estimateSummary?.paintSystem?.toLowerCase() === 'azt'">
                Paint Type: {{ this.estimateResponse?.estimateSummary?.paintType }}
            </div>
        </div>
    </div>
    <table class="main-table paint-table">
        <thead>
            <th></th>
            <th width="40%">Description</th>
            <th>Code</th>
            <th>Unit/Hours</th>
            <th class="price">Price/Unit({{i18n.currencySymbol}})</th>
            <th class="total-price">Total Price({{i18n.currencySymbol}})</th>
        </thead>
        <tbody>

            <tr class="category-paint" *ngIf="paintLabour?.length > 0">
                <td width="20px"></td>
                <td>
                    Paint Labour
                </td>
            </tr>
            <tr *ngFor="let row of paintLabour" [ngClass]="{'child-data': row.isChildItem === true }">
                <td width="20px">
                    <span [innerHTML]="displayNotes(row)"></span>
                </td>
                <td>
                    {{row.description}}
                </td>
                <td>
                    {{row.code}}
                </td>
                <td>
                    {{row.quantity}}
                </td>
                <td>
                    {{row.cost | xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}
                </td>
                <td class="total">
                    {{row.total | xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}
                </td>
            </tr>

        </tbody>
        <tbody class="paint-material">
            <tr class="category-paint" *ngIf="paintMaterial?.length > 0">
                <td colspan="5">
                    Paint Material
                </td>
            </tr>
            <tr *ngFor="let row of paintMaterial" [ngClass]="{'child-data': row.isChildItem === true }">

                <td width="20px">
                    <span [innerHTML]="displayNotes(row)"></span>
                </td>
                <td>
                    {{row.description}}
                </td>
                <td>
                    {{row.code}}
                </td>
                <td>
                    {{row.quantity}}
                </td>
                <td>
                    {{row.cost | xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}
                </td>
                <td class="total">
                    {{row.total | xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}
                </td>
            </tr>

        </tbody>
    </table>
    <table>
        <tbody>
            <tr class="no-border" style="border-top: 1px solid black">
                <td>
                    Total Paint Cost
                </td>
                <td class="total">
                    {{totalPaintCost | xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}
                </td>
            </tr>
        </tbody>
    </table>

    <div class="category">
        <div class="category-text">
            Summary
        </div>
    </div>
    <div class="category">
        <div class="category-text">
            Parts
        </div>
    </div>
    <table>
        <tbody>
            <tr>
                <td>
                    Subtotal
                </td>
                <td class="total">
                    {{this.estimateResponse?.estimateSummary?.partsSubTotal |
                    xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}
                </td>
            </tr>
            <tr>
                <td>
                    Sundry parts
                </td>
                <td class="total">
                    {{this.estimateResponse?.estimateSummary?.sundryParts | xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}
                </td>
            </tr>
            <tr>
                <td>
                    Total before discount
                </td>
                <td class="total">
                    {{this.estimateResponse?.estimateSummary?.partsTotalBeforeDiscount |
                    xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}
                </td>
            </tr>
            <tr>
                <td>
                    Discount on parts({{this.estimateResponse?.estimateSummary?.partsTotalDiscountPercentage}}%)
                </td>
                <td class="total">
                    {{this.estimateResponse?.estimateSummary?.partsTotalDiscount |
                    xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}
                </td>
            </tr>
            <tr class="no-border">
                <td>
                    Total Parts Cost
                </td>
                <td class="total">
                    {{this.estimateResponse?.estimateSummary?.partsTotal | xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}
                </td>
            </tr>
        </tbody>
    </table>
    <div class="category">
        <div class="category-text">
            Labour
        </div>
    </div>
    <table>
        <tbody>
            <tr>
                <td>
                    Labour amount subtotal
                </td>
                <td class="total">
                    {{this.totalLabourCost | xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}
                </td>
            </tr>
            <tr>
                <td>
                    Labour hours
                </td>
                <td class="total">
                    {{this.estimateResponse?.estimateSummary?.labourTimeHours}} Hrs
                </td>
            </tr>
            <tr>
                <td>
                    Labour discount({{this.estimateResponse?.estimateSummary?.labourTotalDiscountPercentage}}%)
                </td>
                <td class="total">
                    {{this.estimateResponse?.estimateSummary?.labourTotalDiscount |
                    xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}
                </td>
            </tr>
            <tr class="no-border">
                <td>
                    Total Labour Cost
                </td>
                <td class="total">
                    {{this.estimateResponse?.estimateSummary?.labourTotal | xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}
                </td>
            </tr>
        </tbody>
    </table>
    <div class="category">
        <div class="category-text">
            Paint
        </div>
    </div>
    <table class="paint-summary">
        <tbody>
            <tr>
                <td>
                    Paint labour time(hrs)
                </td>
                <td class="total">
                    {{this.estimateResponse?.estimateSummary?.paintLabourTimeHour}} Hrs
                </td>
            </tr>
            <tr>
                <td>
                    Paint labour subtotal
                </td>
                <td class="total">
                    {{this.estimateResponse?.estimateSummary?.paintLabourSubTotal |
                    xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}
                </td>
            </tr>
            <tr class="gray-border-bottom">
                <td>
                    Paint labour discount({{this.estimateResponse?.estimateSummary?.paintLabourDiscountPercentage}}%)
                </td>
                <td class="total">
                    {{this.estimateResponse?.estimateSummary?.paintLabourDiscount |
                    xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}
                </td>
            </tr>
            <tr class="no-border">
                <td>
                    Total Paint Labour Cost
                </td>
                <td class="total">
                    {{this.estimateResponse?.estimateSummary?.paintLabourTotal |
                    xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}
                </td>
            </tr>
        </tbody>
    </table>
    <table>
        <tbody>
            <tr>
                <td>
                    Material subtotal
                </td>
                <td class="total">
                    {{this.estimateResponse?.estimateSummary?.paintMaterialSubTotal |
                    xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}
                </td>
            </tr>
            <tr>
                <td>
                    Material paint discount({{this.estimateResponse?.estimateSummary?.paintMateriaDiscountPercentage}}%)
                </td>
                <td class="total">
                    {{this.estimateResponse?.estimateSummary?.paintMaterialDiscount |
                    xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}
                </td>
            </tr>
            <tr class="no-border">
                <td>
                    Total Paint Material Cost
                </td>
                <td class="total">
                    {{this.estimateResponse?.estimateSummary?.paintMaterialTotal |
                    xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}
                </td>
            </tr>
            <tr class="no-border">
                <td>
                    Total Paint Cost
                </td>
                <td class="total">
                    {{this.estimateResponse?.estimateSummary?.paintTotal | xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}
                </td>
            </tr>
        </tbody>
    </table>
    <div class="cost-summary">
        <div class="cost-text">
            <h4>Total cost( w/o GST)</h4>
            <h4>{{total | xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position }}</h4>
        </div>
    </div>
    <div class="notes">
        <div class="span-1">
            <div class="p-10">
                <img src="assets/img/white_flag.png" />
                <span>Manual</span>
            </div>
            <div>
                <mat-icon [svgIcon]="'approximate'" aria-hidden="false"></mat-icon>
                <span>Approximate</span>
            </div>
        </div>

        <div class="span-1">
            <div class="p-10">
                <img src="assets/img/gt.png" />
                <span>Documented by GT Motive</span>
            </div>
            <div class="include">
                <div>
                    <b> I </b>
                    <span>Included</span>
                </div>
                <div>
                    <b>NI</b>
                    <span>Not Included</span>
                </div>
            </div>
        </div>
        <div class="span-1">
            <div class="p-10">
                <img src="assets/img/prepainted_part.png" />
                <span>Prepainted part</span>
            </div>
            <div>
                <b>C </b>
                <span>Conditioned by</span>
            </div>
        </div>

        <div class="span-1">
            <div class="p-10" style="text-align:right;">
                <b>+</b>
                <span>Thrown by</span>
            </div>
            <div>
                <b>-</b>
                <span>Overlapped by</span>
            </div>
        </div>

    </div>
</div>