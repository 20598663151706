export const OPERATION_TYPE = {
  'replace': 'Replace',
  'repair': 'Repair',
  'removeAndRefit': 'Remove & Refit',
  'painting': 'Painting',
  'polish': 'Polish',
  'checkFields': 'Check',
  'performFields': 'Perform'
}


export const DEFAULT_QUANTITY_VALUE = 1