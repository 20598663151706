import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

  /**
   * convertMinsToHrsMins 
   * @param value 
   * @returns 
   */
  transform(mins: number): string {
    return mins%60 === 0 ? `${mins/60}h` : `${(mins/60).toFixed(1)}h`;
  }

}
