<div class="toast-notification" (click)="dismiss()">
    <div class="toast-container">
        <div class="toast-message">
            <div class="info-icon" [ngClass]="{'low-hegh':!showInfo}">
                <mat-icon [svgIcon]="showInfo?'infos':'i'" aria-hidden="false" 
                aria-label='info icon'></mat-icon>
            </div>
           
             
            <label>
                {{message | translate }} &nbsp;
                <span *ngIf="action" (click)="openLabourRatePopup()">
                    {{action}}
                </span>
            </label> 
            <mat-icon  (click)="dismiss()" class="cross-icon"  svgIcon="cross" aria-hidden="false"
                aria-label='info icon'></mat-icon>
        </div>
    </div>
</div>