<div class="estimate-add-part fx-col h-100">
    <div class="toolbar">
        <chq-tabs [mode]="'default'" #tabs [menuItems]="menuItems"></chq-tabs>
    </div>
    <form class="fx-1">
        <div class="row">
            <div class="col-6">
                <chq-input 
                    #operationType
                    [inputModel]="formModel.operationType"
                    (clicked)="hideDropdown($event);" 
                    (controlOutput)="onFormUpdate($event)"
                ></chq-input>
            </div>
            <div class="col-6">
                <chq-input 
                    #operationControl
                    [inputModel]="formModel.operation"
                    (clicked)="hideDropdown($event);" 
                    (controlOutput)="onFormUpdate($event)"
                ></chq-input>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <chq-input 
                    #codeControl
                    [inputModel]="formModel.code"
                    (clicked)="hideDropdown($event);" 
                    (controlOutput)="onFormUpdate($event)"
                ></chq-input>
            </div>
            <div class="col-6">
                <div class="row">
                    <chq-input 
                        class="col-6"
                        [inputModel]="formModel.quantity"
                        (clicked)="hideDropdown($event);" 
                        (controlOutput)="onFormUpdate($event)"
                    ></chq-input>
                    <chq-input 
                        class="col-6"
                        [inputModel]="formModel.price"
                        (clicked)="hideDropdown($event);" 
                        (controlOutput)="onFormUpdate($event)"
                    ></chq-input>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6 repair-type">
                <chq-input 
                    [inputModel]="formModel.repairType"
                    (clicked)="hideDropdown($event);" 
                    (controlOutput)="onFormUpdate($event)"
                ></chq-input>
            </div>
            <div class="col-6">
                <div class="row">
                    <chq-input 
                        class="col-6"
                        [inputModel]="formModel.labourTime"
                        (clicked)="hideDropdown($event);" 
                        (controlOutput)="onFormUpdate($event)"
                    ></chq-input>
                </div>
            </div>
        </div>
    </form> 
    <div class="row">
        <div class="col-6"></div>
        <div class="col-6">
            <div class="footer-button fx-row justify-end">
                <chq-button [buttonModel]="resetButtonModel" class="reset-button"></chq-button>
                <chq-button [buttonModel]="addButtonModel" ></chq-button>
            </div>
        </div>
    </div>   
</div>

 
