import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { CommonService } from './services/common/common.service';
import { Location } from '@angular/common';
import { AuthenticationResult, BrowserUtils } from '@azure/msal-browser';
import { MonitorService } from './services/monitor/monitor.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './services/auth/auth.service';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'car-heal-quote';
  isIframe = false;
  loginDisplay = false;

  /**
   * constructor
   * @param commonService 
   */
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: AuthService,
    public commonService: CommonService,
    private location: Location,
    private monitorService: MonitorService,
    private translateService: TranslateService,
  ) {
    this.authService.handleAuthorizationConfig((result: AuthenticationResult): void => {
      this.monitorService.logEvent('Login Success', [ 'Login', 'addenda-quote' ],
        { 'userId': result.account?.localAccountId, 'email': result.account?.username, 'trackingId': result.correlationId });
    });
  }

  /**
   * ngAfterViewInit hook
   * @param fb FormBuilder 
   */
  ngAfterViewInit(): void {
    this.commonService.updatePage('/quote/case/0');
    this.monitorService.logEvent('ngAfterViewInit', [ 'AppComponent', 'addenda-quote' ]);
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    const currentPath = this.location.path();
    this.monitorService.logEvent('ngOnInit', [ 'AppComponent', 'addenda-quote' ]);
    // Dont perform nav if in iframe or popup, other than for front-channel logout
    this.isIframe = BrowserUtils.isInIframe() && !window.opener && currentPath.indexOf('logout') < 0; // Remove this line to use Angular Universal

    const defaultLang = localStorage.getItem('defaultLanguage');
    if (defaultLang) {
      this.translateService.use(defaultLang);
    }
  }
}
