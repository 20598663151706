import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { menuItem } from 'src/app/model/menu';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'chq-tabs',
  templateUrl: './chq-tabs.component.html',
  styleUrls: [ './chq-tabs.component.scss' ]
})
export class ChqTabsComponent implements OnInit {
  @Input() mode: string = 'default';
  @Input() menuItems: menuItem[];
  @Output() menuItemClick: EventEmitter<menuItem> = new EventEmitter();

  /**
   * constructor
   * @param dialog 
   */
  constructor(private monitorService: MonitorService, private router:Router) { 
    this.router.events.subscribe(()=>{
      this.setCurrentActiveMenu();
    });
  }

  /**
   * split and get last
   */
  splitter(path: string): string {
    const splitedUrls = path?.split('/');
    if(splitedUrls?.length > 0) {
      const currentSplittedUrl = splitedUrls[splitedUrls.length - 1];
      if(currentSplittedUrl.includes('?')){
        const urlWithParams = currentSplittedUrl.split('?')[0];
        return urlWithParams;
      }
      return currentSplittedUrl;
    }
    return '';
  }

  /**
   * get active menu
   */
  setCurrentActiveMenu(): void {
    this.menuItems?.map(x => x.active = false);
    const currentPath = this.splitter(location.href);
    const activeMenu = this.menuItems?.filter((menu: menuItem) => {
      return this.splitter(menu.url) === currentPath || (menu.childMenuLinks && menu.childMenuLinks?.includes(currentPath));
    });
    if (activeMenu && activeMenu.length > 0) {
      activeMenu[0].active = true;
    } else {
      if (this.menuItems && this.menuItems.length > 0) {
        this.menuItems[0].active = true;
      }

    }
  }

  /**
   * set menu active
   */
  setActiveMenu(menuItem:menuItem):void{
    this.menuItems.map(x => x.active = false);
    const activeMenu = this.menuItems.filter(x => x.url === menuItem.url);
    if(activeMenu && activeMenu.length > 0){
      activeMenu[0].active = true;
    }
    
  }

  /**
   * click trigger
   */
  triggerMenuItemClick(menuItem: menuItem): void {
    this.menuItemClick?.emit(menuItem);
  }

  /**
   * init
   */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'ChqTabsComponent', 'addenda-quote' ]);
    this.setCurrentActiveMenu();
  }
}
