import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ConfigurationService } from 'src/app/config/configuration/service/configuration.service';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'xa-camera-overlay',
  templateUrl: './camera-overlay.component.html',
  styleUrls: [ './camera-overlay.component.scss' ]
})
export class CameraOverlayComponent implements OnInit {
  @Input() vehicleModel: string;
  @Input() procedureStep:string;
  @ViewChild('imgElement') imgElement: ElementRef<HTMLImageElement>;
  public overlayShadow:string = '';
  
  /**
   * 
   * @param configurationService 
   */
  constructor(
    private configurationService:ConfigurationService,
    private commonService:CommonService
  ) {}

  /**
   * on init
   */
  ngOnInit(): void {
    this.fillImage();
  }

  /**
   * Returns the URL of the SVG outline for the given vehicle model.
   * The URL is constructed by concatenating the base URL of the
   * vehicle outline SVGs with the vehicle model name.
   * @returns a string URL of the SVG outline.
   */
  public get getVehicleOutline():string {
    return this.vehicleModel || '/assets/img/addenda-white.svg';
  }

  /**
   * fill image
   */
  fillImage():void{
    const img = new Image();
    // eslint-disable-next-line max-len
    this.commonService.imageReaderObservable(this.getVehicleOutline).subscribe((data) => {
      const url = data.image;
      const tempImg = new Image();
      tempImg.src = url;
      tempImg.onload = (event:any):void=>{
        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');
        tempCanvas.height = tempImg.naturalHeight;
        tempCanvas.width = tempImg.naturalWidth;

        tempCtx.drawImage(tempImg, 0, 0);
        this.commonService.processImage(tempCanvas, '#FF0000');
        this.overlayShadow = tempCanvas.toDataURL('image/png');
      }
    });
  }


  /**
   * get orientation
   */
  get getOrientationLock():boolean{
    return this.configurationService.getCameraFlags()?.Camera_Orientation?.toLowerCase() === 'landscape';
  }

  
}
