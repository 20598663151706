<div class="reject-claim-container">
    <div class="header">
        <h3>{{'reject_claim' | translate }}</h3>
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'" (click)="close();"></mat-icon>
        </div>
    </div>
    <div class="content gap-23">
        <div class="sub-title">{{'select_a_reason_below' | translate }}</div>
        <chq-input class="checkbox-label" [inputModel]="reasonModel"
            (controlOutput)="onFormUpdate($event)"></chq-input>
        <chq-input [inputModel]="messageModel" (controlOutput)="onFormUpdate($event);" tabindex="-1"></chq-input>
    </div>
    <div class="footer form-data-footer">
        <chq-button [buttonModel]="sendButton" class="right"></chq-button>
    </div>
</div>