<div class="payment-container fx-col">
    <div class="header">
        <h3>{{ 'payment' | translate }} </h3>
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'" (click)="cancelDialog(false);"></mat-icon>
        </div>
    </div>
    <div class="content fx-col fx-1">
        <payment-add [repairId]="data.repairId" #paymentadd [repairPaymentStatus]="data.paymentStatus"
            [dueAmount]="data.dueAmount" [invoiceDate]="data.invoiceDate" *ngIf="!this.commonService.isViewOnly && !data?.isXeroIntegrated"
            [invoiceNumber]="data.invoiceNumber" (handleAddPayment)="handleAddPayment($event)"></payment-add>
        <payment-list #paymentList [invoiceGuid]="data.invoiceGuid" [isXeroIntegrated]="data?.isXeroIntegrated" [dueAmount]="data.dueAmount"
            (handlePaymentDelete)="handlePaymentDelete($event)"></payment-list>
    </div>
</div>
