<div class="pagination" [ngClass]="{'search-paginator': searchMode === 'search'}">
  <div class="page-pager">
    <div class="pager hidden-sm-down">
         <div class="prev">
          <ul>
            <li [ngClass]="{disabled:pager.currentPage === pager.startPage}" class="page-item first-item">
              <a (click)="setPage(pager.startPage)" class="page-link icon">
                <mat-icon [svgIcon]="'double-arrow-left'" aria-hidden="false" aria-label="first"></mat-icon>
                </a>
            </li>
            <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item previous-item">
                <a (click)="setPage(pager.currentPage - 1)" class="page-link icon">
                  <mat-icon [svgIcon]="'arrow-left'" aria-hidden="false" aria-label="arrow left"></mat-icon>
                  </a>
            </li>
          </ul>
         </div>
          <div class="pages">
            <ul>
              <li *ngFor="let page of pager.pages" [ngClass]="{'active disabled':pager.currentPage === page}" class="page-item number-item">
                <a (click)="setPage(page)" class="page-link">{{page}}</a>
              </li>
            </ul>
          </div>
          <div class="next">
            <ul>
              <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item next-item">
                <a (click)="setPage(pager.currentPage + 1)" class="page-link icon">
                  <mat-icon [svgIcon]="'arrow-right'" aria-hidden="false" aria-label="arrow right"></mat-icon>
                </a>
              </li>
              <li [ngClass]="{disabled:pager.currentPage === pager.endPage}" class="page-item last-item">
                <a (click)="setPage(pager.endPage)" class="page-link icon">
                  <mat-icon [svgIcon]="'double-arrow-right'" aria-hidden="false" aria-label="arrow right"></mat-icon>
                </a>
              </li>
            </ul>
          </div>
    </div>
  </div>
</div>
