<div class="file-upload-container"
     [ngClass]="wrapperClass"
     [style.height]="wrapperStyle?.height"
     [style.width]="wrapperStyle?.width">
  <div class="form-field-text">
    <label class="form-label pointer-events">
      {{label | translate}}
    </label>
    <div class="text-field">
      <input class="form-value"
             type="file"
             [formControl]="internalControl"
             (change)="handleUpload($event)"
             autocomplete="off"
             #input
             [id]="inputId"
             [name]="nameId"
             [maxLength]="maxLength"
             [multiple]="multiple"
             [accept]="fileExtensions"
             [placeholder]="placeholder ?? label"
             [readonly]="readonly"
             [disabled]="disabled"
             [required]="required"
             (keyup)="_onInputInteraction()">
    </div>
  </div>
  <div class="thumbnail-wrapper"
       *ngIf="uploadedFiles?.length > 0">
    <div class="thumbnail-container">
      <div class="thumbnail-card"
           *ngFor="let file of uploadedFiles;let i = index;">
        <mat-icon svgIcon="cross"
                  (click)="removeFile(i);"
                  aria-hidden=" false"
                  class="thumbnail-icon"></mat-icon>
        <img [src]="file.image"
             [alt]="file.fileName" />
        <label>{{file.fileName}}</label>
      </div>
      <div class="thumbnail-card"
           *ngIf="uploadedFiles.length < maxLength || !multiple">
        <img [src]="placeHolderUrl"
             [alt]="placeHolderUrl" />
        <xa-button type="basic"
                   size="xs"
                   (click)="triggerUpload()">Upload</xa-button>
      </div>
    </div>
  </div>


  <div *ngIf="uploadedFiles.length === 0"
       class="placeholder-uploader">

    <div class="drag-drop-container">
      <div class="dd-image-container">
        <div class="car-image-container default-image">
          <img [src]="placeHolderUrl"
               alt="loading..." />
        </div>
      </div>
      <div class="dd-content">
        <h3>Drag and Drop</h3>
        <xa-button type="basic"
                   (click)="triggerUpload()">Select file to upload</xa-button>
      </div>
    </div>
  </div>
</div>