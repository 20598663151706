import { Component, Input } from '@angular/core';

@Component({
  selector: 'chq-custom-loader',
  templateUrl: './chq-custom-loader.component.html',
  styleUrls: [ './chq-custom-loader.component.scss' ]
})
export class CustomLoaderComponent {
  @Input() showLoading: boolean;
  @Input() strokeWidth: string = '3';
  @Input() diameter: string = '30';

}
