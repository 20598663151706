<div class="form-field-text">
    <label class="form-label pointer-events"
           for="inputId">
        {{label | translate}}
        <span class="required"
              *ngIf="required && label">*</span>
    </label>
    <div class="editor-field">
        <editor #editor
                [init]="init"
                [initialValue]="value"></editor>
        <p class="counter-label">{{characterLength}} / {{maxCharacterLimit}}</p>
    </div>

</div>