import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { firstValueFrom } from 'rxjs';
import { PaymentsTableConfig } from 'src/app/config/display/payments-table-config';
import { CommonService, Localization } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { CellClickedCallback, TableCellData, TableConfig, TableRowData } from 'src/app/widgets/xa-table/xa-table.component';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';

@Component({
  selector: 'payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: [ './payment-list.component.scss' ]
})
export class PaymentListComponent {
  @Input() invoiceGuid: any;
  @Input() dueAmount: any;
  @Input() isXeroIntegrated = false
  @ViewChild('paymentTableWrapper') paymentTableWrapper: ElementRef;
  @Output() handlePaymentDelete: EventEmitter<any> = new EventEmitter();
  //Table variables
  public tableConfig: TableConfig = PaymentsTableConfig;
  public tableData: any = [];
  public pageChanged: boolean = false;
  public showData: boolean = false;


  // Currency formatter Variables
  public currencySymbol: string = '';
  public locale: string = '';
  public numberFormat: string = '';
  i18n: Localization;

  /**
    Constructor for the PaymentListComponent.
    @constructor
    @param {MonitorService} monitorService - Service for monitoring operations.
    @param {PaymentService} paymentService - Service for payment.
    @param {CommonService} commonService - An instance of the CommonService class.
  */
  constructor(
    public monitorService: MonitorService,
    public paymentService: PaymentService,
    private commonService: CommonService,
    private translateService: TranslateService,
  ) {
    this.i18n = this.commonService.geti18nInfo();
    this.currencySymbol = this.i18n.currencySymbol + ' '
    this.locale = this.i18n.locale
    this.numberFormat = this.i18n.digitsInfo
  }

  /**
    Lifecycle hook that is called after the component is initialized.
  */
  ngOnInit(): void {
    if(this.isXeroIntegrated) {
      const hideColumnsXeroIntegrated = [ 'DeleteAction' ]
      this.tableConfig.columns.forEach((el)=> {
        if(hideColumnsXeroIntegrated.includes(el.id)) {
          el.style = 'display: none;max-width: 0';
          el.cellStyle = 'display: none;max-width: 0';
        }
      })
    }
    this.monitorService.logEvent('ngOnInit', [ 'PaymentListComponent', 'addenda-repair' ]);
    // this.commonService.userProfileData.subscribe((res) => {
    //   this.currencySymbol = res?.data?.organizationDetail?.currencyName + ' ' || '';
    // })
    this.getPayments(this.invoiceGuid);
  }

  /**
   * CHecks if current browser is mac or not
   * @returns boolean
   */
  get isiOS(): boolean {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  }

  /**
    Handles a cell click event.
    @param {TableRowData} row - The row data.
    @param {Partial} column - The column data.
    @returns {void}
  */
  handleCellClick: CellClickedCallback = (row: TableRowData, column: Partial<TableCellData>, event: PointerEvent): void => {
    if(column.id === 'DeleteAction') {
      this.deletePayment(row[7]?.result?.value)
    } else if(column.id === 'Download') {
      const urls = row[5]?.result.value.split('/');
      const fileName = urls[urls?.length -1]?.split('?')[0];

      this.commonService.showLoading()
      this.commonService.getFileBlob(row[5]?.result?.value).subscribe({
        next: (value)=>{
          this.commonService.hideLoading()
          const blob = new Blob([ value.res ], { type: 'application/octet-stream' });
          saveAs(blob, fileName)
          // const aTag = document.createElement('a');
          // const url = value.fileURL;
          // const urls = row[5]?.result.value.split('/');
          // if(urls && urls?.length > 0){
          //   const fileName = urls[urls?.length -1]?.split('?')[0];
          //   aTag.href = url;
          //   aTag.download =fileName;
          //   aTag.click();
          //   window.URL.revokeObjectURL(url);
          // }
        },
        error: (err: any) => {
          this.commonService.hideLoading();
          this.monitorService.logException(err);
        }
      });

    } else if(column.id === 'Preview') {
      window.open(row[5]?.result?.value, '_top');
    }
  };

  /**
      Retrieves a list of payments associated with a repair ID.
      @returns {Promise} A Promise that resolves with the list of payments.
  */
  public async getPayments(invoiceGuid:string): Promise<any> {
    this.commonService.showLoading();
    if(this.commonService.isViewOnly){
      this.tableConfig.columns = this.tableConfig.columns.filter(x => x.id !== 'DeleteAction');
    }
    this.monitorService.logEvent('getPayments', [ 'PaymentListComponent', 'addenda-repair' ]);
    return firstValueFrom(this.paymentService.getPayments(invoiceGuid)).then((response) => {
      if(response.data) {
        this.formatTableData(response.data);
        this.showData = true;
      }
      this.commonService.hideLoading();
    }).catch((error) => {
      this.commonService.hideLoading();
      this.monitorService.logException(error, SeverityLevel.Error);
    });
  }

  /**
    Formats the table data by adding a formatted amount column to each object in the result array.
    @param {Array} result - The array of objects to be formatted.
    @returns {void}
  */
  formatTableData(result: any): void {
    this.tableData = result;
  }

  /**

    Deletes a payment with the given paymentId.
    @param {string} paymentId - The ID of the payment to be deleted.
  */
  public async deletePayment(paymentId): Promise<any> {
    this.commonService.openDeleteDialog(
      '',
      this.translateService.instant('payment_delete_confirmation'),
      this.translateService.instant('delete'),
      this.translateService.instant('cancel')
    )
      .afterClosed().subscribe((data) => {
        if (data) {
          this.commonService.showLoading();
          this.monitorService.logEvent('deleteRow', [ 'PaymentListComponent', 'addenda-repair' ], {
            paymentId: paymentId
          });
          firstValueFrom(this.paymentService.deletePayment(paymentId, this.invoiceGuid)).then((response) => {
            this.commonService.hideLoading();
            if(response?.message) this.commonService.showInfoToast(5000, response.message);
            const currentObj = this.tableData.filter((row:any)=> row.paymentGuid === paymentId);
            this.handlePaymentDelete.emit({ 'Amount': currentObj[0]?.amount });
            this.getPayments(this.invoiceGuid);
          }).catch((error) => {
            this.commonService.hideLoading();
            this.monitorService.logException(error, SeverityLevel.Error);
          });
        }
      });
  }

  /**
    Adds a payment to the payment list.
    @returns {void}
  */
  addPayment(data: any): void {
    const existingData = [ ...this.tableData ];
    existingData.unshift(data)
    this.formatTableData(existingData);
    this.paymentTableWrapper.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
}
