import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, filter } from 'rxjs';
import { CommonService } from '../services/common/common.service';
import { CoreService } from '../services/core/core.service';
import { MonitorService } from '../services/monitor/monitor.service';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { menuItem, menuItems } from '../model/menu';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
  
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: [ './layout.component.scss' ]
})
export class LayoutComponent implements OnInit {
  orgLogoUrl: string = '';
  orgName: string = '';
  isSideMenuExpanded: boolean = true;
  smDevice: boolean = false;
  
  private readonly destroying$: Subject<void> = new Subject<void>();
  avatarText: string = '';
  avatar: string | ArrayBuffer;
  showSearchDetail:boolean = false;

  hideHeader:boolean = false;
  subscription: Subscription[] = [];
  isExternal: boolean = false;
  menuItems: menuItem[] = menuItems;
  
  /**
   * constructor
   * @param router 
   */
  constructor(
    private commonService: CommonService,
    private monitorService: MonitorService,
    private router: Router,
    private breakpointObserver: BreakpointObserver
  ) {

    if(this.commonService.accessRight.closed) {
      this.commonService.accessRight = new BehaviorSubject<boolean>(true);
    } 

    this.commonService.userProfileData.subscribe((res) => {
      if(res) {
        this.avatarText = `${res?.data.firstName.charAt(0)}${res?.data.lastName.charAt(0)}`;
        this.orgLogoUrl = res?.data.organizationDetail?.logoUrl;
        this.orgName = res?.data.organizationDetail?.name;
        this.commonService.userProfileLoading = false;
        this.commonService.setCurrencySymbolFromSymbolMapper(res?.data?.organizationDetail?.currencyName)
        if (res?.data.userProfileThumbnailImageBlobUrl || res?.data.userProfileImageBlobUrl) { 
          this.avatar = res?.data.userProfileThumbnailImageBlobUrl ?? res?.data.userProfileImageBlobUrl ;

        } else {
          this.avatar = null;
        }
      } 
    })

    this.commonService.avatar.subscribe((imageData) => {
      if (imageData?.image) {
        this.avatar = imageData.image;
      } else {
        this.avatar = null;
      }

      if (imageData?.avatarText) {
        this.avatarText = imageData.avatarText;
      } 
    }); 

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: RouterEvent) => {
        if(event.url.includes('/integration')){
          this.hideHeader = true;
        }else{
          this.hideHeader = false;
        }
      });


    const userProfile = this.commonService.userProfileData.subscribe({
      next: (res: any) => {
        if (res) {
          this.orgLogoUrl = res?.data.organizationDetail?.logoUrl;
          this.orgName = res?.data.organizationDetail?.name;
          const automotiveServices = res?.data?.userPermission?.automotiveServices;
          const permissionList = automotiveServices?.find(x => x.automotiveServiceName.toLowerCase() === 'carheal quote')?.permissions;
          const quoteMenus = this.menuItems?.find(x => x.name === 'quote')?.childMenu;
          if (quoteMenus && quoteMenus?.length > 0 && permissionList && permissionList?.length > 0) {
            this.isExternal = this.commonService.hasPermission('case.external', permissionList);
          }          
        }
    
      }
    });
    this.subscription.push(userProfile);

    this.breakpointObserver.observe([
      '(max-width: 576px)'
    ]).subscribe((result: BreakpointState) => {
      if (result.matches) {
        this.smDevice = true;   
        this.isSideMenuExpanded = false;  
      } else {
        this.smDevice = false; 
        this.isSideMenuExpanded = true;
      }
    });
  }

  /**
   * close search bar
   */
  handleSearchEvent($event:boolean):void{
    this.showSearchDetail = $event;
  }


  /**
   * on init
   */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'LayoutComponent', 'addenda-quote' ]); 
    const el: any = document.getElementById('custom-loader');
    if(el) {
      el.style.display = 'none';
    }
  }

  /**
   * CHecks if current browser is mac or not
   * @returns boolean
   */
  get isiOS(): boolean {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  }

  /**
   * 
   * @param event 
   */
  onToggle(): void {
    this.isSideMenuExpanded = !this.isSideMenuExpanded;
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
    this.commonService.accessRight.unsubscribe();
    this.monitorService.logEvent('ngOnDestroy', [ 'LayoutComponent', 'addenda-quote' ]);
    this.subscription.forEach((sub:Subscription) => sub.unsubscribe());
  }
}
