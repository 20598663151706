import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: [ './alert-dialog.component.scss' ]
})
export class AlertDialogComponent {

  /**
   * constructor
   * @param dialogRef 
   * @param data 
   */
  constructor(public dialogRef: MatDialogRef<AlertDialogComponent>,
    private monitorService:MonitorService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  /**
   * close dialog
   */
  close(): void {
    this.dialogRef.close(true);
    this.monitorService.logEvent('close', [ 'AlertDialogComponent', 'addenda-quote' ]);
  }

}
