import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minuteToHourMinute'
})
export class MinuteToHourMinutePipe implements PipeTransform {

  /**
   * 
   * @param value 
   * @returns 
   */
  transform(value: number): string {
    if (value === null || value === undefined || isNaN(value)) {
      return '';
    }

    const hours = Math.floor(value / 60);
    const minutes = value % 60;

    let result = '';
    if (hours > 0) {
      result += `${hours}h`;
    }
    if (minutes > 0) {
      result += `${result ? ' ' : ''}${minutes}m`;
    }

    return result === '' ? '0h' : result;
  }

}
