<third-party-common-layout class="accept-reject-claim-wrapper">
  <ng-container *ngIf="!isSubmissionSuccess">
    <div class="card-header">
      <div class="row">
        <h2 class="col card-heading">{{"new_claim_repair_assignment" | translate }}</h2>
        <div class="col-auto ml-auto">
          <chq-button [buttonModel]="viewRepairDetails"></chq-button>
        </div>
      </div>
    </div>
    <div class="card-body mobile-view">
      <div class="row mt-40">
        <div class="col-4">
          <label>{{"insurance_company_name" | translate }}</label>
          <span>{{claimDetails?.organizationDetail?.nameOfOrganization || '-'}}</span>
        </div>
      </div>
      <div class="row mt-40">
        <div class="col-4">
          <label>{{"policy_number" | translate }}</label>
          <span>{{claimDetails?.customerDetails?.policyNo || '-'}}</span>
        </div>
        <div class="col-4">
          <label>{{"claim_number" | translate }}</label>
          <span>{{claimDetails?.claimNo || '-'}}</span>
        </div>
        <div class="col-4">
          <label>{{"type_of_claim" | translate }}</label>
          <span>{{claimDetails?.customerDetails?.category || '-'}}</span>
        </div>
      </div>
      <div class="row mt-40">
        <div class="col-4">
          <label>{{"claimant_name" | translate }}</label>
          <span>{{claimDetails?.customerDetails?.customerFullName || '-'}}</span>
        </div>
        <div class="col-4">
          <label>{{"email_address" | translate }}</label>
          <span>{{claimDetails?.customerDetails?.policyHolderEmail || '-'}}</span>
        </div>
        <div class="col-4 row m-0">
          <div class="col-auto p-0 mr-25px">
            <label>{{"code" | translate }} *</label>
            <span>{{claimDetails?.customerDetails?.policyMobileCountryCode || '-'}}</span>
          </div>
          <div class="col-auto">
            <label>{{"mobile_number" | translate }} *</label>
            <span>{{claimDetails?.customerDetails?.policyHolderMobileNumber || '-'}}</span>
          </div>
        </div>
      </div>
      <div class="row mt-40">
        <div class="col-4">
          <label>{{"vehicle_make" | translate }}</label>
          <span>{{claimDetails?.vehicleMetaData?.make || '-'}}</span>
        </div>
        <div class="col-4">
          <label>{{"vehicle_model" | translate }}</label>
          <span>{{claimDetails?.vehicleMetaData?.model || '-'}}</span>
        </div>
        <div class="col-4">
          <label>{{"vehicle_plate_number" | translate }}</label>
          <span>{{claimDetails?.vehicleMetaData?.plateNo || '-'}}</span>
        </div>
      </div>
      <div class="row mt-40">
        <div class="col">
          <label>{{"accident_location" | translate }}</label>
          <span>{{claimDetails?.lossDetails?.lossLocation || '-'}}</span>
        </div>
      </div>
      <div class="row mt-40">
        <div class="col accept-reject-question">
          <span class="d-block">{{'do_you_want_to_accept_this_repair_from_insurance_company' | translate }}</span>
          <chq-input [inputModel]="formModel.acceptRepair" (controlOutput)="onFormUpdate($event)"></chq-input>
        </div>
      </div>
      <div class="row mt-40">
        <div class="col-auto ml-auto">
          <chq-button [buttonModel]="submitButton"></chq-button>
        </div>
      </div>
    </div>
</ng-container>
  <div class="success-submission" *ngIf="isSubmissionSuccess">
    <h3>Success!</h3>
    <label>Your response has been submitted successfully.</label>
  </div>
</third-party-common-layout>
