/* eslint-disable @typescript-eslint/naming-convention */
export const REPAIR_MENU = {
  'Addenda_Repair_Documents': 'Upload Documents',
  'Addenda_Repair_Claim_Customer': 'Personal Info',
  'Addenda_Repair_Estimate': 'Estimate',
  'Addenda_Repair_Photos': 'Upload Images',
};

export const QUOTE_MENU = {
  'Addenda_Quote_Customer': 'Add customer',
  'Addenda_Quote_Vehicle': 'Add vehicle',
  'Addenda_Quote_Documents': 'Add documents',
  'Addenda_Quote_Photos': 'Add photos',
  'Addenda_Quote_Repair_Estimate': 'Repair estimate',
};

export enum QUOTE_ROUTE{
  'Addenda_Quote_Customer'= '',
  'Addenda_Quote_Vehicle'= 'vehicle',
  'Addenda_Quote_Documents'= 'document',
  'Addenda_Quote_Photos'= 'photos',
  'Addenda_Quote_Repair_Estimate'= 'estimate'
}

export enum QUOTE_NEXT_LABEL{
  'Addenda_Quote_Customer'= '',
  'Addenda_Quote_Vehicle'= 'next_add_vehicle',
  'Addenda_Quote_Documents'= 'next_add_documents',
  'Addenda_Quote_Photos'= 'next_add_photos',
  'Addenda_Quote_Repair_Estimate'= 'next_create_estimate'
}

export const FNOL_MENU = {
  'Fnol_Documents': 'Documents',
  'Fnol_Vehicle': 'Vehicle',
  'Fnol_Photos': 'Photos',
  'Fnol_Submit_Claim_Form': 'Submit',
};

export enum FNOL_NEXT_LABEL{
  'Fnol_Vehicle'= 'add_vehicle',
  'Fnol_Documents'= 'add_documents',
  'Fnol_Photos'= 'add_photos',
  'Fnol_Submit_Claim_Form'= 'submit_fnol'
}

