<div class="main-container">
    <ng-container *ngIf="name === 'liva' else defaultTemplate">
        <div class="liva-container">
            <div class="header">
                <img alt="liva-logo"
                     src="assets/img/liva-logo.png" />
            </div>
            <div class="main-content">
                <div class="title">
                    Welcome to our online assistance!
                </div>
                <div class="title-desc">
                    To assist you efficiently and effectively, we kindly request that you submit the necessary details concerning your motor claim. This will enable us to assess your situation promptly and take the appropriate steps to resolve your claim.
                </div>
                <div class="step">
                    Include any supporting documents that may be required, such as
                    <div class="sub-step">
                        a. Police report from the police department
                    </div>
                    <div class="sub-step">
                        b. Photos( angle wise)
                    </div>
                    <div class="sub-step">
                        c. Registration card
                    </div>
                    <div class="sub-step">
                        d. Driving license
                    </div>
                </div>
                <div class="navigation"
                     (click)="navigate()">
                    <img alt="liva-claim"
                         src="assets/img/liva-claim.svg" />
                    <span>Submit a Claim</span>
                </div>
            </div>
            <div class="footer">
                Copyright © {{year}} Liva Insurance
            </div>
        </div>
    </ng-container>

    <ng-template #defaultTemplate>
        <div class="default-container">
            <div class="header">
                <img alt="addenda-logo"
                     *ngIf="logoUrl"
                     [src]="logoUrl" />
            </div>
            <div class="main-content">
                <div class="content-wrapper">
                    <div class="title">
                        {{'title_fnol' | translate}}
                    </div>
                    <div class="title-desc">
                        {{'desc_fnol' | translate}}
                    </div>
                    <div class="step-title">
                        {{pageComponents?.ItemValue | translate}}
                    </div>
                    <div class="step"
                         *ngIf="pageComponents?.value">
                        <div class="sub-step"
                             *ngFor="let slot of pageComponents?.value">
                            <div class="icon-wrapper">
                                <img [src]="baseConfigUrl + slot.ItemValue"
                                     alt="sloTIcon" />
                            </div>
                            {{slot.ItemKey | translate}}
                        </div>
                    </div>

                    <label for="select"
                           *ngIf="!isDirectUrl"
                           class="select-label">Select your Insurance to get started</label>
                    <div class="select-container"
                         *ngIf="!isDirectUrl">
                        <xa-select [(ngModel)]="selectedTenant"
                                   [config]="config"
                                   [options]="options"></xa-select>
                        <button class="proceed-btn"
                                [disabled]="!selectedTenant || !termsAndCondition"
                                (click)="proceed()">Proceed</button>
                    </div>
                    <div class="terms-conditions"
                         *ngIf="!isDirectUrl">
                        <xa-checkbox [(ngModel)]="termsAndCondition"></xa-checkbox>
                        <div class="terms-text">I have read the <span class="terms-link"
                                  (click)="openTermsAndConditions($event)">Terms & Conditions</span></div>
                    </div>
                    <xa-button class="navigation-btn"
                               type="outline"
                               size="lg"
                               *ngIf="isDirectUrl"
                               (click)="navigate()">
                        <span>Proceed</span>
                    </xa-button>

                    <div class="image-wrapper show-sm"
                         *ngIf="androidLink || iosLink">
                        <span class="get-now"> Get it NOW!</span>
                        <div class="img-content">
                            <img class="mobile-screen"
                                 alt="mobile-screen"
                                 src="assets/img/mobile-screen.svg" />
                            <div class="eclipse-bg show-sm"></div>
                        </div>
                        <div class="store-link show-sm">
                            <a rel="noopener"
                               *ngIf="androidLink"
                               [href]="androidLink"
                               target="_blank"><img alt="play-store"
                                     src="assets/img/googleplay.png" /></a>
                            <a rel="noopener"
                               [href]="iosLink"
                               *ngIf="iosLink"
                               target="_blank"><img alt="app-store"
                                     src="assets/img/appstore.png" /></a>
                        </div>

                    </div>
                </div>
                <div class="image-wrapper hide-sm"
                     *ngIf="androidLink || iosLink">
                    <span class="get-now"> Get it NOW!</span>
                    <img class="mobile-screen"
                         alt="mobile-screen"
                         src="assets/img/mobile-screen.svg" />
                    <div class="store-link">
                        <a rel="noopener"
                           [href]="androidLink"
                           target="_blank"
                           *ngIf="androidLink"><img alt="play-store"
                                 src="assets/img/googleplay.png" /></a>
                        <a rel="noopener"
                           [href]="iosLink"
                           target="_blank"
                           *ngIf="iosLink"><img alt="app-store"
                                 src="assets/img/appstore.png" /></a>
                    </div>
                </div>
            </div>
            <div class="footer">
                © Copyright {{year}}, ADDENDA. All Rights Reserved
            </div>

            <div class="eclipse-bg hide-sm"
                 *ngIf="androidLink || iosLink"></div>
        </div>
    </ng-template>

</div>