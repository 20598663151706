<div class="labour-rate-container" [ngClass]="{'text-mode':isViewOnly}" *ngIf="pageReady">
    <div class="header">
        <h3>Labour Rate</h3>
        <div class="close-icon" *ngIf="showClose">
            <mat-icon [svgIcon]="'cross'" style="fill:black;" (click)="cancelDialog();"></mat-icon>
        </div>
    </div>
    <div class="content">
        <div class="content-header">
            <h4>Labour Type</h4>
        </div>
        <div class="content-body row">
            <chq-input id='input-mechanic-cost' class="col-24" [inputModel]="formModel.mechanicCost"
                (controlOutput)="onFormUpdate($event)"></chq-input>
            <chq-input id='input-paint-cost' class="col-24" [inputModel]="formModel.paintCost"
                (controlOutput)="onFormUpdate($event)"></chq-input>
            <chq-input id='input-interior-cost' class="col-24" [inputModel]="formModel.interiorCost"
                (controlOutput)="onFormUpdate($event)"></chq-input>
            <chq-input id='input-electric-cost' class="col-24" [inputModel]="formModel.electricCost"
                (controlOutput)="onFormUpdate($event)"></chq-input>
            <chq-input id='input-body-cost' class="col-24" [inputModel]="formModel.bodyCost"
                (controlOutput)="onFormUpdate($event)"></chq-input>
        </div>
        <div class="content-header discount">
            <h4>Discount Type</h4>
        </div>
        <div class="content-body row discount-row">
            <chq-input id='input-parts-discount' class="col-24" [inputModel]="formModel.partsDiscount" #partsDiscount
                (controlOutput)="onFormUpdate($event)"></chq-input>
            <chq-input id='input-labour-discount' class="col-24" [inputModel]="formModel.labourDiscount" #labourDiscount
                (controlOutput)="onFormUpdate($event)"></chq-input>
            <chq-input id='input-paint-discount' class="col-24" [inputModel]="formModel.paintDiscount" #paintDiscount
                (controlOutput)="onFormUpdate($event)"></chq-input>
            <chq-input id='input-paint-mat-discount' class="col-24" (controlOutput)="onFormUpdate($event)" #matPaintDiscount
                [inputModel]="formModel.paintMatDiscount"></chq-input>
        </div>
    </div>
    <div class="footer">
        <chq-button [buttonModel]="updateButtonModel" [hidden]="isViewOnly"></chq-button>
    </div>
</div>