import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(utc)

@Component({
  selector: 'xa-date-range-picker',
  templateUrl: './xa-date-range-picker.component.html',
  styleUrls: [ './xa-date-range-picker.component.scss' ]
})
export class XaDateRangePickerComponent implements OnInit {
  @Input() showRangeType: boolean = false;
  @Input() includeTime: boolean = false;
  @Output() controlOutput: EventEmitter<any> = new EventEmitter();
  
  public startDate: any = dayjs.Dayjs;
  public endDate: any = dayjs.Dayjs;
  public selectedOption: dayjs.ManipulateType = 'week';
  public sameMonth: boolean = false;

  /**
   * 
   */
  ngOnInit(): void {
    this.updateDateRange();
  }

  /**
   * 
   */
  updateDateRange(): void {
    if (this.selectedOption === 'day') {
      this.endDate = dayjs().endOf('day');
      this.startDate = dayjs().startOf('day');
    } else if (this.selectedOption === 'week') {
      this.endDate = dayjs().endOf('week').add(1, 'day');
      this.startDate = dayjs().startOf('week').add(1, 'day');
    } else if (this.selectedOption === 'month') {
      this.endDate = dayjs().endOf('month');
      this.startDate = dayjs().startOf('month');
    }

    this.emitUtcDateObject();
  }

  /**
   * 
   * @param option 
   */
  selectOption(option: any): void {
    this.selectedOption = option;
    this.updateDateRange();
  }

  /**
   * 
   */
  goPrevious(): void{
    this.startDate = this.startDate.subtract(1, this.selectedOption);
    this.endDate = this.endDate.subtract(1, this.selectedOption);

    this.emitUtcDateObject();
  }

  /**
   * 
   */
  goNext(): void{
    this.startDate = this.startDate.add(1, this.selectedOption);
    this.endDate = this.endDate.add(1, this.selectedOption);

    this.emitUtcDateObject();
  }

  /**
   * 
   */
  emitUtcDateObject():void{
    let currentDate = this.startDate
    const rangeDate = [];

    do {
      rangeDate.push({ key: currentDate.format('dddd'), day: currentDate.format('ddd'), date: currentDate.format('YYYY-MM-DD'), isToday: dayjs().isSame(currentDate, 'day') });
      currentDate = currentDate.add(1, 'day');
    } while (this.endDate > currentDate);

    let json = {
      startDate: dayjs(this.startDate).format('YYYY-MM-DD'),
      endDate: dayjs(this.endDate).format('YYYY-MM-DD'),
      mode: this.selectedOption,
      range: rangeDate
    };

    if(this.includeTime) { 
      json = {
        startDate: dayjs(this.startDate).utc().format(),
        endDate: dayjs(this.endDate).utc().format(),
        mode: this.selectedOption,
        range: rangeDate
      };
    }

    this.sameMonth = this.startDate.month() == this.endDate.month();
    
    this.controlOutput.emit(json);
  }
}
