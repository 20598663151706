<div class="header-container"
     [ngClass]="{ 'align-center': chqModel?.isRepairModule || chqModel?.isClaimModule || chqModel?.isFnolModule}">
  <div class="content-wrapper-left"
       *ngIf="hideHeader !== true && (chqModel?.isRepairModule !== true && chqModel?.isClaimModule !== true && chqModel?.isFnolModule !== true) ">
    <div class=" title-wrapper">
      <div class="circle-wrapper">
        <span>{{ chqModel?.step }}</span>
      </div>
      <div class="title-wrapper">
        <h4>{{ chqModel?.stepName | translate}}</h4>
        <label for="">{{ chqModel?.title | translate }}</label>
      </div>
    </div>
    <div class="description-wrapper">
      <label for="">{{ chqModel?.description | translate }}</label>
    </div>
  </div>
  <ng-container *chqPermission="'newBooking'">

    <div class="content-wrapper-left row-wrapper"
         (click)="handleBack()"
         *ngIf="hideHeader === true">
      <div class="d-flex align-center">
        <mat-icon svgIcon="arrow-back-left"
                  class="back-icon"
                  aria-hidden="false"
                  aria-label='back icon'></mat-icon>
      </div>
      <div class="title-wrapper back-text back-section title-text">
        <h4>{{(chqModel.title ==='Damage Analysis' && !backTitle ? 'uploaded_images': (backTitle || 'back')) | translate
          }}</h4>
      </div>
    </div>
    <div class="content-wrapper-left row-wrapper"
         (click)="backHandler()"
         *ngIf="(chqModel?.isRepairModule === true || chqModel.isClaimModule === true || chqModel?.isFnolModule) && !hideBack && !hideHeader && !chqModel.isClaimSurveyor">
      <div class="d-flex align-center">
        <mat-icon svgIcon="arrow-back-left"
                  class="back-icon"
                  aria-hidden="false"
                  *ngIf=" chqModel?.isHeaderOnly !== true"
                  aria-label='back icon'></mat-icon>
      </div>
      <div class="title-wrapper back-text back-section"
           *ngIf="chqModel?.showTitle === true"
           [ngClass]="{'show-header': chqModel?.isHeaderOnly, 'title-text': !chqModel?.isHeaderOnly}">
        <h4>{{chqModel?.title | translate }}</h4>
      </div>
    </div>
    <div *ngIf="hideBack"></div>
    <div class="content-wrapper-right"
         *ngIf="chqModel?.isRepairModule !== true && chqModel?.isClaimModule !== true && chqModel?.isFnolModule !== true">
      <div class="unique-id-wrapper"
           *ngIf="chqModel?.caseNumber">
        <h4>{{'case_id' | translate}}: {{chqModel.caseNumber}}</h4>
      </div>
      <div class="labour-button-wrapper"
           [ngClass]="{'d-none':this.hideLabourRate}">
        <chq-button class="ai-button"
                    *ngIf="showButton === true"
                    [buttonModel]="nextButtonModel"></chq-button>
        <span (click)="openLabourRatePopup()">{{'labour_rate_discount' | translate}}</span>
      </div>
    </div>

    <ng-container *ngIf="!chqModel.isClaimModule;">
      <div class="content-wrapper-right row-right"
           (click)="deleteAll();"
           *ngIf="(chqModel?.isRepairModule === true || chqModel?.isFnolModule === true) && !hideDelete && 
        chqModel?.isHeaderOnly !== true && this.repairStatus?.toLowerCase() !== 'complete'
        && (!this.commonService.isViewOnly || chqModel?.isFnolModule === true)">
        <div class="d-flex align-center">
          <mat-icon [svgIcon]="chqModel?.rightActionIcon"
                    aria-hidden="false"
                    aria-label='right icon'></mat-icon>
        </div>
        <div class="title-wrapper back-text back-section">
          <h4>{{chqModel?.rightActionText | translate}}</h4>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *chqPermission="'technician'">
    <div class="content-wrapper-left row-wrapper"
         (click)="handleBack()"
         *ngIf="hideHeader === true && (chqModel?.isRepairModule)">
      <div class="d-flex align-center">
        <mat-icon svgIcon="arrow-back-left"
                  class="back-icon"
                  aria-hidden="false"
                  aria-label='back icon'></mat-icon>
      </div>
      <div class="title-wrapper back-text back-section title-text">
        <h4>{{(chqModel.title ==='Damage Analysis' && !backTitle ? 'uploaded_images': (backTitle || 'back')) | translate
          }}</h4>
      </div>
    </div>
  </ng-container>
</div>