import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AiAnalysisService {


  /**
   * constructor
   * @param http
   */
  constructor(
    private http: HttpClient
  ) {
  }

  /**
   * get Parts
   * @returns
   */
  public getParts(): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Lookup/partName`;
    return this.http.get(url);
  }
  /**
   * get DamageType
   * @returns
   */
  public getDamageType(): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Lookup/damageName`;
    return this.http.get(url);
  }

  /**
   * get recommendation
   * @returns
   */
  public getrecommendation(): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Lookup/recommendation`;
    return this.http.get(url);
  }

  /**
   * get add Update DamageAnalysis
   * @returns
   */
  public addUpdateDamageAnalysis(data:any): Observable<any> {
    const url = `${environment.autoApi}/api/v1/addUpdateDamageAnalysis`;
    return this.http.post(url, data);
  }


  /**
   * Delete DamageAnalysis
   * @returns
   */
  public deleteDamageAnalysis(id:any): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Inspection/DeleteDamageDetail/${id}`;
    return this.http.delete(url);
  }


}
