import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanDeactivate, UrlTree } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { FormDataDialogComponent } from 'src/app/dialogs/form-data-dialog/form-data-dialog/form-data-dialog.component';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { ComponentCanDeactivateComponent } from './component-can-deactivate';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivateComponent> {

  /**
   * constructor
   */
  constructor(private dialog: MatDialog, private commonService:CommonService, private monitorService:MonitorService) {}

  /**
   * 
   * @param component 
   * @param currentRoute 
   * @param currentState 
   * @param nextState 
   * @returns 
   */
  canDeactivate(
    component: ComponentCanDeactivateComponent): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
   
    if(!component.canDeactivate()){
      this.monitorService.logEvent('canDeactivate', [ 'CanDeactivateGuard', 'addenda-quote' ]);
      const subject = new Subject<boolean>();
      this.commonService.updatePage('/quote/case/0');
      this.dialog.open(FormDataDialogComponent, {
        disableClose: true,
        data: { 
          subject,
          message: 'By navigating out of this screen you will lose the data you entered. Are you sure you want to continue?'
        }
      });
      return subject.asObservable();
    }
    return true;
  
  }
  
}
