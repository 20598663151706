import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export interface Terms {
  title: string;
  description: string;
}
@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: [ './terms-conditions.component.scss' ]
})

export class TermsConditionsComponent {
  terms: Array<Terms> = [
    {
      title: 'Data Protection Laws',
      description: 'Many countries have specific laws and regulations that govern the collection, processing, and storage of personal data. For the Gulf region, this includes the various national laws as well as any regional agreements.'
    },
    {
      title: 'Consent',
      description: 'It is often a requirement to obtain explicit consent from individuals before collecting their personal data. This consent should be informed, meaning the individuals should know what data is being collected and for what purposes.'
    },
    {
      title: 'Purpose Limitation',
      description: 'Data collected should be used for the purposes stated at the time of collection and not for any other undisclosed purposes.'
    },
    {
      title: 'Data Minimization',
      description: 'Only the minimum amount of data necessary for the stated purpose should be collected.'
    },
    {
      title: 'Accuracy',
      description: 'Efforts should be made to ensure that personal data is accurate, complete, and up-to-date.'
    },
    {
      title: 'Security',
      description: 'Adequate security measures should be in place to protect personal data from unauthorized access, disclosure, alteration, or destruction.'
    },
    {
      title: 'Data Retention',
      description: 'There should be limits on how long personal data is stored, typically related to the purpose for which it was collected.'
    },
    {
      title: 'Access and Correction',
      description: 'Individuals should have the right to access their data and correct it if it is wrong.'
    },
    {
      title: 'Cross-Border Data Transfer',
      description: 'When data is transferred across borders, it is important to ensure that the receiving country has adequate data protection laws in place.'
    },
    {
      title: 'Regulatory Notifications',
      description: 'If the data collection involves sensitive data, there may be requirements to notify or register with a data protection authority or other regulatory bodies.'
    },
    {
      title: 'Breach Notification',
      description: 'In the case of a data breach, there may be legal obligations to notify affected individuals and/or regulatory authorities within a certain time frame.'
    }
  ];

  /**
   * constructor
   * @param dialogRef 
   */
  constructor(
    public dialogRef: MatDialogRef<TermsConditionsComponent>
  ){}

  /**
 * close
 */
  close(): void {
    this.dialogRef.close();
  }
}
